import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel, List,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText, cardMediaClasses } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import MuiToggleButton from '@mui/material/ToggleButton';	
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import GlobalAlert from "./GlobalAlert";
import useToken from "./useToken";
import axios from "./axios";
import useInnerTable from "./components/useInnerTable";
import LoadingButton from '@mui/lab/LoadingButton';
import PuffLoader from "react-spinners/PuffLoader"; 
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
import TimezoneSelect from 'react-timezone-select'
import { BreakfastDiningOutlined } from "@mui/icons-material";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const ToggleButton = styled(MuiToggleButton)(({ selectedColor, unSelectedColor }) => ({	
  width: 'auto',	
  height: '2.46rem',	
  color: 'white',	
  backgroundColor: unSelectedColor,	
  border: '1px solid !important',
  borderColor : '#696969 !important' ,
  fontWeight: '700',	
          fontSize: '0.93rem',	
          textTransform: 'capitalize',	
            
          alignItems: 'center',	
          color: '#FFFFFF',	
          fontFamily: "Lato",	
          borderRadius:'0.31rem',	
  '&.Mui-selected, &.Mui-selected:hover': {	
    color: 'white',	
    backgroundColor: selectedColor,	
      
  },	
  '&:hover': {	
    color: 'white',	
    backgroundColor: unSelectedColor,	
      
  },	
}));

const ToggleButtonSmall = styled(MuiToggleButton)(({ selectedColor, unSelectedColor }) => ({	
  width: 'auto',	
  height: '2.46rem',	
  color: 'white',	
  backgroundColor: unSelectedColor,	
  border: '1px solid !important',
  borderColor : '#696969 !important' ,
  fontWeight: '500',	
          fontSize: '0.68rem',	
          textTransform: 'capitalize',	
            
          alignItems: 'center',	
          color: '#FFFFFF',	
          fontFamily: "Lato",	
          borderRadius:'0.31rem',	
  '&.Mui-selected, &.Mui-selected:hover': {	
    color: 'white',	
    backgroundColor: selectedColor,	
      
  },	
  '&:hover': {	
    color: 'white',	
    backgroundColor: unSelectedColor,	
      
  },	
}));

function RoleAccess ( props){
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const {token} = useToken();
  const {RefreshToken} = props;
  const { getAlertTitle} = GlobalAlert();
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const [showUpdateButton, setshowUpdateButton] = React.useState(false);
  const [filterText,setfilterText] = useState('');
  const [EnLog,setEnLog] = useState([]);
  let [loading, setLoading] = useState(true);
  let [loadingbtn, setloadingbtn] = useState(false);
  
  const [records,setRecords] = useState([]);
  const [recordsAdmin,setRecordsAdmin] = useState([]);
  const [recordsDistributor,setRecordsDistributor] = useState([]);
  const [recordsDealer,setRecordsDealer] = useState([]);
  const [recordsPropertyAdmin,setRecordsPropertyAdmin] = useState([]);
  const [recordsProperty,setRecordsProperty] = useState([]);
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const [filterFnProperty, setFilterFnProperty] = useState({ fn: items => { return items; } });
  const [currentTab,setCurrentTab] = useState(0);
  const [Tab,setTab] = useState(0);
  const [currentNumber,setCurrentNumber] = useState('');
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();
  const headCells = [
    
    { id: 'access_name', label: 'Access Name'},
    { id: 'administrator_control', label: 'Super Administrator'},
    { id: 'administrator_sub_control', label: 'Super Sub Administrator'},
    { id: 'distributor_control', label: 'Partner'},
    { id: 'distributor_sub_control', label: 'Sub Partner'},
    { id: 'dealer_control', label: 'Dealer'},
    { id: 'dealer_sub_control', label: 'Sub Dealer'},
    { id: 'admin_control', label: 'Account Admin'},
    { id: 'admin_sub_control', label: 'Account Sub Admin'},
    { id: 'action', label: 'Action', disableSorting: true},
  ];
  const headCellsProperty = [
    
    { id: 'access_name', label: 'Access Name'},
    { id: 'administrator_control', label: 'Super Administrator'},
    { id: 'administrator_sub_control', label: 'Super Sub Administrator'},
    { id: 'distributor_control', label: 'Partner'},
    { id: 'distributor_sub_control', label: 'Sub Partner'},
    { id: 'dealer_control', label: 'Dealer'},
    { id: 'dealer_sub_control', label: 'Sub Dealer'},
    { id: 'admin_control', label: 'Account Admin'},
    { id: 'admin_sub_control', label: 'Account Sub Admin'},
    { id: 'action', label: 'Action', disableSorting: true},
  ];
  const {
    TblContainer,
    TblHead,
    TblPagination ,
    BelowTblPagination,
    recordsAfterPagingAndSorting ,
    setPageToNewOne ,
  } = useInnerTable(records, headCells, filterFn,10,650);
  const [currentSelection, setCurrentSelection] = useState("0");
  const {
    TblContainer : TblPContainer,
    TblHead :  TblPHead,
    TblPagination : TblPPagination,
    BelowTblPagination : BelowPTblPagination,
    recordsAfterPagingAndSorting : recordsAfterPPagingAndSorting,
    setPageToNewOne : setPPageToNewOne 
  } = useInnerTable(recordsProperty, headCellsProperty, filterFnProperty,10,650);

  const getMyPostData = async () => {
    try {
      const res = await axios.get("/administrator/roleaccess/",{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email },
      });
      console.log("data format"+JSON.stringify( res.data));
      setEnLog(res.data);
      let arrAdmin = [];
      let arrDistributor = [];
      let arrDealer = [];
      let arrPropertyAdmin = [];
      for(let i = 0; i < res.data.length ; i++){
        if( res.data[i].type == 0 ){
          arrAdmin.push(res.data[i]);
        }
        else if (res.data[i].type == 1 ){
          arrDistributor.push(res.data[i]);
        }
        else if (res.data[i].type == 2 ){
          arrDealer.push(res.data[i]);
        }
        else if (res.data[i].type == 3 ){
          arrPropertyAdmin.push(res.data[i]);
        }
    }
    setRecordsAdmin(arrAdmin);
    setRecordsDistributor(arrDistributor);
    setRecordsDealer(arrDealer);
    setRecordsPropertyAdmin(arrPropertyAdmin);
    if(currentSelection == "0"){
      setRecords(arrAdmin);
    }
    else if(currentSelection == "1"){
      setRecords(arrDistributor);
    }
    else if(currentSelection == "2"){
      setRecords(arrDealer);
    }
    else if(currentSelection == "3"){
      setRecords(arrPropertyAdmin);
    }
      
     
      setLoading(false);
      setloadingbtn(false);
    } catch (error) {
      console.log(error.response);
      setLoading(false);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  useEffect(() => {
    getMyPostData();
  }, []);
  /*useEffect(() => {
    
  }, [currentTab]);*/
 
  const updateMyRoleAccessPostData = async (val) => {
    console.log(val);
    setloadingbtn(true);
    try {
      const res = await axios.patch("/administrator/updateroleaccess/",val, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('RBACUS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      getMyPostData();
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setOpenSnackData(getAlertTitle('RBACUF'));
      setSeverityData("error");
      setOpenSnack(true);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };


  
  
  const updateMyRoleAccessInnerPostData = async (val) => {
    console.log(val);
    setloadingbtn(true);
    try {
      const res = await axios.patch("/administrator/updateroleaccessinner/",val, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('RBACUS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      getMyPropertyData(currentIdOfUpper);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setOpenSnackData(getAlertTitle('RBACUF'));
      setSeverityData("error");
      setOpenSnack(true);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const getMyPropertyData = async (val) => {
    try {
        const res = await axios.get("/administrator/roleaccessinner/"+val,{
          headers: { 'Content-Type':  'application/json',
          'token' : token.token,
          'tokenid' : token.email },
        });
        console.log("data format"+res.data);
        
        setRecordsProperty(res.data);
        setLoading(false);
        setloadingbtn(false);
      
        setTab(1);
      } catch (error) {
        console.log(error.message);
        setLoading(false);
        setloadingbtn(false);
        if(error.response.data.message == 'UnAuthorized'){
          RefreshToken();
        }
      }
  };
  
  
  const handleAccessModify = (event) => {
    let idIn;
    for(let i = 0; i < records.length ; i++){
      if(records[i].id == event.target.name){
          idIn = i;
          break;
      }
    }
    let asv = { id:records[idIn].id,access_name:records[idIn].access_name,head:currentSelectionName,administrator_control:records[idIn].administrator_control,administrator_sub_control:records[idIn].administrator_sub_control,distributor_control:records[idIn].distributor_control,distributor_sub_control:records[idIn].distributor_sub_control,dealer_control:records[idIn].dealer_control,dealer_sub_control:records[idIn].dealer_sub_control,admin_control:records[idIn].admin_control,admin_sub_control:records[idIn].admin_sub_control,issub:token.issub,subId:token.subid};
    
    console.log('asv value is '+JSON.stringify(asv));
    updateMyRoleAccessPostData(asv);
  }
  const handleAccessModifyInner = (event) => {
    let idIn;
    for(let i = 0; i < recordsProperty.length ; i++){
      if(recordsProperty[i].id == event.target.name){
          idIn = i;
          break;
      }
    }
    let asv = { id:recordsProperty[idIn].id,head:currentSelectionName,headinner:currentNumber,access_name:recordsProperty[idIn].access_name,administrator_control:recordsProperty[idIn].administrator_control,administrator_sub_control:recordsProperty[idIn].administrator_sub_control,distributor_control:recordsProperty[idIn].distributor_control,distributor_sub_control:recordsProperty[idIn].distributor_sub_control,dealer_control:recordsProperty[idIn].dealer_control,dealer_sub_control:recordsProperty[idIn].dealer_sub_control,admin_control:recordsProperty[idIn].admin_control,admin_sub_control:recordsProperty[idIn].admin_sub_control,issub:token.issub,subId:token.subid};
    
    console.log('asv value is '+JSON.stringify(asv));
    updateMyRoleAccessInnerPostData(asv);
  }
  const handlecheckboxCheckAdminControl=(event) =>{
    let val = event.target.id;
    let controlname = event.target.name;
    let checkid;
    for(let i = 0; i < records.length ; i++){
        if(records[i].id == val ){
            checkid = i;
            break;
        }
    }
    console.log("event valye is "+ event.target.checked + " and id is "+ checkid);
    records[checkid][controlname] = event.target.checked == true ? "1" : "0";
    setRecords(records);
  }
 
  const handlecheckboxCheckAdminControlInner=(event) =>{
    let val = event.target.id;
    let controlname = event.target.name;
    let checkid;
    for(let i = 0; i < recordsProperty.length ; i++){
        if(recordsProperty[i].id == val ){
            checkid = i;
            break;
        }
    }
    console.log("event valye is "+ event.target.checked + " and id is "+ checkid);
    recordsProperty[checkid][controlname] = event.target.checked == true ? "1" : "0";
    setRecordsProperty(recordsProperty);
  }
  const [dataOfAdd,setDataOfAdd] = useState('');
  const handleLPBlackChange = (event) => {
    setDataOfAdd(event.target.value);
  }
  const handleAddNumber = () => {
   /* if((dataOfAdd.trim()).length != 3){
      setOpenSnackData('Area Code Should Be 3 Digit Only.');
    setSeverityData("error");
    setOpenSnack(true);
    }
    else{
      getTwilioNumber();
    }
    */
  }
  const [currentIdOfUpper, setCurrentIdOfUpper] = useState('');
  const ChangeId = (val) =>{
    console.log('val is '+val);
    /*setCurrentTab(1);
    setTab(1);
    setCurrentNumber(val);
    console.log('curremt tab os' +currentTab);
    console.log('curremt numb os' +currentNumber);
    console.log('curremt Tab os' +Tab);*/
      let checkid;
    for(let i = 0; i < records.length ; i++){
        if(records[i].id == val ){
            checkid = i;
            break;
        }
    }
    setCurrentIdOfUpper(val);
     setCurrentNumber(records[checkid].access_name);
    setRecordsProperty([]);
    setCurrentTab(1);
    setTab(1);
    setLoading(true);
    getMyPropertyData(val); 
  }
  const ChangeTab = () => {
    setTab(0);
    setPPageToNewOne();
  }

  const getDisableValue = (val) => {
    if(token.role == 'superadmin'){
      console.log("token sub type" + token.sub_type);
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("20").administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("20").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }

  const handleChangeText = e =>{
    setfilterText(e.target.value);
  }
  const handleReset =() =>{
    setfilterText('');
    if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    
          setFilterFn({
            fn: items => {
              return items;
                    
            }
        })
        setPageToNewOne();
  }
  const handleSearch = () => {
    //let target = e.target;
    setFilterFn({
        fn: items => {
            if (filterText == "")
                return items;
            else{
              
               
                return items.filter(x => x.access_name.toString().toLowerCase().includes(filterText.toLowerCase()))
                
               
                
              
             
            }
                
        }
    })
    setPageToNewOne();
}
const [currentSelectionName,setCurrentSelectionName] = useState('Administrator');
const handleChangeMDMType = (event, newAlignment) => {	
  setCurrentSelection(newAlignment);
  
  if(newAlignment == "0"){
    setRecords(recordsAdmin);
    setCurrentSelectionName('Administrator');
  }
  else if(newAlignment == "1"){
    setRecords(recordsDistributor);
    setCurrentSelectionName('Partner');
  }
  else if(newAlignment == "2"){
    setRecords(recordsDealer);
    setCurrentSelectionName('Dealer');
  }
  else if(newAlignment == "3"){
    setRecords(recordsPropertyAdmin);
    setCurrentSelectionName('Account Admin');
  }
  setPageToNewOne();
}
  return <> <Box sx={mainThemeStyle.boxTopHeader}>
   {/*  {(Tab == 0 && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroup} active>Identity</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} >Manage User Access</Button>
    </Stack> )}
    {(Tab == 1 && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} >Identity</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} active>Manage User Access</Button>
    </Stack> )} */}
</Box>
{(Tab == 0 && <Box sx={mainThemeStyle.boxHeaderBelow}>

           <Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px',display: { xs: "none", md: "flex" }}}>
               <div >
                <Stack direction='row' spacing={2} padding={2}>
                  <Typography></Typography>
                <ToggleButtonGroup
      
      value={currentSelection}
      exclusive
      onChange={handleChangeMDMType}
      aria-label="large button group"
    >
      <ToggleButton  height = "40px" value="0" selectedColor="#24A0ED" unSelectedColor="gray" >Administrator</ToggleButton>
      <ToggleButton   height = "40px" value="1" selectedColor="#24A0ED" unSelectedColor="gray">Partner</ToggleButton>
      <ToggleButton   height = "40px" value="2" selectedColor="#24A0ED" unSelectedColor="gray">Dealer</ToggleButton>
      <ToggleButton   height = "40px" value="3" selectedColor="#24A0ED" unSelectedColor="gray">Account Admin</ToggleButton>

    </ToggleButtonGroup>
      </Stack>
               </div>
              
           </Box>
           <Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px',display: { xs: "flex", md: "none" }}}>
               <div >
               <Stack direction='row' spacing={2} padding={2}>
                 
                <ToggleButtonGroup
      
      value={currentSelection}
      exclusive
      onChange={handleChangeMDMType}
      aria-label="large button group"
    >
      <ToggleButtonSmall  height = "40px" value="0" selectedColor="#24A0ED" unSelectedColor="gray" >Administrator</ToggleButtonSmall>
      <ToggleButtonSmall   height = "40px" value="1" selectedColor="#24A0ED" unSelectedColor="gray">Partner</ToggleButtonSmall>
      <ToggleButtonSmall   height = "40px" value="2" selectedColor="#24A0ED" unSelectedColor="gray">Dealer</ToggleButtonSmall>
      <ToggleButtonSmall   height = "40px" value="3" selectedColor="#24A0ED" unSelectedColor="gray"> Admin</ToggleButtonSmall>

    </ToggleButtonGroup>
      </Stack>
               </div>
              
           </Box>
           <Divider sx={{backgroundColor:'white'}} />
          
           <Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px', display: { xs: "none", md: "flex" }}}>
               <div >
             <Stack direction='row' spacing={2} padding={2}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Find Access Control
                   </Typography>
                   <TextField
        id="input-location"
        type="text"
      placeholder="Access Name"
      sx={mainThemeStyle.InputMenuItem}
      onChange={handleChangeText}
       variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && filterText != '') {
                          handleSearch();
                        }
                      }}
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />
      
      <Button sx={mainThemeStyle.normalButtonDown}  onClick={handleSearch} disabled={!filterText}> Go </Button>
      <Button sx={mainThemeStyle.normalButtonDown}  onClick={handleReset}> Reset Filter </Button>
      </Stack>  
     
               </div>
              
           </Box>
           <Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px',display: { xs: "flex", md: "none" }}}>
               <Stack direction='column' spacing={2} >
                <Stack direction='row' spacing={2} padding={1}>
                   <Typography variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>
                   Find Access Control
                   </Typography>
                   <TextField
        id="input-location"
        type="text"
      placeholder="Access Name"
      sx={mainThemeStyle.InputMenuItem}
      onChange={handleChangeText}
       variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                          style={{paddingTop: '16px'}}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' && filterText != '') {
                              handleSearch();
                            }
                          }}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /> </Stack><Stack direction='row' spacing={2} >
        <Typography></Typography>
        <Button sx={mainThemeStyle.normalButtonDown}  onClick={handleSearch} disabled={!filterText}> Go </Button>
      <Button sx={mainThemeStyle.normalButtonDown}  onClick={handleReset}> Reset Filter </Button>
      </Stack>
               </Stack>
              
           </Box>
        {/*    <Divider sx={{backgroundColor:'white'}} /> */}
   
        <TblContainer>
                    <TblHead />

        <TableBody>
         {recordsAfterPagingAndSorting().map(item => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              
              <TableCell align="left"  sx={mainThemeStyle.cellRow}>
             {item.inner == 1 &&  <Typography  sx={ mainThemeStyle.cellGreenClickableBig} onClick={() => {ChangeId(item.id)}}> {item.access_name}</Typography>}
             {item.inner == 0 &&  <Typography  sx={ mainThemeStyle.cellBlue} > {item.access_name}</Typography>}
            
                </TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}>
              <Checkbox  sx={mainThemeStyle.formCheckBoxLow}  id={item.id} name="administrator_control"   disabled = {getDisableValue("")} onChange={handlecheckboxCheckAdminControl} defaultChecked={item.administrator_control== "1" ? true : false } />
                 </TableCell>
                 
                 <TableCell align="left"  sx={mainThemeStyle.cellRow}>
              <Checkbox  sx={mainThemeStyle.formCheckBoxLow}  id={item.id} name="administrator_sub_control"  disabled = {getDisableValue("")} onChange={handlecheckboxCheckAdminControl} defaultChecked={item.administrator_sub_control== "1" ? true : false } />
                 </TableCell>

                 <TableCell align="left"  sx={mainThemeStyle.cellRow}>
            {item.type > 0 &&  <Checkbox  sx={mainThemeStyle.formCheckBoxLow}  id={item.id} name="distributor_control"  disabled = {getDisableValue("")} onChange={handlecheckboxCheckAdminControl} defaultChecked={item.distributor_control== "1" ? true : false } />}
            {item.type < 1 &&<Typography align="left" sx={mainThemeStyle.cellRow}>N/A</Typography>}
                 </TableCell>

                 <TableCell align="left"  sx={mainThemeStyle.cellRow}>
                 {item.type > 0 && <Checkbox  sx={mainThemeStyle.formCheckBoxLow}  id={item.id} name="distributor_sub_control"  disabled = {getDisableValue("")} onChange={handlecheckboxCheckAdminControl} defaultChecked={item.distributor_sub_control== "1" ? true : false } />}
              {item.type < 1 &&<Typography align="left" sx={mainThemeStyle.cellRow}>N/A</Typography>}
                 </TableCell>

                 <TableCell align="left"  sx={mainThemeStyle.cellRow}>
               {item.type > 1 && <Checkbox  sx={mainThemeStyle.formCheckBoxLow}  id={item.id} name="dealer_control"  disabled = {getDisableValue("")} onChange={handlecheckboxCheckAdminControl} defaultChecked={item.dealer_control== "1" ? true : false } />}
              {item.type < 2 &&<Typography align="left" sx={mainThemeStyle.cellRow}>N/A</Typography>}
                 </TableCell>

                 <TableCell align="left"  sx={mainThemeStyle.cellRow}>
               {item.type > 1 && <Checkbox  sx={mainThemeStyle.formCheckBoxLow}  id={item.id} name="dealer_sub_control"  disabled = {getDisableValue("")} onChange={handlecheckboxCheckAdminControl} defaultChecked={item.dealer_sub_control== "1" ? true : false } />}
              {item.type < 2 &&<Typography align="left" sx={mainThemeStyle.cellRow}>N/A</Typography>}
                 </TableCell>

                 <TableCell align="left"  sx={mainThemeStyle.cellRow}>
               {item.type > 2 && <Checkbox  sx={mainThemeStyle.formCheckBoxLow}  id={item.id} name="admin_control" onChange={handlecheckboxCheckAdminControl} defaultChecked={item.admin_control== "1" ? true : false } />}
              {item.type < 3 &&<Typography align="left" sx={mainThemeStyle.cellRow}>N/A</Typography>}
                 </TableCell>

                 <TableCell align="left"  sx={mainThemeStyle.cellRow}>
               {item.type > 2 && <Checkbox  sx={mainThemeStyle.formCheckBoxLow}  id={item.id} name="admin_sub_control"  disabled = {getDisableValue("")} onChange={handlecheckboxCheckAdminControl} defaultChecked={item.admin_sub_control== "1" ? true : false } />}
              {item.type < 3 &&<Typography align="left" sx={mainThemeStyle.cellRow}>N/A</Typography>}
                 </TableCell>

                 <TableCell align="left">
                  
               <LoadingButton sx={mainThemeStyle.viewLogButton} name={item.id} loading={loadingbtn} onClick={handleAccessModify}  disabled = {getDisableValue("")}> Save Changes </LoadingButton>
                 </TableCell>
            </TableRow>
          ))}
        </TableBody>
      
    </TblContainer>
   
    <PuffLoader color="#878787" loading={loading} cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"50px",
    marginBottom:"50px",}} />
  <BelowTblPagination ></BelowTblPagination>




</Box>)}
{(Tab == 1 && <Box sx={mainThemeStyle.boxHeaderBelow}>

          
           
          
          <Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px',display:{xs:'none',md:'flex'}}}>
               <div>
        
       <Stack direction='row' spacing={2} padding={2}>
     <Typography></Typography>
                   <Button sx={mainThemeStyle.normalButtonDown}  onClick={ChangeTab}> Go Back</Button>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                  {currentSelectionName} {currentNumber} Inner Elements
                   </Typography>
      </Stack>
        
      
               </div>
              
           </Box>
           <Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px',display:{xs:'flex',md:'none'}}}>
               <div>
        
       <Stack direction='row' spacing={2} >
     <Typography></Typography>
                   <Button sx={{
                    width: 'auto',
                    height: '2.06rem',
                    top:'1.5rem',
                    
                    fontWeight: '700',
                    fontSize: '0.83rem',
                    lineHeight:'0.93rem',
                    
                    alignItems: 'center',
                    color: '#FFFFFF',
                    fontFamily: "Lato",
                    borderRadius:'0.31rem',
                   '&:hover': {
                    backgroundColor: 'rgba(109, 195, 252, 1)',
                   }
                   }}  onClick={ChangeTab}> Go Back</Button>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                   {currentSelectionName} {currentNumber} Inner Elements

                   </Typography>
      </Stack>
        
      
               </div>
              
           </Box>
            <Divider sx={{backgroundColor:'white'}} /> 
   
         
       <TblPContainer>
                    <TblPHead />

        <TableBody>
         {recordsAfterPPagingAndSorting().map(item => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              
              <TableCell align="left"  sx={mainThemeStyle.cellRow}>
             <Typography  sx={ mainThemeStyle.cellBlue} > {item.access_name}</Typography>
            
                </TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}>
              <Checkbox  sx={mainThemeStyle.formCheckBoxLow}  id={item.id} name="administrator_control"  disabled = {getDisableValue("")} onChange={handlecheckboxCheckAdminControlInner} defaultChecked={item.administrator_control== "1" ? true : false } />
                 </TableCell>
                 
                 <TableCell align="left"  sx={mainThemeStyle.cellRow}>
              <Checkbox  sx={mainThemeStyle.formCheckBoxLow}  id={item.id} name="administrator_sub_control"  disabled = {getDisableValue("")} onChange={handlecheckboxCheckAdminControlInner} defaultChecked={item.administrator_sub_control== "1" ? true : false } />
                 </TableCell>

                 <TableCell align="left"  sx={mainThemeStyle.cellRow}>
            {item.type > 0 &&  <Checkbox  sx={mainThemeStyle.formCheckBoxLow}  id={item.id} name="distributor_control"  disabled = {getDisableValue("")} onChange={handlecheckboxCheckAdminControlInner} defaultChecked={item.distributor_control== "1" ? true : false } />}
            {item.type < 1 &&<Typography align="left" sx={mainThemeStyle.cellRow}>N/A</Typography>}
                 </TableCell>

                 <TableCell align="left"  sx={mainThemeStyle.cellRow}>
                 {item.type > 0 && <Checkbox  sx={mainThemeStyle.formCheckBoxLow}  id={item.id} name="distributor_sub_control"  disabled = {getDisableValue("")} onChange={handlecheckboxCheckAdminControlInner} defaultChecked={item.distributor_sub_control== "1" ? true : false } />}
              {item.type < 1 &&<Typography align="left" sx={mainThemeStyle.cellRow}>N/A</Typography>}
                 </TableCell>

                 <TableCell align="left"  sx={mainThemeStyle.cellRow}>
               {item.type > 1 && <Checkbox  sx={mainThemeStyle.formCheckBoxLow}  id={item.id} name="dealer_control"  disabled = {getDisableValue("")} onChange={handlecheckboxCheckAdminControlInner} defaultChecked={item.dealer_control== "1" ? true : false } />}
              {item.type < 2 &&<Typography align="left" sx={mainThemeStyle.cellRow}>N/A</Typography>}
                 </TableCell>

                 <TableCell align="left"  sx={mainThemeStyle.cellRow}>
               {item.type > 1 && <Checkbox  sx={mainThemeStyle.formCheckBoxLow}  id={item.id} name="dealer_sub_control"  disabled = {getDisableValue("")}  onChange={handlecheckboxCheckAdminControlInner} defaultChecked={item.dealer_sub_control== "1" ? true : false } />}
              {item.type < 2 &&<Typography align="left" sx={mainThemeStyle.cellRow}>N/A</Typography>}
                 </TableCell>

                 <TableCell align="left"  sx={mainThemeStyle.cellRow}>
               {item.type > 2 && <Checkbox  sx={mainThemeStyle.formCheckBoxLow}  id={item.id} name="admin_control"  disabled = {getDisableValue("")} onChange={handlecheckboxCheckAdminControlInner} defaultChecked={item.admin_control== "1" ? true : false } />}
              {item.type < 3 &&<Typography align="left" sx={mainThemeStyle.cellRow}>N/A</Typography>}
                 </TableCell>

                 <TableCell align="left"  sx={mainThemeStyle.cellRow}>
               {item.type > 2 && <Checkbox  sx={mainThemeStyle.formCheckBoxLow}  id={item.id} name="admin_sub_control"  disabled = {getDisableValue("")} onChange={handlecheckboxCheckAdminControlInner} defaultChecked={item.admin_sub_control== "1" ? true : false } />}
              {item.type < 3 &&<Typography align="left" sx={mainThemeStyle.cellRow}>N/A</Typography>}
                 </TableCell>

                 <TableCell align="left">
                  
               <LoadingButton sx={mainThemeStyle.viewLogButton} name={item.id} loading={loadingbtn} onClick={handleAccessModifyInner}  disabled = {getDisableValue("")}> Save Changes </LoadingButton>
                 </TableCell>
            </TableRow>
          ))}
        </TableBody>
      
    </TblPContainer>
    <PuffLoader color="#878787" loading={loading} cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"50px",
    marginBottom:"50px",}} />
  

   <BelowPTblPagination ></BelowPTblPagination> 


</Box>)}
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
 </> 
          
          
          
          
          ;
};

export default RoleAccess;