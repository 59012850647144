import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel, List,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText, cardMediaClasses } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useTable from "./components/useTable";
import useInnerTable from "./components/useInnerTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import useToken from "./useToken";
import { useNavigate } from "react-router-dom";
import TimezoneSelect from 'react-timezone-select';
import axios from "./axios";
import PuffLoader from "react-spinners/PuffLoader"; 
import GlobalAlert from "./GlobalAlert";
import LoadingButton from '@mui/lab/LoadingButton';
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function GlobalAlertView ( props){
  const {token} = useToken();
  const { getAlertTitle} = GlobalAlert();
  const {RefreshToken} = props;
  const [EnLog,setEnLog] = useState([]);
  let [loading, setLoading] = useState(true);
  const [filterText, setfilterText] = useState('');
  let [loadingbtn,setloadingbtn] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const myContainer = useRef(null);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const [arrayOfChange, setArrayOfChange] = useState(EnLog);
  let Alphabet =['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();
  const navigate = useNavigate();
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const getMyPostData = async () => {
    try {
      const res = await axios.get("/administrator/globalalert/"+token.id,{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email },
      });
      console.log(res.data);
      setEnLog(res.data);
      setArrayOfChange(res.data);
      setLoading(false);
      setloadingbtn(false);

    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const headCells = [
    { id: 'type', label: 'Alert Type' },
    { id: 'message', label: 'Alert Message' },
    { id: 'access', label: 'Action', disableSorting: true },
  ];
  const {
    TblContainer ,
    TblHead ,
    TblPagination ,
    BelowTblPagination,
    recordsAfterPagingAndSorting ,
    setPageToNewOne 
  } = useTable(EnLog, headCells, filterFn,"Download Alert Dialouge");

  // NOTE:  calling the function
  useEffect(() => {
    getMyPostData();
  }, []);
  const getDisableValue = (val) => {
    if(token.role == 'superadmin'){
      console.log("token sub type" + token.sub_type);
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("16").administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("16").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const [currentGroupName,setCurrentGroupName] = useState('');
  const [titleforalert, setTitleforalert] = React.useState("");
  const [currentDeleteID, setCurrentDeleteID] = useState('');
  
  const checkAtOrNot = (value) =>{
    let ad = EnLog.filter(x => x.type.toLowerCase().startsWith(value.toLowerCase()));
    console.log(ad.length);
    return ad.length;
 }


  const tableGroupNameChange = (event) =>{
    let ds = event.target.name;
    let idIn;
    for(let i = 0; i < arrayOfChange.length ; i++){
      if(arrayOfChange[i].id == ds ){
          idIn = i;
          break;
      }
    }
    arrayOfChange[idIn].message = event.target.value;
    setArrayOfChange(arrayOfChange);
  }
  const updateGroupPost = async (val) => {
    console.log("In update post data");
    setloadingbtn(true);
    try {
      const res = await axios.patch("/administrator/globalalert/"+token.id,val, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,
      'token' : token.token,
      'tokenid' : token.email}});
      setOpenSnackData('Alert Message Successfully Updated.');
      setSeverityData("success");
      setOpenSnack(true);
      //setLoading(false);
      //setshowUpdateButton(false);
      console.log(res.data);
      getMyPostData();
     
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setloadingbtn(false);
      setOpenSnackData('Alert Message Updated Failed, Please Try Again Later.');
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };
  const handleChangeText = e => {
    console.log(e.target.value);
    let value = e.target.value;
    setfilterText(value);
    console.log(filterText);
}
  const handleTableNameSave = (event) => {
    let ds = event.target.name;
    let idIn;
    for(let i = 0; i < arrayOfChange.length ; i++){
      if(arrayOfChange[i].id == ds ){
          idIn = i;
          break;
      }
    }
    console.log(EnLog);
    console.log(arrayOfChange);
    let val = arrayOfChange[idIn].type;
        if(val.length <=  0){
          setOpenSnackData("Alert Message Can't Be Null, Please Enter Alert Message.");
          setSeverityData("error");
          setOpenSnack(true);
        }
        else{
          let jsd = { "id":arrayOfChange[idIn].id,
          "type":arrayOfChange[idIn].type,
          "message":arrayOfChange[idIn].message,
            "issub":token.issub,
            "subId":token.subid};
          updateGroupPost(jsd);
        }
   
   
  }
  const [open, setOpen] = React.useState(false);
  const [currentDelteGroupName, setCurrentDelteGroupName] = React.useState('');
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseActive =() =>{
    setOpen(false);
    //deleteMyGroupPostData();
  }
  const [showitemType, setShowItemType] = useState(true);
  const [showitemMessage, setShowItemMessage] = useState(false);
  const FilterMenu = {
    id: "1",
    label: "Alert Type",
    items: [
      {
        label: "Alert Type",
        callback: () =>  {
            setShowItemType(true);
            setShowItemMessage(false);
         if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    
          setfilterText('');
        }
      },
      {
        label: "Alert Message",
        callback: () =>  {
            setShowItemType(false);
            setShowItemMessage(true);
         if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    
          setfilterText('');
        }
      },
    ]
    }
    const handleReset =() =>{
        setfilterText('');
        if(document.getElementById('input-location') != null)
        document.getElementById('input-location').value ='';
       
        
              setFilterFn({
                fn: items => {
                  return items;
                        
                }
            })
            setPageToNewOne();
      }
      const jumpToAlphabet = (val) =>{
        console.log('value is '+val);
        setFilterFn({
          fn: items => {
            return items.filter(x => x.type.toLowerCase().startsWith(val.toLowerCase()))
                  
          }
      })
      setPageToNewOne();
      }
      const handleSearch = () => {
        //let target = e.target;
        setFilterFn({
            fn: items => {
                if (filterText == "")
                    return items;
                else if(showitemType){
                  
                    return items.filter(x => x.type.toString().toLowerCase().includes(filterText.toLowerCase()))
    
                  }
                  
                  else if(showitemMessage){
                     return items.filter(x => x.message.toString().toLowerCase().includes(filterText.toLowerCase()))
                    }
                    else{
                      return items;
                    }
                  }
                
                    
            })
        setPageToNewOne();
    }
  return <> <Box><Typography sx={mainThemeStyle.lablefilter}>Filter</Typography>
  
  <Box sx={{position: 'absolute',
           width:'680px',
           height: '280px',
           top:'160px',
           left: '38px',
           display: { xs: "none", md: "flex" },
           right: '38px',
           flexGrow: 1,
          
           }}>
             
           <Stack direction="row" spacing={2} >
                  
                   <NestedButtonDropdown
            itemId={FilterMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={FilterMenu}
          /> 
           
          <Typography sx={mainThemeStyle.lableContains}>Contains</Typography>
                
                   
          <TextField
                        id="input-location"
                        ref={myContainer}
                        type= 'text'
                        name="text"
                        onChange={handleChangeText}
                        sx={mainThemeStyle.InputFilter}
                        InputLabelProps={{ shrink: true }}
                        hiddenLabel
                        variant="filled"
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && filterText != '') {
                            handleSearch();
                          }
                        }}
                        InputProps={{
                           
                            sx: {
                                
                                color: 'rgba(255, 255, 255, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#171E29',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '5px 0 5px 0',
                                textIndent: '15px !important',
                                border: `1px solid rgba(31, 39, 54, 1)`,
                                
                                
                            },
                        }}
                       
                    />

                   
                   <Button sx={mainThemeStyle.filterResetButton} onClick={handleSearch} disabled={!filterText}>Go</Button>
                   
                   <Button sx={mainThemeStyle.filterResetButton} onClick={handleReset}>Reset Filters</Button>
                  </Stack>
                  </Box>
          
  <Box sx={{position: 'absolute',
           width:'auto',
           height: '280px',
           top:'160px',
           left: '38px',
           display: { xs: "flex", md: "none" },
           right: '38px',
           flexGrow: 1,
          
           }}>
             <Grid container>
             <Grid item >
           <Stack direction="row" spacing={2} >
                  
                   <NestedButtonDropdown
            itemId={FilterMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={FilterMenu}
          /> 
           
         {/*  <Typography sx={mainThemeStyle.lableContains}>Contains</Typography> */}
                


                   
                   <TextField
                        id="input-location"
                        ref={myContainer}
                        type= 'text'
                        name="text"
                        onChange={handleChangeText}
                        sx={mainThemeStyle.InputFilterSmall}
                        InputLabelProps={{ shrink: true }
                      }
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && filterText != '') {
                          handleSearch();
                        }
                      }}
                        InputProps={{
                           
                            sx: {
                                
                                color: 'rgba(255, 255, 255, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#171E29',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '5px 0 5px 0',
                                textIndent: '15px !important',
                                border: `1px solid rgba(31, 39, 54, 1)`,
                               
                                
                            },
                        }}
                       
                    /></Stack></Grid>
                   <Grid >
                   <Stack direction="row" justifyContent="right"
  alignItems="right">
                   <Button sx={mainThemeStyle.filterResetButtonSmall} onClick={handleSearch} disabled={!filterText}>Go</Button>
                   
                   <Button sx={mainThemeStyle.filterResetButtonSmall} onClick={handleReset}>Reset Filters</Button>
                   </Stack>
                 </Grid></Grid></Box>
  
  
                 <Typography sx={mainThemeStyle.lableJump}>Jump</Typography>
                 
                 <ButtonGroup variant="text"  sx={{position: 'absolute',
           maxWidth:'250px',
           height: 'auto',
           top:'250px',
           left: '38px',
           display: { xs: "none", md: "flex" }
           }}>

          

          {
            Alphabet.map(item => (
              checkAtOrNot(item) > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  onClick={() => {jumpToAlphabet(item)}}>{item}</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>{item}</Button>
            ))
          }

       
     

    </ButtonGroup>
    
   <Box sx={mainThemeStyle.mainTablebox} backgroundColor="menubarprimary.main">

   <BelowTblPagination ></BelowTblPagination>
           <TblContainer>
           <TblHead />
        <TableBody>
        {recordsAfterPagingAndSorting().map(item => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
             <TableCell  align="left" sx={mainThemeStyle.cellRow}> {item.type}</TableCell>
              <TableCell align="left" sx={mainThemeStyle.InputFilter}>
              <TextField
        id="text"
        type="text"
        hiddenLabel
        name={item.id}
        defaultValue={item.message}
       variant="filled"
       
       onChange={tableGroupNameChange}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("")}
                        InputProps={{
                           
                            sx: {
                                minWidth:"500px",
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '5px 0 5px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
              </TableCell>
              <TableCell align="left"> 
                                    
            <LoadingButton  sx={mainThemeStyle.viewLogButton}  loading={loadingbtn} name={item.id} onClick={handleTableNameSave} disabled = {getDisableValue("")}>Save Changes</LoadingButton>
                              
              </TableCell>
   
              
            </TableRow>
          ))}
        </TableBody>
     
    </TblContainer>
{/* <BelowTblPagination></BelowTblPagination> 
    <PuffLoader color="#878787" loading={loading} cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"50px",
    marginBottom:"50px",}} />*/}
<Box sx={{marginTop:'10px'}}>
                <PuffLoader color="#878787" loading={loading} cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"50px",
    marginBottom:"50px",}} />



</Box></Box>
                 </Box>
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleClose} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActive} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
 </> 
          
          
          
          
          ;
};

export default GlobalAlertView;