import React from "react";
import { Box } from "@mui/system";
import { Button, Grid, InputLabel, List,Dialog, Typography,ButtonGroup,DialogContent,DialogActions ,DialogTitle,IconButton,Snackbar,Alert, Icon,FormControlLabel ,FormControl ,FormLabel ,Radio , RadioGroup , Checkbox  } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format, isThisSecond } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import GlobalAlert from "./GlobalAlert";
import axios from "./axios";
import PuffLoader from "react-spinners/PuffLoader"; 
import useToken from "./useToken";
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";

const headCells = [
  { id: 'loc_name', label: 'Device Name' },
  { id: 'loc_username', label: 'User Name' },
  { id: 'loc_user_type', label: 'User Type' },
  { id: 'guest_name', label: 'Guest Name' },
  { id: 'guest_pin', label: 'Guest PIN' },
  { id: 'phone_number', label: 'Guest Phone#' },
  { id: 'pin_type', label: 'PIN Type' },
  { id: 'pin_use_time', label: 'PIN Use Time' },
  { id: 'pin_expiry_date', label: 'Exp. Date' },
  { id: 'status', label: 'Status' },
  { id: 'action', label: 'Action', disableSorting: true },
]

let TYPE = ['Android','iOS'], GuestPINTYPE = ['One-Time Guest','Limited Guest','Unlimited Guest'],StatusTYPE = ['Expired','Active'];
let Alphabet =['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
/*for (let i =0; i < 200; i++){
  EnLog[i] = {
    id: i,
    locationname:faker.company.name(),
    residentname:faker.name.fullName(),
    usertype:TYPE[Math.floor(Math.random()*TYPE.length)],
    guestname:faker.name.fullName(),
    guestpin:faker.random.numeric(4),
    guestphone:faker.phone.number('##########'),
    pintype:GuestPINTYPE[Math.floor(Math.random()*GuestPINTYPE.length)],
    pinusetime:faker.random.numeric(),
    dateexpiry:format(faker.date.future(), 'dd/MM/yyyy'),
    status:StatusTYPE[Math.floor(Math.random()*StatusTYPE.length)],
  }
}*/


function GuestPIN ( props){
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const {token} = useToken();
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();
  const {RefreshToken} = props;
  const { getAlertTitle} = GlobalAlert();
  const [EnLog,setEnLog] = useState([]);
  let [loading, setLoading] = useState(true);
  const getMyPostData = async () => {
    try {
      const res = await axios.get("/admin/guestpin/"+token.id,{
        headers: { 'Content-Type':  'application/json' ,
        'token' : token.token,
        'tokenid' : token.email},
      });
      console.log(res.data);
      setEnLog(res.data);
      setRecords(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };

  const getDisableValueEdit = () => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("50").administrator_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("50").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("50").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("50").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("50").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("50").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("50").admin_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("50").admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const getDisableValue = (val) => {
    if(getDisableValueEdit() == false){
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("50");
       // console.log("value is "+ JSON.stringify(valOfDisableData));
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        //console.log("value is "+ JSON.stringify(valOfDisableData[idData]));
        let valOfDisable = valOfDisableData[idData].administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("50");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("50");
       // console.log("value is "+ JSON.stringify(valOfDisableData));
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
       // console.log("value is "+ JSON.stringify(valOfDisableData[idData]));
        let valOfDisable = valOfDisableData[idData].distributor_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("50");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("50");
       // console.log("value is "+ JSON.stringify(valOfDisableData));
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
       // console.log("value is "+ JSON.stringify(valOfDisableData[idData]));
        let valOfDisable = valOfDisableData[idData].dealer_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("50");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("50");
       // console.log("value is "+ JSON.stringify(valOfDisableData));
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
       // console.log("value is "+ JSON.stringify(valOfDisableData[idData]));
        let valOfDisable = valOfDisableData[idData].admin_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("50");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
  }else{
    return true;
  }
    
  }
  const getDisableValueDelete = () => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("51").administrator_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("51").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("51").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("51").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("51").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("51").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("51").admin_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("51").admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  // NOTE:  calling the function
  useEffect(() => {
    getMyPostData();
  }, []);
  const [apiValue, setAPIValue] = useState({id:"",pin_type:"",pin_use_time:"",pin_expiry_date:"","issub":token.issub,"subId":token.subid});
  const myContainer = useRef(null);
  const [shownameType, setShownameType] = useState(true);
  const [showLocationType, setShowLocationType] = useState(true);
  const [showResidentType, setShowResidentType] = useState(false);
  const [showUserType, setShowUserType] = useState(false);
  const [showGuestName, setShowGuestName] = useState(false);
  const [showGuestPhone, setShowGuestPhone] = useState(false);
  const [showGuestPIN, setShowGuestPIN] = useState(false);
  const [showPINTYpe, setShowPINTYpe] = useState(false);
  const [showPINUseTime, setShowPINUseTime] = useState(false);
  const [showDateExpiry, setShowDateExpiry] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [filterText, setfilterText] = useState('');
  const [resetClick, setResetClicked] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const [showUpdateButton, setshowUpdateButton] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [titleforalert, setTitleforalert] = React.useState("");
 
  const FilterMenu = {
    id: "1",
    label: "Device Name",
    items: [
      {
        label: "Device Name",
        callback: () =>  {
          setShownameType(true);
          setShowLocationType(true);
          setShowResidentType(false);
          setShowUserType(false);
          setShowGuestName(false);
          setShowGuestPIN(false);
          setShowGuestPhone(false);
          setShowPINTYpe(false);
          setShowPINUseTime(false);
          setShowDateExpiry(false);
          setShowStatus(false);
         // myContainer.this.setState({ //value:''});
         if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    if(document.getElementById('date') != null)
          document.getElementById('date').value ='';
          setfilterText('');
          //console.log(format(new Date(), 'dd/MM/yyyy'));
        }
         
      },
      {
        label: "User Name",
        callback: () =>  { 
            setShownameType(true);
            setShowLocationType(false);
            setShowResidentType(true);
            setShowUserType(false);
            setShowGuestName(false);
            setShowGuestPIN(false);
            setShowGuestPhone(false);
            setShowPINTYpe(false);
            setShowPINUseTime(false);
            setShowDateExpiry(false);
            setShowStatus(false);
          if(document.getElementById('input-location') != null)
          document.getElementById('input-location').value ='';
          if(document.getElementById('date') != null)
                document.getElementById('date').value ='';
          setfilterText('');
        }
      },
      {
        label: "User Type",
        callback: () => {
            setShownameType(false);
            setShowLocationType(false);
            setShowResidentType(false);
            setShowUserType(true);
            setShowGuestName(false);
            setShowGuestPIN(false);
            setShowGuestPhone(false);
            setShowPINTYpe(false);
            setShowPINUseTime(false);
            setShowDateExpiry(false);
            setShowStatus(false);
          if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    if(document.getElementById('date') != null)
          document.getElementById('date').value ='';
          setfilterText('');
        }
      },
      {
        label: "Guest Name",
        callback: () => {
            setShownameType(true);
            setShowLocationType(false);
            setShowResidentType(false);
            setShowUserType(false);
            setShowGuestName(true);
            setShowGuestPIN(false);
            setShowGuestPhone(false);
            setShowPINTYpe(false);
            setShowPINUseTime(false);
            setShowDateExpiry(false);
            setShowStatus(false);
          if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    if(document.getElementById('date') != null)
          document.getElementById('date').value ='';
          setfilterText('');
        }
      },
      {
        label: "Guest PIN",
        callback: () => {
            setShownameType(true);
            setShowLocationType(false);
            setShowResidentType(false);
            setShowUserType(false);
            setShowGuestName(false);
            setShowGuestPIN(true);
            setShowGuestPhone(false);
            setShowPINTYpe(false);
            setShowPINUseTime(false);
            setShowDateExpiry(false);
            setShowStatus(false);
          if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    if(document.getElementById('date') != null)
          document.getElementById('date').value ='';
          setfilterText('');
        }
      },
      {
        label: "Guest Phone",
        callback: () => {
            setShownameType(true);
            setShowLocationType(false);
            setShowResidentType(false);
            setShowUserType(false);
            setShowGuestName(false);
            setShowGuestPIN(false);
            setShowGuestPhone(true);
            setShowPINTYpe(false);
            setShowPINUseTime(false);
            setShowDateExpiry(false);
            setShowStatus(false);
          if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    if(document.getElementById('date') != null)
          document.getElementById('date').value ='';
          setfilterText('');
        }
      },
      {
        label: "PIN Type",
        callback: () => {
            setShownameType(false);
            setShowLocationType(false);
            setShowResidentType(false);
            setShowUserType(false);
            setShowGuestName(false);
            setShowGuestPIN(false);
            setShowGuestPhone(false);
            setShowPINTYpe(true);
            setShowPINUseTime(false);
            setShowDateExpiry(false);
            setShowStatus(false);
          if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    if(document.getElementById('date') != null)
          document.getElementById('date').value ='';
          setfilterText('');
        }
      },
      {
        label: "PIN Use Time",
        callback: () => {
            setShownameType(true);
            setShowLocationType(false);
            setShowResidentType(false);
            setShowUserType(false);
            setShowGuestName(false);
            setShowGuestPIN(false);
            setShowGuestPhone(false);
            setShowPINTYpe(false);
            setShowPINUseTime(true);
            setShowDateExpiry(false);
            setShowStatus(false);
          if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    if(document.getElementById('date') != null)
          document.getElementById('date').value ='';
          setfilterText('');
        }
      },
      {
        label: "PIN Exp. Date",
        callback: () => {
            setShownameType(false);
            setShowLocationType(false);
            setShowResidentType(false);
            setShowUserType(false);
            setShowGuestName(false);
            setShowGuestPIN(false);
            setShowGuestPhone(false);
            setShowPINTYpe(false);
            setShowPINUseTime(false);
            setShowDateExpiry(true);
            setShowStatus(false);
          if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    if(document.getElementById('date') != null)
          document.getElementById('date').value ='';
          setfilterText('');
        }
      },
      {
        label: "Status",
        callback: () => {
            setShownameType(false);
            setShowLocationType(false);
            setShowResidentType(false);
            setShowUserType(false);
            setShowGuestName(false);
            setShowGuestPIN(false);
            setShowGuestPhone(false);
            setShowPINTYpe(false);
            setShowPINUseTime(false);
            setShowDateExpiry(false);
            setShowStatus(true);
          if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    if(document.getElementById('date') != null)
          document.getElementById('date').value ='';
          setfilterText('');
        }
      },
    ]
  };
  const TypeMenu = {
    id: "1",
    label: "Select Type",
    items: [
      {
        label: "Android",
        callback: () =>  {setfilterText("Android")}
       
      },
      {
        label: "iOS",
        callback: () =>  {setfilterText("iOS")}
      },
    ]
  };
  const StatusTYPEMenu = {
    id: "1",
    label: "Select Type",
    items: [
      {
        label: "Expired",
        callback: () =>  {setfilterText("1")}
       
      },
      {
        label: "Active",
        callback: () =>  {setfilterText("0")}
      },
    ]
  };
  const GuestPINTYPEMenu = {
    id: "1",
    label: "Select Type",
    items: [
      {
        label: "Single Use PIN",
        callback: () => {setfilterText("Single Use PIN")}
      },
      {
        label: "Limited Use PIN",
        callback: () =>  {setfilterText("Limited Use PIN")}
       
      },
      {
        label: "Unlimited Use PIN",
        callback: () =>  {setfilterText("Unlimited Use PIN")}
      }
      
    ]
  };
  const [records, setRecords] = useState(EnLog)
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
  const handleChangeText = e => {
    console.log(e.target.value);
    let value = e.target.value;
    setfilterText(value);
    console.log(filterText);
}
const handleClose = () => {
  setOpen(false);
};
const deleteMyPostData = async () => {
  try {
    const res = await axios.delete("/admin/guestpin/"+token.id+"/"+currentId,
    {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
    'token' : token.token,
    'tokenid' : token.email,
    'issub':token.issub,
    'subId':token.subid,
    'gpin':guestPINName }});
    console.log( res.data);
    setOpenSnackData(getAlertTitle('GPINDS'));
    setSeverityData("success");
    setOpenSnack(true);
    setLoading(false);
    getMyPostData();
  } catch (error) {
    console.log(error.message);
    setLoading(false);
    setOpenSnackData(getAlertTitle('GPINDF'));
    setSeverityData("error");
    setOpenSnack(true);
    if(error.response.data.message == 'UnAuthorized'){
      RefreshToken();
    }
  }
};
const [guestPINName, setGuestPINName] = useState('');
const handleCloseActive =() =>{
  setOpen(false);
  deleteMyPostData();
}
const updateMyPostData = async () => {
  try {
    const res = await axios.patch("/admin/guestpin/"+token.id,apiValue, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
    'token' : token.token,
    'tokenid' : token.email }});
    console.log( res.data);
    setOpenSnackData(getAlertTitle('GPINUS'));
    setSeverityData("success");
    setOpenSnack(true);
    setLoading(false);
    setshowUpdateButton(false);
    
    getMyPostData();
  } catch (error) {
    console.log(error.message);
    setLoading(false);
    setOpenSnackData(getAlertTitle('GPINUF'));
    setSeverityData("error");
    setOpenSnack(true);
    if(error.response.data.message == 'UnAuthorized'){
      RefreshToken();
    }
  }
};
const updateGuestPINData = () => {
  setOpenPopup(false);
  updateMyPostData();
}

const handleChangeEditText = e =>{
    console.log(apiValue);
    setshowUpdateButton(true);
    apiValue.pin_expiry_date = e.target.value;
    setAPIValue({id:apiValue.id,pin_type:apiValue.pin_type,pin_use_time:apiValue.pin_use_time,pin_expiry_date:apiValue.pin_expiry_date,"issub":token.issub,"subId":token.subid});
}

const handleRadioChange = (event) => {
  //setValue(event.target.value);
  //console.log(apiValue);
  apiValue.pin_type = event.target.value;
 // console.log(apiValue.pin_type);
 if(apiValue.pin_type != "Limited Use PIN"){
  apiValue.pin_use_time = "1";
  UseTimeMenu.label = "1";
  setUseTimeMenu(UseTimeMenu);
 }
 
  setshowUpdateButton(true);
  setAPIValue({id:apiValue.id,pin_type:apiValue.pin_type,pin_use_time:apiValue.pin_use_time,pin_expiry_date:apiValue.pin_expiry_date,"issub":token.issub,"subId":token.subid});
};
  const {
      TblContainer,
      TblHead,
      TblPagination,
      BelowTblPagination,
      recordsAfterPagingAndSorting,
      setPageToNewOne
  } = useTable(records, headCells, filterFn,"Download Logs");
 /*function setDtaForReact(){
 
}*/
/*useEffect(() => {  console.log('use effect clicked'); if(resetClick){
  console.log('reset clicked');
  setResetClicked(false);
  setfilterText('');
  if(document.getElementById('input-location') != null)
  document.getElementById('input-location').value ='';
  if(document.getElementById('date') != null)
        document.getElementById('date').value ='';
       
  handleSearch();
}}, []);*/
const [UseTimeMenu,setUseTimeMenu] = useState({
  id: "1",
  label: "Select Use Time",
  items: [
    {
      label: "1",
      callback: () =>  {
        apiValue.pin_use_time = "1";
        setAPIValue(apiValue);
        setshowUpdateButton(true);
      }
     
    },
    {
      label: "2",
      callback: () =>  {
        apiValue.pin_use_time = "2";
        setAPIValue(apiValue);
        setshowUpdateButton(true);
      }
     
    },
    {
      label: "3",
      callback: () =>  {
        apiValue.pin_use_time = "3";
        setAPIValue(apiValue);
        setshowUpdateButton(true);
      }
     
    },
    {
      label: "4",
      callback: () =>  {
        apiValue.pin_use_time = "4";
        setAPIValue(apiValue);
        setshowUpdateButton(true);
      }
     
    },
    {
      label: "5",
      callback: () =>  {
        apiValue.pin_use_time = "5";
        setAPIValue(apiValue);
        setshowUpdateButton(true);
      }
     
    },
    {
      label: "6",
      callback: () =>  {
        apiValue.pin_use_time = "6";
        setAPIValue(apiValue);
        setshowUpdateButton(true);
      }
     
    },
    {
      label: "7",
      callback: () =>  {
        apiValue.pin_use_time = "7";
        setAPIValue(apiValue);
        setshowUpdateButton(true);
      }
     
    },
    {
      label: "8",
      callback: () =>  {
        apiValue.pin_use_time = "8";
        setAPIValue(apiValue);
        setshowUpdateButton(true);
      }
     
    },
    {
      label: "9",
      callback: () =>  {
        apiValue.pin_use_time = "9";
        setAPIValue(apiValue);
        setshowUpdateButton(true);
      }
     
    },
  ]
});

const handleAddGuestPIN = () => {
  
}
  const handleReset =() =>{
    setfilterText('');
    if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    if(document.getElementById('date') != null)
          document.getElementById('date').value ='';
    
          setFilterFn({
            fn: items => {
              return items;
                    
            }
        })
        setPageToNewOne();
  }
  const checkAtOrNot = (value) =>{
     let ad = records.filter(x => x.loc_name.toLowerCase().startsWith(value.toLowerCase()));
     console.log(ad.length);
     return ad.length;
  }

  const jumpToAlphabet = (val) =>{
    console.log('value is '+val);
    setFilterFn({
      fn: items => {
        return items.filter(x => x.loc_name.toLowerCase().startsWith(val.toLowerCase()))
              
      }
  })
  setPageToNewOne();
  }

  const DeletId = (val) =>{
    setCurrentId(val);
    let deletid;
    for(let i = 0; i < EnLog.length ; i++){
        if(EnLog[i].id == val ){
            deletid = i;
            break;
        }
    }
    setGuestPINName(EnLog[deletid].guest_name);
   /* EnLog.splice(deletid,1);
    setFilterFn({
        fn: items => {
          return items;
                
        }
    })-*/
     setTitleforalert("Are you sure you want to delete guest pin for guest name "+ EnLog[deletid].guest_name + " ?");
     setOpen(true);
  }
  const ChangeId = (val) =>{
    console.log(currentId);
    let indexOfCurrent;
    setshowUpdateButton(false);
    for(let i = 0; i < EnLog.length ; i++){
        if(EnLog[i].id == val ){
          indexOfCurrent = i;
            break;
        }
    }
    apiValue.id = val;
    apiValue.pin_type = EnLog[indexOfCurrent].pin_type;
    apiValue.pin_expiry_date = EnLog[indexOfCurrent].pin_expiry_date;
    console.log("Enlog use time "+EnLog[indexOfCurrent].pin_use_time);
    if(parseInt(EnLog[indexOfCurrent].pin_use_time) != 0){
    UseTimeMenu.label = EnLog[indexOfCurrent].pin_use_time;
    setUseTimeMenu(UseTimeMenu);
    }
    else{
      UseTimeMenu.label = "1";
      setUseTimeMenu(UseTimeMenu);
    }
    setAPIValue(apiValue);
    setCurrentId(val);
    setOpenPopup(true);
  }
 
  /*useEffect(()=>{
    setOpenPopup(true);
  },[currentId]);*/
 
  
  const ShowLog = () =>{
    let indexOfCurrent;
    for(let i = 0; i < EnLog.length ; i++){
        if(EnLog[i].id == currentId ){
          indexOfCurrent = i;
            break;
        }
    }
    
    return(
    <>
      <Box sx={{
        display: { xs: "none", md: "flex" },
        }}>
    <Grid container spacing={2}> 
      <Grid item >
        <Stack direction="column"  spacing={2}>
      {/* <Typography sx={mainThemeStyle.popUpLableAnswer}> id </Typography> */}
      <Typography sx={mainThemeStyle.popUpLableAnswer} > User Device </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} > Guest PIN </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} >Guest Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} >Guest Phone Number </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={150} >Type Of PIN </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} >PIN Expiration Date </Typography>
      </Stack>
      </Grid>
      <Grid item >
      <Stack direction="column"  spacing={2}>
      {/* <Typography sx={mainThemeStyle.popUpLable}>{EnLog[indexOfCurrent].id}</Typography> */}
      <Typography sx={mainThemeStyle.popUpLable}> {EnLog[indexOfCurrent].loc_name} </Typography>
      <Typography sx={mainThemeStyle.popUpLable}> {EnLog[indexOfCurrent].guest_pin} </Typography>
      <Typography  sx={mainThemeStyle.popUpLable}>{ EnLog[indexOfCurrent].guest_name} </Typography>
      <Typography  sx={mainThemeStyle.popUpLable}> {EnLog[indexOfCurrent].phone_number}</Typography>
      <Box  height={200}>
     
      <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        value={ apiValue.pin_type }
        name="radio-buttons-group"
        onChange={handleRadioChange}
        disabled = {getDisableValue("205")}
      >
      <FormControlLabel value="Single Use PIN" control={<Radio />} label="Single Use PIN" sx={mainThemeStyle.popUpLable} />
      <Stack direction="row"  spacing={2}> <FormControlLabel  value="Limited Use PIN" control={<Radio />} label="Limited Use PIN" sx={mainThemeStyle.popUpLable} />
      <NestedButtonDropdown
            itemId={UseTimeMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.PinSelection}
            data={UseTimeMenu}
          /></Stack>
      <FormControlLabel  value="Unlimited Use PIN" control={<Radio />} label="Unlimited Use PIN" sx={mainThemeStyle.popUpLable} />
      </RadioGroup>
    </FormControl>
      
      </Box>
      <TextField
        id="date"
        type="date"
        value={apiValue.pin_expiry_date }
        sx={mainThemeStyle.InputFilterPopup}
        onChange={handleChangeEditText}
        InputLabelProps={{
          shrink: true,
        }}
        hiddenLabel
        disabled = {getDisableValue("206")}
        variant='filled'
        InputProps={{
                           
          sx: {
              
              color: 'rgba(255, 255, 255, 1)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#171E29',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              textIndent: '15px !important',
              border: `1px solid rgba(31, 39, 54, 1)`,
             
              '&.Mui-disabled': {
             

                background: 'rgba(135, 135, 135, 0.5)',
              },
              
              "& input.Mui-disabled": {
                color: "white"
              }
          },
      }}
      />
      </Stack>
      </Grid>
      
    { showUpdateButton &&  <Button sx={mainThemeStyle.saveButton} onClick={updateGuestPINData}  disabled = {getDisableValueEdit()}>Save</Button>}
      
      </Grid></Box>
      
      <Box sx={{
        display: { xs: "flex", md: "none" },
        }}>
          <Stack  direction="column"> 
     <Stack  direction="row"> 
        <Stack direction="column"  spacing={2}>
      {/* <Typography sx={mainThemeStyle.popUpLableAnswer}> id </Typography> */}
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> User Device </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> Guest PIN </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>Guest Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>Guest Phone </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={200} marginTop={40}>Type Of PIN </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>PIN Expiration Date </Typography>
     
      </Stack>
      
      <Stack direction="column"  spacing={2}>
      
      <Typography sx={mainThemeStyle.popUpLable} height={40}> {EnLog[indexOfCurrent].loc_name} </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> {EnLog[indexOfCurrent].guest_pin} </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ EnLog[indexOfCurrent].guest_name} </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}> {EnLog[indexOfCurrent].phone_number}</Typography>
      <Box  height={200}>
     
      <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        value={ apiValue.pin_type }
        name="radio-buttons-group"
        onChange={handleRadioChange}
        disabled = {getDisableValue("205")}
      >
      <FormControlLabel value="Single Use PIN" control={<Radio />} label="Single Use PIN" sx={mainThemeStyle.popUpLable} />
      <Stack direction="column"  spacing={2}> <FormControlLabel  value="Limited Use PIN" control={<Radio />} label="Limited Use PIN" sx={mainThemeStyle.popUpLable} />
      <NestedButtonDropdown
            itemId={UseTimeMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.PinSelection}
            data={UseTimeMenu}
          /></Stack>
      <FormControlLabel  value="Unlimited Use PIN" control={<Radio />} label="Unlimited Use PIN" sx={mainThemeStyle.popUpLable} />
      </RadioGroup>
    </FormControl>
      
      </Box>
      <TextField
        id="date"
        type="date"
        value={apiValue.pin_expiry_date }
        sx={mainThemeStyle.InputFilterPopup}
        onChange={handleChangeEditText}
        InputLabelProps={{
          shrink: true,
        }}
        hiddenLabel
        disabled = {getDisableValue("206")}
        height={40}
        variant='filled'
        InputProps={{
                           
          sx: {
              marginTop:'70px',
              color: 'rgba(255, 255, 255, 1)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#171E29',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              textIndent: '15px !important',
              border: `1px solid rgba(31, 39, 54, 1)`,
              '&.Mui-disabled': {
             

                background: 'rgba(135, 135, 135, 0.5)',
              },
              
              "& input.Mui-disabled": {
                color: "white"
              }
              
          },
      }}
      />
      </Stack>
      
      </Stack>
   
      { showUpdateButton &&  <Button sx={mainThemeStyle.saveButton} onClick={updateGuestPINData}>Save</Button>}</Stack>
      </Box>
      </>
      )
  }
  const handleSearch = () => {
    //let target = e.target;
    setFilterFn({
        fn: items => {
            if (filterText == "")
                return items;
            else{
              if(shownameType){
                console.log(shownameType);
                if(showLocationType)
                return items.filter(x => x.loc_name.toLowerCase().includes(filterText.toLowerCase()))

                else if(showResidentType)
                return items.filter(x => x.loc_username.toLowerCase().includes(filterText.toLowerCase()))

                else if(showGuestName)
                return items.filter(x => x.guest_name.toLowerCase().includes(filterText.toLowerCase()))
                
                else if(showGuestPIN)
                return items.filter(x => x.guest_pin.toString().toLowerCase().includes(filterText.toLowerCase()))
                
                else if(showGuestPhone)
                return items.filter(x => x.phone_number.toString().toLowerCase().includes(filterText.toLowerCase()))
                
                else if(showPINUseTime)
                return items.filter(x => x.pin_use_time.toString().toLowerCase().includes(filterText.toLowerCase()))
                
                else 
                return items;
                
              }
              else if(showDateExpiry){
                var mystr = filterText;
                mystr = moment(mystr).format('YYYY-MM-DD');
                return items.filter(x => x.pin_expiry_date.toLowerCase().includes(mystr))
              }
              else if(showUserType){
                
                
                  return items.filter(x => x.loc_user_type.toLowerCase() === (filterText.toLowerCase()))
                
              }
             
              else if(showPINTYpe){
              
                return items.filter(x => x.pin_type.toLowerCase() === (filterText.toLowerCase()))
              
              }

              else if(showStatus){
                
                
                  return items.filter(x => x.status.toString().toLowerCase() === (filterText.toLowerCase()))
                
              }
            }
                
        }
    })
    setPageToNewOne();
}
  return <><Box><Typography sx={mainThemeStyle.lablefilter}>Filter</Typography>
  
  <Box sx={{position: 'absolute',
           width:'880px',
           height: '280px',
           top:'160px',
           left: '38px',
           display: { xs: "none", md: "flex" },
           right: '38px',
           flexGrow: 1,
          
           }}>
             
           <Stack direction="row" spacing={2} >
                  
                   <NestedButtonDropdown
            itemId={FilterMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={FilterMenu}
          /> {shownameType  &&
           
          <Typography sx={mainThemeStyle.lableContains}>Contains</Typography>}
                 {(showUserType || showPINTYpe  || showStatus)  && 
                   <Typography sx={mainThemeStyle.lableContains}>Equals</Typography>}
                   {showUserType  &&  
                   <NestedButtonDropdown
            itemId={TypeMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={TypeMenu}
          />
                  }

                   {showPINTYpe  &&  
                   <NestedButtonDropdown
            itemId={GuestPINTYPEMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={GuestPINTYPEMenu}
            
          />
                  }
                  {showStatus  &&  
                   <NestedButtonDropdown
            itemId={StatusTYPEMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={StatusTYPEMenu}
            
          />
                  }
                   {showDateExpiry && <TextField
        id="date"
        type="date"
        defaultValue=""
        sx={mainThemeStyle.InputFilter}
        onChange={handleChangeText}
        InputLabelProps={{
          shrink: true,
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && filterText != '') {
            handleSearch();
          }
        }}
        InputProps={{
                           
          sx: {
              
              color: 'rgba(255, 255, 255, 1)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#171E29',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              textIndent: '15px !important',
              border: `1px solid rgba(31, 39, 54, 1)`,
             
              
          },
      }}
      />}

{shownameType &&
                   
                   <TextField
                        id="input-location"
                        ref={myContainer}
                        type= 'text'
                        name="text"
                        onChange={handleChangeText}
                        sx={mainThemeStyle.InputFilter}
                        hiddenLabel
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && filterText != '') {
                          handleSearch();
                        }
                      }}
                        InputProps={{
                           
                            sx: {
                                
                                color: 'rgba(255, 255, 255, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#171E29',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '5px 0 5px 0',
                                textIndent: '15px !important',
                                border: `1px solid rgba(31, 39, 54, 1)`,
                               
                                
                            },
                        }}
                       
                    />}
                   
                   <Button sx={mainThemeStyle.filterResetButton} onClick={handleSearch}  disabled={!filterText}>Go</Button>
                   
                   <Button sx={mainThemeStyle.filterResetButton} onClick={handleReset}>Reset Filters</Button>

                  {/*  <Button sx={mainThemeStyle.filterResetButton} onClick={handleAddGuestPIN}>Generate Guest PIN</Button> */}
                  
                  </Stack>
                  </Box>
          
  <Box sx={{position: 'absolute',
           width:'auto',
           height: '280px',
           top:'160px',
           left: '38px',
           display: { xs: "flex", md: "none" },
           right: '38px',
           flexGrow: 1,
          
           }}>
             <Grid container direction="column">
             <Grid item >
           <Stack direction="row" spacing={2} >
                  
                   <NestedButtonDropdown
            itemId={FilterMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={FilterMenu}
          /> 
                   {showUserType  &&  
                   <NestedButtonDropdown
            itemId={TypeMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={TypeMenu}
          />
                  }

                   {showPINTYpe  &&  
                   <NestedButtonDropdown
            itemId={GuestPINTYPEMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={GuestPINTYPEMenu}
            
          />
                  }
                  {showStatus  &&  
                   <NestedButtonDropdown
            itemId={StatusTYPEMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={StatusTYPEMenu}
            
          />
                  }
                   {showDateExpiry && <TextField
        id="date"
        type="date"
        defaultValue=""
        sx={mainThemeStyle.InputFilter}
        onChange={handleChangeText}
        InputLabelProps={{
          shrink: true,
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && filterText != '') {
            handleSearch();
          }
        }}
        InputProps={{
                           
          sx: {
              
              color: 'rgba(255, 255, 255, 1)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#171E29',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              textIndent: '15px !important',
              border: `1px solid rgba(31, 39, 54, 1)`,
             
              
          },
      }}
      />}

{shownameType &&
                   
                   <TextField
                        id="input-location"
                        ref={myContainer}
                        type= 'text'
                        name="text"
                        onChange={handleChangeText}
                        hiddenLabel
                        sx={mainThemeStyle.InputFilterSmall}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && filterText != '') {
                          handleSearch();
                        }
                      }}
                        InputProps={{
                           
                            sx: {
                                
                                color: 'rgba(255, 255, 255, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#171E29',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '5px 0 5px 0',
                                textIndent: '15px !important',
                                border: `1px solid rgba(31, 39, 54, 1)`,
                               
                                
                            },
                        }}
                       
                    />}</Stack></Grid>
                   <Grid item>
                   <Stack direction="row" >
                   <Button sx={mainThemeStyle.filterResetButtonSmall} onClick={handleSearch}  disabled={!filterText}>Go</Button>
                   
                   <Button sx={mainThemeStyle.filterResetButtonSmall} onClick={handleReset}>Reset Filters</Button>
                   </Stack>
                 </Grid></Grid></Box>
  
  
                 <Typography sx={mainThemeStyle.lableJump}>Jump</Typography>
                 
                 <ButtonGroup variant="text"  sx={{position: 'absolute',
           maxWidth:'250px',
           height: 'auto',
           top:'250px',
           left: '38px',
           display: { xs: "none", md: "flex" }
           }}>

          

          {
            Alphabet.map(item => (
              checkAtOrNot(item) > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  onClick={() => {jumpToAlphabet(item)}}>{item}</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>{item}</Button>
            ))
          }

        {/* { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('B') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >B</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>B</Button>}
        { checkAtOrNot('C') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >C</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>C</Button>}
        { checkAtOrNot('D') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >D</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>D</Button>}
        { checkAtOrNot('E') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >E</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>E</Button>}
        { checkAtOrNot('F') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >F</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>F</Button>}
        { checkAtOrNot('G') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >G</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>G</Button>}
        { checkAtOrNot('H') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >H</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>H</Button>}
        { checkAtOrNot('I') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >I</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>I</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>}
        { checkAtOrNot('A') > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  >A</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>A</Button>} */}
     

    </ButtonGroup>
    {/* <TableContainer component={Paper} sx={{position: 'absolute',
           width:'auto',
           height: 'auto',
           top:'320px',
           left: '38px',
           right:'38px',
           backgroundColor:'#171E29',
          
           }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow  sx={{ borderBottom: "1.5px solid black",
               }} >
            <TableCell sx={mainThemeStyle.cellHeader}>Location Name</TableCell>
            <TableCell sx={mainThemeStyle.cellHeader}>Resident Name</TableCell>
            <TableCell sx={mainThemeStyle.cellHeader}>Guest Name</TableCell>
            <TableCell sx={mainThemeStyle.cellHeader}>User Type</TableCell>
            <TableCell sx={mainThemeStyle.cellHeader}>Entry Type</TableCell>
            <TableCell sx={mainThemeStyle.cellHeader}>Date Added</TableCell>
            <TableCell sx={mainThemeStyle.cellHeader}>Time Stamp</TableCell>
            <TableCell sx={mainThemeStyle.cellHeader}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {EnLog.map((row) => (
            <TableRow
              key={row.locationname}
              sx={{ borderBottom: "1.5px solid black",'&:last-child td, &:last-child th': { border: 0 },
               }}
            >
              <TableCell align="left" sx={mainThemeStyle.cellRow}>{row.locationname}</TableCell>
              <TableCell align="left" sx={mainThemeStyle.cellRow}>{row.residentname}</TableCell>
              <TableCell align="left" sx={mainThemeStyle.cellRow}>{row.guestname}</TableCell>
              <TableCell align="left" sx={mainThemeStyle.cellRow}>{row.usertype}</TableCell>
              <TableCell align="left" sx={mainThemeStyle.cellRow}>{row.entrytype}</TableCell>
              <TableCell align="left" sx={mainThemeStyle.cellDateTime}>{row.dateadded}</TableCell>
              <TableCell align="left" sx={mainThemeStyle.cellDateTime}>{row.timestamp}</TableCell>
              <TableCell ><Button  sx={mainThemeStyle.viewLogButton}>View Log</Button></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>     */}
   
   <Box sx={mainThemeStyle.mainTablebox}>
   <BelowTblPagination ></BelowTblPagination>
    <TblContainer>
                    <TblHead />
                    <TableBody>
                        {
                            recordsAfterPagingAndSorting().map(item =>
                                ( <TableRow
                                  key={item.id}
                                  sx={{ borderBottom: "1.5px solid black",'&:last-child td, &:last-child th': { border: 0 },
                                   }}
                                >
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.loc_name}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.loc_username}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.loc_user_type}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.guest_name}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.guest_pin}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.phone_number}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.pin_type}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.pin_use_time}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellGreen}>{item.pin_expiry_date}</TableCell>
                                  { item.status == "1"  && <TableCell align="left" sx={mainThemeStyle.cellRed}>Expired</TableCell>}
                                  { item.status == "0"  && <TableCell align="left" sx={mainThemeStyle.cellGreen}>Active</TableCell>}
                                  <TableCell >
                                  <Stack direction="row" spacing={2}>
                                    <Typography></Typography>
                                    <Button  sx={mainThemeStyle.viewLogButton} onClick={() => {ChangeId(item.id)}} disabled = {getDisableValueEdit()}> Edit</Button>
                                  <Button  sx={mainThemeStyle.viewLogButtonRed} onClick={() => {DeletId(item.id)}} disabled = {getDisableValueDelete()}>Delete</Button></Stack></TableCell>
                                </TableRow>)
                            )
                        }
                    </TableBody>
                   
                </TblContainer>
                <Box sx={{marginTop:'10px'}}>
                <PuffLoader color="#878787" loading={loading} cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"50px",
    marginBottom:"50px",}} />



</Box></Box>
                 </Box>
                 <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
          <Popup
                title="Edit Guest PIN"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
             
      <ShowLog/>
               
            </Popup>
            
            <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleClose} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActive} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
            </> 
          
          
          
          
          ;
};

export default GuestPIN;