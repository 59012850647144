import React from "react";
import { Box } from "@mui/system";
import { Button, Grid, InputLabel, List, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import GlobalAlert from "./GlobalAlert";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import axios from "./axios";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import PuffLoader from "react-spinners/PuffLoader";
import EditIcon from '@mui/icons-material/Edit';
import useToken from "./useToken";
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useParams,useNavigate  } from "react-router-dom";
const headCells = [
  { id: 'copmpany', label: 'Account'},
  { id: 'name', label: 'Contact Name' },
 
 /*  { id: 'dealer_company_name', label: 'Dealer Company' }, */
  { id: 'total_unit', label: 'Total Unit' },
  { id: 'monthly_cost', label: 'Monthly Cost' },
  { id: 'annual_cost', label: 'Annual Cost' },
  { id: 'start_date', label: 'Start Date'},
  { id: 'email', label: 'Email' },
  /* { id: 'password', label: 'Password' }, */
  { id: 'address', label: 'Address' },
  { id: 'city', label: 'City' },
  { id: 'state', label: 'State' },
  { id: 'zip_code', label: 'Zip' },
 /*  { id: 'location_count', label: 'No. Of Locations' }, */
  { id: 'action', label: 'Action', disableSorting: true  },
]


let Alphabet =['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];



function ManagePropertyDealer ( props){
    const {token} = useToken();
  const navigate = useNavigate();
  const { getAlertTitle} = GlobalAlert();
  const {RefreshToken,switchToSubUser} = props;
  const myContainer = useRef(null);
  const [shownameType, setShownameType] = useState(true);
  const [EnLog,setEnLog] = useState([]);
  const [showPropertyNameType, setShowPropertyNameType] = useState(false);
  const [showCompanyName, setShowCompanyName] = useState(true);
  const [showDealerCompany, setShowDealerCompany] = useState(false);
  const [showTotalUnit, setShowTotalUnit] = useState(false);
  const [showMonthlyCost, setShowMonthlyCost] = useState(false);
  const [showAnnualCost, setShowAnnualCost] = useState(false);
  const [showOfficeNumber, setShowOfficeNumber] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [showCity, setShowCity] = useState(false);
  const [showState, setShowState] = useState(false);
  const [showZipCode, setShowZipCode] = useState(false);
  const [showLocationCount, setShowLocationCount] = useState(false);
  const [showDateAdded,setShowDateAdded] = useState(false);
  const [filterText, setfilterText] = useState('');
  const [resetClick, setResetClicked] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();

  const FilterMenu = {
    id: "1",
    label: "Account Name",
    items: [
      {
        label: "Account Name",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(true);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
            setShowDateAdded(false);
            setfilterText('');
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "Contact Name",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(true);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
            setShowDateAdded(false);
            setfilterText('');
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
     /*  {
        label: "Dealer Company",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(true);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      }, */
      {
        label: "Total Unit",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(true);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
            setShowDateAdded(false);
            setfilterText('');
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "Monthly Cost",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(true);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
            setShowDateAdded(false);
            setfilterText('');
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "Annual Cost",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(true);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
            setShowDateAdded(false);
            setfilterText('');
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "Office Number",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(true);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
            setShowDateAdded(false);
            setfilterText('');
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "Email",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(true);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
            setShowDateAdded(false);
            setfilterText('');
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      }/* ,
      {
        label: "Password",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(true);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      } */,
      {
        label: "Address",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(true);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
            setShowDateAdded(false);
            setfilterText('');
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "City",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(true);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(false);
            setShowDateAdded(false);
            setfilterText('');
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "State",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(true);
            setShowZipCode(false);
            setShowLocationCount(false);
            setShowDateAdded(false);
            setfilterText('');
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "ZipCode",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(true);
            setShowLocationCount(false);
            setShowDateAdded(false);
            setfilterText('');
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "Location Count",
        callback: () =>  {
            setShownameType(true);
            setShowPropertyNameType(false);
            setShowCompanyName(false);
            setShowDealerCompany(false);
            setShowTotalUnit(false);
            setShowMonthlyCost(false);
            setShowAnnualCost(false);
            setShowOfficeNumber(false);
            setShowEmail(false);
            setShowPassword(false);
            setShowAddress(false);
            setShowCity(false);
            setShowState(false);
            setShowZipCode(false);
            setShowLocationCount(true);
            setShowDateAdded(false);
            setfilterText('');
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
        }
         
      },
      {
        label: "Start Date",
        callback: () =>  {
          setShownameType(false);
          setShowPropertyNameType(false);
          setShowCompanyName(false);
          setShowDealerCompany(false);
          setShowTotalUnit(false);
          setShowMonthlyCost(false);
          setShowAnnualCost(false);
          setShowOfficeNumber(false);
          setShowEmail(false);
          setShowPassword(false);
          setShowAddress(false);
          setShowCity(false);
          setShowState(false);
          setShowZipCode(false);
          setShowLocationCount(false);
          setShowDateAdded(true);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
            if(document.getElementById('date') != null)
        document.getElementById('date').value ='';
        setfilterText('');
      
        }
        
         
      }
    ]
  };
  let [loading, setLoading] = useState(true);

  const getDisableValueEdit = () => {
    console.log('role is '+ token.role);
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("32").administrator_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("32").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      console.log('role is '+ token.sub_type);
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("32").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("32").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("32").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("32").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const getDisableValueDelete = () => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("33").administrator_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("33").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("33").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("33").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("33").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("33").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }

  const getMyPostData = async () => {
    try {
      const res = await axios.get("/dealer/propertyowner/"+token.id,{
        headers: { 'Content-Type':  'application/json' ,
        'token' : token.token,
        'tokenid' : token.email},
      });
      console.log(res.data);
      let dscount = 0;
      let dscountplus = 0;
      let dscountplusyealry = 0;
    for(let i = 0; i < res.data.length ; i++){
      let ds = res.data[i];
      dscount = dscount + ds['total_unit'];
      dscountplus = dscountplus + ds['monthly_cost'];
      dscountplusyealry = dscountplusyealry + ds['annual_cost'];
    }
    setGetTotalUnit(dscount);
    setGetMonthlyCost(dscountplus);
    setGeYearlyCost(dscountplusyealry);
    console.log(res.data);
      setEnLog(res.data);
      setRecords(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  useEffect(() => {
    getMyPostData();
  }, []);
  const [records, setRecords] = useState(EnLog)
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const handleChangeText = e => {
    console.log(e.target.value);
    let value = e.target.value;
    setfilterText(value);
    console.log(filterText);
}
  const {
      TblContainer,
      TblHead,
      TblPagination,
      BelowTblPagination,
      recordsAfterPagingAndSorting,
      setPageToNewOne
  } = useTable(records, headCells, filterFn,"Download Accounts");
 /*function setDtaForReact(){
 
}*/
/*useEffect(() => {  console.log('use effect clicked'); if(resetClick){
  console.log('reset clicked');
  setResetClicked(false);
  setfilterText('');
  if(document.getElementById('input-location') != null)
  document.getElementById('input-location').value ='';
  if(document.getElementById('date') != null)
        document.getElementById('date').value ='';
       
  handleSearch();
}}, []);*/
const [gettotalUnit,setGetTotalUnit] = React.useState(0);
const [getMonthlyCost,setGetMonthlyCost] = React.useState(0);
const [getYearlyCost,setGeYearlyCost] = React.useState(0);
  const handleReset =() =>{
    setfilterText('');
    if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    
          setFilterFn({
            fn: items => {
              return items;
                    
            }
        })
        setPageToNewOne();
  }
  const checkAtOrNot = (value) =>{
     let ad = records.filter(x => x.name.toLowerCase().startsWith(value.toLowerCase()));
     console.log(ad.length);
     return ad.length;
  }

  const jumpToAlphabet = (val) =>{
    console.log('value is '+val);
    setFilterFn({
      fn: items => {
        return items.filter(x => x.copmpany.toLowerCase().startsWith(val.toLowerCase()))
              
      }
  })
  setPageToNewOne();
  }
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseActiveDelete =() =>{
    setOpenDelete(false);
    deleteMyPostData();
  }
  const deleteMyPostData = async () => {
    try {
      const res = await axios.delete("/dealer/deletepropertyowner/"+currentId,
       {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email,
      'issub':token.issub,
      'subId':token.subid,
      'name':currentPorpertyName }});
      console.log( 'delete data'+res.data);
      setOpenSnackData(getAlertTitle('EPDDS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      getMyPostData();
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setOpenSnackData(getAlertTitle('EPDDF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}

    }
  };
  const [currentPorpertyName, setCurrentPorpertyName] = useState('');
  const ChangeId = (val,val2) =>{
    console.log(currentId);
    if(val2 == 0){
      let disableval = getDisableValueEdit();
      if(disableval == true){
        setOpenSnackData("You don't have access to perform this action");
        setSeverityData("error");
        setOpenSnack(true);
      }
      else{
      let idofItem =  records.filter(x => x.id == val);
      let itemtopass = idofItem[0];
      navigate('../property/edit',  {state:{editproperty : itemtopass} })
      }
     
    }else if(val2 == 2){
      let disableval = getDisableValueDelete();
      if(disableval == true){
        setOpenSnackData("You don't have access to perform this action");
        setSeverityData("error");
        setOpenSnack(true);
      }
      else{
      setCurrentId(val);
      let deletid;
      for(let i = 0; i < EnLog.length ; i++){
          if(EnLog[i].id == val ){
              deletid = i;
              break;
          }
      }
      setCurrentPorpertyName(EnLog[deletid].copmpany);
      setTitleforalert("Are you sure you want to delete sccount owner "+ EnLog[deletid].copmpany + "?");
      setOpenDelete(true);
    }
    }
    else if(val2 == 3){
      setCurrentId(val);
      let deletid;
      for(let i = 0; i < EnLog.length ; i++){
          if(EnLog[i].id == val ){
              deletid = i;
              break;
          }
      }
      setCurrentPorpertyName(EnLog[deletid].copmpany);
      setTitleforalert("Do you want to manage the account for "+ EnLog[deletid].copmpany + "?");
      setOpen(true);
    }
    else if(val2 == 1){
      setOpenSnack(true);
    }
  }
  const DeletId = (val) =>{
    setCurrentId(val);
    let deletid;
    for(let i = 0; i < EnLog.length ; i++){
        if(EnLog[i].id == val ){
            deletid = i;
            break;
        }
    }
    EnLog.splice(deletid,1);
    setFilterFn({
        fn: items => {
          return items;
                
        }
    })
  }
  const [open, setOpen] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [titleforalert, setTitleforalert] = React.useState("");
  

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseActive =() =>{
    setOpen(false);
    let deletid;
      for(let i = 0; i < EnLog.length ; i++){
          if(EnLog[i].id == currentId ){
              deletid = i;
              break;
          }
      }
      switchToSubUser({email:token.email,
        account_name:EnLog[deletid].copmpany,username:'SUPER DEALER',user_id:EnLog[deletid].id,role:'admin', sub_type : token.sub_type});
  }
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const changeTimeZone  =  (date, timeZone)=> {
    const laDate = moment.utc(date).format();
    //if (typeof date === 'string') {
      const ldated =  new Date(
        new Date(laDate).toLocaleString('en-US', {
          timeZone,
        }),
      );
      const mystr = moment(ldated).format('YYYY-MM-DD');
      return mystr;
   /* }
  
    return new Date(
      date.toLocaleString('en-US', {
        timeZone,
      }),
    );*/
  }
  
  const handleSearch = () => {
    //let target = e.target;
    setFilterFn({
        fn: items => {
            if (filterText == "")
                return items;
            else{
              if(shownameType){
               
                
                console.log(shownameType);
                if(showPropertyNameType)
                return items.filter(x => x.name.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showCompanyName)
                return items.filter(x => x.copmpany.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showDealerCompany)
                return items.filter(x => x.dealer_company_name.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showTotalUnit)
                return items.filter(x => x.total_unit.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showMonthlyCost)
                return items.filter(x => x.monthly_cost.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showAnnualCost)
                return items.filter(x => x.annual_cost.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showOfficeNumber)
                return items.filter(x => x.office.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showEmail)
                return items.filter(x => x.email.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showPassword)
                return items.filter(x => x.password.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showAddress)
                return items.filter(x => x.address.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showCity)
                return items.filter(x => x.city.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showState)
                return items.filter(x => x.state.toString().toLowerCase().includes(filterText.toLowerCase()))

                else if(showZipCode)
                return items.filter(x => x.zip_code.toString().toLowerCase().includes(filterText.toLowerCase()))
                
                else if(showLocationCount)
                return items.filter(x => x.location_count.toString().toLowerCase().includes(filterText.toLowerCase()))
                
                else 
                return items;
                
              }
              else if(showDateAdded){
                
                var mystr = filterText;
                mystr = moment(mystr).format('YYYY-MM-DD');
                return items.filter(x => changeTimeZone(x.start_date, 'America/New_York').toLowerCase().includes(mystr))
              
              }
              else{
                return items;
              }
            }
                
        }
    })
    setPageToNewOne();
}
  return <><Box>
    <Typography sx={mainThemeStyle.lableActivityDown}> {"Summary Of Services"}</Typography>
  <Typography sx={mainThemeStyle.lableActivityDownOne}>{ "Total Units :" +gettotalUnit}</Typography>
  <Typography sx={mainThemeStyle.lableActivityDownTwo}>{ "Monthly Cost : $" +getMonthlyCost}</Typography>
  <Typography sx={mainThemeStyle.lableActivityDownThree}>{ "Annual Cost : $" +getYearlyCost}</Typography>
    <Typography sx={mainThemeStyle.lablefilter}>Filter</Typography>
  
  <Box sx={{position: 'absolute',
           width:'680px',
           height: '280px',
           top:'160px',
           left: '38px',
           display: { xs: "none", md: "flex" },
           right: '38px',
           flexGrow: 1,
          
           }}>
             
           <Stack direction="row" spacing={2} >
                  
                   <NestedButtonDropdown
            itemId={FilterMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={FilterMenu}
          /> {shownameType  &&
           
          <Typography sx={mainThemeStyle.lableContains}>Contains</Typography>}
              
                   
{shownameType &&
                   
                   <TextField
                        id="input-location"
                        ref={myContainer}
                        type= 'text'
                        name="text"
                        onChange={handleChangeText}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && filterText != '') {
                            handleSearch();
                          }
                        }}
                        sx={mainThemeStyle.InputFilter}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                                
                                color: 'rgba(255, 255, 255, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#171E29',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '5px 0 5px 0',
                                textIndent: '15px !important',
                                border: `1px solid rgba(31, 39, 54, 1)`,
                               
                                
                            },
                        }}
                       
                    />}
                    {showDateAdded  && <TextField
        id="date"
        type="date"
        hiddenLabel
        sx={mainThemeStyle.InputFilter}
        onChange={handleChangeText}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && filterText != '') {
            handleSearch();
          }
        }}
        InputLabelProps={{
          shrink: true,
        }}
        variant="filled"
        InputProps={{
                           
          sx: {
              
              color: 'rgba(255, 255, 255, 1)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#171E29',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              textIndent: '15px !important',
              border: `1px solid rgba(31, 39, 54, 1)`,
             
              
          },
      }}
     
      />}
                   <Button sx={mainThemeStyle.filterResetButton} onClick={handleSearch} disabled={!filterText}>Go</Button>
                   
                   <Button sx={mainThemeStyle.filterResetButton} onClick={handleReset}>Reset Filters</Button>
                  </Stack>
                  </Box>
          
  <Box sx={{position: 'absolute',
           width:'auto',
           height: '280px',
           top:'160px',
           left: '38px',
           display: { xs: "flex", md: "none" },
           right: '38px',
           flexGrow: 1,
          
           }}>
             <Grid container  direction="column">
             <Grid item >
           <Stack direction="row" spacing={2} >
                  
                   <NestedButtonDropdown
            itemId={FilterMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={FilterMenu}
          /> {/* {shownameType  &&
           
          <Typography sx={mainThemeStyle.lableContains}>Contains</Typography>} */}
                  {showDateAdded  && <TextField
        id="date"
        type="date"
        hiddenLabel
        sx={mainThemeStyle.InputFilter}
        onChange={handleChangeText}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && filterText != '') {
            handleSearch();
          }
        }}
        InputLabelProps={{
          shrink: true,
        }}
        variant="filled"
        InputProps={{
                           
          sx: {
              
              color: 'rgba(255, 255, 255, 1)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#171E29',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              textIndent: '15px !important',
              border: `1px solid rgba(31, 39, 54, 1)`,
             
              
          },
      }}/>}
                  

{shownameType &&
                   
                   <TextField
                        id="input-location"
                        ref={myContainer}
                        type= 'text'
                        name="text"
                        onChange={handleChangeText}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && filterText != '') {
                            handleSearch();
                          }
                        }}
                        sx={mainThemeStyle.InputFilterSmall}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                                
                                color: 'rgba(255, 255, 255, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#171E29',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '5px 0 5px 0',
                                textIndent: '15px !important',
                                border: `1px solid rgba(31, 39, 54, 1)`,
                               
                                
                            },
                        }}
                        
                       
                    />}</Stack></Grid>
                   <Grid >
                   <Stack direction="row" >
                   <Button sx={mainThemeStyle.filterResetButtonSmall} onClick={handleSearch} disabled={!filterText}>Go</Button>
                   
                   <Button sx={mainThemeStyle.filterResetButtonSmall} onClick={handleReset}>Reset Filters</Button>
                   </Stack>
                 </Grid></Grid></Box>
  
  
                 <Typography sx={mainThemeStyle.lableJump}>Jump</Typography>
                 
                 <ButtonGroup variant="text"  sx={{position: 'absolute',
           maxWidth:'250px',
           height: 'auto',
           top:'250px',
           left: '38px',
           display: { xs: "none", md: "flex" }
           }}>

          

          {
            Alphabet.map(item => (
              checkAtOrNot(item) > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  onClick={() => {jumpToAlphabet(item)}}>{item}</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>{item}</Button>
            ))
          }

    </ButtonGroup>
    
   
   <Box sx={mainThemeStyle.mainTablebox} backgroundColor="menubarprimary.main">
   <BelowTblPagination ></BelowTblPagination>
    <TblContainer>
                    <TblHead />
                    <TableBody>
                        {
                            recordsAfterPagingAndSorting().map(item =>
                                ( <TableRow
                                  key={item.id}
                                  sx={{ borderBottom: "1.5px solid black",'&:last-child td, &:last-child th': { border: 0 },
                                   }}
                                >
                             {token.sub_type == "1" && <TableCell align="left" sx={mainThemeStyle.cellGreenClickable} onClick={() => {ChangeId(item.id,3)}}>{item.copmpany}</TableCell>}
                                
                                  
                               {token.sub_type == "0" &&     <TableCell align="left" sx={mainThemeStyle.cellGreen}>{item.copmpany}</TableCell> }
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.name}</TableCell>
                                 {/*  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.dealer_company_name}</TableCell> */}
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.total_unit}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>${item.monthly_cost}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>${item.annual_cost}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{changeTimeZone(item.start_date, 'America/New_York')}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.email}</TableCell>
                               {/*    <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.password}</TableCell> */}
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.address}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.city}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.state}</TableCell>
                                  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.zip_code}</TableCell>
                                 {/*  <TableCell align="left" sx={mainThemeStyle.cellRow}>{item.location_count}</TableCell>
                               */}    <TableCell>
                                  <Stack direction="row" spacing={2}>
                                     
                                    <IconButton sx={{ backgroundColor: '#2A2F3B'}}  size="small" color="primary"  onClick={() => {ChangeId(item.id,0)}}><EditIcon /></IconButton>
                                    {token.issub == false && <IconButton sx={{ backgroundColor: '#2A2F3B'}}  size="small" color="greensuccess"  onClick={() => {ChangeId(item.id,3)}}><ManageAccountsIcon /></IconButton>}
                                   
                                    <IconButton sx={{ backgroundColor: '#2A2F3B'}}  size="small" color="redwarning"  onClick={() => {ChangeId(item.id,2)}}><DeleteIcon /></IconButton>
                                    </Stack> 
                                  </TableCell>
                                </TableRow>)
                            )
                        }
                    </TableBody>
                   
                </TblContainer>
                <Box sx={{marginTop:'10px'}}>
                <PuffLoader color="#878787" loading={loading} cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"50px",
    marginBottom:"50px",}} />



</Box></Box>
                 </Box>
                 <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleClose} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActive} autoFocus sx={mainThemeStyle.normalBigButton}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleCloseDelete} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActiveDelete} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog></> 
          
          
          
          
          ;
};

export default ManagePropertyDealer;