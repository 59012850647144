import './App.css';
import "./index.css";
import Home from "./home";
import HomeAdmin from './homeAdmin';
import HomeDistributor from './homeDistributor';
import HomeDealer from './homeDealer';
import HomeUser from './HomeUser';
import Location from "./location";
import LocationUser from './locationuser';
import User from "./user";
import UserUser from "./useruser";
import Activity from "./Activity";
import ActivityUser from './ActivityUser';
import SetUp from "./setup";
import Misc from "./misc";
import UserProfile from './userProfile';
import {mainThemeStyle} from './components/MainTheme';
import Navigation from './components/Navigation';
import Header from "./components/header/header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Popup from "./components/Popup";
import { Button, Grid, InputLabel, List, Typography,ButtonGroup ,IconButton, Icon,Box,Stack,TextField,InputAdornment,Alert,Snackbar } from "@mui/material";
import Property from './property';
import DistProfile from './DistProfile';
import ManagePropertyDist from './ManagePropertyDist';
import Dealer from './dealer';
import DealerDist from './dealerdist';
import Distributor from './distributor';
import DistributorManager from './DistributorManager';
import PropertyDealer from './propertydealer';
import DealerProfile from './DealerProfile';
import DealerManager from './DealerManager';
import QRWebViewer from './QRWebViewer';
import SystemLog from './SystemLog';
import useToken from './useToken';
import {  ThemeProvider } from '@mui/material/styles';
import Theme from './components/ItemTheme';
import { useRef,useEffect } from "react";
import Missing from './Missing';
import Login from './Login';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import HomeWebViewer from './HomeWebViewer';
import { useState } from 'react';
import Cookies from 'js-cookie'
import { Link ,useNavigate} from "react-router-dom";
import GlobalAlert from './GlobalAlert';
import GlobalRoleAccess from './GlobalRoleAccess';
import GlobalRoleAccessInner from './GlobalRoleAccessInner';
import DistributorSetUp from './distsetup';
import DealerSetUp from './dealersetup';
import { LoadingButton } from "@mui/lab";
import { Details } from '@mui/icons-material';
import { jsx } from '@emotion/react';
import axios from "./axios";
//import { makeStyles } from "@mui/material/styles";

//const useStyles = makeStyles({});

function App() {
 // const classes = useStyles();
 let navigate = useNavigate();
 const { setGlobalAlertData,globalAlertData} = GlobalAlert();
 const {globalRoleAccessData,setGlobalRoleAccessData} = GlobalRoleAccess();
 const {globalRoleAccessInnerData,setGlobalRoleAccessInnerData} = GlobalRoleAccessInner();
  const [userDetails,setUserDtails] = useState({"email":"","phone":"","type":"","password":"","id":"","subid":"","name":"","role":"","fname":"","lname":"", "token":"",issub:false, "sub_type" : "0","username":""});
  const { token, setToken,clearToken,serverDetails,setServerDetails } = useToken();
  const [localserverDetails,setLocalServerDetails] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupPassword, setOpenPopupPassword] = useState(false);
  const theme = Theme;
  const readCookie = () => {
    const user = Cookies.get("mdvlogin");
    if(user){

    }
    else{
      localStorage.clear();
      clearToken();
    }
  }
  useEffect(() => {
    readCookie();
  }, []);

  const [switchU,setSwitchU] = useState(0);
  const switchToSubUser = (val) => {
    let dataToSet = serverDetails[0];
    
    userDetails.email = val.email;
   // userDetails.password =val.password;
     
   // userDetails.phone = 'n/a';
   // userDetails.type = 'Web';
    userDetails.issub = true;
    userDetails.token = dataToSet.token;
      
    userDetails.fname = val.account_name;
    userDetails.name = val.username;
    userDetails.username = val.username;
    userDetails.id = val.user_id;
    userDetails.subid = token.subid;

    //console.log('subid is '+ userDetails.subid);
       
    userDetails.lname = '';
    
    userDetails.role = val.role;

    userDetails.sub_type = val.sub_type;
    
    if(val.role != 'superadmin')
    addlog({email:val.email,role:val.role,issub:true,id:val.user_id,name:val.username});
      
       
    setToken(userDetails);
    setGlobalAlertData(dataToSet.alertData);
    setGlobalRoleAccessData(dataToSet.role_access);
    setGlobalRoleAccessInnerData(dataToSet.role_access_inner);
    navigate('/');
   // console.log('subid is '+ JSON.stringify(dataToSet.role_access));
   // console.log('subid is '+ JSON.stringify(token));

   // console.log('value get '+JSON.stringify(val));
  }

  const handleSwitchUser = (e) => {
   // console.log('in handel swi');
    let ds = e.target.name;
     let dataToSet = serverDetails[ds];
   //  console.log('alert data'+JSON.stringify(globalAlertData));
     console.log("In switch user");
     let role = userDetails.role;
   //  console.log('alert data'+JSON.stringify(globalAlertData));
     if(dataToSet.role == 'superadmin'){
       userDetails.email = dataToSet.Admin_id;
       userDetails.password = dataToSet.Password;
       userDetails.issub = dataToSet.issub;
       userDetails.sub_type = dataToSet.sub_type;
       userDetails.name ='Super Admin';
       userDetails.username = 'Super Admin';
       if(dataToSet.issub == true){
        userDetails.username = dataToSet.username;
        userDetails.name = dataToSet.username;
        userDetails.email = dataToSet.email;
        userDetails.fname = dataToSet.account_name;
        userDetails.subid = dataToSet.id;
        userDetails.id = 'n/a';
       }else{
        userDetails.fname = '';
        userDetails.id = 'n/a';
        userDetails.subid ='';
       }
       userDetails.token = dataToSet.token;
       userDetails.lname = '';
       userDetails.phone = 'n/a';
       userDetails.type = 'Web';
       
       
       console.log("In switch user super");
       userDetails.role = dataToSet.role;
       if(dataToSet.issub == true && role != 'superadmin')
       addlog({email:dataToSet.email,role:dataToSet.role,issub:dataToSet.issub,id:dataToSet.id,name:dataToSet.username});
      
       
     }
     else if(dataToSet.role == 'admin' || dataToSet.role == 'webviewer'){
     userDetails.email = dataToSet.email;
     userDetails.password =dataToSet.password;
     userDetails.sub_type = dataToSet.sub_type;
     userDetails.phone = 'n/a';
       userDetails.type = 'Web';
       userDetails.issub = dataToSet.issub;
       userDetails.token = dataToSet.token;
       if(dataToSet.issub == true){
        userDetails.fname = dataToSet.account_name;
      userDetails.name = dataToSet.username;
      userDetails.username = dataToSet.username;
      userDetails.id = dataToSet.user_id;
      userDetails.subid = dataToSet.id;
      if(dataToSet.role == 'admin')
        addlog({email:dataToSet.email,role:dataToSet.role,issub:dataToSet.issub,id:dataToSet.user_id,name:dataToSet.username});
     
       }else{
        //console.log('dataset'+JSON.stringify(dataToSet));
        userDetails.fname = '';
        userDetails.username = dataToSet.name;
        userDetails.name = dataToSet.copmpany;
        userDetails.id = dataToSet.id;
        userDetails.subid ='';
        if(dataToSet.role == 'admin')
        addlog({email:dataToSet.email,role:dataToSet.role,issub:dataToSet.issub,id:dataToSet.id,name:dataToSet.name});
    
       }
       userDetails.lname = '';
    
     userDetails.role = dataToSet.role;
   }
   else if(dataToSet.role == 'distributor'){
     userDetails.email = dataToSet.email;
     userDetails.password = dataToSet.password;
     userDetails.sub_type = dataToSet.sub_type;
     userDetails.phone = 'n/a';
       userDetails.type = 'Web';
       userDetails.issub = dataToSet.issub;
       userDetails.token = dataToSet.token;
       if(dataToSet.issub == true){
        userDetails.fname = dataToSet.account_name;
        userDetails.name = dataToSet.username;
        userDetails.username = dataToSet.username;
        userDetails.id = dataToSet.user_id;
      userDetails.subid = dataToSet.id;
      addlog({email:dataToSet.email,role:dataToSet.role,issub:dataToSet.issub,id:dataToSet.user_id,name:dataToSet.username});
   
       }else{
        userDetails.fname = '';
        userDetails.username = dataToSet.contact_name;
        userDetails.name = dataToSet.company_name;
        userDetails.subid ='';
        userDetails.id = dataToSet.dis_id;
        addlog({email:dataToSet.email,role:dataToSet.role,issub:dataToSet.issub,id:dataToSet.dis_id,name:dataToSet.contact_name});
     
       }
       userDetails.lname = '';
    
     userDetails.role = dataToSet.role;

   //  console.log('account_name '+dataToSet.company_name);
    // console.log('username '+dataToSet.username);
    // console.log('contact_name '+dataToSet.contact_name);
   }
   else if(dataToSet.role == 'dealer'){
     userDetails.email =dataToSet.email;
     userDetails.password = dataToSet.password;
     userDetails.sub_type = dataToSet.sub_type;
     userDetails.phone = 'n/a';
       userDetails.type = 'Web';
       userDetails.issub = dataToSet.issub;
       userDetails.token = dataToSet.token;
       if(dataToSet.issub == true){
        userDetails.fname = dataToSet.account_name;
        userDetails.name = dataToSet.username;
        userDetails.username = dataToSet.username;
        userDetails.id = dataToSet.user_id;
      userDetails.subid = dataToSet.id;
      addlog({email:dataToSet.email,role:dataToSet.role,issub:dataToSet.issub,id:dataToSet.user_id,name:dataToSet.username});
   
       }else{
        userDetails.fname = '';
        userDetails.name = dataToSet.company_name;
        userDetails.username = dataToSet.contact_name;
        userDetails.subid ='';
        userDetails.id = dataToSet.del_id;
        addlog({email:dataToSet.email,role:dataToSet.role,issub:dataToSet.issub,id:dataToSet.del_id,name:dataToSet.contact_name});
     
       }
       userDetails.lname = '';
     
     userDetails.role = dataToSet.role;
   }
   else if(dataToSet.role == 'user'){
    userDetails.email =dataToSet.user_email;
    userDetails.password = 'n/a';
    userDetails.id = dataToSet.id;
    userDetails.phone = dataToSet.phone_no;
     userDetails.type = dataToSet.type;
     userDetails.fname =dataToSet.fname;
       userDetails.lname = dataToSet.lname;
       userDetails.token = dataToSet.token;
    userDetails.name = dataToSet.fname+" "+dataToSet.lname;
    userDetails.username = dataToSet.fname+" "+dataToSet.lname;
    userDetails.role = dataToSet.role;
  }
  //console.log('subid is '+ JSON.stringify(dataToSet.role_access));
   setOpenPopup(false);
   setToken(userDetails);
   setGlobalAlertData(dataToSet.alertData);
   setGlobalRoleAccessData(dataToSet.role_access);
    setGlobalRoleAccessInnerData(dataToSet.role_access_inner);
   setSwitchU(switchU+1);
   navigate('/');
  }
  const handleLoginCredential = (e) => {
    //console.log(e.target.name);
    let ds = e.target.name;
  //  console.log('in handel swidd');
   /* for(let i = 0; i < setServerDetails.length ; i++){
      if(arrayOfChange[i].group_id == ds ){
          idIn = i;
          break;
      }
    }*/
    let dataToSet = localserverDetails[ds];
    
    if(dataToSet.role == 'superadmin'){
      userDetails.email = dataToSet.Admin_id;
      userDetails.password = dataToSet.Password;
      userDetails.issub = dataToSet.issub;
      userDetails.token = dataToSet.token;
      userDetails.name ='Super Admin';
      userDetails.username = 'Super Admin';
      userDetails.sub_type = dataToSet.sub_type;
       if(dataToSet.issub == true){
        userDetails.username = dataToSet.username;
        userDetails.name = dataToSet.username;
        userDetails.email = dataToSet.email;
        userDetails.fname = dataToSet.account_name;
        userDetails.id = '';
        userDetails.subid = dataToSet.id;
       }else{
        userDetails.fname = '';
        userDetails.id = 'n/a';
        userDetails.subid = '';
       }
     
      userDetails.phone = 'n/a';
       userDetails.type = 'Web';
       addlog({email:dataToSet.email,role:dataToSet.role,issub:dataToSet.issub,id:dataToSet.id,name:dataToSet.username});
      
      userDetails.lname = '';
      userDetails.role = dataToSet.role;
    }
    else if(dataToSet.role == 'admin' || dataToSet.role == 'webviewer'){
    userDetails.email = dataToSet.email;
    userDetails.password =dataToSet.password;
    userDetails.issub = dataToSet.issub;
    userDetails.token = dataToSet.token;
    userDetails.sub_type = dataToSet.sub_type;
       if(dataToSet.issub == true){
        userDetails.fname = dataToSet.account_name;
        userDetails.name = dataToSet.username;
        userDetails.username = dataToSet.username;
        userDetails.id = dataToSet.user_id;
      userDetails.subid = dataToSet.id;
      if(dataToSet.role == 'admin')
        addlog({email:dataToSet.email,role:dataToSet.role,issub:dataToSet.issub,id:dataToSet.user_id,name:dataToSet.username});
     
       }else{
        userDetails.fname = '';
        userDetails.username = dataToSet.name;
        userDetails.name = dataToSet.copmpany;
        userDetails.id = dataToSet.id;
      userDetails.subid = '';
      if(dataToSet.role == 'admin')
      addlog({email:dataToSet.email,role:dataToSet.role,issub:dataToSet.issub,id:dataToSet.id,name:dataToSet.name});
  
       }
    
    userDetails.phone = 'n/a';
       userDetails.type = 'Web';
    
    
    userDetails.lname = '';
    userDetails.role = dataToSet.role;
  }
  else if(dataToSet.role == 'distributor'){
    userDetails.email = dataToSet.email;
    userDetails.password = dataToSet.password;
    userDetails.sub_type = dataToSet.sub_type;
    userDetails.issub = dataToSet.issub;
    userDetails.token = dataToSet.token;
    if(dataToSet.issub == true){
     userDetails.fname = dataToSet.account_name;
     userDetails.name = dataToSet.username;
     userDetails.username = dataToSet.username;
     userDetails.id = dataToSet.user_id;
     userDetails.subid = dataToSet.id;
     addlog({email:dataToSet.email,role:dataToSet.role,issub:dataToSet.issub,id:dataToSet.user_id,name:dataToSet.username});
   
    }else{
     userDetails.fname = '';
     userDetails.username = dataToSet.contact_name;
     userDetails.name = dataToSet.company_name;
     userDetails.id = dataToSet.dis_id;
     userDetails.subid = '';
     addlog({email:dataToSet.email,role:dataToSet.role,issub:dataToSet.issub,id:dataToSet.dis_id,name:dataToSet.contact_name});
      
    }    
    userDetails.phone = 'n/a';
    userDetails.type = 'Web';
  
   
    userDetails.lname = '';
    userDetails.role = dataToSet.role;
  }
  else if(dataToSet.role == 'dealer'){
    userDetails.email =dataToSet.email;
    userDetails.password = dataToSet.password;
    userDetails.sub_type = dataToSet.sub_type;
    userDetails.issub = dataToSet.issub;
    userDetails.token = dataToSet.token;
    if(dataToSet.issub == true){
     userDetails.fname = dataToSet.account_name;
     userDetails.name = dataToSet.username;
     userDetails.username = dataToSet.username;
     userDetails.id = dataToSet.user_id;
     userDetails.subid = dataToSet.id;
     addlog({email:dataToSet.email,role:dataToSet.role,issub:dataToSet.issub,id:dataToSet.user_id,name:dataToSet.username});
   
    }else{
     userDetails.fname = '';
     userDetails.name = dataToSet.company_name;
     userDetails.username = dataToSet.contact_name;
     userDetails.id = dataToSet.del_id;
     userDetails.subid = '';
     addlog({email:dataToSet.email,role:dataToSet.role,issub:dataToSet.issub,id:dataToSet.del_id,name:dataToSet.contact_name});
     
    }
    
    userDetails.phone = 'n/a';
    userDetails.type = 'Web';
   
    userDetails.role = dataToSet.role;
    
    userDetails.lname = '';
  }
  else if(dataToSet.role == 'user'){
    userDetails.email =dataToSet.user_email;
    userDetails.password = 'n/a';
    userDetails.id = dataToSet.id;
    userDetails.token = dataToSet.token;
    userDetails.phone = dataToSet.phone_no;
     userDetails.type = dataToSet.type;
    userDetails.name = dataToSet.fname+" "+dataToSet.lname;
    userDetails.username = dataToSet.fname+" "+dataToSet.lname;
    userDetails.fname = dataToSet.fname;
    userDetails.lname = dataToSet.lname;
    userDetails.role = dataToSet.role;
  }
  localStorage.clear();
   
  setOpenPopup(false);
  //setUserDtails(userDetails);
  Cookies.set('mdvlogin', true, { expires: 30 });
    setToken(userDetails);
    setServerDetails(localserverDetails);
    setGlobalAlertData(dataToSet.alertData);
    setGlobalRoleAccessData(dataToSet.role_access);
    setGlobalRoleAccessInnerData(dataToSet.role_access_inner);
    navigate('/');
    
  }

  const LoginUser = (details) =>{
    ///console.log('In like wise'+details.rows);
    
    setLocalServerDetails(details.rows);
    
    if((details.rows).length > 1){
    setOpenPopup(true);
     }
    else{
      let dataToSet = details.rows[0];
    
      if(dataToSet.role == 'superadmin'){
        userDetails.email = dataToSet.Admin_id;
        userDetails.password = dataToSet.Password;
        userDetails.issub = dataToSet.issub;
        userDetails.phone = 'n/a';
       userDetails.type = 'Web';
        userDetails.name ='Super Admin';
        userDetails.username = 'Super Admin';
        userDetails.token = dataToSet.token;
        userDetails.role = dataToSet.role;
        userDetails.sub_type = dataToSet.sub_type;
        if(dataToSet.issub == true){
          userDetails.username = dataToSet.username;
          userDetails.name = dataToSet.username;
          userDetails.email = dataToSet.email;
          userDetails.fname = dataToSet.account_name;
          userDetails.id = '';
          userDetails.subid = dataToSet.id;
         }else{

          userDetails.fname = '';
          userDetails.id = 'n/a';
          userDetails.subid = '';
         }
        userDetails.lname = '';
        addlog({email:dataToSet.email,role:dataToSet.role,issub:dataToSet.issub,id:dataToSet.id,name:dataToSet.username});
      }
      else if(dataToSet.role == 'admin' || dataToSet.role == 'webviewer'){
      userDetails.email = dataToSet.email;
      userDetails.password =dataToSet.password;
      userDetails.issub = dataToSet.issub;
      userDetails.token = dataToSet.token;
      userDetails.phone = 'n/a';
       userDetails.type = 'Web';
       userDetails.sub_type = dataToSet.sub_type;
      userDetails.role = dataToSet.role;
      if(dataToSet.issub == true){
        userDetails.fname = dataToSet.account_name;
        userDetails.name = dataToSet.username;
        userDetails.username = dataToSet.username;
        userDetails.id = dataToSet.user_id;
        userDetails.subid = dataToSet.id;
        if(dataToSet.role == 'admin')
        addlog({email:dataToSet.email,role:dataToSet.role,issub:dataToSet.issub,id:dataToSet.user_id,name:dataToSet.username});
       }else{
        userDetails.fname = '';
        userDetails.name = dataToSet.copmpany;
        userDetails.username = dataToSet.name;
        userDetails.id = dataToSet.id;
        userDetails.subid = '';
        if(dataToSet.role == 'admin')
        addlog({email:dataToSet.email,role:dataToSet.role,issub:dataToSet.issub,id:dataToSet.id,name:dataToSet.name});
       }
        userDetails.lname = '';
       
    }
    else if(dataToSet.role == 'distributor'){
      userDetails.email = dataToSet.email;
      userDetails.password = dataToSet.password;
      userDetails.issub = dataToSet.issub;
      userDetails.token = dataToSet.token;
      userDetails.phone = 'n/a';
       userDetails.type = 'Web';
       userDetails.sub_type = dataToSet.sub_type;
       if(dataToSet.issub == true){
        userDetails.fname = dataToSet.account_name;
        userDetails.name = dataToSet.username;
        userDetails.username = dataToSet.username;
        userDetails.id = dataToSet.user_id;
        userDetails.subid = dataToSet.id;
        addlog({email:dataToSet.email,role:dataToSet.role,issub:dataToSet.issub,id:dataToSet.user_id,name:dataToSet.username});
       }else{
        userDetails.fname = '';
        userDetails.name = dataToSet.company_name;
        userDetails.username = dataToSet.contact_name;
        userDetails.id = dataToSet.dis_id;
        userDetails.subid = '';
        addlog({email:dataToSet.email,role:dataToSet.role,issub:dataToSet.issub,id:dataToSet.dis_id,name:dataToSet.contact_name});
       }
      userDetails.role = dataToSet.role;
      
    userDetails.lname = '';
    addlog({email:dataToSet.email,role:dataToSet.role,issub:dataToSet.issub,id:dataToSet.id});
    }
    else if(dataToSet.role == 'dealer'){
      userDetails.email =dataToSet.email;
      userDetails.password = dataToSet.password;
      userDetails.issub = dataToSet.issub;
      userDetails.token = dataToSet.token;
      userDetails.phone = 'n/a';
       userDetails.type = 'Web';
       userDetails.sub_type = dataToSet.sub_type;
       if(dataToSet.issub == true){
        userDetails.fname = dataToSet.account_name;
        userDetails.name = dataToSet.username;
        userDetails.username = dataToSet.username;
        userDetails.id = dataToSet.user_id;
        userDetails.subid = dataToSet.id;
        addlog({email:dataToSet.email,role:dataToSet.role,issub:dataToSet.issub,id:dataToSet.user_id,name:dataToSet.username});
       }else{
        userDetails.fname = '';
        userDetails.name = dataToSet.company_name;
        userDetails.username = dataToSet.contact_name;
        userDetails.id = dataToSet.del_id;
        userDetails.subid = '';
        addlog({email:dataToSet.email,role:dataToSet.role,issub:dataToSet.issub,id:dataToSet.del_id,name:dataToSet.contact_name});
       }
      userDetails.role = dataToSet.role;
    userDetails.lname = '';
   
    }
    else if(dataToSet.role == 'user'){
      userDetails.email =dataToSet.user_email;
      userDetails.password = 'n/a';
      userDetails.token = dataToSet.token;
      userDetails.id = dataToSet.id;
      userDetails.phone = dataToSet.phone_no;
       userDetails.type = dataToSet.type;
      userDetails.name = dataToSet.fname+" "+dataToSet.lname;
      userDetails.username = dataToSet.fname+" "+dataToSet.lname;
      userDetails.fname = dataToSet.fname
    userDetails.lname = dataToSet.lname;
      userDetails.role = dataToSet.role;
    }
    localStorage.clear();
     clearToken();
     Cookies.set('mdvlogin', true, { expires: 30 });
      setOpenPopup(false);
      setToken(userDetails);
      setServerDetails(details.rows);
      setGlobalAlertData(dataToSet.alertData);
      setGlobalRoleAccessData(dataToSet.role_access);
    setGlobalRoleAccessInnerData(dataToSet.role_access_inner);
      navigate('/');
     
    
    } 
   
  }
  const ShowLog = () =>{
   
  return(<Grid container spacing={2} direction="column"> {
    
    localserverDetails.map((row,index) => <Button name={index} sx={mainThemeStyle.popUpButton}  onClick={handleLoginCredential}>
      {/* {row.role == 'superadmin' ? 'SUPER ADMINISTRATOR USER' : ( row.role == 'admin' ? (row.issub == true ? row.username :row.name) :( row.role == 'user' ? row.fname+" "+row.lname :  (row.issub == true ? row.username :row.contact_name)))} as {row.role}  {row.issub == true ? (' for '+ row.account_name): '' }
     */}  {/* {row.role == 'superadmin' ? (row.issub == true ? 'SUPER ADMINISTRATOR SUB USER' :'SUPER ADMINISTRATOR USER') : 
      ( row.role == 'admin' ? (row.issub == true ? 'PROPERTY MANAGER for '+ row.account_name:'PROPERTY ADMIN for '+ row.copmpany)
       :( row.role == 'distributor' ? (row.issub == true ? 'PARTNER MANAGER for '+ row.account_name:'PARTNER ADMIN for '+ row.company_name) :
       ( row.role == 'dealer' ? (row.issub == true ? 'DEALER MANAGER for '+ row.account_name:'DEALER ADMIN for '+ row.company_name) :( row.role == 'webviewer' ? (row.issub == true ? 'Web MANAGER for '+ row.account_name:'Web ADMIN for '+ row.copmpany) :row.fname+" "+row.lname+ " as " + row.role)))) } */}
      {row.role == 'superadmin' ? (row.issub == true ? (row.sub_type == '1' ? 'SUPER ADMINISTRATOR USER': 'SUPER ADMINISTRATOR SUB-USER')  :'SUPER ADMINISTRATOR USER') : 
      ( row.role == 'admin' ? (row.issub == true ? (row.sub_type == '1' ? 'PROPERTY PRIMARY ADMIN for '+ row.account_name: 'PROPERTY MANAGER for '+ row.account_name) :'PROPERTY PRIMARY ADMIN for '+ row.copmpany)
       :( row.role == 'distributor' ? (row.issub == true ? (row.sub_type == '1' ? 'PARTNER PRIMARY ADMIN for '+ row.account_name: 'PARTNER MANAGER for '+ row.account_name) :'PARTNER PRIMARY ADMIN for '+ row.company_name) :
       ( row.role == 'dealer' ? (row.issub == true ? (row.sub_type == '1' ? 'DEALER PRIMARY ADMIN for '+ row.account_name: 'DEALER MANAGER for '+ row.account_name):'DEALER PRIMARY ADMIN for '+ row.company_name):( row.role == 'webviewer' ? (row.issub == true ? (row.sub_type == '1' ? 'WEB PRIMARY ADMIN for '+ row.account_name: 'WEB MANAGER for '+ row.account_name):'WEB PRIMARY ADMIN for '+ row.copmpany) :row.fname+" "+row.lname+ " as " + row.role)))) }
      </Button>)
      }
    </Grid>)
}
const handleCloseSnack= (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setOpenSnack(false);
};
const [isVisible ,setIsVisibile] = useState(false);
const [isVisibleReenter,setIsVisibleReenter] = useState(false);
const [passwords, setPasswords] = useState('');
const [passwordsReEnter, setPasswordsReEnter] = useState('');
const [openSnack, setOpenSnack] = useState(false);
  const [openSnackData, setOpenSnackData] =useState("This is Success Data");
  const [severityData, setSeverityData] =useState("success");

const handlePasswordReEnterChange= (event) => {
  setPasswordsReEnter(event.target.value);
}
const handlePasswordChange = (event) => {
  setPasswords(event.target.value);
}
const onClickTogglePassword = () => {
  setIsVisibile(!isVisible);
}

const onClickToggleReEnterPassword = () => {
  setIsVisibleReenter(!isVisibleReenter);
}
const { getAlertTitle} = GlobalAlert();
const handleChangePassword = (event) => {

  if(passwords == ""){
    setOpenSnackData(getAlertTitle('PNE'));
    setSeverityData("error");
    setOpenSnack(true);
  }else if(passwordsReEnter == ""){
    setOpenSnackData(getAlertTitle('RPNE'));
    setSeverityData("error");
    setOpenSnack(true);
  }
  else if(passwords.length < 6){
    setOpenSnackData(getAlertTitle('PLST6E'));
    setSeverityData("error");
    setOpenSnack(true);
  }
  else if(passwords != passwordsReEnter){
    setOpenSnackData(getAlertTitle('PRPNS'));
    setSeverityData("error");
    setOpenSnack(true);
  }
  else{
    updateMyPostData();
  }
  
}
let [loading, setLoading] = useState(false);

const addlog = async (val) => {
  try {
    const res = await axios.post("/addloginevent",val, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,
    'token' : token.token,
    'tokenid' : token.email}});
    console.log( "log posted");
  
  } catch (error) {
    //console.log(error.response);
  
   
  }

}
const updateMyPostData = async () => {
  setLoading(true);
  try {
    const res = await axios.post("/changePassword",{email:token.email,password:passwords}, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,
    'token' : token.token,
    'tokenid' : token.email}});
    //console.log( res.data);
    setOpenSnackData(getAlertTitle('PSUS'));
    setSeverityData("success");
    setOpenSnack(true);
    setLoading(false);
    setOpenPopupPassword(false);
  } catch (error) {
    //console.log(error.response);
    setLoading(false);
    setOpenSnackData(getAlertTitle('PSUF'));
    setSeverityData("error");
    setOpenSnack(true);
    if(error.response.data.message == 'UnAuthorized'){
      RefreshToken();
    }
  }
};
const ShowInsideLog = () =>{
   
  return(<Grid container spacing={2} direction="column"> {
    
    serverDetails.map((row,index) => <Button name={index} sx={mainThemeStyle.popUpButton}  onClick={handleSwitchUser}>
    {/*  {row.role == 'superadmin' ? 'SUPER ADMINISTRATOR USER' : ( row.role == 'admin' ? (row.issub == true ? row.username :row.name) :( row.role == 'user' ? row.fname+" "+row.lname :  (row.issub == true ? row.username :row.contact_name)))} as {row.role}  {row.issub == true ? (' for '+ row.account_name): '' }
     */} {row.role == 'superadmin' ? (row.issub == true ? (row.sub_type == '1' ? 'SUPER ADMINISTRATOR USER': 'SUPER ADMINISTRATOR SUB-USER')  :'SUPER ADMINISTRATOR USER') : 
      ( row.role == 'admin' ? (row.issub == true ? (row.sub_type == '1' ? 'PROPERTY PRIMARY ADMIN for '+ row.account_name: 'PROPERTY MANAGER for '+ row.account_name) :'PROPERTY PRIMARY ADMIN for '+ row.copmpany)
       :( row.role == 'distributor' ? (row.issub == true ? (row.sub_type == '1' ? 'PARTNER PRIMARY ADMIN for '+ row.account_name: 'PARTNER MANAGER for '+ row.account_name) :'PARTNER PRIMARY ADMIN for '+ row.company_name) :
       ( row.role == 'dealer' ? (row.issub == true ? (row.sub_type == '1' ? 'DEALER PRIMARY ADMIN for '+ row.account_name: 'DEALER MANAGER for '+ row.account_name):'DEALER PRIMARY ADMIN for '+ row.company_name):( row.role == 'webviewer' ? (row.issub == true ? (row.sub_type == '1' ? 'WEB PRIMARY ADMIN for '+ row.account_name: 'WEB MANAGER for '+ row.account_name):'WEB PRIMARY ADMIN for '+ row.copmpany) :row.fname+" "+row.lname+ " as " + row.role)))) }
      </Button>)
      }
    </Grid>)
}
  const LogOutUser = () => {
    Cookies.remove('mdvlogin');
    clearToken();
    setLocalServerDetails([]);
  }

  const RefreshToken = () => {
    
    setToken({"email":token.email,
    "phone":token.phone,
    "type":token.type,
    "password":token.password,
    "id":token.id,
    "subid":token.subid,
    "name":token.name,
    "role":token.role,
    "fname":token.fname,
    "lname":token.lname, 
    "token":"",
    "issub":token.issub});
    navigate('/');
  }
  
  if(!token){
   return<> <Routes><Route exact path ="*" element={<Login  LoginUser={LoginUser} token={token} showAdLogin={true} isWebViewer={false}/> } />
   <Route exact path ="/resident" element={<Login  LoginUser={LoginUser} token={token} showAdLogin={false} isWebViewer={false}/>} />
   <Route exact path ="/webviewer" element={<Login  LoginUser={LoginUser} token={token} showAdLogin={true} isWebViewer={true}/>} />
    <Route exact path ="/virtual" element={<QRWebViewer/>} /> 
    <Route exact path ="/virtual/:id" element={<QRWebViewer/>} /> 
   </Routes>
   
   <ThemeProvider theme={theme}>
    <Popup
                title="Select User Account"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                noclose = {true}
            >
             
      <ShowLog/>
               
            </Popup></ThemeProvider>
   </>
  }
  if(token.token.length < 5){
    return<> <Routes><Route exact path ="*" element={<Login  LoginUser={LoginUser}  token={token} showAdLogin={true} isWebViewer={false}/>} />
    <Route exact path ="/resident" element={<Login  LoginUser={LoginUser} token={token} showAdLogin={false} isWebViewer={false}/>} />
    <Route exact path ="/webviewer" element={<Login  LoginUser={LoginUser} token={token} showAdLogin={true} isWebViewer={true}/>} />
    <Route exact path ="/virtual" element={<QRWebViewer/>} /> 
    <Route exact path ="/virtual/:id" element={<QRWebViewer/>} /> 
    </Routes>
   <ThemeProvider theme={theme}>
     <Popup
                 title="Select User Account"
                 openPopup={openPopup}
                 setOpenPopup={setOpenPopup}
                 noclose = {true}
             >
              
       <ShowLog/>
                
             </Popup></ThemeProvider>
    </>
   }
  return (
    <div >
    <Header LogOutUser={LogOutUser} setOpenPopup={setOpenPopup} token={token} setOpenPopupPassword={setOpenPopupPassword}/>
      <Routes>
        {(token.role == 'admin' &&  <Route exact path ="/" element={<Home />} />)}
        {(token.role == 'superadmin' &&  <Route exact path ="/" element={<HomeAdmin />} />)}
        {(token.role == 'distributor' &&  <Route exact path ="/" element={<HomeDistributor />} />)}
        {(token.role == 'dealer' &&  <Route exact path ="/" element={<HomeDealer />} />)}
        {(token.role == 'user' &&  <Route exact path ="/" element={<HomeUser />} />)}
        {(token.role == 'webviewer' &&  <Route exact path ="/" element={<HomeWebViewer RefreshToken={RefreshToken} token={token} switchU={switchU}/>} />)}
        {(token.role == 'admin' && <Route exact path="/location" element={<Location RefreshToken={RefreshToken}/>} />)}
        {(token.role == 'admin' && <Route exact path="/location/:id" element={<Location RefreshToken={RefreshToken}/>} />)}
        {(token.role == 'admin' && <Route exact path="/user" element={ <User RefreshToken={RefreshToken}/>} />)}
        {(token.role == 'admin' && <Route exact path="/user/:id" element={ <User RefreshToken={RefreshToken}/>} />)}
        {(token.role == 'admin' && <Route exact path="/user/:id/:id" element={ <User RefreshToken={RefreshToken}/>} />)}
        {(token.role == 'admin' && <Route exact path="/activity" element={ <Activity  RefreshToken={RefreshToken}/>} />)}
        {(token.role == 'admin' && <Route exact path="/activity/:id" element={ <Activity RefreshToken={RefreshToken}/>} />)}
        {(token.role == 'admin' && <Route exact path="/setup" element={ <SetUp  RefreshToken={RefreshToken}/>} />)}
        {(token.role == 'admin' && <Route exact path="/webviewer" element={<HomeWebViewer RefreshToken={RefreshToken} token={token} switchU={switchU}/>} />)}
        {(token.role == 'admin' && <Route exact path="/setup/:id" element={ <SetUp  RefreshToken={RefreshToken}/>} />)}
        {(token.role == 'admin' && <Route exact path="/setup/:id/:id" element={ <SetUp  RefreshToken={RefreshToken}/>} />)}
        {(token.role == 'superadmin' &&  <Route exact path="/propertyowner" element={<Property RefreshToken={RefreshToken} switchToSubUser={switchToSubUser}/>} />)}
        {(token.role == 'superadmin' &&  <Route exact path="/propertyowner/:id" element={<Property RefreshToken={RefreshToken} switchToSubUser={switchToSubUser}/>} />)}
        {(token.role == 'superadmin' &&  <Route exact path="/dealer" element={<Dealer RefreshToken={RefreshToken} switchToSubUser={switchToSubUser}/>} />)}
        {(token.role == 'superadmin' &&  <Route exact path="/dealer/:id" element={<Dealer RefreshToken={RefreshToken} switchToSubUser={switchToSubUser}/>} />)}
        {(token.role == 'superadmin' &&  <Route exact path="/distributor" element={<Distributor RefreshToken={RefreshToken} switchToSubUser={switchToSubUser}/>} />)}
        {(token.role == 'superadmin' &&  <Route exact path="/distributor/:id" element={<Distributor RefreshToken={RefreshToken} switchToSubUser={switchToSubUser}/>} />)}
        {(token.role == 'superadmin' &&  <Route exact path="/extra" element={<Misc RefreshToken={RefreshToken} switchToSubUser={switchToSubUser}/>} />)}
        {(token.role == 'superadmin' &&  <Route exact path="/extra/:id" element={<Misc RefreshToken={RefreshToken} switchToSubUser={switchToSubUser}/>} />)}
        {(token.role == 'distributor' &&  <Route exact path="/dealer" element={<DealerDist RefreshToken={RefreshToken} switchToSubUser={switchToSubUser}/>} />)}
        {(token.role == 'distributor' &&  <Route exact path="/dealer/:id" element={<DealerDist RefreshToken={RefreshToken} switchToSubUser={switchToSubUser}/>} />)}
        {(token.role == 'distributor' &&  <Route exact path="/property" element={<ManagePropertyDist RefreshToken={RefreshToken} switchToSubUser={switchToSubUser}/>} />)}
        {(token.role == 'distributor' &&  <Route exact path="/profile" element={<DistProfile RefreshToken={RefreshToken} switchToSubUser={switchToSubUser}/>} />)}
        {(token.role == 'distributor' &&  <Route exact path="/setup" element={<DistributorSetUp RefreshToken={RefreshToken}/>} />)}
        {(token.role == 'distributor' &&  <Route exact path="/setup/:id" element={<DistributorSetUp RefreshToken={RefreshToken}/>} />)}
        {(token.role == 'dealer' &&  <Route exact path="/property" element={<PropertyDealer RefreshToken={RefreshToken} switchToSubUser={switchToSubUser}/>} />)}
        {(token.role == 'dealer' &&  <Route exact path="/property/:id" element={<PropertyDealer RefreshToken={RefreshToken} switchToSubUser={switchToSubUser}/>} />)}
        {(token.role == 'dealer' &&  <Route exact path="/profile" element={<DealerProfile RefreshToken={RefreshToken}/>} />)}
        {(token.role == 'dealer' &&  <Route exact path="/setup" element={<DealerSetUp RefreshToken={RefreshToken}/>} />)}
        {(token.role == 'dealer' &&  <Route exact path="/setup/:id" element={<DealerSetUp RefreshToken={RefreshToken}/>} />)}
        {(token.role == 'user' && <Route exact path="/activity" element={ <ActivityUser RefreshToken={RefreshToken}/>} />)}
        {(token.role == 'user' && <Route exact path="/activity/:id" element={ <ActivityUser RefreshToken={RefreshToken}/>} />)}
        {(token.role == 'user' && <Route exact path="/location" element={<LocationUser RefreshToken={RefreshToken}/>} />)}
        {(token.role == 'user' && <Route exact path="/user" element={ <UserUser RefreshToken={RefreshToken}/>} />)}
        {(token.role == 'user' && <Route exact path="/user/:id" element={ <UserUser RefreshToken={RefreshToken}/>} />)}
        {(token.role == 'user' && <Route exact path="/profile" element={ <UserProfile RefreshToken={RefreshToken}/>} />)}
        <Route exact path ="/virtual" element={<QRWebViewer/>} /> 
    <Route exact path ="/virtual/:id" element={<QRWebViewer/>} /> 
        <Route  path="*" element={<Missing />} />
      </Routes>
      <ThemeProvider theme={theme}>
    <Popup
                title="Select User Account"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
             
      <ShowInsideLog/>
               
            </Popup>
            <Popup 
              title="Change Password"
              openPopup={openPopupPassword}
              setOpenPopup={setOpenPopupPassword}
          >
           
           <>
     <Box sx={{
      display: { xs: "none", md: "flex" },
      }}>
    <Grid container spacing={2}> 
      <Grid item >
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> New Password </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> Re-Enter Password </Typography>
      </Stack>
      </Grid>
      <Grid item >
      <Stack direction="column"  spacing={2}>
      <TextField
                        id="input-password"
                        type= {isVisible ? 'text' : 'password'}
                        name="password"
                        placeholder="Password"
                        height={40}
                        sx={mainThemeStyle.InputMenuItem}
                        value={passwords}
                        variant = "filled"
                        onChange={handlePasswordChange}
                        InputLabelProps={{ shrink: true }}
                        
                        InputProps={{
                           
                            style: {
                                
                              color:'rgba(170, 170, 170, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                               
                                
                            },
                            
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        style={{
                                            color:
                                            'rgba(170, 170, 170,1)',
                                            padding: '18px 5px 0 0',
                                        }}
                                        aria-label={
                                            'visibility token'
                                        }
                                        onClick={onClickTogglePassword}
                                    >
                                       
                                       {isVisible ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                        
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                       
                    />
                    <TextField
                        id="input-password"
                        type= {isVisibleReenter ? 'text' : 'password'}
                        name="password"
                        placeholder="Password"
                        height={40}
                        sx={mainThemeStyle.InputMenuItem}
                        value={passwordsReEnter}
                        variant = "filled"
                        onChange={handlePasswordReEnterChange}
                        InputLabelProps={{ shrink: true }}
                        
                        InputProps={{
                           
                            style: {
                                
                              color:'rgba(170, 170, 170, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                               
                                
                            },
                            
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        style={{
                                            color:
                                            'rgba(170, 170, 170,1)',
                                            padding: '18px 5px 0 0',
                                        }}
                                        aria-label={
                                            'visibility token'
                                        }
                                        onClick={onClickToggleReEnterPassword}
                                    >
                                       
                                       {isVisibleReenter ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                        
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                       
                    />
                  
                   
      </Stack>
      </Grid>
      
      </Grid></Box>
      <Box sx={{
      display: { xs: "flex", md: "none" },
      }}>
    <Stack  direction="row"> 
        
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> New Password </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> Re-Enter Password </Typography>
      
      </Stack>
      
      <Stack direction="column"  spacing={2}>
      <TextField
                        id="input-password"
                        type= {isVisible ? 'text' : 'password'}
                        name="password"
                        placeholder="Password"
                        height={40}
                        sx={mainThemeStyle.InputMenuItem}
                        value={passwords}
                        variant = "filled"
                        onChange={handlePasswordChange}
                        InputLabelProps={{ shrink: true }}
                        
                        InputProps={{
                           
                            style: {
                                
                              color:'rgba(170, 170, 170, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                               
                                
                            },
                            
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        style={{
                                            color:
                                            'rgba(170, 170, 170,1)',
                                            padding: '18px 5px 0 0',
                                        }}
                                        aria-label={
                                            'visibility token'
                                        }
                                        onClick={onClickTogglePassword}
                                    >
                                       
                                       {isVisible ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                        
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                       
                    />
                    <TextField
                        id="input-password"
                        type= {isVisibleReenter ? 'text' : 'password'}
                        name="password"
                        placeholder="Password"
                        height={40}
                        sx={mainThemeStyle.InputMenuItem}
                        value={passwordsReEnter}
                        variant = "filled"
                        onChange={handlePasswordReEnterChange}
                        InputLabelProps={{ shrink: true }}
                        
                        InputProps={{
                           
                            style: {
                                
                              color:'rgba(170, 170, 170, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                               
                                
                            },
                            
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        style={{
                                            color:
                                            'rgba(170, 170, 170,1)',
                                            padding: '18px 5px 0 0',
                                        }}
                                        aria-label={
                                            'visibility token'
                                        }
                                        onClick={onClickToggleReEnterPassword}
                                    >
                                       
                                       {isVisibleReenter ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                        
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                       
                    />
                   
      </Stack>
      
      
      </Stack></Box>
      
      <LoadingButton sx={mainThemeStyle.saveButton} loading={loading} onClick={handleChangePassword}>Change Password</LoadingButton>
        </>
             
          </Popup>
          <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
            </ThemeProvider>
    </div>
    /* (userDetails.id != "" ) ?(
    <div >
    <Header userDetails={userDetails} setUserDtails={setUserDtails}/>
      <Routes>
        <Route exact path ="/" element={<Home />} />
        <Route exact path="/location" element={<Location />} />
        <Route exact path="/location/:id" element={<Location />} />
        <Route exact path="/user" element={ <User />} />
        <Route exact path="/user/:id" element={ <User />} />
        <Route exact path="/user/:id/:id" element={ <User />} />
        <Route exact path="/activity" element={ <Activity />} />
        <Route exact path="/activity/:id" element={ <Activity />} />
        <Route exact path="/setup" element={ <SetUp  />} />
        <Route exact path="/setup/:id" element={ <SetUp  />} />
        <Route exact path="/setup/:id/:id" element={ <SetUp  />} />
        <Route exact path="/property" element={<Property />} />
        <Route exact path="/property/:id" element={<Property />} />
        <Route exact path="/dealer" element={<Dealer />} />
        <Route exact path="/dealer/:id" element={<Dealer />} />
        <Route exact path="/distributor" element={<Distributor />} />
        <Route exact path="/distributor/:id" element={<Distributor />} />
        <Route exact path="/systemlog" element={<SystemLog />} />
      </Routes>
      
    </div>) :(<Login  LoginUser={LoginUser}/>) */
  );
}

export default App;
