import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel, List,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText, cardMediaClasses } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import GlobalAlert from "./GlobalAlert";
import useToken from "./useToken";
import axios from "./axios";
import useInnerTable from "./components/useInnerTable";
import LoadingButton from '@mui/lab/LoadingButton';
import PuffLoader from "react-spinners/PuffLoader"; 
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
import TimezoneSelect from 'react-timezone-select'
import { BreakfastDiningOutlined } from "@mui/icons-material";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function TwillioNumber ( props){
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const {token} = useToken();
  const {RefreshToken} = props;
  const { getAlertTitle} = GlobalAlert();
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const [showUpdateButton, setshowUpdateButton] = React.useState(false);
  const [filterText,setfilterText] = useState('');
  const [EnLog,setEnLog] = useState([]);
  let [loading, setLoading] = useState(true);
  let [loadingbtn, setloadingbtn] = useState(false);
  const [apiValue, setAPIValue] = useState({lic_blacklist:""});
  const [records,setRecords] = useState([]);
  const [recordsProperty,setRecordsProperty] = useState([]);
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const [filterFnProperty, setFilterFnProperty] = useState({ fn: items => { return items; } });
  const [currentTab,setCurrentTab] = useState(0);
  const [Tab,setTab] = useState(0);
  const [currentNumber,setCurrentNumber] = useState('');
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();
  const headCells = [
    
    { id: 'number', label: 'Twilio Numbers'},
    { id: 'count', label: '# Of Devices Assigned'},
  ];
  const headCellsProperty = [
    
    { id: 'loc_name', label: 'Property Name'},
    { id: 'fname', label: 'Device Owner'},
    { id: 'unit', label: 'Unit'},
  ];
  const {
    TblContainer,
    TblHead,
    TblPagination ,
    BelowTblPagination,
    recordsAfterPagingAndSorting ,
    setPageToNewOne ,
  } = useInnerTable(records, headCells, filterFn,10,650);

  const {
    TblContainer : TblPContainer,
    TblHead :  TblPHead,
    TblPagination : TblPPagination,
    BelowTblPagination : BelowPTblPagination,
    recordsAfterPagingAndSorting : recordsAfterPPagingAndSorting,
    setPageToNewOne : setPPageToNewOne 
  } = useInnerTable(recordsProperty, headCellsProperty, filterFnProperty,10,650);

  const getMyPostData = async () => {
    try {
      const res = await axios.get("/administrator/twilionumber/",{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email },
      });
      console.log("data format"+JSON.stringify( res.data));
      setEnLog(res.data);
      setRecords(res.data);
      setAPIValue(apiValue);
      setLoading(false);
      setloadingbtn(false);
    } catch (error) {
      console.log(error.response);
      setLoading(false);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const getDisableValue = (val) => {
    if(token.role == 'superadmin'){
      console.log("token sub type" + token.sub_type);
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("12").administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("12").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  useEffect(() => {
    getMyPostData();
  }, []);
  /*useEffect(() => {
    
  }, [currentTab]);*/
  const updateMyPostData = async () => {
    setloadingbtn(true);
    try {
    
      const res = await axios.patch("/admin/getprofile/"+token.id,apiValue, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,
      'token' : token.token,
      'tokenid' : token.email}});
      console.log( res.data);
      setOpenSnackData("ResidentLink LP Blacklist successfully updated");
      setSeverityData("success");
      setDataOfAdd('');
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
      setloadingbtn(false);
      getMyPostData();
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setOpenSnackData("ResidentLink LP Blacklist update failed, try again later");
      setSeverityData("error");
      setOpenSnack(true);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };


  const getMyPropertyData = async (val) => {
    try {
        const res = await axios.get("/administrator/numberdetails/"+val,{
          headers: { 'Content-Type':  'application/json',
          'token' : token.token,
          'tokenid' : token.email },
        });
        console.log("data format"+res.data);
        
        setRecordsProperty(res.data);
        setLoading(false);
        setloadingbtn(false);
      
        setTab(1);
      } catch (error) {
        console.log(error.message);
        setLoading(false);
        setloadingbtn(false);
        if(error.response.data.message == 'UnAuthorized'){
          RefreshToken();
        }
      }
  };
  const getTwilioNumber= async () => {
    setloadingbtn(true);
    const requestBody = {
      'area_code' : dataOfAdd,  
    };
  const requestOptions = {
      method: 'POST',
      headers: {
          Accept:  'application/json',
          'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: JSON.stringify(requestBody)
  };
  fetch("https://mdvaccess.com/php/getlocno.php?area_code="+dataOfAdd, requestOptions
      ).then( async  response => {
          const data = await response.json();
          console.log("response is "+JSON.stringify( data));
          if(!response.ok){
            throw new Error();
        }
        else{
          if(data.mydoorview.locphone != ""){
            setOpenSnackData(getAlertTitle('TNAS'));
            setSeverityData("success");
            setDataOfAdd('');
            setOpenSnack(true);
          }
          else{
            setOpenSnackData(getAlertTitle('TNAACNE'));
            setSeverityData("error");
            setOpenSnack(true);
          }
        }
        setloadingbtn(false);
         /* console.log(response);
          if(!response.ok){
              throw new Error();
          }
          else{*/
         /* setOpenSnackData("Successful. You may now open the Door.");
          setSeverityData("success");
          setOpenSnack(true);
          setLoading(false);
          uploadLog(uid,utype,pintype,1,bitmap,guestnames,"");*/
           
            //getMyPostData();
          //}
              
      }).catch(error => {
        setOpenSnackData(getAlertTitle('TNAACNE'));
        setSeverityData("error");
        setOpenSnack(true);
        setloadingbtn(false);
       /* setLoading(false);
        setOpenSnackData('There was an error while opening door, please try again later');
        setSeverityData("error");
        setOpenSnack(true);
        uploadLog(uid,utype,pintype,0,bitmap,guestnames,"");*/
      }).finally(() => {
        setloadingbtn(false);
      });

  }
 
  const [dataOfAdd,setDataOfAdd] = useState('');
  const handleLPBlackChange = (event) => {
    setDataOfAdd(event.target.value);
  }
  const handleAddNumber = () => {
    if((dataOfAdd.trim()).length != 3){
      setOpenSnackData('Area Code Should Be 3 Digit Only.');
    setSeverityData("error");
    setOpenSnack(true);
    }
    else{
      getTwilioNumber();
    }
    
  }
  const ChangeId = (val) =>{
    console.log('val is '+val);
    /*setCurrentTab(1);
    setTab(1);
    setCurrentNumber(val);
    console.log('curremt tab os' +currentTab);
    console.log('curremt numb os' +currentNumber);
    console.log('curremt Tab os' +Tab);*/
     setCurrentNumber(val);
    setRecordsProperty([]);
    setCurrentTab(1);
    setTab(1);
    setLoading(true);
    getMyPropertyData(val); 
  }
  const ChangeTab = () => {
    setTab(0);
    setPPageToNewOne();
  }

  const handleChangeText = e =>{
    setfilterText(e.target.value);
  }
  const handleReset =() =>{
    setfilterText('');
    if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    
          setFilterFn({
            fn: items => {
              return items;
                    
            }
        })
        setPageToNewOne();
  }
  const handleSearch = () => {
    //let target = e.target;
    setFilterFn({
        fn: items => {
            if (filterText == "")
                return items;
            else{
              
               
                return items.filter(x => x.number.toString().toLowerCase().includes(filterText.toLowerCase()))
                
               
                
              
             
            }
                
        }
    })
    setPageToNewOne();
}
  return <> <Box sx={mainThemeStyle.boxTopHeader}>
   {/*  {(Tab == 0 && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroup} active>Identity</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} >Manage User Access</Button>
    </Stack> )}
    {(Tab == 1 && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} >Identity</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} active>Manage User Access</Button>
    </Stack> )} */}
</Box>
{(Tab == 0 && <Box sx={mainThemeStyle.boxHeaderBelow}>

           <Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px',display: { xs: "none", md: "flex" }}}>
               <div >
                <Stack direction='row' spacing={2} padding={2}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Add New Twillio Number
                   </Typography>
                   <TextField
        id="text"
        type="text"
      placeholder="Enter Area Code"
      value={dataOfAdd}
      onChange={handleLPBlackChange}
       variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                      sx={mainThemeStyle.InputMenuItem}
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />
      
      <LoadingButton sx={mainThemeStyle.normalButtonDown} loading={loadingbtn}  onClick={handleAddNumber} disabled = {getDisableValue("")}> Add </LoadingButton>
      </Stack>
               </div>
              
           </Box>
           <Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px',display: { xs: "flex", md: "none" }}}>
               <div >
               <Stack direction='column'  >
                
                <Stack direction='row' spacing={2} padding={1}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Add New Twillio Number
                   </Typography>
                   </Stack>
                   <Stack direction='row' spacing={2} padding={1}>
                   <TextField
        id="text"
        type="text"
      placeholder="Enter Area Code"
      value={dataOfAdd}
      onChange={handleLPBlackChange}
       variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                      sx={mainThemeStyle.InputMenuItem}
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />
      
      <LoadingButton sx={mainThemeStyle.normalButtonDown} loading={loadingbtn} onClick={handleAddNumber} disabled = {getDisableValue("")}> Add </LoadingButton>
      </Stack></Stack>
               </div>
              
           </Box>
           <Divider sx={{backgroundColor:'white'}} />
          
           <Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px', display: { xs: "none", md: "flex" }}}>
               <div >
             <Stack direction='row' spacing={2} padding={2}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Find Twillio Number
                   </Typography>
                   <TextField
        id="input-location"
        type="text"
      placeholder="Number"
      sx={mainThemeStyle.InputMenuItem}
      onChange={handleChangeText}
       variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && filterText != '') {
                          handleSearch();
                        }
                      }}
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />
      
      <Button sx={mainThemeStyle.normalButtonDown}  onClick={handleSearch} disabled={!filterText}> Go </Button>
      <Button sx={mainThemeStyle.normalButtonDown}  onClick={handleReset}> Reset Filter </Button>
      </Stack>  
     
               </div>
              
           </Box>
           <Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px',display: { xs: "flex", md: "none" }}}>
               <Stack direction='column' spacing={2} >
                <Stack direction='row' spacing={2} padding={1}>
                   <Typography variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>
                   Find Twillio Number
                   </Typography>
                   <TextField
        id="input-location"
        type="text"
      placeholder="Number"
      sx={mainThemeStyle.InputMenuItem}
      onChange={handleChangeText}
       variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                          style={{paddingTop: '16px'}}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' && filterText != '') {
                              handleSearch();
                            }
                          }}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /> </Stack><Stack direction='row' spacing={2} >
        <Typography></Typography>
        <Button sx={mainThemeStyle.normalButtonDown}  onClick={handleSearch} disabled={!filterText}> Go </Button>
      <Button sx={mainThemeStyle.normalButtonDown}  onClick={handleReset}> Reset Filter </Button>
      </Stack>
               </Stack>
              
           </Box>
        {/*    <Divider sx={{backgroundColor:'white'}} /> */}
   
        <TblContainer>
                    <TblHead />

        <TableBody>
         {recordsAfterPagingAndSorting().map(item => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              
              <TableCell align="left"  sx={mainThemeStyle.cellRow}>
              <Typography /* sx={mouseHover ? mainThemeStyle.cellRowTypoGreen :mainThemeStyle.cellRowTypo} height={40} onMouseOver={() => {
          setMouseHover(true);
          
      }} */ sx={ mainThemeStyle.cellGreenClickable} onClick={() => {ChangeId(item.number)}}/*  onMouseOut={() => {
        setMouseHover(false);
      }} */> {item.number}</Typography>
                
                </TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}>{item.count} </TableCell>
            </TableRow>
          ))}
        </TableBody>
      
    </TblContainer>
   
    <PuffLoader color="#878787" loading={loading} cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"50px",
    marginBottom:"50px",}} />
  <BelowTblPagination ></BelowTblPagination>




</Box>)}
{(Tab == 1 && <Box sx={mainThemeStyle.boxHeaderBelow}>

          
           
          
          <Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px',display:{xs:'none',md:'flex'}}}>
               <div>
        
       <Stack direction='row' spacing={2} padding={2}>
     <Typography></Typography>
                   <Button sx={mainThemeStyle.normalButtonDown}  onClick={ChangeTab}> Go Back</Button>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                      Twillio Number :- {currentNumber}
                   </Typography>
      </Stack>
        
      
               </div>
              
           </Box>
           <Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px',display:{xs:'flex',md:'none'}}}>
               <div>
        
       <Stack direction='row' spacing={2} >
     <Typography></Typography>
                   <Button sx={{
                    width: 'auto',
                    height: '2.06rem',
                    top:'1.5rem',
                    
                    fontWeight: '700',
                    fontSize: '0.83rem',
                    lineHeight:'0.93rem',
                    
                    alignItems: 'center',
                    color: '#FFFFFF',
                    fontFamily: "Lato",
                    borderRadius:'0.31rem',
                   '&:hover': {
                    backgroundColor: 'rgba(109, 195, 252, 1)',
                   }
                   }}  onClick={ChangeTab}> Go Back</Button>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                      Twillio Number :- {currentNumber}
                   </Typography>
      </Stack>
        
      
               </div>
              
           </Box>
            <Divider sx={{backgroundColor:'white'}} /> 
   
         
       <TblPContainer>
                    <TblPHead />

        <TableBody>
         {recordsAfterPPagingAndSorting().map(item => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              
              <TableCell align="left"  sx={mainThemeStyle.cellRow}>
              {item.loc_name}
                </TableCell>
                <TableCell align="left"  sx={mainThemeStyle.cellRow}>
              {item.fname} {item.lname}
                </TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}>{item.unit} </TableCell>
            </TableRow>
          ))}
        </TableBody>
      
    </TblPContainer>
    <PuffLoader color="#878787" loading={loading} cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"50px",
    marginBottom:"50px",}} />
  

   <BelowPTblPagination ></BelowPTblPagination> 


</Box>)}
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
 </> 
          
          
          
          
          ;
};

export default TwillioNumber;