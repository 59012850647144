import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel, Select, MenuItem,List,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText, cardMediaClasses } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import AddIcon from '@mui/icons-material/Add';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import GlobalAlert from "./GlobalAlert"; 
import axios from "./axios";
import useToken from "./useToken";
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
import TimezoneSelect from 'react-timezone-select'
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function AddNewProperty ( {dataOfDealer,RefreshToken,...props}){
  const {token} = useToken();
  const { getAlertTitle} = GlobalAlert();
  let [dataOfProperty,setDataOfProperty] = useState({
    name: '',
    email: '',
    password: '',
    city: '',
    state: '',
    zip_code: '',
    copmpany: '',
    website_url: '',
    address: '',
    address_two: '',
    office: '',
    date_purchase:'',
    total_unit: '',
    unit_cost: '',
    dealer: '1',
    api_access: '',
    lpr_accesss: '',
    elevator_accesss: '',
    logo_url: '',
    image_upload_data: '',
    issub : token.issub,
    subId : token.subid
  });
  const [currentTab, setCurrentTab] = useState(0);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const [imageUrl, setImageUrl] = useState( NoImage);
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();
  const getDisableValue = () => {
    if(token.role == 'superadmin'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("7").administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("7").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const currentDisableValue = getDisableValue();
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const [showUpdateButton,setshowUpdateButton] = useState(false);
  
  const [loading,setLoading] = useState(false);
  const [isVisible,setIsVisibile] = useState(false);
  const onClickTogglePassword = () =>{
    setIsVisibile(!isVisible);
  }
  const [selectedImage, setSelectedImage] = useState(null);
  const setPropertyData = () => {
    setshowUpdateButton(true);
    setDataOfProperty({
      name: dataOfProperty.name,
      email: dataOfProperty.email,
      password: dataOfProperty.password,
      city: dataOfProperty.city,
      state: dataOfProperty.state,
      zip_code: dataOfProperty.zip_code,
      copmpany: dataOfProperty.copmpany,
      website_url: dataOfProperty.website_url,
      address: dataOfProperty.address,
      address_two: dataOfProperty.address_two,
      office: dataOfProperty.office,
      date_purchase: dataOfProperty.date_purchase,
      total_unit: dataOfProperty.total_unit,
      unit_cost: dataOfProperty.unit_cost,
      dealer: dataOfProperty.dealer,
      api_access: dataOfProperty.api_access,
      lpr_accesss: dataOfProperty.lpr_accesss,
      elevator_accesss: dataOfProperty.elevator_accesss,
      logo_url: dataOfProperty.logo_url,
      image_upload_data: dataOfProperty.image_upload_data,
      issub : token.issub,
      subId : token.subid
    });
  }
  const handleInputChange = (event) =>{
    let ds = event.target.name;
    dataOfProperty[ds] = event.target.value;
    setPropertyData();
  }
  const handlecheckboxCheck =(event) =>{
    let ds = event.target.name;
    dataOfProperty[ds] = event.target.checked == true ? "1" : "0";
    setPropertyData();
  }
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleUpdateDistributor = () =>{
   // console.log(JSON.stringify(dataOfProperty));
    if(dataOfProperty.name == ""){
      setOpenSnackData(getAlertTitle('APNNE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(dataOfProperty.copmpany == ""){
      setOpenSnackData(getAlertTitle('APCNNE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(dataOfProperty.email == ""){
      setOpenSnackData(getAlertTitle('APENE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    /*if(dataOfProperty.dealer == ""){
      setOpenSnackData("Dealer Company Can't Be Null, Please Select Delaer Company.");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }*/
    if(!isValidEmail(dataOfProperty.email)){
      setOpenSnackData(getAlertTitle('APENV'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
   /*  if(dataOfProperty.password.length  < 1 ){
      setOpenSnackData(getAlertTitle('APPNE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    } */
    updateMyPostData();
  }
  useEffect(() => {
    console.log("readr image calling");
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
      console.log("sle"+selectedImage.name);
    }
  }, [selectedImage]);
  const updateMyPostData = async () => {
    setLoading(true);
    try {
      const res = await axios.post("/administrator/addpropertyowner/",dataOfProperty, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      console.log('response data is '+ res.data);
      setOpenSnackData(getAlertTitle('APPAS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
      setDataOfProperty({
        name: '',
        email: '',
        password: '',
        city: '',
        state: '',
        zip_code: '',
        copmpany: '',
        website_url: '',
        address: '',
        address_two: '',
        office: '',
        date_purchase:'',
        total_unit: '',
        unit_cost: '',
        dealer: '1',
        api_access: '',
        lpr_accesss: '',
        elevator_accesss: '',
        logo_url: '',
        image_upload_data: '',
        issub : token.issub,
        subId : token.subid
      });
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setOpenSnackData(getAlertTitle('APPAF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const GetDistributorCompany = () => {
    for(let i = 0; i < dataOfDealer.length ; i++){
      if(dataOfDealer[i].del_id == dataOfProperty.dealer){
        return dataOfDealer[i].company_name;
      }
    }

  };
  const handleSetDistributor = (event) => {
   
    for(let i = 0; i < dataOfDealer.length ; i++){
      if(dataOfDealer[i].company_name == event.target.value){
        console.log("evetm "+ event.target.value);
        dataOfProperty.dealer = dataOfDealer[i].del_id;
        setPropertyData();
        break;
      }
    }
   
  };
  const MenuProps = {
    PaperProps: {
      style: {
        backgroundColor: "#1E1E1E",
          borderRadius: 0,
          maxHeight:'250px',
          borderRadius: 10,
          padding:0
      },
    },
  };
  const handleTabClick = (val) => {
    setCurrentTab(val);
  }
  return  <> 
  {currentDisableValue == false && <Box sx={mainThemeStyle.boxTopHeader}>
      {(currentTab == 0 && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(0)}} active>Account Details</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Billing Details</Button>
  
  </Stack>)}
  {(currentTab == 1 && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} active>Account Details</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(1)}}>Billing Details</Button>
   
  </Stack>)}
            
</Box> }

{currentDisableValue == false && <Box sx={{position: 'absolute',
           width:'auto',
          
           top:'150px',
           left: '5px',
           display: { xs: "flex", md: "none" },
           right: '5px',
           flexGrow: 1,
          
           }}>
            {(currentTab == 0 && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
<Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(0)}} active>Account Details</Button>
  </Grid> <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Billing Details</Button>
  </Grid>
  </Grid>)}
  {(currentTab == 1 && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
<Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} active>Account Details</Button>
    </Grid> <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(1)}}>Billing Details</Button>
    </Grid>
  </Grid>)}

           </Box> }
           {currentDisableValue == false && <Box sx={mainThemeStyle.boxHeaderBelow}>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
               {(currentTab == 0 &&<Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                      Add Account Details
                   </Typography> )}
                   {(currentTab == 1 &&<Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                      Add Account Billing Details
                   </Typography> )}
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           {(currentTab == 0 && <><Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Company Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Dealer Company</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Address 1 </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Address 2 </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> City </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> State </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Zip </Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
         <TextField
       
       type="text"
       name = "name"
       onChange={handleInputChange}
       value={dataOfProperty.name}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                         variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
     />
         <TextField
      
       type="text"
       name = "copmpany"
       onChange={handleInputChange}
       value={dataOfProperty.copmpany}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
     />
  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "app_unlock_mode"
            
            value={GetDistributorCompany()}
          label="Age"
          onChange={handleSetDistributor}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            { dataOfDealer.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.company_name}>
                      {menuItem.company_name}
                    </MenuItem>
                  );
                })} 
          </Select>
         <TextField
      
       type="text"
       name = "address"
       onChange={handleInputChange}
       value={dataOfProperty.address}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
     />
      <TextField
       
       type="text"
       name = "address_two"
       onChange={handleInputChange}
       value={dataOfProperty.address_two}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
     />
     <TextField
                      
                      height={40}
                      type= 'text'
                      name = "city"
                       onChange={handleInputChange}
                      value={dataOfProperty.city}
                      sx={mainThemeStyle.InputMenuItem}
                      InputLabelProps={{ shrink: true }
                    }
                    
                    variant="filled"
                      InputProps={{
                         
                          sx: {
                              
                              color:'rgba(135, 135, 135, 0.5)',
                              height:'33px',
                              borderRadius: '5px',
                              backgroundColor: '#121212',
                              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                              padding: '0 0 15px 0',
                              textIndent: '15px !important',
                            
                             
                              
                          },
                      }}
                     
                  />
                   <TextField
                       
                       height={40}
                       type= 'text'
                       name = "state"
                       onChange={handleInputChange}
                       value={dataOfProperty.state}
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
                   />
                   <TextField
                       
                       height={40}
                       type= 'text'
                       name = "zip_code"
                       onChange={handleInputChange}
                       value={dataOfProperty.zip_code}
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
                   />
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={200}>Logo</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Office Number #</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Email</Typography>
      {/* <Typography sx={mainThemeStyle.popUpLable} height={40}>Password</Typography> */}
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Website URL</Typography>
     
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
        
      <Stack direction="row" spacing={2} >
        <img  src={imageUrl} height={200} width={200}></img>
        <Button  sx={mainThemeStyle.normalButton} variant="contained" component="label">Upload <input hidden accept="image/png, image/jpeg" multiple type="file" 
        onChange={ 
          e => {
            if (e.target.files[0].size > 2000000) {
              console.log("File too large");
              return;
            }
            
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
        
            reader.onload = () => {
              console.log(reader.result);
              setshowUpdateButton(true);
              setSelectedImage(e.target.files[0]);
              dataOfProperty.image_upload_data = reader.result;
              setPropertyData();
            };
            reader.onerror = error => {
              console.log("Error: ", error);
            };


          
          }
        }/></Button>
        </Stack>
      
                     
        <TextField
           
           height={40}
           type= 'number'
           name = "office"
           onChange={handleInputChange}
           value={dataOfProperty.office}
           sx={mainThemeStyle.InputMenuItem}
           InputLabelProps={{ shrink: true }
         }
         
         variant="filled"
           InputProps={{
              
               sx: {
                   
                   color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 15px 0',
                   textIndent: '15px !important',
                 
                  
                   
               },
           }}
          
       />
        <TextField
           
           height={40}
           type= 'text'
           name = "email"
           onChange={handleInputChange}
           value={dataOfProperty.email}
           sx={mainThemeStyle.InputMenuItem}
           InputLabelProps={{ shrink: true }
         }
         
         variant="filled"
           InputProps={{
              
               sx: {
                   
                   color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 15px 0',
                   textIndent: '15px !important',
                 
                  
                   
               },
           }}
          
       />
       {/* <TextField
           
           height={40}
           type= {isVisible ? 'text' : 'password'}
           name = "password"
           onChange={handleInputChange}
           value={dataOfProperty.password}
           margin="normal"
           variant = "filled"
           
           InputLabelProps={{
               shrink: true,
               style: {
               '&::placeholder': {
                   textOverflow: 'ellipsis !important',
                   color: 'blue'
                 },
               }
           }}
           
           InputProps={{
              
               style: {
                   
                 color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 18px 0',
                   textIndent: '15px !important',
                  
                   
               },
               
               endAdornment: (
                   <InputAdornment position="end">
                       <IconButton
                           style={{
                               color:
                               'rgba(135, 135, 135, 0.5)',
                               padding: '18px 5px 0 0',
                           }}
                           aria-label={
                               'visibility token'
                           }
                           onClick={onClickTogglePassword}
                       >
                          
                          {isVisible ? (
                           <VisibilityOff />
                       ) : (
                           <Visibility />
                       )}
                           
                       </IconButton>
                   </InputAdornment>
               ),
           }}
          
       /> */}
        <TextField
           
           height={40}
           type= 'text'
           name = "website_url"
           onChange={handleInputChange}
           value={dataOfProperty.website_url}
           sx={mainThemeStyle.InputMenuItem}
           InputLabelProps={{ shrink: true }
         }
         
         variant="filled"
           InputProps={{
              
               sx: {
                   
                   color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 15px 0',
                   textIndent: '15px !important',
                 
                  
                   
               },
           }}
          
       />
       
       
</Stack>
      </Grid>
     
      
      </Grid>
      </Box>
      <Box sx={{display: { xs: "flex", md: "none" }}}>
      <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Company Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Dealer Company</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Address 1 </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Address 2 </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> City </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> State </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Zip </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={140}>Logo</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Office Number #</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Email</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Website URL</Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
         <TextField
       
       type="text"
       name = "name"
       onChange={handleInputChange}
       value={dataOfProperty.name}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                         variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
     />
         <TextField
      
       type="text"
       name = "copmpany"
       onChange={handleInputChange}
       value={dataOfProperty.copmpany}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
     />
  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "app_unlock_mode"
            
            value={GetDistributorCompany()}
          label="Age"
          onChange={handleSetDistributor}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            { dataOfDealer.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.company_name}>
                      {menuItem.company_name}
                    </MenuItem>
                  );
                })} 
          </Select>
         <TextField
      
       type="text"
       name = "address"
       onChange={handleInputChange}
       value={dataOfProperty.address}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
     />
      <TextField
       
       type="text"
       name = "address_two"
       onChange={handleInputChange}
       value={dataOfProperty.address_two}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
     />
     <TextField
                      
                      height={40}
                      type= 'text'
                      name = "city"
                       onChange={handleInputChange}
                      value={dataOfProperty.city}
                      sx={mainThemeStyle.InputMenuItem}
                      InputLabelProps={{ shrink: true }
                    }
                    
                    variant="filled"
                      InputProps={{
                         
                          sx: {
                              
                              color:'rgba(135, 135, 135, 0.5)',
                              height:'33px',
                              borderRadius: '5px',
                              backgroundColor: '#121212',
                              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                              padding: '0 0 15px 0',
                              textIndent: '15px !important',
                            
                             
                              
                          },
                      }}
                     
                  />
                   <TextField
                       
                       height={40}
                       type= 'text'
                       name = "state"
                       onChange={handleInputChange}
                       value={dataOfProperty.state}
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
                   />
                   <TextField
                       
                       height={40}
                       type= 'text'
                       name = "zip_code"
                       onChange={handleInputChange}
                       value={dataOfProperty.zip_code}
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
                   />

<Stack direction="column" spacing={2} >
        <img  src={imageUrl} height={100} width={100}></img>
        <Button  sx={mainThemeStyle.normalButton} variant="contained" component="label">Upload <input hidden accept="image/png, image/jpeg" multiple type="file" 
        onChange={ 
          e => {
            if (e.target.files[0].size > 2000000) {
              console.log("File too large");
              return;
            }
            
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
        
            reader.onload = () => {
              console.log(reader.result);
              setshowUpdateButton(true);
              setSelectedImage(e.target.files[0]);
              dataOfProperty.image_upload_data = reader.result;
              setPropertyData();
            };
            reader.onerror = error => {
              console.log("Error: ", error);
            };


          
          }
        }/></Button>
        </Stack>
      
                     
        <TextField
           
           height={40}
           type= 'number'
           name = "office"
           onChange={handleInputChange}
           value={dataOfProperty.office}
           sx={mainThemeStyle.InputMenuItem}
           InputLabelProps={{ shrink: true }
         }
         
         variant="filled"
           InputProps={{
              
               sx: {
                   
                   color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 15px 0',
                   textIndent: '15px !important',
                 
                  
                   
               },
           }}
          
       />
        <TextField
           
           height={40}
           type= 'text'
           name = "email"
           onChange={handleInputChange}
           value={dataOfProperty.email}
           sx={mainThemeStyle.InputMenuItem}
           InputLabelProps={{ shrink: true }
         }
         
         variant="filled"
           InputProps={{
              
               sx: {
                   
                   color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 15px 0',
                   textIndent: '15px !important',
                 
                  
                   
               },
           }}
          
       />
       
        <TextField
           
           height={40}
           type= 'text'
           name = "website_url"
           onChange={handleInputChange}
           value={dataOfProperty.website_url}
           sx={mainThemeStyle.InputMenuItem}
           InputLabelProps={{ shrink: true }
         }
         
         variant="filled"
           InputProps={{
              
               sx: {
                   
                   color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 15px 0',
                   textIndent: '15px !important',
                 
                  
                   
               },
           }}
          
       />
      </Stack>
      </Grid>
      
     
     
     
      
      </Grid>
      </Box>
      </> )}
      {(currentTab == 1 && <><Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Unit Cost</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Enable Auth API </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Enable Elevator API </Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
         <TextField
       
       type="number"
       name = "unit_cost"
       onChange={handleInputChange}
       value={dataOfProperty.unit_cost}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                         variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
     />
       <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="api_access" onChange={handlecheckboxCheck} checked={dataOfProperty.api_access == "1" ? true : false } />}
        label=""
      />
  <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="elevator_accesss" onChange={handlecheckboxCheck} checked={dataOfProperty.elevator_accesss == "1" ? true : false } />}
        label=""
      />
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Total Unit</Typography>
      {/* <Typography sx={mainThemeStyle.popUpLable} height={40}>Total Unit</Typography> */}
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Enable LPR API</Typography>
      
     
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
        
    
                     
        {/* <TextField
           
           height={40}
           type= 'number'
           name = "total_unit"
           onChange={handleInputChange}
           value={dataOfProperty.total_unit}
           sx={mainThemeStyle.InputMenuItem}
           InputLabelProps={{ shrink: true }
         }
         
         variant="filled"
           InputProps={{
              
               sx: {
                   
                   color:'rgba(135, 135, 135, 0.5)',
                   height:'33px',
                   borderRadius: '5px',
                   backgroundColor: '#121212',
                   boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                   padding: '0 0 15px 0',
                   textIndent: '15px !important',
                 
                  
                   
               },
           }}
          
       /> */}
       <TextField
       
       type="number"
       name = "total_unit"
       onChange={handleInputChange}
       value={dataOfProperty.total_unit}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                         variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
     />
         <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="lpr_accesss" onChange={handlecheckboxCheck} checked={dataOfProperty.lpr_accesss == "1" ? true : false } />}
        label=""
      />
       
       
       
</Stack>
      </Grid>
     
      
      </Grid> </Box>
      <Box sx={{display: { xs: "flex", md: "none" }}}><Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Unit Cost</Typography>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Total Unit</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Enable Auth API </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Enable Elevator API </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Enable LPR API</Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
         <TextField
       
       type="number"
       name = "unit_cost"
       onChange={handleInputChange}
       value={dataOfProperty.unit_cost}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                         variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
     />
      <TextField
       
       type="number"
       name = "total_unit"
       onChange={handleInputChange}
       value={dataOfProperty.total_unit}
       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     
                         variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}
                      
     />
       <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="api_access" onChange={handlecheckboxCheck} checked={dataOfProperty.api_access == "1" ? true : false } />}
        label=""
      />
  <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="elevator_accesss" onChange={handlecheckboxCheck} checked={dataOfProperty.elevator_accesss == "1" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="lpr_accesss" onChange={handlecheckboxCheck} checked={dataOfProperty.lpr_accesss == "1" ? true : false } />}
        label=""
      />
      </Stack>
      </Grid>
      
    
   
     
      
      </Grid> </Box></>)}
      {showUpdateButton &&  <Box sx= {mainThemeStyle.boxDown}><LoadingButton
         
         sx={mainThemeStyle.normalButtonRelative}
         loading={loading}
         onClick={handleUpdateDistributor}
         variant="outlined"
         startIcon={<AddIcon />}
         loadingPosition="start"
       >
         Add New Account Owner
       </LoadingButton>
    <Typography></Typography>
      </Box>}
</Box> }
{currentDisableValue == true && 
 <Box sx={mainThemeStyle.box} >
 <Typography sx={mainThemeStyle.lable}>Access For This Page Is Blocked</Typography>
 
  
  
  </Box>
    
    
    }
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>

 </> 
          
          
          
          
          ;
};

export default AddNewProperty;