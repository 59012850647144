import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel, List,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText, cardMediaClasses } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import useToken from "./useToken";
import axios from "./axios";
import useInnerTable from "./components/useInnerTable";
import LoadingButton from '@mui/lab/LoadingButton';
import PuffLoader from "react-spinners/PuffLoader"; 
import TimezoneSelect from 'react-timezone-select'
import { AppBlocking, BreakfastDiningOutlined, Rule } from "@mui/icons-material";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function AdminLogs ( props){
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const {token} = useToken();
  const {RefreshToken} = props;
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const [showUpdateButton, setshowUpdateButton] = React.useState(false);
  const [filterText,setfilterText] = useState('');
  const [EnLog,setEnLog] = useState([]);
  let [loading, setLoading] = useState(true);
  let [loadingbtn, setloadingbtn] = useState(false);
  const [records,setRecords] = useState([]);
  const [recordsProperty,setRecordsProperty] = useState([]);
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const [filterFnProperty, setFilterFnProperty] = useState({ fn: items => { return items; } });
  const [currentTab,setCurrentTab] = useState(0);
  const [Tab,setTab] = useState(0);
  const [currentNumber,setCurrentNumber] = useState('');
  const [open, setOpen] = React.useState(false);
  const [titleforalert, setTitleforalert] = React.useState("");
  const myContainer = useRef(null);
  const headCells = [
    
    /* { id: 'title', label: 'Feature Title'},
    { id: 'cost', label: 'Cost'},
    { id: 'action', label: 'Action', disableSorting: true}, */
  ];
  const handleChangeText = e => {
    console.log(e.target.value);
    let value = e.target.value;
    setfilterText(value);
    console.log(filterText);
}
let Alphabet =['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
const changeTimeZone  =  (date, timeZone)=> {
  const laDate = moment.utc(date).format();
  //if (typeof date === 'string') {
    const ldated =  new Date(
      new Date(laDate).toLocaleString('en-US', {
        timeZone,
      }),
    );
    const mystr = moment(ldated).format('YYYY-MM-DD HH:mm:ss');
    return mystr;
 /* }

  return new Date(
    date.toLocaleString('en-US', {
      timeZone,
    }),
  );*/
}
const ActionTypeMenu = {
  id: "1",
  label: "Select Type",
  items: [
    {
      label: "Add",
      callback: () =>  {setfilterText("added")}
     
    },
    {
      label: "Edit",
      callback: () =>  {setfilterText("edited")}
    },
    {
      label: "Delete",
      callback: () => {setfilterText("deleted")}
    },
    {
      label: "Log In",
      callback: () => {setfilterText("logged in")}
    },
    
  ]
};
const UserTypeMenu = {
  id: "1",
  label: "Select Type",
  items: [
    {
      label: "Super-Administrator",
      callback: () =>  {setfilterText("Super-Administrator")}
     
    },
    {
      label: "Property Admin",
      callback: () =>  {setfilterText("Property Admin")}
    },
    {
      label: "Property Sub Admin",
      callback: () => {setfilterText("Property Sub Admin")}
    },
    {
      label: "Distributor",
      callback: () => {setfilterText("Distributor")}
    },
    {
      label: "Sub Distributor",
      callback: () => {setfilterText("Sub Distributor")}
    },
    {
      label: "Dealer",
      callback: () => {setfilterText("Dealer")}
    },
    {
      label: "Sub Dealer",
      callback: () => {setfilterText("Sub Dealer")}
    },
    {
      label: "Resident User",
      callback: () => {setfilterText("Resident User")}
    },
    {
      label: "Brivo API",
      callback: () => {setfilterText("Brivo API")}
    },
    {
      label: "MyDoorView API",
      callback: () => {setfilterText("MyDoorView API")}
    },
  ]
};
const checkAtOrNot = (value) =>{
  let ad = records.filter(x => x.user_name.toLowerCase().startsWith(value.toLowerCase()));
  console.log(ad.length);
  return ad.length;
}

const jumpToAlphabet = (val) =>{
 console.log('value is '+val);
 setFilterFn({
   fn: items => {
     return items.filter(x => x.user_name.toLowerCase().startsWith(val.toLowerCase()))
           
   }
})
setPageToNewOne();
}
  const [openPopup, setOpenPopup] = useState(false);
  const {
    TblContainer,
    TblHead,
    TblPagination ,
    BelowTblPagination,
    recordsAfterPagingAndSorting ,
    setPageToNewOne ,
  } = useTable(records, headCells, filterFn,'Download System Logs');

  
  const [apiValue, setAPIValue] = useState({id:"",title:"",cost:""});
  const getMyPostData = async () => {
    try {
      const res = await axios.get("/admin/getlogs/"+token.id,{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email },
      });
      console.log("data format"+res.data);
      setEnLog(res.data);
      setRecords(res.data);
      setLoading(false);
      setloadingbtn(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  useEffect(() => {
    getMyPostData();
  }, []);
  /*useEffect(() => {
    
  }, [currentTab]);*/
  const [shownameType,setShownameType] = useState(false);
  const [showUserType,setShowUserType] = useState(false);
  const [showUserName,setShowUserName] = useState(false);
  const [showActionType,setShowActionType] = useState(true);
  const [showDateAdded,setShowDateAdded] = useState(false);

  const FilterMenu = {
    id: "1",
    label: "Action Type",
    items: [
       {
        label: "User Type",
        callback: () =>  {
            setShownameType(false);
            setShowUserType(true);
            setShowUserName(false);
            setShowActionType(false);
            setShowDateAdded(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
            if(document.getElementById('date') != null)
            document.getElementById('date').value ='';
            setfilterText('');
        }
         
      },
      {
        label: "User Name",
        callback: () =>  {
          setShownameType(true);
          setShowUserType(false);
          setShowUserName(true);
          setShowActionType(false);
          setShowDateAdded(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
            if(document.getElementById('date') != null)
            document.getElementById('date').value ='';
            setfilterText('');
        }
         
      }, 
      {
        label: "Action Type",
        callback: () =>  {
          setShownameType(false);
          setShowUserType(false);
          setShowUserName(false);
          setShowActionType(true);
          setShowDateAdded(false);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
            if(document.getElementById('date') != null)
            document.getElementById('date').value ='';
            setfilterText('');
        }
         
      },
      {
        label: "Date Added",
        callback: () =>  {
          setShownameType(false);
          setShowUserType(false);
          setShowUserName(false);
          setShowActionType(false);
          setShowDateAdded(true);
         if(document.getElementById('input-location') != null)
            document.getElementById('input-location').value ='';
            if(document.getElementById('date') != null)
        document.getElementById('date').value ='';
        setfilterText('');
      
        }
        
         
      }
      
    ]
  };
  const handleReset =() =>{
    setfilterText('');
    if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
    if(document.getElementById('date') != null)
    document.getElementById('date').value ='';
          setFilterFn({
            fn: items => {
              return items;
                    
            }
        })
        setPageToNewOne();
  }

  const [currentId,setCurrentId] = useState();
  const [nameOfFeature,setNameOfFeature] = useState('');
  const [costOfFeature,setCostOfFeature] = useState('');
 
  
  const handleClose = () => {
    setOpen(false);
  };
  
  const handleSearch = () => {
    //let target = e.target;
    setFilterFn({
        fn: items => {
            if (filterText == "")
                return items;
            else{
              if(shownameType){
                
                if(showUserName)
                return items.filter(x => (x.user_name.toString().toLowerCase()).includes(filterText.toLowerCase()))
                
                else 
                return items;
                
              }
              else if(showDateAdded){
                
                var mystr = filterText;
                mystr = moment(mystr).format('YYYY-MM-DD');
                return items.filter(x => changeTimeZone( x.date, 'America/New_York').toLowerCase().includes(mystr))
              
              }
              else if(showUserType){
                return items.filter(x => x.user_type.toLowerCase() === (filterText.toLowerCase()))
              }
              else if(showActionType){
                return items.filter(x => x.action.toLowerCase() === (filterText.toLowerCase()))
              }
              else{
                return items;
              }
              
            }
                
        }
    })
    setPageToNewOne();
}
  return <><Box><Typography sx={mainThemeStyle.lablefilter}>Filter</Typography>
  
  <Box sx={{position: 'absolute',
           width:'680px',
           height: '280px',
           top:'160px',
           left: '38px',
           display: { xs: "none", md: "flex" },
           right: '38px',
           flexGrow: 1,
          
           }}>
             
           <Stack direction="row" spacing={2} >
                  
                   <NestedButtonDropdown
            itemId={FilterMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={FilterMenu}
          /> {shownameType  &&
           
          <Typography sx={mainThemeStyle.lableContains}>Contains</Typography>}
                 {(showActionType || showUserType)  && 
                   <Typography sx={mainThemeStyle.lableContains}>Equals</Typography>}
                   {showActionType  &&  
                   <NestedButtonDropdown
            itemId={ActionTypeMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={ActionTypeMenu}
          />
                  }
                  {showUserType  &&  
                   <NestedButtonDropdown
            itemId={UserTypeMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={UserTypeMenu}
          />
                  }
                  {showDateAdded  && <TextField
        id="date"
        type="date"
        hiddenLabel
        sx={mainThemeStyle.InputFilter}
        onChange={handleChangeText}
        InputLabelProps={{
          shrink: true,
        }}
        variant="filled"
        onKeyDown={(e) => {
          if (e.key === 'Enter' && filterText != '') {
            handleSearch();
          }
        }}
        InputProps={{
                           
          sx: {
              
              color: 'rgba(255, 255, 255, 1)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#171E29',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              textIndent: '15px !important',
              border: `1px solid rgba(31, 39, 54, 1)`,
             
              
          },
      }}/>}

{shownameType &&
                   
                   <TextField
                        id="input-location"
                        ref={myContainer}
                        type= 'text'
                        name="text"
                        onChange={handleChangeText}
                        sx={mainThemeStyle.InputFilter}
                        InputLabelProps={{ shrink: true }}
                        hiddenLabel
                        variant="filled"
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && filterText != '') {
                            handleSearch();
                          }
                        }}
                        InputProps={{
                           
                            sx: {
                                
                                color: 'rgba(255, 255, 255, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#171E29',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '5px 0 5px 0',
                                textIndent: '15px !important',
                                border: `1px solid rgba(31, 39, 54, 1)`,
                                
                                
                            },
                        }}
                       
                    />}
                   
                   <Button sx={mainThemeStyle.filterResetButton} onClick={handleSearch} disabled={!filterText}>Go</Button>
                   
                   <Button sx={mainThemeStyle.filterResetButton} onClick={handleReset}>Reset Filters</Button>
                  </Stack>
                  </Box>
          
                  <Box sx={{position: 'absolute',
           width:'auto',
           height: '280px',
           top:'160px',
           left: '38px',
           display: { xs: "flex", md: "none" },
           right: '38px',
           flexGrow: 1,
          
           }}>
             <Grid container  direction="column">
             <Grid item >
           <Stack direction="row" spacing={2} >
                  
                   <NestedButtonDropdown
            itemId={FilterMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={FilterMenu}
          /> {/* {shownameType  &&
           
          <Typography sx={mainThemeStyle.lableContains}>Contains</Typography>}
                 {(showActionType || showUserType)  && 
                   <Typography sx={mainThemeStyle.lableContains}>Equals</Typography>} */}
                   {showActionType  &&  
                   <NestedButtonDropdown
            itemId={ActionTypeMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={ActionTypeMenu}
          />
                  }
                  {showUserType  &&  
                   <NestedButtonDropdown
            itemId={UserTypeMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButton}
            data={UserTypeMenu}
          />
                  }
                  {showDateAdded  && <TextField
        id="date"
        type="date"
        hiddenLabel
        sx={mainThemeStyle.InputFilter}
        onChange={handleChangeText}
        InputLabelProps={{
          shrink: true,
        }}
        variant="filled"
        onKeyDown={(e) => {
          if (e.key === 'Enter' && filterText != '') {
            handleSearch();
          }
        }}
        InputProps={{
                           
          sx: {
              
              color: 'rgba(255, 255, 255, 1)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#171E29',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              textIndent: '15px !important',
              border: `1px solid rgba(31, 39, 54, 1)`,
             
              
          },
      }}/>}

{shownameType &&
                   
                   <TextField
                        id="input-location"
                        ref={myContainer}
                        type= 'text'
                        name="text"
                        onChange={handleChangeText}
                        sx={mainThemeStyle.InputFilter}
                        InputLabelProps={{ shrink: true }}
                        hiddenLabel
                        variant="filled"
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && filterText != '') {
                            handleSearch();
                          }
                        }}
                        InputProps={{
                           
                            sx: {
                                
                                color: 'rgba(255, 255, 255, 1)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#171E29',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '5px 0 5px 0',
                                textIndent: '15px !important',
                                border: `1px solid rgba(31, 39, 54, 1)`,
                                
                                
                            },
                        }}
                       
                    />} </Stack>
                   </Grid>
                   <Grid item >
                   <Stack direction="row" >
                   <Button sx={mainThemeStyle.filterResetButtonSmall} onClick={handleSearch} disabled={!filterText}>Go</Button>
                   
                   <Button sx={mainThemeStyle.filterResetButtonSmall} onClick={handleReset}>Reset Filters</Button>
                   </Stack>
                   </Grid>
                   </Grid>
                  </Box>
                  <Typography sx={mainThemeStyle.lableJump}>Jump</Typography>
                 <ButtonGroup variant="text"  sx={{position: 'absolute',
           maxWidth:'250px',
           height: 'auto',
           top:'250px',
           left: '38px',
           display: { xs: "none", md: "flex" }
           }}>

          

          {
            Alphabet.map(item => (
              checkAtOrNot(item) > 0 ?  <Button sx= {mainThemeStyle.ButtonJump}  onClick={() => {jumpToAlphabet(item)}}>{item}</Button> :  <Button sx= {mainThemeStyle.ButtonJump} disabled>{item}</Button>
            ))
          }


    </ButtonGroup>
    <Box sx={mainThemeStyle.mainTablebox} backgroundColor="menubarprimary.main">
    <BelowTblPagination ></BelowTblPagination>
        <TblContainer>
                    <TblHead />

        <TableBody>
         {recordsAfterPagingAndSorting().map(item => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
             
              <TableCell align="left" style={{ width: 360 }} sx={mainThemeStyle.cellRow}>
                {/* {item.user_type+" user "+item.user_name+" "+
              item.action+" "+item.action_type+" "+item.action_name+" at "+item.date}  */}
              <Stack direction="row" spacing={0.5}>
                <Typography  sx={mainThemeStyle.cellWhiteBig}>{item.user_type}</Typography>
                {item.user_type != 'MyDoorView API'  &&   item.user_type != 'Brivo API' &&<Typography  sx={mainThemeStyle.cellRowMedium}>user</Typography>}
                <Typography sx={mainThemeStyle.cellBlue}>{item.user_name}</Typography>
                <Typography sx={mainThemeStyle.cellRowMedium}>{item.action}</Typography>
                <Typography sx={mainThemeStyle.cellRowMedium}>{item.action_type}</Typography>
                <Typography sx={mainThemeStyle.cellRowMediumPink}>{item.action_name}</Typography>
                <Typography  sx={mainThemeStyle.cellRowMedium}>at</Typography>
                <Typography sx={mainThemeStyle.cellGreenBig}>{changeTimeZone(item.date, 'America/New_York')}</Typography>
              </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      
    </TblContainer>
   
    <Box sx={{marginTop:'10px'}}>
                <PuffLoader color="#878787" loading={loading} cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"50px",
    marginBottom:"50px",}} />



</Box></Box>
                 </Box>

 </> 
          
          
          
          
          ;
};

export default AdminLogs;