import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel, List,Divider,Select, MenuItem,FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useInnerTable from "./components/useInnerTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import GlobalAlert from "./GlobalAlert";
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";

import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from "./axios";
import useToken from "./useToken";
import TimezoneSelect from 'react-timezone-select'
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function EditUser ( {idOfuser,RefreshToken,...props}){

  const {token} = useToken();
  const { getAlertTitle} = GlobalAlert();
  let [dataOfUser,setDataOfUser] = useState(idOfuser);
  const [currentTab, setCurrentTab] = useState(0);
  const locationAccessName =['Resident Vue Security','Cornerstone Security','One Time Pizza','Open Square','NewYork Gate','Heavy Security','MyDoor View Head office'];
  const groupAccessName =['Beta Tester','James Home','Everyone On The list'];
  const [showUpdateButton,setshowUpdateButton] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [titleforalert, setTitleforalert] = React.useState("");
  const [imageUrl, setImageUrl] = useState( dataOfUser.user_image != null ? "https://mdvaccess.com/"+dataOfUser.user_image : NoImage);
  useEffect(() => {
    console.log("readr image calling");
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
      console.log("sle"+selectedImage.name);
    }
  }, [selectedImage]);
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseActive =() =>{
    setOpen(false);
    if(currentDeleteType == "Group"){
    deleteMyGroupPostData();
    }
    if(currentDeleteType == "RFID"){
      deleteMyRFIDPostData();
    }
    if(currentDeleteType == "License"){
      console.log("current type is license");
      deleteMyLicensePostData();
    }
    
  }

  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();


  const getDisableValue = (val) => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("42");
       // console.log("value is "+ JSON.stringify(valOfDisableData));
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        //console.log("value is "+ JSON.stringify(valOfDisableData[idData]));
        let valOfDisable = valOfDisableData[idData].administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("42");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("42");
       // console.log("value is "+ JSON.stringify(valOfDisableData));
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
       // console.log("value is "+ JSON.stringify(valOfDisableData[idData]));
        let valOfDisable = valOfDisableData[idData].distributor_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("42");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("42");
       // console.log("value is "+ JSON.stringify(valOfDisableData));
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
       // console.log("value is "+ JSON.stringify(valOfDisableData[idData]));
        let valOfDisable = valOfDisableData[idData].dealer_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("42");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("42");
       // console.log("value is "+ JSON.stringify(valOfDisableData));
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
       // console.log("value is "+ JSON.stringify(valOfDisableData[idData]));
        let valOfDisable = valOfDisableData[idData].admin_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("42");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
   
    
  }
  
  const deleteMyGroupPostData = async () => {
    setLoading(true);
    try {
      const res = await axios.delete("/admin/usergroup/"+token.id+"/"+currentDeleteID,
      {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email,
      'issub':token.issub,
      'subId':token.subid,
      'usergroup':userGroupName,
      'userfname':dataOfUser.fname,
      'userlname':dataOfUser.lname  }});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('EFGUDS'));
      setSeverityData("success");
      setOpenSnack(true);
     // setLoading(false);
      getMyPostData();
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setOpenSnackData(getAlertTitle('EFGUDF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };
  const deleteMyRFIDPostData = async () => {
    setLoading(true);
    try {
      const res = await axios.delete("/admin/deleterfid/"+token.id+"/"+currentDeleteID, 
      {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email,
      'issub':token.issub,
      'subId':token.subid,
      'rfidname':userRFIDName,
      'userfname':dataOfUser.fname,
      'userlname':dataOfUser.lname }});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('EURCDS'));
      setSeverityData("success");
      setOpenSnack(true);
      //setLoading(false);
      getMyPostData();
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setOpenSnackData(getAlertTitle('EURCDF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };
  const deleteMyLicensePostData = async () => {
    setLoading(true);
    try {
      const res = await axios.delete("/admin/deletelicense/"+token.id+"/"+currentDeleteID,
      {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email,
      'issub':token.issub,
      'subId':token.subid,
      'license':userLicenseName,
      'userfname':dataOfUser.fname,
      'userlname':dataOfUser.lname  }});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('EULPDS'));
      setSeverityData("success");
      setOpenSnack(true);
      //setLoading(false);
      getMyPostData();
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setOpenSnackData(getAlertTitle('EULPDF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };

  const [currentDeleteID, setCurrentDeleteID] = useState('');
  const [currentDeleteType, setCurrentDeleteType] = useState('');
  const [userGroupName, setUerGroupName] = useState('');
  const [userRFIDName, setUserRFIDName] = useState('');
  const [userLicenseName, setUserLicenseName] = useState('');
  const handleGroupDelete = (val ,val2) =>{
    setTitleforalert("Are you sure you want to delete this user from Group "+ val + " ?");
    setCurrentDeleteID(val2);
    setUerGroupName(val);
    setCurrentDeleteType('Group');
    console.log(val2);
    setOpen(true);
  }

  const handleRFIDDelete = (val,val2) =>{
    setTitleforalert("Are you sure you want to delete RFID Card which generic # is "+ val2 + " ?");
    setCurrentDeleteID(val);
    setUserRFIDName(val2);
    setCurrentDeleteType('RFID');
    console.log(val);
    setOpen(true);
  }
  const handleLicenseDelete= (val,val2) =>{
    setTitleforalert("Are you sure you want to delete License Plate "+ val2 + " ?");
    setCurrentDeleteID(val);
    setUserLicenseName(val2);
    setCurrentDeleteType('License');
    console.log(val);
    setOpen(true);
  }
  const handleTabClick =(val) =>{
    setCurrentTab(val);
  }
  const updateMyDirectoryPostData = async (val) => {
    try {
      const res = await axios.patch("/admin/updateuserlocation/"+token.id,{id:val.id,door_pin:val.door_pin,floor:val.floor,hide:val.hide,location_code:val.location_code,issub:token.issub,subId:token.subid,userfname:dataOfUser.fname,userlname:dataOfUser.lname}, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,
      'token' : token.token,
      'tokenid' : token.email}});
      console.log( res.data);
      setOpenSnackData(res.data.rows);
      setSeverityData("success");
      setOpenSnack(true);
      //setLoading(false);
      getMyPostData();
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setOpenSnackData(getAlertTitle('EUDUF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };

  const handleDirectorySave = (event) => {
    let ds = event.target.name;
    let idIn;
    for(let i = 0; i < recordsDirectory.length ; i++){
      if(recordsDirectory[i].id == ds ){
          idIn = i;
          break;
      }
    }
    console.log('location name '+getLocname(recordsDirectory[idIn].location_code));
    console.log('pin length  '+getValueOfPINLength(recordsDirectory[idIn].location_code));
    if( arrayOfChange[idIn].access == 0){
      setOpenSnackData("Directory Changes Updated Successfully.");
      setSeverityData("success");
      setOpenSnack(true);
    }
    else{
        let val = getValueOfPINLength(arrayOfChange[idIn].location_code);
        if((arrayOfChange[idIn].door_pin).length == parseInt(val)){
          setLoading(true);
          updateMyDirectoryPostData(arrayOfChange[idIn]);
        }
        else{
          if((arrayOfChange[idIn].door_pin).length == 0){
            setLoading(true);
            updateMyDirectoryPostData(arrayOfChange[idIn]);
          }
          else{
            setOpenSnackData("Door PIN Length Should be "+val+" Digit Only." );
            setSeverityData("error");
            setOpenSnack(true);
          }
        }
    }
   
  }

  const headCells = [
    { id: 'loc_name', label: 'Device Name' },
    { id: 'access', label: 'Access', disableSorting: true },
  ];

  const headCellsDirectory = [
    { id: 'location_name', label: 'Device Name' },
    { id: 'door_pin', label: 'Door PIN', disableSorting: true },
    { id: 'floor', label: 'Floor', disableSorting: true },
    { id: 'dial_code', label: 'Dial Code', disableSorting: true },
    { id: 'hide', label: 'Hide From Directory', disableSorting: true },
    { id: 'action', label: 'Action', disableSorting: true },
  ];

  const headCellsCallLog = [
    { id: 'loc_name', label: 'Device Name' },
    { id: 'call_date', label: 'Date' },
    { id: 'group_call', label: 'Group Call' },
  ];

  const headCellsGroup = [
    { id: 'group_name', label: 'Group Name' },
    { id: 'action', label: 'Action', disableSorting: true },
  ];

  const headCellsRFID = [
    { id: 'generic', label: 'Generic Card #' },
    { id: 'weigand', label: '26 bit Wiegand #'},
    { id: 'action', label: 'Action', disableSorting: true },
  ];

  const headCellsLicense = [
    { id: 'lic_state', label: 'License State' },
    { id: 'lic_number', label: 'License Number'},
    { id: 'action', label: 'Action', disableSorting: true },
  ];
  const checkIfResidnetLinkOrNot = (val,val2) =>{
    //cam_brand
    let loc_access = val;
    let loc_list = val2;
    let arrayofdirectory = [];
    for(let i = 0; i < loc_access.length ; i ++){
      for(let ad = 0; ad < loc_list.length ; ad ++){
        if(loc_list[ad].loc_code == loc_access[i].location_code){
          if(loc_list[ad].cam_brand == 'ResidentLink' || loc_list[ad].cam_brand == 'Virtual Panel'){
            arrayofdirectory.push(loc_access[i]);
          }
        }
      }
      
    }
    return arrayofdirectory;
  }
  const [records, setRecords] = useState(dataOfUser.location_list);
  const [recordsDirectory, setRecordsDirectory] = useState(checkIfResidnetLinkOrNot(dataOfUser.location_access,dataOfUser.location_list));
 
  const [recordsCallLog, setRecordsCallLog] = useState(dataOfUser.call_log);

  const getArrayOfChange = (val) => {
    let arraychange = [];
    for(let i = 0; i < val.length ; i++){

      arraychange.push({id:val[i].id,door_pin:val[i].door_pin, hide:val[i].hide,
        access:0, location_code:val[i].location_code, floor:val[i].floor
      });
    }
    return arraychange;
  }

  const [arrayOfChange, setArrayOfChange] = useState(getArrayOfChange(checkIfResidnetLinkOrNot(dataOfUser.location_access,dataOfUser.location_list)));
  const [recordsGroup, setRecordsGroup] = useState(dataOfUser.group);
  const [customField,setCustomField ] =  useState(dataOfUser.custom_field);
  const [userCustomField,setUserCustomField ] =  useState(dataOfUser.user_custom_field);
  const [recordsRFID, setRecordsRFID] = useState(dataOfUser.rfid);
  const [recordsLicense, setRecordsLicense] = useState(dataOfUser.license);
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const [filterFnDirectory, setFilterFnDirectory] = useState({ fn: items => { return items; } });
  const [filterFnCallLog, setFilterFnCallLog] = useState({ fn: items => { return items; } });
  const [filterFnGroup, setFilterFnGroup] = useState({ fn: items => { return items; } });
  const [filterFnRFID, setFilterFnRFID] = useState({ fn: items => { return items; } });
  const [filterFnLicense, setFilterFnLicense] = useState({ fn: items => { return items; } });
  const [loading, setLoading] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const {
    TblContainer,
    TblHead,
    TblPagination,
    BelowTblPagination,
    recordsAfterPagingAndSorting,
    setPageToNewOne
} = useInnerTable(records, headCells, filterFn,5,650);
const MenuProps = {
  PaperProps: {
    style: {
      backgroundColor: "#1E1E1E",
        borderRadius: 0,
        maxHeight:'250px',
        borderRadius: 10,
        padding:0
    },
  },
};

const {
  TblContainer : TblContainerDirectory,
  TblHead :  TblHeadDirecory,
  TblPagination : TblPaginationDirectory,
  BelowTblPagination : BelowTblPaginationDirectory,
  recordsAfterPagingAndSorting : recordsAfterPagingAndSortingDirectory,
  setPageToNewOne : setPageToNewOneDirectory
} = useInnerTable(recordsDirectory, headCellsDirectory, filterFnDirectory,5,650);

const {
  TblContainer : TblContainerCallLog,
  TblHead :  TblHeadCallLog,
  TblPagination : TblPaginationCallLog,
  BelowTblPagination : BelowTblPaginationCallLog,
  recordsAfterPagingAndSorting : recordsAfterPagingAndSortingCallLog,
  setPageToNewOne : setPageToNewOneCallLog
} = useInnerTable(recordsCallLog, headCellsCallLog, filterFnCallLog,10,650);

const handleChangeBoot = (event) => {
  let ds = event.target.name;
  dataOfUser[ds] = event.target.value;
  setUserForData();
}
const {
  TblContainer : TblContainerGroup,
  TblHead :  TblHeadGroup,
  TblPagination : TblPaginationGroup,
  BelowTblPagination : BelowTblPaginationGroup,
  recordsAfterPagingAndSorting : recordsAfterPagingAndSortingGroup,
  setPageToNewOne : setPageToNewOneGroup
} = useInnerTable(recordsGroup, headCellsGroup, filterFnGroup,10,650);


const {
  TblContainer : TblContainerRFID,
  TblHead :  TblHeadRFID,
  TblPagination : TblPaginationRFID,
  BelowTblPagination : BelowTblPaginationRFID,
  recordsAfterPagingAndSorting : recordsAfterPagingAndSortingRFID,
  setPageToNewOne : setPageToNewOneRFID
} = useInnerTable(recordsRFID, headCellsRFID, filterFnRFID,5,350);
const LicState = [
  "AL","AK","AS","AZ","AR","CA","CO","CT","DE","DC","FM","FL","GA","GU","HI","ID","IL","IN","IA","KS","KY",
  "LA","ME","MH","MD","MA","MI","MN","MS","MO","MT","NE","NV","NH","NJ","NM","NY","NC","ND","MP","OH","OK",
  "OR","PW","PA","PR","RI","SC","SD","TN","TX","UT","VT","VI","VA","WA","WV","WI","WY"
];

const [currentLicenseState,setCurrentLicenseState] = useState('AL');
const [currentLicensePlate,setCurrentLicensePlate] = useState('');

const handleChangeLicenseState = (event) => {
  setCurrentLicenseState(event.target.value);
}
const handleLicensePlateChange= (event) => {
  setCurrentLicensePlate(event.target.value);
}
const {
  TblContainer : TblContainerLicense,
  TblHead :  TblHeadLicense,
  TblPagination : TblPaginationLicense,
  BelowTblPagination : BelowTblPaginationLicense,
  recordsAfterPagingAndSorting : recordsAfterPagingAndSortingLicense,
  setPageToNewOne : setPageToNewOneLicense
} = useInnerTable(recordsLicense, headCellsLicense, filterFnLicense,5,350);
const BootTime = {
  id: "1",
  label: "00:00",
  items: [
    {
      label: "None",
      callback: () =>  {}
    },
    {
      label: "12:00 AM",
      callback: () =>  {}
    },
    {
      label: "12:30 AM",
      callback: () =>  {}
    },
    {
      label:  "01:00 AM",
      callback: () =>  {}
    },
    {
      label:  "01:30 AM",
      callback: () =>  {}
    },
    {
      label: "02:00 AM",
      callback: () =>  {}
    },
    {
      label: "02:30 AM",
      callback: () =>  {}
    },
    {
      label: "03:00 AM",
      callback: () =>  {}
    },
    {
      label: "03:30 AM",
      callback: () =>  {}
    },
    {
      label: "04:00 AM",
      callback: () =>  {}
    },
    {
      label: "04:30 AM",
      callback: () =>  {}
    },
    {
      label: "05:00 AM",
      callback: () =>  {}
    },
    {
      label: "05:30 AM",
      callback: () =>  {}
    },
    {
      label:  "06:00 AM",
      callback: () =>  {}
    },
    {
      label: "06:30 AM",
      callback: () =>  {}
    },
    {
      label: "07:00 AM",
      callback: () =>  {}
    },
    {
      label: "07:30 AM",
      callback: () =>  {}
    },
    {
      label: "08:00 AM",
      callback: () =>  {}
    },
    {
      label: "08:30 AM",
      callback: () =>  {}
    },
    {
      label: "09:00 AM",
      callback: () =>  {}
    },
    {
      label: "09:30 AM",
      callback: () =>  {}
    },
    {
      label: "10:00 AM",
      callback: () =>  {}
    },
    {
      label: "10:30 AM",
      callback: () =>  {}
    },
    {
      label: "11:00 AM",
      callback: () =>  {}
    },
    {
      label: "11:30 AM",
      callback: () =>  {}
    },
    {
      label: "12:00 PM",
      callback: () =>  {}
    },
    {
      label: "12:30 PM",
      callback: () =>  {}
    },
    {
      label: "01:00 PM",
      callback: () =>  {}
    },
    {
      label: "01:30 PM",
      callback: () =>  {}
    },
    {
      label: "02:00 PM",
      callback: () =>  {}
    },
    {
      label: "02:30 PM",
      callback: () =>  {}
    },
    {
      label: "03:00 PM",
      callback: () =>  {}
    },
    {
      label: "03:30 PM",
      callback: () =>  {}
    },
    {
      label: "04:00 PM",
      callback: () =>  {}
    },
    {
      label: "04:30 PM",
      callback: () =>  {}
    },
    {
      label: "05:00 PM",
      callback: () =>  {}
    },
    {
      label: "05:30 PM",
      callback: () =>  {}
    },
    {
      label: "06:00 PM",
      callback: () =>  {}
    },
    {
      label: "06:30 PM",
      callback: () =>  {}
    },
    {
      label: "07:00 PM",
      callback: () =>  {}
    },
    {
      label: "07:30 PM",
      callback: () =>  {}
    },
    {
      label: "08:00 PM",
      callback: () =>  {}
    },
    {
      label: "08:30 PM",
      callback: () =>  {}
    },
    {
      label: "09:00 PM",
      callback: () =>  {}
    },
    {
      label: "09:30 PM",
      callback: () =>  {}
    },
    {
      label: "10:00 PM",
      callback: () =>  {}
    },
    {
      label: "10:30 PM",
      callback: () =>  {}
    },
    {
      label: "11:00 PM",
      callback: () =>  {}
    },
    {
      label: "11:30 PM",
      callback: () =>  {}
    },
    {
      label: "11:59 PM",
      callback: () =>  {}
    }
  ]
}
  
  let RFIDCardData = [], licenseData =[],userCallLogData =[],GroupTYPE = ['Yes','No'];
  for (let i =0; i < 5; i++){
    RFIDCardData[i] = {
      id: i,
      generic:faker.random.numeric(8),
      weigand:faker.name.fullName(8),
    }
  }
  for (let i =0; i < 5; i++){
    licenseData[i] = {
      id: i,
      licenseState:faker.random.alpha(2),
      licensePlate:faker.random.alphaNumeric(7),
    }
  }
  for (let i =0; i < 5; i++){
    userCallLogData[i] = {
      id: i,
    locationname:faker.company.name(),
    timestamp:format(faker.date.recent(), 'dd/MM/yyyy'),
    groupcall:GroupTYPE[Math.floor(Math.random()*GroupTYPE.length)],
    }
  }
  const setUserForData = () => {
    setshowUpdateButton(true);
    setDataOfUser({
      id: dataOfUser.id,
      type:dataOfUser.type,
      fname: dataOfUser.fname,
      lname: dataOfUser.lname,
      phone_no: dataOfUser.phone_no,
      unitno: dataOfUser.unitno,
      user_email: dataOfUser.user_email,
      dateadded: dataOfUser.dateadded,
      last_call_date: dataOfUser.last_call_date,
      status: dataOfUser.status,
      user_image: dataOfUser.user_image,
      custom_data: dataOfUser.custom_data,
      dateupdated: dataOfUser.dateupdated,
      devicetoken: dataOfUser.devicetoken,
      expiry:dataOfUser.expiry,
      floor:dataOfUser.floor,
      pin_enable:dataOfUser.pin_enable,
      lpr_enable:dataOfUser.lpr_enable,
      location_list:dataOfUser.location_list,
      location_access: dataOfUser.location_access,
      rfid: dataOfUser.rfid,
      license: dataOfUser.license,
      rfid_format: dataOfUser.rfid_format,
      group: dataOfUser.group,
      call_log: dataOfUser.call_log,
      custom_field :  dataOfUser.custom_field,
      user_custom_field :  dataOfUser.user_custom_field,
      image_upload_data: dataOfUser.image_upload_data? dataOfUser.image_upload_data : '',
      wiegand:dataOfUser.wiegand,
      start_time:dataOfUser.start_time,
      end_time:dataOfUser.end_time,
      admin_State_ID:dataOfUser.admin_State_ID,
      admin_State:dataOfUser.admin_State
      });
    

  }
  const handlecheckboxCheckReverse=(event) =>{
    let ds = event.target.name;
    dataOfUser[ds] = event.target.checked == true ? "0" : "1";
   // setshowUpdateButton(true);
   setUserForData();
  }
  const handlecheckboxCheck=(event) =>{
    let ds = event.target.name;
    dataOfUser[ds] = event.target.checked == true ? "1" : "0";
   // setshowUpdateButton(true);
   setUserForData();
  }
  const [changeCustomField, setChangeCustomField] = useState([]);
  const handleInputChange=(event) =>{
    console.log(dataOfUser);
    let ds = event.target.name;
    dataOfUser[ds] = event.target.value;
    setUserForData();
  }
  const handleInputChangeCustomField = (event) => {
    console.log(dataOfUser);
    let adIn = {id:event.target.id,type:event.target.type ,name:event.target.name,value:event.target.value};
    let indexOfCurrent;
    for(let i = 0; i < changeCustomField.length ; i++){
      if(changeCustomField[i].id == event.target.id){
        indexOfCurrent = i;
          break;
      }
      
  }
  if(indexOfCurrent >= 0){
    changeCustomField[indexOfCurrent].value = event.target.value;
    setChangeCustomField(changeCustomField);
  }
  else{
    changeCustomField.push(adIn);
    setChangeCustomField(changeCustomField);
  }
    console.log(changeCustomField);
    setUserForData();
  }
  const getValueOfCustomField =  (value) => {
    console.log('user custom field ' +userCustomField);
    let indexOfCurrent;
  for(let i = 0; i < userCustomField.length ; i++){
      if(userCustomField[i].cust_id == value){
        indexOfCurrent = i;
          break;
      }
  }
  if(indexOfCurrent >= 0){
    console.log('user custom field ' +userCustomField[indexOfCurrent].val);
  return userCustomField[indexOfCurrent].val;
}
else{
  return '';
}
   }
  const directoryChange = (event) =>{
    let ds = event.target.name;
    let idIn;
    for(let i = 0; i < recordsDirectory.length ; i++){
      if(recordsDirectory[i].id == ds ){
          idIn = i;
          break;
      }
    }
    console.log("idIn "+idIn);

    let leg = getValueOfPINLength(recordsDirectory[idIn].location_code);

    if((event.target.value).length > leg ){
      event.target.value = event.target.value.slice(0, leg);
    }

    arrayOfChange[idIn].door_pin = event.target.value;
    arrayOfChange[idIn].access = 1;
    setArrayOfChange(arrayOfChange);
    //dataOfUser.location_access[parseInt(idIn)].door_pin = event.target.value;
    
    //setRecordsDirectory(dataOfUser.location_access);
    //setUserForData();
    console.log(arrayOfChange);
  }
  
  const floorValChange = (event) =>{
    let ds = event.target.name;
    let idIn;
    for(let i = 0; i < recordsDirectory.length ; i++){
      if(recordsDirectory[i].id == ds ){
          idIn = i;
          break;
      }
    }
    console.log("idIn "+idIn);

    
    if((event.target.value).length > 64 ){
      event.target.value = 64;
    }

    arrayOfChange[idIn].floor = event.target.value;
    arrayOfChange[idIn].access = 1;
    setArrayOfChange(arrayOfChange);
    //dataOfUser.location_access[parseInt(idIn)].door_pin = event.target.value;
    
    //setRecordsDirectory(dataOfUser.location_access);
    //setUserForData();
    console.log(arrayOfChange);
  }
  const getDisableCheck = (val) => {
    //let ds = event.target.name;
    let idIn;
    for(let i = 0; i < recordsDirectory.length ; i++){
      if(recordsDirectory[i].id == val ){
          idIn = i;
          break;
      }
    }
    let disable = arrayOfChange[idIn].access;

    console.log("disable is "+ disable);

    return disable == 1 ? true :false;

  }
  const directoryCheckChange = (event) =>{
    let ds = event.target.name;
    let idIn;
    for(let i = 0; i < recordsDirectory.length ; i++){
      if(recordsDirectory[i].id == ds ){
          idIn = i;
          break;
      }
    }
    let hide = event.target.checked ? 1 : 0;
   
    arrayOfChange[idIn].access = 1;
    arrayOfChange[idIn].hide = hide;
    setArrayOfChange(arrayOfChange);
    console.log(arrayOfChange);
    //setRecordsDirectory(dataOfUser.location_access);
    //setUserForData();
    console.log("dafs "+ds);
  }
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const updateMyPostData = async () => {
    try {
      const res = await axios.patch("/admin/updateuser/"+token.id+"/"+dataOfUser.id+"/" +dataOfUser.type,{"id":dataOfUser.id,
      "type":dataOfUser.type,
      "fname":dataOfUser.fname,
      "lname": dataOfUser.lname,
      "phone_no": dataOfUser.phone_no,
      "unitno": dataOfUser.unitno,
      "user_email": dataOfUser.user_email,
      "user_image": dataOfUser.user_image,
      "custom_data": dataOfUser.custom_data,
      "expiry":  dataOfUser.expiry,
      "floor":  dataOfUser.floor,
      "lpr" :dataOfUser.lpr_enable,
      "pin" :dataOfUser.pin_enable,
      "wiegand" :dataOfUser.wiegand,
      "start_time" :dataOfUser.start_time,
      "end_time" :dataOfUser.end_time,
      "image_upload_data": dataOfUser.image_upload_data,
      "custom_field" :changeCustomField,
      "issub":token.issub,"subId":token.subid
    
    }, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
    'token' : token.token,
    'tokenid' : token.email }});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('EUES'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setOpenSnackData(getAlertTitle('EUEF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };
  const getValueOfChek = (val) =>{
    let loc_access = dataOfUser.location_access;
    let ret = false;
    for(let i = 0; i < loc_access.length ; i ++){
      let loc_code = loc_access[i].location_code;
      if(loc_code == val){
        ret = true;
        break;
      }
    }
    return ret;
  }

  

  const getLocname =(val) => {
    let loc_access = dataOfUser.location_list;
    let ret;
    for(let i = 0; i < loc_access.length ; i ++){
      let loc_code = loc_access[i].loc_code;
      if(loc_code == val){
        ret = loc_access[i].loc_name;
        break;
      }
    }
    return ret;
  }

  const getValueOfPINLength = (val) =>{
    let loc_access = dataOfUser.location_list;
    let ret;
    for(let i = 0; i < loc_access.length ; i ++){
      let loc_code = loc_access[i].loc_code;
      if(loc_code == val){
        ret = loc_access[i].pin_length;
        break;
      }
    }
    console.log('ret val'+ret);
    return ret;
  }
  const addRFIDPost = async () => {
    console.log("In update post data");
    setLoading(true);
    try {
      const res = await axios.post("/admin/addrfid/"+token.id,{format:dataOfUser.rfid_format,generic:rfidInput,ufname:dataOfUser.fname,ulname:dataOfUser.lname,pid:token.id,user_phone:dataOfUser.phone_no,issub:token.issub,subId:token.subid}, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('EURCAS'));
      setSeverityData("success");
      setOpenSnack(true);
      //setLoading(false);
      //setshowUpdateButton(false);
      getMyPostData();
     
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setOpenSnackData(getAlertTitle('EURCAF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };
  const addLicesnePost = async () => {
    console.log("In update post data");
    setLoading(true);
    try {
      const res = await axios.post("/admin/addlicense/"+token.id,{lic_number:currentLicensePlate,lic_state:currentLicenseState,pid:token.id,user_phone:dataOfUser.phone_no,userfname:dataOfUser.fname,userlname:dataOfUser.lname,user_type:dataOfUser.type,issub:token.issub,subId:token.subid}, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      console.log( res.data);
      setOpenSnackData(res.data.rows);
      setSeverityData("success");
      setOpenSnack(true);
      //setLoading(false);
      //setshowUpdateButton(false);
      getMyPostData();
     
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setOpenSnackData(getAlertTitle('EULPAF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };
  const [rfidInput,setRfidInput] = useState('');
  const handleAddLicense = (val) => {
    if(currentLicensePlate.length > 0){
       addLicesnePost();
    }
    else{
      setOpenSnackData(getAlertTitle('EULPNNE'));
      setSeverityData("error");
      setOpenSnack(true);
    }

  }
  const handleAddRFID = (val) => {
    console.log("rfid input is "+rfidInput);
    if(dataOfUser.rfid_format == 1 || dataOfUser.rfid_format == 2){
      if(rfidInput.length < 11 &&  rfidInput.length > 6){
        addRFIDPost();

      }
      else{
        setOpenSnackData(getAlertTitle('EURCGLNV'));
        setSeverityData("error");
        setOpenSnack(true);
      }
    }
    else{
      if(rfidInput.length < 9 &&  rfidInput.length > 6){
        addRFIDPost();

      }
      else{
        setOpenSnackData(getAlertTitle('EURCWLNV'));
        setSeverityData("error");
        setOpenSnack(true);
      }
    }
  }
  const RFIDInputChange = (event) => {
    setRfidInput(event.target.value);
  }
  const getValueOfPINLengthMax = (val) =>{
    let loc_access = dataOfUser.location_list;
    let ret;
    for(let i = 0; i < loc_access.length ; i ++){
      let loc_code = loc_access[i].loc_code;
      if(loc_code == val){
        ret = loc_access[i].pin_length;
        break;
      }
    }
    console.log('ret val'+ret);
    if(ret == 4){
      return 9999;
    }
    else{
      return 999999;
    }
    //return ret;
  }
  
  const [currentLocCode, setCurentLocCode] = useState('');
  const [currentAllow, setCurentAllow] = useState('1');

  const getMyPostData = async () => {
    try {
      const res = await axios.get("/admin/getuserdetails/"+token.id+"/"+dataOfUser.id+"/" +dataOfUser.type,{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email },
      });
      console.log("data of user admin is "+res.data);
      setDataOfUser(res.data);
      setRecords(res.data.location_list);
      setRecordsDirectory(checkIfResidnetLinkOrNot(res.data.location_access, res.data.location_list));
      setRecordsCallLog(res.data.call_log);
      setRecordsGroup(res.data.group);
      setRecordsRFID(res.data.rfid);
      setCustomField(res.data.custom_field);
      setUserCustomField(res.data.user_custom_field);
      setChangeCustomField([]);
      setRecordsLicense(res.data.license);
      setArrayOfChange(getArrayOfChange(res.data.location_access));
      setImageUrl( dataOfUser.user_image != null ? "https://mdvaccess.com/"+dataOfUser.user_image : NoImage);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };

  const updateStateMyPostData = async (val) => {
    // console.log(currentObj.type + " "+ currentObj.devicetoken + " " +currentObj.id);
    setLoading(true);
     try {
       const res = await axios.patch("/admin/updatestate/"+token.id+"/"+val,{issub:token.issub,subId:token.subid,userfname:dataOfUser.fname,userlname:dataOfUser.lname} ,{headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
       'token' : token.token,
       'tokenid' : token.email }});
       console.log( res.data);
       setOpenSnackData(getAlertTitle('EUESS'));
       setSeverityData("success");
       setOpenSnack(true);
       setLoading(false);
       getMyPostData();
     } catch (error) {
       console.log(error.message);
       setLoading(false);
       setOpenSnackData(getAlertTitle('EUESF'));
       setSeverityData("error");
       setOpenSnack(true);
       if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
     }
   };
  const changeState =(val) => {
    console.log('change val');
    if(val != ''){
      updateStateMyPostData(val);
     console.log('change val');
    }

  }
  const changeAccessOfUser = (event) => {
    console.log(event.target.name + " value " +event.target.checked);
    setCurentLocCode(event.target.name);
    
    setCurentAllow(event.target.checked ? '1' : '0');
    let allow = event.target.checked ? '1' : '0';
    updateUserAccess(event.target.name,allow);
   /* console.log({"id":dataOfUser.id,
    "type":dataOfUser.type,
    "expiry":  dataOfUser.expiry,
    "deviceToken":dataOfUser.devicetoken,
    "phone": dataOfUser.phone_no,
    "pin_length": getValueOfPINLength(currentLocCode),
    "location_code": currentLocCode,
    "allow":currentAllow
    
  });*/
  }
  const updateUserAccess = async (val,val2) => {
    try {
      const res = await axios.patch("/admin/registeruser/"+token.id,{"id":dataOfUser.id,
      "type":dataOfUser.type,
      "expiry":  dataOfUser.expiry,
      "deviceToken":dataOfUser.devicetoken,
      "phone": dataOfUser.phone_no,
      "pin_length":getValueOfPINLength(val),
      "location_code": val,
      "allow":val2,
      "userfname":dataOfUser.fname,
      "userlname":dataOfUser.lname,
      "issub":token.issub,
      "subId":token.subid
    }, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
    'token' : token.token,
    'tokenid' : token.email }});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('EUELAS'));
      setSeverityData("success");
      setOpenSnack(true);
      
      getMyPostData();
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setOpenSnackData(getAlertTitle('EUELAF'));
      setSeverityData("error");
      setOpenSnack(true);
      setRecords(dataOfUser.location_list);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };

  const handleUpdateUser = () => {
    console.log(dataOfUser);
    setLoading(true);
    let userfname = dataOfUser.fname;
    userfname = userfname.replaceAll(" ","");
    if(userfname == ""){
      setOpenSnackData(getAlertTitle('EUUFNNE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    let userlname = dataOfUser.lname;
    userlname = userlname.replaceAll(" ","");
    if(userlname == ""){
      setOpenSnackData(getAlertTitle('EUULNNE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }

    if (!isValidEmail(dataOfUser.user_email)) {
      setOpenSnackData(getAlertTitle('EUUENV'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    } 

    updateMyPostData();
  }
  return <> 
  
  <Box sx={mainThemeStyle.boxTopHeader}>
     {(currentTab == 0 && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(0)}} active>Identity</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Manage User Access</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Directory</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Groups</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Credentials</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Call Logs</Button>
  </Stack>)}
  {(currentTab == 1 && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} active>Identity</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(1)}}>Manage User Access</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Directory</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Groups</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Credentials</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Call Logs</Button>
  </Stack>)}
  {(currentTab == 2 && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} active>Identity</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Manage User Access</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(2)}}>Directory</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Groups</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Credentials</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Call Logs</Button>
  </Stack>)}
  {(currentTab == 3 && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} active>Identity</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Manage User Access</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Directory</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(3)}}>Groups</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Credentials</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Call Logs</Button>
  </Stack>)}
  {(currentTab == 4 && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} active>Identity</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Manage User Access</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Directory</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Groups</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(4)}}>Credentials</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Call Logs</Button>
  </Stack>)}
  {(currentTab == 5 && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} active>Identity</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Manage User Access</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Directory</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Groups</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Credentials</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(5)}}>Call Logs</Button>
  </Stack>)}
  
            
</Box>

<Box sx={{position: 'absolute',
           width:'auto',
          
           top:'100px',
           left: '5px',
           display: { xs: "flex", md: "none" },
           right: '5px',
           flexGrow: 1,
          
           }}>
 
{(currentTab == 0 && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
<Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(0)}} active>Identity</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Manage User Access</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Directory</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Groups</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Credentials</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Call Logs</Button>
    </Grid> </Grid>)}
  {(currentTab == 1 && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
<Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} active>Identity</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(1)}}>Manage User Access</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Directory</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Groups</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Credentials</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Call Logs</Button>
    </Grid> </Grid>)}
  {(currentTab == 2 && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
<Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} active>Identity</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Manage User Access</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(2)}}>Directory</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Groups</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Credentials</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Call Logs</Button>
    </Grid> </Grid>)}
  {(currentTab == 3 && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
<Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} active>Identity</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Manage User Access</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Directory</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(3)}}>Groups</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Credentials</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Call Logs</Button>
    </Grid> </Grid>)}
  {(currentTab == 4 && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
<Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} active>Identity</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Manage User Access</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Directory</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Groups</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(4)}}>Credentials</Button>
    </Grid><Grid item > <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Call Logs</Button>
   </Grid> </Grid>)}
  {(currentTab == 5 && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
<Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} active>Identity</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Manage User Access</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Directory</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Groups</Button>
    </Grid><Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Credentials</Button>
    </Grid><Grid item > <Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(5)}}>Call Logs</Button>
  </Grid> </Grid>)}
 
  
        </Box> 
{(currentTab == 0 && <Box sx={mainThemeStyle.boxHeaderBelow}>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       User Identity
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           
           <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> First Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Last Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Unit Number </Typography>
      {/* <Typography  sx={mainThemeStyle.popUpLable} height={40}>User Floor</Typography> */}
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Exp. Date </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>PIN Access </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Wiegand Card Reader </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}> Wiegand Schedule Time </Typography>
      {customField.map((row) => (
        Math.abs(row.number % 2) == 0 &&
           <Typography sx={mainThemeStyle.popUpLable} height={40}> {row.name} </Typography>
      ))}

      {showUpdateButton && <LoadingButton
         
         sx={mainThemeStyle.normalButton}
         loading={loading}
         onClick={handleUpdateUser}
         variant="outlined"
         startIcon={<SaveIcon />}
         loadingPosition="start"
       >
         Save User Details
       </LoadingButton>}
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <TextField
                       
                        height={40}
                        type= 'text'
                        name="fname"
                        value={dataOfUser.fname}
                        sx={mainThemeStyle.InputMenuItem}
                        onChange={handleInputChange}
                        variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("186")}
                      InputProps={{
                           
                        sx: {
                            
                            color:'rgba(135, 135, 135, 0.5)',
                            height:'33px',
                            borderRadius: '5px',
                            backgroundColor: '#121212',
                            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                            padding: '0 0 15px 0',
                            textIndent: '15px !important',
                          
                            '&.Mui-disabled': {
             

                              background: 'rgba(135, 135, 135, 0.5)',
                            },
                            
                            "& input.Mui-disabled": {
                              color: "white"
                            }
                            
                        },
                    }}
                    />
      <TextField
                       
                       height={40}
                       type= 'text'
                       name="lname"
                       value={dataOfUser.lname}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       variant="filled"
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("187")}
                     InputProps={{
                          
                       sx: {
                           
                           color:'rgba(135, 135, 135, 0.5)',
                           height:'33px',
                           borderRadius: '5px',
                           backgroundColor: '#121212',
                           boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                           padding: '0 0 15px 0',
                           textIndent: '15px !important',
                           '&.Mui-disabled': {
             

                            background: 'rgba(135, 135, 135, 0.5)',
                          },
                          
                          "& input.Mui-disabled": {
                            color: "white"
                          }
                          
                           
                       },
                   }}
                   />
      <TextField
                       
                       height={40}
                       type= 'text'
                       name="unitno"
                       value={dataOfUser.unitno}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       variant="filled"
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("188")}
                     InputProps={{
                          
                       sx: {
                           
                           color:'rgba(135, 135, 135, 0.5)',
                           height:'33px',
                           borderRadius: '5px',
                           backgroundColor: '#121212',
                           boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                           padding: '0 0 15px 0',
                           textIndent: '15px !important',
                           '&.Mui-disabled': {
             

                            background: 'rgba(135, 135, 135, 0.5)',
                          },
                          
                          "& input.Mui-disabled": {
                            color: "white"
                          }
                          
                           
                       },
                   }}
                   />
      {/* <TextField
                       
                       height={40}
                       type= 'text'
                       name="floor"
                       onChange={handleInputChange}
                       value={dataOfUser.floor}
                       sx={mainThemeStyle.InputMenuItem}
                       
                       variant="filled"
                       InputLabelProps={{ shrink: true }
                     }
                         
                     InputProps={{
                          
                       sx: {
                           
                           color:'rgba(135, 135, 135, 0.5)',
                           height:'33px',
                           borderRadius: '5px',
                           backgroundColor: '#121212',
                           boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                           padding: '0 0 15px 0',
                           textIndent: '15px !important',
                         
                          
                           
                       },
                   }}
                   /> */}
     <TextField
        id="date"
        type="date"
        value={dataOfUser.expiry}
        name="expiry"
        variant="filled"
        onChange={handleInputChange}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("189")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />


          <FormControlLabel   sx={ {height:'35px'}} 
        control={<IOSSwitch sx={{ m: 1 }} name="pin_enable" onChange={handlecheckboxCheckReverse}   disabled = {getDisableValue("190")} checked={dataOfUser.pin_enable == "0" ? true : false } />}
        label=""
      />
        <FormControlLabel   sx={ {height:'35px'}} 
        control={<IOSSwitch sx={{ m: 1 }} name="wiegand" onChange={handlecheckboxCheck}   disabled = {getDisableValue("191")} checked={dataOfUser.wiegand == "1" ? true : false } />}
        label=""
      />
      <Stack direction="row" spacing={2} height={40}>

<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'4px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "start_time"
 disabled = {getDisableValue("192")}
 value={dataOfUser.start_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select>
<Typography sx={mainThemeStyle.popUpLableAnswer} paddingTop="7px"> To </Typography>
<Box>
<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'4px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "end_time"
 disabled = {getDisableValue("192")}
 value={dataOfUser.end_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select></Box>
</Stack>
      {customField.map((row) => (
    Math.abs(row.number % 2) == 0 &&
            <TextField
            id={row.id}
            type={row.type}
           defaultValue = {getValueOfCustomField(row.id)}
            name={row.name}
            variant="filled"
            onChange={handleInputChangeCustomField}
            sx={mainThemeStyle.InputMenuItem}
                            InputLabelProps={{ shrink: true }
                          }
                          disabled = {getDisableValue("244")}
                            InputProps={{
                               
                                sx: {
                                    
                                    color:'rgba(135, 135, 135, 0.5)',
                                    height:'33px',
                                    borderRadius: '5px',
                                    backgroundColor: '#121212',
                                    boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                    padding: '0 0 15px 0',
                                    textIndent: '15px !important',
                                  
                                    '&.Mui-disabled': {
             

                                      background: 'rgba(135, 135, 135, 0.5)',
                                    },
                                    
                                    "& input.Mui-disabled": {
                                      color: "white"
                                    }
                                    
                                },
                            }}
                           
          />
      ))}
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={200}> User Image </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Email </Typography>
      
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>LPR Access </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Phone </Typography>
      {customField.map((row) => (
        Math.abs(row.number % 2) == 1 &&
           <Typography sx={mainThemeStyle.popUpLable} height={40}> {row.name} </Typography>
      ))}
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
          <Stack direction="row" spacing={2}>
        <img  src={imageUrl} height={200} width={200}></img>
        <Button  sx={mainThemeStyle.normalButton}   disabled = {getDisableValue("193")} variant="contained">Upload <input hidden accept="image/png, image/jpeg" multiple type="file" 
        onChange={ 
          e => {
            if (e.target.files[0].size > 2000000) {
              console.log("File too large");
              return;
            }
            
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
        
            reader.onload = () => {
              console.log(reader.result);
              setSelectedImage(e.target.files[0]);
              dataOfUser.image_upload_data = reader.result;
              setUserForData();
            };
            reader.onerror = error => {
              console.log("Error: ", error);
            };


          
          }
        }/></Button>
        </Stack>
      
        <TextField
                       
                       height={40}
                       type= 'text'
                       name="user_email"
                       value={dataOfUser.user_email}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       variant="filled"
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("194")}
                     InputProps={{
                          
                       sx: {
                           
                           color:'rgba(135, 135, 135, 0.5)',
                           height:'33px',
                           borderRadius: '5px',
                           backgroundColor: '#121212',
                           boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                           padding: '0 0 15px 0',
                           textIndent: '15px !important',
                           '&.Mui-disabled': {
             

                            background: 'rgba(135, 135, 135, 0.5)',
                          },
                          
                          "& input.Mui-disabled": {
                            color: "white"
                          }
                          
                           
                       },
                   }}
                   />
                      {/* <TextField
                       
                       height={40}
                       type= 'phone'
                       name="phone_no"
                       value={dataOfUser.phone_no}
                       sx={mainThemeStyle.InputMenuItem}
                       
                       variant="filled"
                       InputLabelProps={{ shrink: true }
                     }
                         
                     InputProps={{
                          
                       sx: {
                           
                           color:'rgba(135, 135, 135, 0.5)',
                           height:'33px',
                           borderRadius: '5px',
                           backgroundColor: '#121212',
                           boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                           padding: '0 0 15px 0',
                           textIndent: '15px !important',
                         
                          
                           
                       },
                   }}
                   /> */}
                   
                   <FormControlLabel   sx={ {height:'40px'}} 
        control={<IOSSwitch sx={{ m: 1 }} name="lpr_enable"   disabled = {getDisableValue("195")} onChange={handlecheckboxCheckReverse} checked={dataOfUser.lpr_enable == "0" ? true : false } />}
        label=""
      />
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>{dataOfUser.phone_no}</Typography>
                   {customField.map((row) => (
    Math.abs(row.number % 2) == 1 &&
            <TextField
            id={row.id}
            type={row.type}
           defaultValue = {getValueOfCustomField(row.id)}
            name={row.name}
            variant="filled"
            onChange={handleInputChangeCustomField}
            sx={mainThemeStyle.InputMenuItem}
                            InputLabelProps={{ shrink: true }
                          }
                          disabled = {getDisableValue("244")}
                            InputProps={{
                               
                                sx: {
                                    
                                    color:'rgba(135, 135, 135, 0.5)',
                                    height:'33px',
                                    borderRadius: '5px',
                                    backgroundColor: '#121212',
                                    boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                    padding: '0 0 15px 0',
                                    textIndent: '15px !important',
                                    '&.Mui-disabled': {
             

                                      background: 'rgba(135, 135, 135, 0.5)',
                                    },
                                    
                                    "& input.Mui-disabled": {
                                      color: "white"
                                    }
                                   
                                    
                                },
                            }}
                           
          />
      ))}
      </Stack>
      </Grid>
     
      
      </Grid></Box>
      <Box sx={{display: { xs: "flex", md: "none" }}}>
           <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> First Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Last Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Unit Number </Typography>
     {/*  <Typography  sx={mainThemeStyle.popUpLable} height={40}>User Floor</Typography> */}
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Exp. Date </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>PIN Access </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Wiegand Card Reader </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={120}> Wiegand Schedule Time </Typography>
      
      <Typography sx={mainThemeStyle.popUpLable} height={145}> User Image </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Email </Typography>
      
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>LPR Access </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Phone </Typography>
      {customField.map((row) => (
         <Typography sx={mainThemeStyle.popUpLable} height={40}> {row.name} </Typography>
      ))}

   
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <TextField
                       
                        height={40}
                        type= 'text'
                        name="fname"
                        value={dataOfUser.fname}
                        sx={mainThemeStyle.InputMenuItem}
                        onChange={handleInputChange}
                        variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("186")}
                      InputProps={{
                           
                        sx: {
                            
                            color:'rgba(135, 135, 135, 0.5)',
                            height:'33px',
                            borderRadius: '5px',
                            backgroundColor: '#121212',
                            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                            padding: '0 0 15px 0',
                            textIndent: '15px !important',
                            '&.Mui-disabled': {
             

                              background: 'rgba(135, 135, 135, 0.5)',
                            },
                            
                            "& input.Mui-disabled": {
                              color: "white"
                            }
                           
                            
                        },
                    }}
                    />
      <TextField
                       
                       height={40}
                       type= 'text'
                       name="lname"
                       value={dataOfUser.lname}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       variant="filled"
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("187")}
                     InputProps={{
                          
                       sx: {
                           
                           color:'rgba(135, 135, 135, 0.5)',
                           height:'33px',
                           borderRadius: '5px',
                           backgroundColor: '#121212',
                           boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                           padding: '0 0 15px 0',
                           textIndent: '15px !important',
                         
                           '&.Mui-disabled': {
             

                            background: 'rgba(135, 135, 135, 0.5)',
                          },
                          
                          "& input.Mui-disabled": {
                            color: "white"
                          }
                           
                       },
                   }}
                   />
      <TextField
                       
                       height={40}
                       type= 'text'
                       name="unitno"
                       value={dataOfUser.unitno}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       variant="filled"
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("188")}
                     InputProps={{
                          
                       sx: {
                           
                           color:'rgba(135, 135, 135, 0.5)',
                           height:'33px',
                           borderRadius: '5px',
                           backgroundColor: '#121212',
                           boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                           padding: '0 0 15px 0',
                           textIndent: '15px !important',
                         
                           '&.Mui-disabled': {
             

                            background: 'rgba(135, 135, 135, 0.5)',
                          },
                          
                          "& input.Mui-disabled": {
                            color: "white"
                          }
                           
                       },
                   }}
                   />
     {/*  <TextField
                       
                       height={40}
                       type= 'text'
                       name="floor"
                       onChange={handleInputChange}
                       value={dataOfUser.floor}
                       sx={mainThemeStyle.InputMenuItem}
                       
                       variant="filled"
                       InputLabelProps={{ shrink: true }
                     }
                         
                     InputProps={{
                          
                       sx: {
                           
                           color:'rgba(135, 135, 135, 0.5)',
                           height:'33px',
                           borderRadius: '5px',
                           backgroundColor: '#121212',
                           boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                           padding: '0 0 15px 0',
                           textIndent: '15px !important',
                         
                          
                           
                       },
                   }}
                   /> */}
     <TextField
        id="date"
        type="date"
        value={dataOfUser.expiry}
        name="expiry"
        variant="filled"
        onChange={handleInputChange}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("189")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />


          <FormControlLabel   sx={ {height:'35px'}} 
        control={<IOSSwitch sx={{ m: 1 }} name="pin_enable"   disabled = {getDisableValue("190")} onChange={handlecheckboxCheckReverse} checked={dataOfUser.pin_enable == "0" ? true : false } />}
        label=""
      />
        <FormControlLabel   sx={ {height:'35px'}} 
        control={<IOSSwitch sx={{ m: 1 }} name="wiegand"   disabled = {getDisableValue("191")} onChange={handlecheckboxCheck} checked={dataOfUser.wiegand == "1" ? true : false } />}
        label=""
      />
<Stack direction="column" spacing={2} height={120}>

<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'4px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "start_time"
 disabled = {getDisableValue("192")}
 value={dataOfUser.start_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select>
<Typography sx={mainThemeStyle.popUpLableAnswer} > To </Typography>
<Box>
<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'-15px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "end_time"
 disabled = {getDisableValue("192")}
 value={dataOfUser.end_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select></Box>
</Stack>
<Stack direction="column" spacing={2}>
        <img  src={imageUrl} height={100} width={100}></img>
        <Button  sx={mainThemeStyle.normalButton} variant="contained"    disabled = {getDisableValue("193")}>Upload <input hidden accept="image/png, image/jpeg" multiple type="file" 
        onChange={ 
          e => {
            if (e.target.files[0].size > 2000000) {
              console.log("File too large");
              return;
            }
            
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
        
            reader.onload = () => {
              console.log(reader.result);
              setSelectedImage(e.target.files[0]);
              dataOfUser.image_upload_data = reader.result;
              setUserForData();
            };
            reader.onerror = error => {
              console.log("Error: ", error);
            };


          
          }
        }/></Button>
        </Stack>
      
        <TextField
                       
                       height={40}
                       type= 'text'
                       name="user_email"
                       value={dataOfUser.user_email}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       variant="filled"
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("194")}
                     InputProps={{
                          
                       sx: {
                           
                           color:'rgba(135, 135, 135, 0.5)',
                           height:'33px',
                           borderRadius: '5px',
                           backgroundColor: '#121212',
                           boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                           padding: '0 0 15px 0',
                           textIndent: '15px !important',
                         
                           '&.Mui-disabled': {
             

                            background: 'rgba(135, 135, 135, 0.5)',
                          },
                          
                          "& input.Mui-disabled": {
                            color: "white"
                          }
                           
                       },
                   }}
                   />
                      
                   
                   <FormControlLabel   sx={ {height:'40px'}} 
        control={<IOSSwitch sx={{ m: 1 }} name="lpr_enable"    disabled = {getDisableValue("195")} onChange={handlecheckboxCheckReverse} checked={dataOfUser.lpr_enable == "0" ? true : false } />}
        label=""
      />
      <Typography  sx={mainThemeStyle.popUpLable} paddingTop='7px' height={40}>{dataOfUser.phone_no}</Typography>
                   {customField.map((row) => (
   
            <TextField
            id={row.id}
            type={row.type}
           defaultValue = {getValueOfCustomField(row.id)}
            name={row.name}
            variant="filled"
            onChange={handleInputChangeCustomField}
            sx={mainThemeStyle.InputMenuItem}
                            InputLabelProps={{ shrink: true }
                          }
                          disabled = {getDisableValue("244")}
                            InputProps={{
                               
                                sx: {
                                    
                                    color:'rgba(135, 135, 135, 0.5)',
                                    height:'33px',
                                    borderRadius: '5px',
                                    backgroundColor: '#121212',
                                    boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                    padding: '0 0 15px 0',
                                    textIndent: '15px !important',
                                    '&.Mui-disabled': {
             

                                      background: 'rgba(135, 135, 135, 0.5)',
                                    },
                                    
                                    "& input.Mui-disabled": {
                                      color: "white"
                                    }
                                   
                                    
                                },
                            }}
                           
          />
      ))}
      
      {customField.map((row) => (
    Math.abs(row.number % 2) == 0 &&
            <TextField
            id={row.id}
            type={row.type}
           defaultValue = {getValueOfCustomField(row.id)}
            name={row.name}
            variant="filled"
            onChange={handleInputChangeCustomField}
            sx={mainThemeStyle.InputMenuItem}
                            InputLabelProps={{ shrink: true }
                          }
                          disabled = {getDisableValue("244")}
                            InputProps={{
                               
                                sx: {
                                    
                                    color:'rgba(135, 135, 135, 0.5)',
                                    height:'33px',
                                    borderRadius: '5px',
                                    backgroundColor: '#121212',
                                    boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                    padding: '0 0 15px 0',
                                    textIndent: '15px !important',
                                    '&.Mui-disabled': {
             

                                      background: 'rgba(135, 135, 135, 0.5)',
                                    },
                                    
                                    "& input.Mui-disabled": {
                                      color: "white"
                                    }
                                   
                                    
                                },
                            }}
                           
          />
      ))}
      </Stack>
      </Grid>
      
      {showUpdateButton && <LoadingButton
         
         sx={{  width: 'auto',
         height: '2.06rem',
         marginTop:'1rem',
         button: {
             textTransform: "none"
           },
         fontWeight: '700',
         fontSize: '0.83rem',
         lineHeight:'0.93rem',
         marginLeft:'16px',
         alignItems: 'center',
         color: '#FFFFFF',
         fontFamily: "Lato",
         borderRadius:'0.31rem',
        '&:hover': {
         backgroundColor: 'rgba(109, 195, 252, 1)',
        }}}
         loading={loading}
         onClick={handleUpdateUser}
         variant="outlined"
         startIcon={<SaveIcon />}
         loadingPosition="start"
       >
         Save User Details
       </LoadingButton>}
     
      
      </Grid></Box>
      <div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       User Details
                   </Typography>
                   
               </div>
               </div>
               <Divider sx={{backgroundColor:'white'}} />
               <Box sx={{display: { xs: "none", md: "flex" }}}>
               <Grid container spacing={2} padding= '32px'> 
               <Grid item xs={2}>
               <Stack direction="column"  spacing={2}>
                <Typography sx={mainThemeStyle.popUpLable} height={40}> User Status </Typography>
                <Typography sx={mainThemeStyle.popUpLable} height={40}> Created </Typography>
                <Typography sx={mainThemeStyle.popUpLable} height={40}> Updated </Typography>
                </Stack>
               </Grid>
               <Grid item xs={2}>
               <Stack direction="column"  spacing={2}>
               {dataOfUser.admin_State == 1 ? <Button sx={mainThemeStyle.popUpLableAnswerGreenButton}  variant='text'  onClick={() =>{changeState(dataOfUser.admin_State_ID)}}>ACTIVE</Button> 
                :( dataOfUser.admin_State == -1 ?  <Button sx={mainThemeStyle.popUpLableAnswerYellowButton}  variant='text'  onClick={() =>{changeState(dataOfUser.admin_State_ID)}}>PENDING</Button>  :
                <Button sx={mainThemeStyle.popUpLableAnswerRedButton}  variant='text'  onClick={() =>{changeState(dataOfUser.admin_State_ID)}}>BLOCKED</Button> )}
                   
                <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {dataOfUser.dateadded} </Typography>
                <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {dataOfUser.dateupdated} </Typography>
                </Stack>
               </Grid>
               </Grid></Box>
               <Box sx={{display: { xs: "flex", md: "none" }}}>
               <Grid container spacing={2}  padding= '10px' paddingBottom='32px' paddingTop='32px'> 
               <Grid item xs={5}>
               <Stack direction="column"  spacing={2}>
                <Typography sx={mainThemeStyle.popUpLable} height={40}> User Status </Typography>
                <Typography sx={mainThemeStyle.popUpLable} height={40}> Created </Typography>
                <Typography sx={mainThemeStyle.popUpLable} height={40}> Updated </Typography>
                </Stack>
               </Grid>
               <Grid item xs={7}>
               <Stack direction="column"  spacing={2}>
               {dataOfUser.admin_State == 1 ? <Button sx={mainThemeStyle.popUpLableAnswerGreenButton}  variant='text'  onClick={() =>{changeState(dataOfUser.admin_State_ID)}}>ACTIVE</Button> 
                :( dataOfUser.admin_State == -1 ?  <Button sx={mainThemeStyle.popUpLableAnswerYellowButton}  variant='text'  onClick={() =>{changeState(dataOfUser.admin_State_ID)}}>PENDING</Button>  :
                <Button sx={mainThemeStyle.popUpLableAnswerRedButton}  variant='text'  onClick={() =>{changeState(dataOfUser.admin_State_ID)}}>BLOCKED</Button> )}
                   
                <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {dataOfUser.dateadded} </Typography>
                <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {dataOfUser.dateupdated} </Typography>
                </Stack>
               </Grid>
               </Grid></Box>
</Box>)}

{(currentTab == 1 && <Box sx={mainThemeStyle.boxHeaderBelow}>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Manage User Access
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           
           <TblContainer>
                    <TblHead />

        <TableBody>
         {recordsAfterPagingAndSorting().map(item => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {item.loc_name}
              </TableCell>
              <TableCell ><Checkbox sx={mainThemeStyle.formCheckBox} name={item.loc_code} onChange={changeAccessOfUser}   disabled = {getDisableValue("196")} defaultChecked={getValueOfChek(item.loc_code)}/></TableCell>
            </TableRow>
          ))}
        </TableBody>
     
    </TblContainer>
    <BelowTblPagination ></BelowTblPagination>
</Box>)}

{(currentTab == 2 && <Box sx={mainThemeStyle.boxHeaderBelow}>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Directory
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <TblContainerDirectory>
                    <TblHeadDirecory />

        <TableBody>
         {recordsAfterPagingAndSortingDirectory().map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell align="left" sx={mainThemeStyle.cellRow}>
              {getLocname(row.location_code)}
              </TableCell>
              <TableCell align="left"> <TextField
        id="text"
        type="number"
        sx={mainThemeStyle.InputMenuItem}
        name={row.id}
        defaultValue =  {row.door_pin}
        onChange = {directoryChange}
        
          variant = "filled"
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("197")}
                        InputProps={{
                          inputProps: {max: getValueOfPINLength(row.location_code) },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>

<TableCell align="left"> <TextField
        id="text"
        type="number"
        sx={mainThemeStyle.InputMenuItem}
        name={row.id}
        defaultValue =  {row.floor}
        onChange = {floorValChange}
        disabled = {getDisableValue("198")}
          variant = "filled"
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: {max: 64 },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell align="left" sx={mainThemeStyle.cellRow}> {row.dial_code}</TableCell>
              <TableCell align="left"><Checkbox name={row.id} sx={mainThemeStyle.formCheckBox}   disabled = {getDisableValue("199")} onChange = {directoryCheckChange} defaultChecked={row.hide == 1 ? true : false}/></TableCell>
              <TableCell align="left">
                <LoadingButton loading={loading} name={row.id} sx={mainThemeStyle.viewLogButton} onClick={handleDirectorySave} >Save Changes</LoadingButton>
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      
    </TblContainerDirectory>
    <BelowTblPaginationDirectory ></BelowTblPaginationDirectory>

</Box>)}
{(currentTab == 3 && <Box sx={mainThemeStyle.boxHeaderBelow}>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Groups
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <TblContainerGroup>
                    <TblHeadGroup />

        <TableBody>
         {recordsAfterPagingAndSortingGroup().map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell align="left" sx={mainThemeStyle.cellRow}>
              {/* <TextField
        id="text"
        type="text"
       value={row}
       variant = "filled"
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /> */} {row.group_name}
              </TableCell>
              <TableCell align="left"> <Button  sx={mainThemeStyle.viewLogButton}  disabled = {getDisableValue("200")} name={row.group_name} onClick={() =>{handleGroupDelete(row.group_name,row.group_id)}}>Delete</Button></TableCell>
   
              
            </TableRow>
          ))}
        </TableBody>
     
    </TblContainerGroup>
    <BelowTblPaginationGroup></BelowTblPaginationGroup>
</Box>)}
{(currentTab == 4 && <Box sx={mainThemeStyle.boxHeaderBelow}>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Credentials
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "block" }}}>
           <Grid container spacing={2} padding= '32px'  height={60}> 
      <Grid item xs={2}>
        <Stack direction="column"  spacing={2} >
      <Typography sx={mainThemeStyle.popUpLable} height={40} > Add RFID Card Number </Typography>
      
      </Stack>
      </Grid>
      
      <Grid item xs={3}>
      <Stack direction="row"  spacing={2}>
      <TextField
                       
                        height={40}
                        type= 'number'
                        name="text"
                        placeholder="Card Number"
                        value={rfidInput}
                        onChange={RFIDInputChange}
                        variant = "filled"
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("201")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
      <LoadingButton sx={mainThemeStyle.normalButton} onClick={handleAddRFID} loading={loading} disabled = {getDisableValue("201")}>Add</LoadingButton>
          
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={1.5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={200}> Add Vehicle </Typography>
      </Stack>
      </Grid>
      <Grid item xs={3.5}>
        <Stack direction="column"  spacing={2}>
          <Stack direction="row" spacing={2}>
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            top:'1px',
            bottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "lic-state"
            disabled = {getDisableValue("203")}
          label="Age"
          onChange={handleChangeLicenseState}
          variant="filled"
          value ={currentLicenseState}
          hiddenLabel
         
          MenuProps={MenuProps}>
            
            {LicState.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem}>
                      {menuItem}
                    </MenuItem>
                  );
                })}
          </Select>
          <TextField
                       
                   height={40}
                   type= 'text'
                   name="text"
                   placeholder="License Plate"
                   variant = "filled"
                   value={currentLicensePlate}
                   onChange={handleLicensePlateChange}
                   sx={mainThemeStyle.InputMenuItem}
                   InputLabelProps={{ shrink: true }
                 }
                 disabled = {getDisableValue("203")}
                   InputProps={{
                      
                       sx: {
                           
                           color:'rgba(135, 135, 135, 0.5)',
                           height:'33px',
                           borderRadius: '5px',
                           backgroundColor: '#121212',
                           boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                           padding: '0 0 15px 0',
                           textIndent: '15px !important',
                         
                           '&.Mui-disabled': {
             

                            background: 'rgba(135, 135, 135, 0.5)',
                          },
                          
                          "& input.Mui-disabled": {
                            color: "white"
                          }
                           
                       },
                   }}
                  
               />
         <LoadingButton sx={mainThemeStyle.normalButton} onClick={handleAddLicense} disabled = {getDisableValue("203")} loading={loading}>Add</LoadingButton>
        </Stack>
      </Stack>
      </Grid>
      
      </Grid>
      <Typography sx={mainThemeStyle.popUpLableRedSmall} height={40} padding= '32px'>{dataOfUser.rfid_format == 1 ? "Entering format: Generic (10 digit format,e.g. 0003849138)" :(dataOfUser.rfid_format == 2 ? "* Entering format: 34 bit Wiegand (10 digit format,e.g. 0003849138)" : "* Entering format: 26 bit Wiegand (8 digit format, e.g. 05847050)")}</Typography>
      <Grid container  padding= '32px'>
       
      <Grid item xs={5}>
      <TblContainerRFID>
                    <TblHeadRFID />
        <TableBody>
         {recordsAfterPagingAndSortingRFID().map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
            
              <TableCell  align="left" sx={mainThemeStyle.cellRow}> {row.generic}</TableCell>
              <TableCell  align="left" sx={mainThemeStyle.cellRow}> {row.weigand}</TableCell>
              <TableCell align="left"> <LoadingButton  sx={mainThemeStyle.viewLogButton} loading={loading} disabled = {getDisableValue("202")} onClick={() => {handleRFIDDelete(row.id,row.generic)}}>Delete</LoadingButton></TableCell>
            </TableRow>
          ))}
        </TableBody>
      
    </TblContainerRFID>
    
    </Grid>
    <Grid item xs={2}>
    
    </Grid>
    <Grid item xs={5}>
    <TblContainerLicense>
                    <TblHeadLicense />
        <TableBody>
         {recordsAfterPagingAndSortingLicense().map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              
              <TableCell  align="left" sx={mainThemeStyle.cellRow}> {row.lic_state}</TableCell>
              <TableCell   align="left" sx={mainThemeStyle.cellRow}> {row.lic_number}</TableCell>
              <TableCell align="left"><LoadingButton  sx={mainThemeStyle.viewLogButton} loading={loading} disabled = {getDisableValue("204")} onClick={() => {handleLicenseDelete(row.id,row.lic_number)}}>Delete</LoadingButton></TableCell>
            </TableRow>
          ))}
        </TableBody>
      
    </TblContainerLicense>
   
    </Grid>
      </Grid>
      
    
      <Grid container spacing={3}>
      <Grid item xs={5}>
      <BelowTblPaginationRFID></BelowTblPaginationRFID></Grid>
      <Grid item xs={1}>
      </Grid>
      <Grid item xs={6}>
      <BelowTblPaginationLicense></BelowTblPaginationLicense></Grid></Grid>
      </Box>
      
      <Box sx={{display: { xs: "block", md: "none" }}}>
           <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2} >
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Add RFID Card Number </Typography>
      
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="row"  spacing={2}>
      <TextField
                       
                        height={40}
                        type= 'number'
                        name="text"
                        placeholder="Card Number"
                        value={rfidInput}
                        onChange={RFIDInputChange}
                        variant = "filled"
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("201")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
      <LoadingButton sx={mainThemeStyle.normalButton} onClick={handleAddRFID} loading={loading} disabled = {getDisableValue("201")}>Add</LoadingButton>
          
      </Stack>
      </Grid>

      
     
      
      
      </Grid>
      <Typography sx={mainThemeStyle.popUpLableRedSmall} height={40} padding= '10px'>{dataOfUser.rfid_format == 1 ? "Entering format: Generic (10 digit format,e.g. 0003849138)" :(dataOfUser.rfid_format == 2 ? "* Entering format: 34 bit Wiegand (10 digit format,e.g. 0003849138)" : "* Entering format: 26 bit Wiegand (8 digit format, e.g. 05847050)") }</Typography>
      
      
      
      <TblContainerRFID>
                    <TblHeadRFID />
        <TableBody>
         {recordsAfterPagingAndSortingRFID().map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
            
              <TableCell  align="left" sx={mainThemeStyle.cellRow}> {row.generic}</TableCell>
              <TableCell  align="left" sx={mainThemeStyle.cellRow}> {row.weigand}</TableCell>
              <TableCell align="left"> <LoadingButton  sx={mainThemeStyle.viewLogButton} loading={loading} disabled = {getDisableValue("202")} onClick={() => {handleRFIDDelete(row.id,row.generic)}}>Delete</LoadingButton></TableCell>
            </TableRow>
          ))}
        </TableBody>
      
    </TblContainerRFID>
    
    <BelowTblPaginationRFID></BelowTblPaginationRFID>
    
    <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='100px' > 
    <Grid item xs={12} >
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable}> Add Vehicle </Typography>
      </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="column"  spacing={2}>
          <Stack direction="row" spacing={2}>
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            top:'1px',
            bottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled = {getDisableValue("203")}
            name = "lic-state"
          label="Age"
          onChange={handleChangeLicenseState}
          variant="filled"
          value ={currentLicenseState}
          hiddenLabel
         
          MenuProps={MenuProps}>
            
            {LicState.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem}>
                      {menuItem}
                    </MenuItem>
                  );
                })}
          </Select>
          <TextField
                       
                   height={40}
                   type= 'text'
                   name="text"
                   placeholder="License Plate"
                   variant = "filled"
                   value={currentLicensePlate}
                   onChange={handleLicensePlateChange}
                   sx={mainThemeStyle.InputMenuItem}
                   InputLabelProps={{ shrink: true }
                 }
                 disabled = {getDisableValue("203")}
                   InputProps={{
                      
                       sx: {
                           
                           color:'rgba(135, 135, 135, 0.5)',
                           height:'33px',
                           borderRadius: '5px',
                           backgroundColor: '#121212',
                           boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                           padding: '0 0 15px 0',
                           textIndent: '15px !important',
                           '&.Mui-disabled': {
             

                            background: 'rgba(135, 135, 135, 0.5)',
                          },
                          
                          "& input.Mui-disabled": {
                            color: "white"
                          }
                          
                           
                       },
                   }}
                  
               />
         <LoadingButton sx={mainThemeStyle.normalButton} onClick={handleAddLicense} disabled = {getDisableValue("203")} loading={loading}>Add</LoadingButton>
        </Stack>
      </Stack>
      </Grid>
      
     
      
      
      </Grid>
      
      <TblContainerLicense>
                    <TblHeadLicense />
        <TableBody>
         {recordsAfterPagingAndSortingLicense().map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              
              <TableCell  align="left" sx={mainThemeStyle.cellRow}> {row.lic_state}</TableCell>
              <TableCell   align="left" sx={mainThemeStyle.cellRow}> {row.lic_number}</TableCell>
              <TableCell align="left"><LoadingButton  sx={mainThemeStyle.viewLogButton} disabled = {getDisableValue("204")} loading={loading} onClick={() => {handleLicenseDelete(row.id,row.lic_number)}}>Delete</LoadingButton></TableCell>
            </TableRow>
          ))}
        </TableBody>
      
    </TblContainerLicense>
    <BelowTblPaginationLicense></BelowTblPaginationLicense>
      </Box>
</Box>)}

{(currentTab == 5 && <Box sx={mainThemeStyle.boxHeaderBelow}>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       User Call Log
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
     

          
      <TblContainerCallLog>
                    <TblHeadCallLog />
        <TableBody>
         {recordsAfterPagingAndSortingCallLog().map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
             
              <TableCell  align="left" sx={mainThemeStyle.cellRow}> {row.loc_name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.call_date}</TableCell>
              <TableCell  align="left" sx={mainThemeStyle.cellRow}> {row.group_call == 1 ? "Yes" : "No"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      
    </TblContainerCallLog>
    <BelowTblPaginationCallLog></BelowTblPaginationCallLog>
    
</Box>)}
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleClose} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActive} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
 </> 
          
          
          
          
          ;
};

export default EditUser;