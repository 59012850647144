import React from "react";
import {  ThemeProvider } from '@mui/material/styles';
import Theme from './components/ItemTheme'
import { Box } from "@mui/system";
import { Button, Grid, List, Typography } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import  NestedButtonDropDownHeader  from "./components/header/NestedButtonDropDownHeader";
import ManageLocation from "./ManageLocation";
import CallLog from "./CallLog";
import AddIcon from '@mui/icons-material/Add';
import LpTags from "./LpTags";
import { useState } from "react";
import ManageUsers from "./ManageUsers";
import { useParams,useNavigate,useLocation  } from "react-router-dom";
import { useEffect } from "react";
import EditUser from "./EditUser";
import AddUser from "./AddUser";
import CallingGroup from "./CallingGroup";
import OfficeGroup from "./OfficeGroup";
import ManageProperty from "./ManageProperty";
import AddNewProperty from "./AddNewProperty";
import AddNewPropertyDealer from "./AddNewPropertyDealer";
import EditProperty from "./EditProperty";
import EditPropertyDealer from "./EditPropertyDealer";
import PuffLoader from "react-spinners/PuffLoader";
import axios from "./axios";
import ManagePropertyDealer from "./ManagePropertyDealer";

const PropertyDealer = (props) => {
  console.log("IN location Page");
  const navigate = useNavigate();
  const {RefreshToken,switchToSubUser} = props;
  const {id} = useParams();
  console.log('id is '+id);
  const theme = Theme;
  const location = useLocation();
  //const [dataOfDealer,setDataOfDealer] = useState('');

 /* const getMyDealerData = async () => {
    try {
      const res = await axios.get("/administrator/dealer/",{
        headers: { 'Content-Type':  'application/json' },
      });
      //console.log("data of user admin is "+res.data['custom_field'][0]['id']);
      

      setDataOfDealer(res.data);
    } catch (error) {
      console.log(error.message);
    }
  };*/
  
  useEffect(()=>{
    /*if(id == 'editproperty'){
      setDataOfDealer('');
      getMyDealerData();
     }
     if(id == 'addproperty'){
      setDataOfDealer('');
      getMyDealerData();
     }*/
  },[id]);
  
  
 const handleAdd = () =>{
  navigate('../property/add', { replace: true });
 }
 const handleEdit = () =>{
  navigate('../property/manage', { replace: true });
}


  return (
<ThemeProvider theme={theme}>

    <Box sx={mainThemeStyle.mainbox} backgroundColor="menubarprimary.main">
    <Box sx={mainThemeStyle.box}>
    {(id === 'manage' &&  <Typography sx={mainThemeStyle.lable}>Accounts</Typography>)}
    {(id === 'add' &&  <Typography sx={mainThemeStyle.lable}>Add Account</Typography>)}
    {(id === 'edit' &&  <Typography sx={mainThemeStyle.lable}> Account: {location.state.editproperty.name}</Typography>)}
    </Box>
    
    {/* {(id === 'manage' &&
    <Button startIcon={<AddIcon />} sx={mainThemeStyle.activityButton} onClick={handleAdd} >Add Property</Button>)}
   {(id === 'add' && <Button sx={mainThemeStyle.activityButton} onClick={handleEdit} >View Property</Button>)}
   {(id === 'edit' &&  <Button sx={mainThemeStyle.activityButton} onClick={handleEdit} >View Property</Button>)}
     */}
    {(id === 'manage' && <ManagePropertyDealer RefreshToken={RefreshToken} switchToSubUser={switchToSubUser}/>)}
    {(id === 'add'  &&<AddNewPropertyDealer RefreshToken={RefreshToken}/>)}
   
    {(id === 'edit' &&<EditPropertyDealer idOfProperty={location.state.editproperty} RefreshToken={RefreshToken} />)}
   
    </Box>
   


   
  
</ThemeProvider>);
};

export default PropertyDealer;