import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox,Switch,Grid, InputLabel,Select,MenuItem, List,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText } from "@mui/material";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import MuiToggleButton from '@mui/material/ToggleButton';	
import LoadingButton from '@mui/lab/LoadingButton';
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import  NestedButtonDropDownHeader  from "./components/header/NestedButtonDropDownHeader";
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import Tooltip from '@mui/material/Tooltip';	
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import useInnerTable from "./components/useInnerTable";	
import ListItemIcon from '@mui/material/ListItemIcon';	
import ListItemText from '@mui/material/ListItemText';	
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useToken from "./useToken";
import QRCode from "qrcode";
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import Autocomplete from '@mui/material/Autocomplete';
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
//import moment from "moment";
import moment from "moment-timezone";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import { useLocation } from "react-router-dom";
import TimezoneSelect from 'react-timezone-select';
import Timezones from "./components/Timezones";
import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";
import GlobalAlert from "./GlobalAlert";
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
import axios from "./axios";
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { DisabledByDefault, Label, SettingsAccessibility } from "@mui/icons-material";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
const ToggleButton = styled(MuiToggleButton)(({ selectedColor, unSelectedColor }) => ({	
  width: 'auto',	
  height: '2.46rem',	
  color: 'white',	
  backgroundColor: unSelectedColor,	
  fontWeight: '700',	
          fontSize: '0.93rem',	
          textTransform: 'capitalize',	
            
          alignItems: 'center',	
          color: '#FFFFFF',	
          fontFamily: "Lato",	
          borderRadius:'0.31rem',	
  '&.Mui-selected, &.Mui-selected:hover': {	
    color: 'white',	
    backgroundColor: selectedColor,	
      
  },	
  '&:hover': {	
    color: 'white',	
    backgroundColor: unSelectedColor,	
      
  },	
}));

export default function EditLocationAdmin ( {idOflocation,RefreshToken,switchToSubUser,...props}){
  const location = useLocation();
  const {token} = useToken();
  const { getAlertTitle} = GlobalAlert();
  //console.log(moment.tz.countries());
  const TimeZoneList = moment.tz.zonesForCountry('US');
  let [dataOfLocation,setDataOfLocation] = useState(idOflocation);
  const myContainer = useRef(null);
  const [selectedTimezone, setSelectedTimezone] =useState(
    dataOfLocation.time_zone
  );
  const changeSelectedTimeZone = (event) => {
    dataOfLocation.time_zone = event.target.value;
    setSelectedTimezone(event.target.value);
    setLocationForData();
  }
  const [openSnack, setOpenSnack] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentBridge, setCurrentBridge] = useState( "");	
  const [currentCamera, setCurrentCamera] = useState("");	
  const [currentBridgeId, setCurrentBridgeId] = useState( "");	
  const [currentCameraId, setCurrentCameraId] = useState("");	
  const [currentBridgeType, setCurrentBridgeType] = useState("Dedicated");	
  const [currentCameraType, setCurrentCameraType] = useState("Entry");
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();
  const [qr,setqr] = useState('');
  const [imageUrl, setImageUrl] = useState("https://mdvaccess.com/"+dataOfLocation.loc_photo);


  const getDisableValue = (val) => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("10");
        console.log("value is "+ JSON.stringify(valOfDisableData));
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        console.log("value is "+ JSON.stringify(valOfDisableData[idData]));
        let valOfDisable = valOfDisableData[idData].administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("10");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
   
    
  }

  const getBridgeData = () => {		
    let bridgeArray = [];		
    		
    for(let i = 0; i < (dataOfLocation.bridge).length ; i++){		
      if((dataOfLocation.bridge)[i].location_id == dataOfLocation.loc_search_code){		
        bridgeArray.push((dataOfLocation.bridge)[i]);		
          		
      }		
    }		
    return bridgeArray;		
  }		
  const getBridgeAddArray = () => {		
    let bridgeArray = [];		
    		
    for(let i = 0; i < (dataOfLocation.bridge).length ; i++){		
      if((dataOfLocation.bridge)[i].location_id == "" || (dataOfLocation.bridge)[i].type == "1"){		
        bridgeArray.push((dataOfLocation.bridge)[i]);		
          		
      }		
    }		
    const strAscending = [...bridgeArray].sort((a, b) =>		
    a.name > b.name ? 1 : -1,		
  );		
    return strAscending;		
  }		
  const getCameraData = () => {		
    let cameraArray = [];		
   		
    for(let i = 0; i < (dataOfLocation.camera).length ; i++){		
      if((dataOfLocation.camera)[i].location_id == dataOfLocation.loc_search_code){		
        cameraArray.push((dataOfLocation.camera)[i]);		
          		
      }		
    }		
    return cameraArray;		
  }		
  const getCameraAddArray = () => {		
    let cameraArray = [];		
   console.log("camera add array"+JSON.stringify(dataOfLocation));		
    for(let i = 0; i < (dataOfLocation.camera).length ; i++){		
      if((dataOfLocation.camera)[i].location_id == "" ){		
        cameraArray.push((dataOfLocation.camera)[i]);		
          		
      }		
    }		
   /* cameraArray =  cameraArray.sort(function(a, b) {		
      if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;		
      if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;		
      return 0;		
     });*/		
     const strAscending = [...cameraArray].sort((a, b) =>		
    a.name > b.name ? 1 : -1,		
  );		
  console.log("sort array"+JSON.stringify(strAscending));		
    return strAscending;		
  }		
  const [recordsBridge, setRecordsBridge] = useState(getBridgeData());		
  const [recordsCamera, setRecordsCamera] = useState(getCameraData());		
  const [addBridge,setAddBridge] = useState(getBridgeAddArray());		
  const [addCamera,setAddCamera] = useState(getCameraAddArray());		
  const handleBridgeDelete = (val) =>{		
   		
    for(let i = 0; i < (dataOfLocation.bridge).length ; i++){		
      if((dataOfLocation.bridge)[i].id == val){		
        (dataOfLocation.bridge)[i].location_id = "";		
        (dataOfLocation.bridge)[i].type = "0";		
        console.log("dataOfLocation.type "+(dataOfLocation.bridge)[i].type);		
      }		
    }		
    setLocationForData();		
  }		
  const handleCameraDelete = (val,val2) =>{		
    for(let i = 0; i < (dataOfLocation.camera).length ; i++){		
      if((dataOfLocation.camera)[i].id == val){		
        (dataOfLocation.camera)[i].location_id = "";		
        (dataOfLocation.camera)[i].type = "0";		
        console.log("dataOfLocation.type "+(dataOfLocation.camera)[i].type);		
      }		
    }		
    setLocationForData();		
  }	
 	
  const getBrivoDoorArray = () => {		
    let brivoArray = [];		
    	
    let brivoarr = dataOfLocation.brivo_array;	
    brivoArray.push({ id: "None", name: "None", disabled: false });		
    brivoarr.forEach(element => {	
      let notinArray = false;	
      for (let j = 0 ; j < dataOfLocation.brivoDoor.length ; j++){	
        let accessarray = JSON.parse(dataOfLocation.brivoDoor[j]);	
        console.log('accessarray'+accessarray);	
        if(element.id != accessarray.brivoprimeid ){	
          for(let i = 0; i < 64 ; i++){	
            if(element.id == accessarray.program[i] ){	
              notinArray = true;	
              break;	
            }	
          }	
        	
        }else{	
          notinArray = true;	
        }	
      }	
      if(element.id != dataOfLocation.brivo_access.brivoprimeid ){	
        for(let i = 0; i < 64 ; i++){	
          if(element.id == dataOfLocation.brivo_access.program[i] ){	
            notinArray = true;	
            break;	
          }	
        }	
      	
      }else{	
        notinArray = true;	
      }	
      if(notinArray == false){	
       // brivoArray.push(element);		
        brivoArray.push({ id: element.id, name: element.name, disabled: false });	
      }	
      else{	
        brivoArray.push({ id: element.id, name: element.name, disabled: true });	
      }	
      	
    });	
  /*  const strAscending = [...brivoArray].sort((a, b) =>		
    a.name > b.name ? 1 : -1,		
  );	*/	
    return brivoArray;	
  }		
 


  const GenerateQRCode = () => {
   // let hexString = (dataOfLocation.loc_search_code).toString(16);
    let qr = '';
    let encoded = base64_encode(dataOfLocation.loc_search_code);
  //  let url = "https://mdvaccess.com/"+hexString;
    QRCode.toDataURL(
      "https://mdvaccess.com/virtual/"+encoded,
      {
        width : 800,
        margin : 2,
      },
      (err,url) => {
        if(err) return console.error(err);
        console.log(url);
        setqr(url);
       
      }
    )
    //return qr;
  }
  useEffect(() =>{
    GenerateQRCode();
  }, []);

  useEffect(() => {
    console.log("readr image calling");
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
      console.log("sle"+selectedImage.name);
      /*var reader = new FileReader();
      reader.readAsDataURL(URL.createObjectURL(selectedImage));
      console.log("readr image");
      reader.onload = () => {
        console.log("reader Image"+reader.result); //base64encoded string
      };
      reader.onerror = error => {
        console.log("Error: ", error);
      };*/
    }
  }, [selectedImage]);
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  
  console.log("data of table"+idOflocation); 
  const [loading, setLoading] = React.useState(false);
  let holdeopnermode = 0;
  if(dataOfLocation.hold_open.indefinite == "1"){
    holdeopnermode = 0;
  }
  else if(dataOfLocation.hold_open.definite == "1"){
    holdeopnermode = 1;
  }
  else if(dataOfLocation.hold_open.finite == "1"){
    holdeopnermode = 2;
  }
  const [enableHoldOpen, setEnableHoldOpen] =useState(parseInt(dataOfLocation.hold_open.holdopen));
  
  
  const [enableElevatorControl, setEnableElevatorControl] =useState(0);
  const [currentTab, setCurrentTab] = useState(0);
  const rowsHoleOpen =['1','2','3','4','5','6','7'];
  const rowsElevatorControl =[0,1,2,3,4,5,6,7];
  const handleTabClick =(val) =>{
    setCurrentTab(val);
  }
  const [showUpdateButton,setshowUpdateButton] = useState(false);
  const [holdopenvar, setholdopenvar] = useState({holdopen:"0",indefinite:"1",definite:"0",finite:"0",expiry:"",time_on:"12:00 AM",time_off:"12:00 AM",relay1:"0",relay2:"0",table:[{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"}]});
  const [elevatorvar, setelevatorvar] = useState({elevatorcontrol:"0",ipaddress:"",totalrelays:"",elduration:"",flduration:"",diselevator:"",disrelease:"",diselapp:"",program:[null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]});
  const [brivovar, setbrivovar] = useState({brivoprimeid:"",brivoprimename:"",brivofloors:"",program:[null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]});
  
 
  
  const handleChangeEventOfHoldOpen = (event) => {
    setEnableHoldOpen(event.target.checked);
    if(dataOfLocation.hold_open["holdopen"] === undefined){
    console.log("In not available");
    holdopenvar.holdopen = event.target.checked ? "1" : "0";
    setholdopenvar(holdopenvar);
    dataOfLocation.hold_open = holdopenvar;
    }else{
      console.log("In  available");
      console.log("In  available and event target"+ event.target.checked);
      dataOfLocation.hold_open.holdopen = event.target.checked ? "1" : "0";
    }
    //console.log(dataOfLocation);
    setLocationForData();
  };

  const handleIndefiniteRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open[ds] = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleOneTimeValue = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open[ds] = event.target.value ;
    setLocationForData();
  }
  const handleChangeOpenStart=(event)=>{
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].st = event.target.value;
    setLocationForData();
  }
  const handleChangEndStart=(event)=>{
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].en = event.target.value;
    setLocationForData();
  }
  const handleSundayRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].s = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleMondayRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].m = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleTuesdayRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].t = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleWednesdayRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].w = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleThursdayRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].th = event.target.checked ? "1" : "0" ;
      setLocationForData();
  }

  const handleChangeMDMType = (event, newAlignment) => {	
    dataOfLocation.mdm_type = newAlignment;
      setLocationForData();
  }
  const handleFridayRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].f = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleSaturdayRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].sa = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleHoldRelayOne = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].r1 = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleHoldRelayTwo = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].r2 = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleChangeValueOfHoldOpen = (event) => {
    let ds = event.target.name;
    if( ds == "indefinite"){
        dataOfLocation.hold_open.indefinite ="1" ;
        dataOfLocation.hold_open.definite = "0" ;
        dataOfLocation.hold_open.finite =  "0";
      }
    else if(ds == "definite"){
        dataOfLocation.hold_open.indefinite ="0" ;
        dataOfLocation.hold_open.definite = "1" ;
        dataOfLocation.hold_open.finite =  "0";
      }
    else if (ds == "finite"){
        dataOfLocation.hold_open.indefinite ="0" ;
        dataOfLocation.hold_open.definite = "0" ;
        dataOfLocation.hold_open.finite =  "1";
    }
    console.log(dataOfLocation);
    setLocationForData();
  };
  const handleChangeBrivoFloorValue = (event) => {	
    if( event.target.value > (dataOfLocation.brivo_array.length)){	
      event.target.value  =  dataOfLocation.brivo_array.length;	
    }	
    dataOfLocation.brivo_access.brivofloors = event.target.value;	
    setLocationForData();	
  }
  const handleChangeBrivoDoorValue = (event) => {
     /* if(dataOfLocation.brivo_access["brivoprimeid"] === undefined){	
      //console.log("in not defined");	
      let brivoarr = dataOfLocation.brivo_array;	
      brivovar.brivofloors = brivoarr.length;	
      brivoarr.forEach(element => {	
        if(element.name == event.target.value){	
          brivovar.brivoprimeid = element.id;	
          brivovar.brivoprimename = element.name;	
        }	
      });	
      setbrivovar(brivovar);	
      dataOfLocation.brivo_access = brivovar;	
      setLocationForData();	
    }else{*/	
      //console.log("in  defined");	
      let brivoarr = dataOfLocation.brivo_array;	
      brivovar.brivofloors = brivoarr.length;	
      //setbrivovar(brivovar);	
      if(event.target.value == "None"){	
        dataOfLocation.brivo_access.brivoprimeid = "";	
        dataOfLocation.brivo_access.brivoprimename = "";	
      }else{	
      brivoarr.forEach(element => {	
        if(element.name == event.target.value){	
          //dataOfLocation.brivo_access.brivofloors = brivoarr.length;	
          dataOfLocation.brivo_access.brivoprimeid = element.id;	
          dataOfLocation.brivo_access.brivoprimename = element.name;	
        }	
      });	
    }	
      setLocationForData();	
   // }
  }

  const handleChangePDKMainValue = (event) => {
   
    let pdkarr = dataOfLocation.pdk_device_array;
   
    if(event.target.value == "None"){
      dataOfLocation.pdk_access.primeid = "";
      dataOfLocation.pdk_access.primename = "";
    }else{
     pdkarr.forEach(element => {
      if(element.name == event.target.value){
       
        dataOfLocation.pdk_access.primeid = element.id;
        dataOfLocation.pdk_access.primename = element.name;
      }
    });
  }
    setLocationForData();
 // }
}

const handleChangePDKNodeValue = (event) => {

 let pdkarr = pdkNodeArray;

 if(event.target.value == "None"){
   dataOfLocation.pdk_node = "";
  
 }else{
  pdkarr.forEach(element => {
   if(element.name == event.target.value){
    
     dataOfLocation.pdk_node = element.name;
    
   }
 });
}
console.log("value of event is "+ event.target.value);
 setLocationForData();

}
const handleChangePDKGroupValue = (event,newValue) => {
   
  console.log("call inside");
  let pdkarr = pdkGroupArray;
 
  if(newValue == "None"){
    dataOfLocation.pdk_group = "";
   
  }else{
   pdkarr.forEach(element => {
    if(element.name == newValue){
     
      dataOfLocation.pdk_group = element.name;
     
    }
  });
}
  setLocationForData();

}
const handleChangeBrivoGroupValue = (event,newValue) => {
   
  console.log("call inside");
  let pdkarr = brivoGroupArray;
 
  if(newValue == "None"){
    dataOfLocation.brivo_group_name = "";
   
  }else{
   pdkarr.forEach(element => {
    if(element == newValue){
     
      dataOfLocation.brivo_group_name = element;
     
    }
  });
}
  setLocationForData();

}




const handleChangePDKOptionalValue = (event) => {

 let pdkarr = dataOfLocation.pdk_device_array;

 if(event.target.value == "None"){
   dataOfLocation.pdk_access.secondid = "";
   dataOfLocation.pdk_access.secondname = "";
 }else{
  pdkarr.forEach(element => {
   if(element.name == event.target.value){
    
     dataOfLocation.pdk_access.secondid = element.id;
     dataOfLocation.pdk_access.secondname = element.name;
   }
 });
}
 setLocationForData();
// }
}
  const handleBrivoInCallRelayTriggerCheck = (event) => {
    let ds = event.target.checked == true ? "1" : "0";
    let temparr = dataOfLocation.brivo_access;
    if(temparr.icrs == null){
      dataOfLocation.brivo_access = {brivoprimeid:temparr.brivoprimeid,brivoprimename:temparr.brivoprimename,brivofloors:temparr.brivofloors,icrt:ds,icrs:[{name:"",id:""},{name:"",id:""},{name:"",id:""}],program:temparr.program};
 
    }else{
      dataOfLocation.brivo_access = {brivoprimeid:temparr.brivoprimeid,brivoprimename:temparr.brivoprimename,brivofloors:temparr.brivofloors,icrt:ds,icrs:temparr.icrs,program:temparr.program};
 
    }
    setLocationForData();
    }
  const handleChangeCustomNameValue = (event) => {
    let ds = event.target.name;
    dataOfLocation.brivo_access.icrs[parseInt(ds)].name =  event.target.value;
    setLocationForData();
  
 }
 const handleChangeCustomDoorValue = (event) => {
     let brivoarr = dataOfLocation.brivo_array;
     let ds = event.target.name;
     if(event.target.value == "None"){
       dataOfLocation.brivo_access.icrs[parseInt(ds)].id = "";
       
     }else{
     brivoarr.forEach(element => {
       if(element.name == event.target.value){
         dataOfLocation.brivo_access.icrs[parseInt(ds)].id = element.id;
       }
     });
   }
     setLocationForData();
  // }
 }
 const getBrivoCustomArray = () => {	
  let brivoArray = [];	
  
  let brivoarr = dataOfLocation.brivo_array;

  brivoArray.push({ id: "None", name: "None", disabled: false });	
  brivoarr.forEach(element => {
    let notinArray = false;
    if(dataOfLocation.brivo_access.icrs != null){
        for(let i = 0; i < 3 ; i++){
          if(element.id == dataOfLocation.brivo_access.icrs[i].id ){
            notinArray = true;
            break;
          }
        }
      }
    
    if(element.id == dataOfLocation.brivo_access.brivoprimeid ){
     
      notinArray = true;
    }
    if(notinArray == false){
     // brivoArray.push(element);	
      brivoArray.push({ id: element.id, name: element.name, disabled: false });
    }
    else{
      brivoArray.push({ id: element.id, name: element.name, disabled: true });
    }
    
  });

/*  const strAscending = [...brivoArray].sort((a, b) =>	
  a.name > b.name ? 1 : -1,	
);	*/
  return brivoArray;
}	

const getPdkNodeArray = () => {	
  let pdkArray = [];	
  
  let pdkarr = dataOfLocation.pdk_cloudid_array;

  pdkArray.push({ id: "None", name: "None", disabled: false });	
  pdkarr.forEach(element => {
   
     pdkArray.push({ id: "None", name: element, disabled: false });
   
  });

  return pdkArray;
}	
const getBrivoGroupValue = () => {
  let nameOfunit;
 
  if(dataOfLocation.brivo_group_name == "" || dataOfLocation.brivo_group_name == null){
    nameOfunit = "None";
  }
  else{
   brivoGroupArray.forEach(element => {
    if(element == dataOfLocation.brivo_group_name){
      nameOfunit = element;
    }

  });
  }
  //console.log("main value"+nameOfunit );
  return nameOfunit;
}
const getPdkGroupArray = () => {	
  let pdkArray = [];	
  
  let pdkarr = dataOfLocation.pdk_group_array;

  pdkArray.push({ id: "None", name: "None", disabled: false });	
  pdkarr.forEach(element => {
   
     pdkArray.push({ id: "None", name: element, disabled: false });
   
  });

  return pdkArray;
}	
const getBrivoGroupArray = () => {	
  let briArr = [];	
  
  let pdkarr = dataOfLocation.brivo_group_array;

  briArr.push("None");	
  pdkarr.forEach(element => {
   
    briArr.push(element);
   
  });

  return briArr;
}	

const getPdkDeviceArray = () => {	
  let pdkArray = [];	
  
  let brivoarr = dataOfLocation.pdk_device_array;

  pdkArray.push({ id: "None", name: "None", disabled: false });	
  brivoarr.forEach(element => {
    let notinArray = false;
    
    
    if(element.id == dataOfLocation.pdk_access.primeid ){
     
      notinArray = true;
    }
    if(element.id == dataOfLocation.pdk_access.secondid ){
     
      notinArray = true;
    }

    if(notinArray == false){
     pdkArray.push({ id: element.id, name: element.name, disabled: false });
    }
    else{
      pdkArray.push({ id: element.id, name: element.name, disabled: true });
    }
    
  });

  return pdkArray;
}	
const [brivoCustomArray,setBrivoCustomArray] = useState(getBrivoCustomArray());	
const [brivoDoorArray,setBrivoDoorArray] = useState(getBrivoDoorArray());

const [pdkNodeArray,setPdkNodeArray] = useState(getPdkNodeArray());	

const [pdkGroupArray,setPdkGroupArray] = useState(getPdkGroupArray());	

const [brivoGroupArray,setBrivoGroupArray] = useState(getBrivoGroupArray());	

const [pdkDeviceArray,setPdkDeviceArray] = useState(getPdkDeviceArray());	
  const handleChangeBrivoRelay = (event) => {
    let ds = event.target.name;
    let brivoarr = dataOfLocation.brivo_array;
      brivoarr.forEach(element => {
        if(element.name == event.target.value){
          dataOfLocation.brivo_access.program[parseInt(ds)] = element.id;
        }
      });
    setLocationForData();
  }

  const getPDKNodeValue = () => {
    let nameOfunit;
   
    if(dataOfLocation.pdk_node == "" || dataOfLocation.pdk_node == null){
      nameOfunit = "None";
    }
    else{
      pdkNodeArray.forEach(element => {
      if(element.name == dataOfLocation.pdk_node){
        nameOfunit = element.name;
      }

    });
    }
    //console.log("main value"+nameOfunit );
    return nameOfunit;
  }

  const getPDKGroupValue = () => {
    let nameOfunit;
   
    if(dataOfLocation.pdk_group == "" || dataOfLocation.pdk_group == null){
      nameOfunit = "None";
    }
    else{
      pdkGroupArray.forEach(element => {
      if(element.name == dataOfLocation.pdk_group){
        nameOfunit = element.name;
      }

    });
    }
    //console.log("main value"+nameOfunit );
    return nameOfunit;
  }
  const DisplayedICon = {
    id: "1",
    label: 'Message Alert',
    items: [
      {
        label: 'Message Alert',
        callback: () =>  {}
      },
      {
        label: 'Bell Alert',
        callback: () =>  {}
      },
      {
        label: 'Info Alert',
        callback: () =>  {}
      },
      {
        label: 'Alert Lights',
        callback: () =>  {}
      },
      {
        label: 'Stop',
        callback: () =>  {}
      },
      {
        label: 'Announcement',
        callback: () =>  {}
      },
      {
        label: 'Hello',
        callback: () =>  {}
      },
      {
        label: 'Festive',
        callback: () =>  {}
      }
      ,
      {
        label: 'Maintenance',
        callback: () =>  {}
      }
    ]
  }

  const handleSetDisplayLogo = (event) => {
    
    if(event.target.value == 'Message Alert'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/1028/1028741.png';
      setLocationForData();
    }else  if(event.target.value == 'Bell Alert'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/2058/2058148.png';
      setLocationForData();
    }else  if(event.target.value == 'Info Alert'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/2538/2538026.png';
      setLocationForData();
    }else  if(event.target.value == 'Alert Lights'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/9069/9069950.png';
      setLocationForData();
    }
    else  if(event.target.value == 'Stop'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/2698/2698545.png';
      setLocationForData();
    }
    else  if(event.target.value == 'Announcement'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/8303/8303231.png';
      setLocationForData();
    }
    else  if(event.target.value == 'Hello'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/5129/5129597.png';
      setLocationForData();
    }
    else if(event.target.value == 'Festive'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/864/864763.png';
      setLocationForData();
    }
    else if(event.target.value == 'Maintenance'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/8956/8956124.png';
      setLocationForData();
    }
  }
  const getDisplayedLogo = () => {
    if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/1028/1028741.png'){
      return 'Message Alert';
    }
    else if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/2058/2058148.png'){
      return 'Bell Alert';
    }
    else if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/2538/2538026.png'){
      return 'Info Alert';
    }
    else if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/9069/9069950.png'){
      return 'Alert Lights';
    }
    else if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/2698/2698545.png'){
      return 'Stop';
    }
    else if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/8303/8303231.png'){
      return 'Announcement';
    }
    else if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/5129/5129597.png'){
      return 'Hello';
    }
    else if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/864/864763.png'){
      return 'Festive';
    }
    else if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/8956/8956124.png'){
      return 'Maintenance';
    }
    else{
      return 'Message Alert';
    }
  }
  const getBrivoValueMain = () => {
    let nameOfunit;	
    let brivoarr = dataOfLocation.brivo_array;	
    if(dataOfLocation.brivo_access.brivoprimeid == "" || dataOfLocation.brivo_access.brivoprimeid == null){	
      nameOfunit = "None";	
    }	
    else{	
    brivoarr.forEach(element => {	
      if(element.id == dataOfLocation.brivo_access.brivoprimeid){	
        nameOfunit = element.name;	
      }	
    });	
    }	
    console.log("main value"+nameOfunit );	
    return nameOfunit;
  }

  const getPDKValueMain = () => {
    let nameOfunit;
    let pdkarr = dataOfLocation.pdk_device_array;
    if(dataOfLocation.pdk_access.primeid == "" || dataOfLocation.pdk_access.primeid == null){
      nameOfunit = "None";
    }
    else{
      pdkarr.forEach(element => {
      if(element.id == dataOfLocation.pdk_access.primeid){
        nameOfunit = element.name;
      }

    });
    }
    //console.log("main value"+nameOfunit );
    return nameOfunit;
  }

  const getPDKValueOptional = () => {
    let nameOfunit;
    let pdkarr = dataOfLocation.pdk_device_array;
    if(dataOfLocation.pdk_access.secondid == "" || dataOfLocation.pdk_access.secondid == null){
      nameOfunit = "None";
    }
    else{
      pdkarr.forEach(element => {
      if(element.id == dataOfLocation.pdk_access.secondid){
        nameOfunit = element.name;
      }

    });
    }
    //console.log("main value"+nameOfunit );
    return nameOfunit;
  }
  const getCustomNameValue = (idOfunit) => {
    let nameOfunit;
   
   
    nameOfunit = dataOfLocation.brivo_access.icrs[idOfunit].name;
  
    return nameOfunit;

  }
  const getCustomDoorValue = (idOfunit) => {
    let nameOfunit;
    let brivoarr = dataOfLocation.brivo_array;
   // let ads = JSON.stringify(dataOfLocation.brivo_access);
   //console.log(dataOfLocation.brivo_access.program);
   if(dataOfLocation.brivo_access.icrs[idOfunit].id == "" || dataOfLocation.brivo_access.icrs[idOfunit].id == null){
    nameOfunit = "None";
   }else{
    brivoarr.forEach(element => {
      if(element.id == dataOfLocation.brivo_access.icrs[idOfunit].id){
        nameOfunit = element.name;
      }
    });
  }
    return nameOfunit;

  }
  const getBrivoValue = (idOfunit) => {	
    let nameOfunit;	
    let brivoarr = dataOfLocation.brivo_array;	
   // let ads = JSON.stringify(dataOfLocation.brivo_access);	
   //console.log(dataOfLocation.brivo_access.program);	
   if(dataOfLocation.brivo_access.program[idOfunit] == "" || dataOfLocation.brivo_access.program[idOfunit] == null){	
    nameOfunit = "None";	
   }else{	
    brivoarr.forEach(element => {	
      if(element.id == dataOfLocation.brivo_access.program[idOfunit]){	
        nameOfunit = element.name;	
      }	
    });	
  }	
    return nameOfunit;	
  }
  const handleChangeEleInnerRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.elevator_access.program[parseInt(ds)] = event.target.value ;
    setLocationForData();
  }
  const handleChangeEleValue = (event) => {
    let ds = event.target.name;
    dataOfLocation.elevator_access[ds] = event.target.value;
    setLocationForData();
  };
  const handleChangeEleCheckValue = (event) => {
    let ds = event.target.name;
    dataOfLocation.elevator_access[ds] = event.target.checked ? "1" : "0";
    setLocationForData();
  };
  const handleChangeEventOfElevatorControl = (event) => {
    setEnableElevatorControl(event.target.checked);
    if(dataOfLocation.elevator_access["elevatorcontrol"] === undefined){
    console.log("In not available");
    elevatorvar.elevatorcontrol = event.target.checked ? "1" : "0";
    setelevatorvar(elevatorvar);
    dataOfLocation.elevator_access = elevatorvar;
    }else{
      console.log("In  available");
      console.log("In  available and event target"+ event.target.checked);
      dataOfLocation.elevator_access.elevatorcontrol = event.target.checked ? "1" : "0";
    }
    //console.log(dataOfLocation);
    setLocationForData();
  };

  const ScreenLayout = {
    id: "1",
    label: "Portrait",
    items: [
      {
        label: "Portrait",
        callback: () =>  {}
      },
      {
        label: "Landscape",
        callback: () =>  {}
      },
    ]
  }
  
  const GetValueOfLocationScreenLayout = (val) => {
    if(val == "1"){
      return "Portrait";
    }else{
      return  "Landscape";
    }
  }
  const ChangeScreenLayout = (event) => {
    if(event.target.value == "Portrait") {
      dataOfLocation.loc_screen_layout = "1";
      setLocationForData();
    }else{
      dataOfLocation.loc_screen_layout = '0';
      setLocationForData();
    }
  }
  const UnlockingModeQR = {
    id: "1",
    label: "Web Relay",
    items: [
      {
        label: "Web Relay",
        callback: () =>  {}
      },
      
      {
        label: "BRIVO",
        callback: () =>  {}
      },{
        label: "PDK",
        callback: () =>  {}
      }]
  }
  const GetUnlockingModeQR = () =>{
    if(dataOfLocation.unlocking_mode == '1'){
      return  "BRIVO";
    }
    else  if(dataOfLocation.unlocking_mode == '4'){
      return  "PDK";
    }
    else{
      return  "Web Relay";
    }
  }
  const handleSetUnlockingModeQR = (event) => {
    if(event.target.value == 'BRIVO'){
      dataOfLocation.unlocking_mode = '1'
      setLocationForData();
    }else if(event.target.value == 'PDK'){
      dataOfLocation.unlocking_mode = '4'
      setLocationForData();
    }
    else{
      dataOfLocation.unlocking_mode = '0';
      dataOfLocation.brivo_access = {brivoprimeid:"",brivoprimename:"",brivofloors:"",icrt:0,icrs:[{name:"",id:""},{name:"",id:""},{name:"",id:""}],program:[null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]};
      setLocationForData();
    }
  }
  const UnlcokingMode = {
    id: "1",
    label: "BRIVO",
    items: [
      {
        label: "BRIVO",
        callback: () =>  {}
      },
      {
        label: "USB",
        callback: () =>  {}
      },
      {
        label: "GPIO",
        callback: () =>  {}
      },
      {
        label: "ResidentVue",
        callback: () =>  {}
      },
      {
        label: "PDK",
        callback: () =>  {}
      }
    ]
  }
  // if(dataOfLocation.unlocking_mode == '1'){
  //   UnlcokingMode.label =  "GPIO";
  // }else  if(dataOfLocation.unlocking_mode == '2'){
  //   UnlcokingMode.label =  "ResidentVue";
  // }else  if(dataOfLocation.unlocking_mode == '3'){
  //   UnlcokingMode.label =  "BRIVO";
  // }else  if(dataOfLocation.unlocking_mode == '4'){
  //   return  "PDK";
  // }
  // else{
  //   UnlcokingMode.label =  "USB";
  // }
  const GetUnlockingMode = () =>{
    if(dataOfLocation.unlocking_mode == '1'){
      return  "GPIO";
    }else  if(dataOfLocation.unlocking_mode == '2'){
      return  "ResidentVue";
    }else  if(dataOfLocation.unlocking_mode == '3'){
      return  "BRIVO";
    }else  if(dataOfLocation.unlocking_mode == '4'){
      return  "PDK";
    }
    else{
      return  "USB";
    }
  }
  const handleSetUnlockingMode = (event) => {
    if(event.target.value == 'GPIO'){
      dataOfLocation.unlocking_mode = '1';
      dataOfLocation.brivo_access = {brivoprimeid:"",brivoprimename:"",brivofloors:"",icrt:0,icrs:[{name:"",id:""},{name:"",id:""},{name:"",id:""}],program:[null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]};
      setLocationForData();
    }else  if(event.target.value == 'ResidentVue'){
      dataOfLocation.unlocking_mode = '2';
      dataOfLocation.brivo_access = {brivoprimeid:"",brivoprimename:"",brivofloors:"",icrt:0,icrs:[{name:"",id:""},{name:"",id:""},{name:"",id:""}],program:[null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]};
      setLocationForData();
    }else  if(event.target.value == 'BRIVO'){
      dataOfLocation.unlocking_mode = '3'
      setLocationForData();
    }else if(event.target.value == 'PDK'){
      dataOfLocation.unlocking_mode = '4'
      dataOfLocation.brivo_access = {brivoprimeid:"",brivoprimename:"",brivofloors:"",icrt:0,icrs:[{name:"",id:""},{name:"",id:""},{name:"",id:""}],program:[null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]};
      setLocationForData();
    }
    else{
      dataOfLocation.unlocking_mode = '0';
      dataOfLocation.brivo_access = {brivoprimeid:"",brivoprimename:"",brivofloors:"",icrt:0,icrs:[{name:"",id:""},{name:"",id:""},{name:"",id:""}],program:[null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]};
      setLocationForData();
    }
  }
  const AppUserUnlockMode = {
    id: "1",
    label: "ResidentVue Push Notification",
    items: [
      {
        label: "ResidentVue Push Notification",
        callback: () =>  {}
      },
      {
        label: "Unattended Unlock",
        callback: () =>  {}
      },
      {
        label: "Disable User Swipe Unlock",
        callback: () =>  {}
      }
    ]
  }
  const AppUserUnlockModeCBW = {
    id: "1",
    label: "Unattended Unlock",
    items: [
      {
        label: "Unattended Unlock",
        callback: () =>  {}
      },
      {
        label: "Disable User Swipe Unlock",
        callback: () =>  {}
      }
    ]
  }
  const GetAppUnlockModeCBW = () => {
    if(dataOfLocation.app_unlock_mode == '2'){
      return  "Disable User Swipe Unlock";
    }else{
      return  "Unattended Unlock";
    }
  }
  const GetAppUnlockMode = () => {
    if(dataOfLocation.app_unlock_mode == '1'){
      return  "Unattended Unlock";
    }else  if(dataOfLocation.app_unlock_mode == '2'){
      return  "Disable User Swipe Unlock";
    }else{
      return  "ResidentVue Push Notification";
    }
  }
  const handleSetAppUnlockMode = (event) => {
    console.log(dataOfLocation);
    console.log(selectedTimezone.value);
    if(event.target.value == 'Unattended Unlock'){
      dataOfLocation.app_unlock_mode = '1'
      setLocationForData();
    }else  if(event.target.value == 'Disable User Swipe Unlock'){
      dataOfLocation.app_unlock_mode = '2'
      setLocationForData();
    }else{
      dataOfLocation.app_unlock_mode = '0'
      setLocationForData();
    }
  }
  const handleSetAppUnlockModeCBW = (event) => {
    console.log(dataOfLocation);
    console.log(selectedTimezone.value);
    if(event.target.value == 'Unattended Unlock'){
      dataOfLocation.app_unlock_mode = '1'
      setLocationForData();
    }else  if(event.target.value == 'Disable User Swipe Unlock'){
      dataOfLocation.app_unlock_mode = '2'
      setLocationForData();
    }else{
      dataOfLocation.app_unlock_mode = '1'
      setLocationForData();
    }
  }
  const [uploadLocationData, setUploadLocationData] = useState('');
  const setLocationForData = () => {
    setshowUpdateButton(true);
    setDataOfLocation({
      id: dataOfLocation.id,
      issub : token.issub,
      subId : token.subid,
      loc_name: dataOfLocation.loc_name,
      loc_no: dataOfLocation.loc_no,
      loc_photo: dataOfLocation.loc_photo,
      loc_code: dataOfLocation.loc_code,
      loc_search_code: dataOfLocation.loc_search_code,
      cam_brand: dataOfLocation.cam_brand,
      cam_ip: dataOfLocation.cam_ip,
      cam_rtsp: dataOfLocation.cam_rtsp,
      cam_username: dataOfLocation.cam_username,
      cam_password: dataOfLocation.cam_password,
      userid: dataOfLocation.userid,
      licence_key: dataOfLocation.licence_key,
      expirydate:dataOfLocation.expirydate,
      added_by:dataOfLocation.added_by,
      status:dataOfLocation.status,
      date_purchased: dataOfLocation.date_purchased,
      subscription: dataOfLocation.subscription,
      sub_id: dataOfLocation.sub_id,
      pin_length: dataOfLocation.pin_length,
      delivery_pin: dataOfLocation.delivery_pin,
      warning_phone: dataOfLocation.warning_phone,
      loc_property_name: dataOfLocation.loc_property_name,
      unlocking_mode: dataOfLocation.unlocking_mode,
      vegax_duration: dataOfLocation.vegax_duration,
      vegax_second_duration: dataOfLocation.vegax_second_duration,
      relay_one_fname: dataOfLocation.relay_one_fname,
      relay_two_fname: dataOfLocation.relay_two_fname,
      tablet_name: dataOfLocation.tablet_name,
      loc_office_name: dataOfLocation.loc_office_name,
      loc_add_one: dataOfLocation.loc_add_one,
      loc_add_two: dataOfLocation.loc_add_two,
      loc_telephone_dial: dataOfLocation.loc_telephone_dial,
      wel_message: dataOfLocation.wel_message,
      wel_message_header: dataOfLocation.wel_message_header,
      wel_message_headerlogo: dataOfLocation.wel_message_headerlogo,
      wel_message_headercolor : dataOfLocation.wel_message_headercolor,
      loc_url_watermark: dataOfLocation.loc_url_watermark,
      loc_refresh_rate: dataOfLocation.loc_refresh_rate,
      loc_screen_layout: dataOfLocation.loc_screen_layout,
      setting_reftesh_rate: dataOfLocation.setting_reftesh_rate,
      web_status: dataOfLocation.web_status,
      loc_zip_code: dataOfLocation.loc_zip_code,
      r_pin_off: dataOfLocation.r_pin_off,
      g_pin_off: dataOfLocation.g_pin_off,
      lkey_reset: dataOfLocation.lkey_reset,
      pin_blaacklist: dataOfLocation.pin_blaacklist,
      time_zone: dataOfLocation.time_zone,
      pin_start_time: dataOfLocation.pin_start_time,
      pin_end_time:dataOfLocation.pin_end_time,
      card_start_time: dataOfLocation.card_start_time,
      card_end_time:dataOfLocation.card_end_time,
      mdm_type:dataOfLocation.mdm_type,
      device_id: dataOfLocation.device_id,
      rebootday: dataOfLocation.rebootday,
      reboottime: dataOfLocation.reboottime,
      rfid_enable: dataOfLocation.rfid_enable,
      app_unlock_mode: dataOfLocation.app_unlock_mode,
      lpr_enable:dataOfLocation.lpr_enable,
      lpr_cam1: dataOfLocation.lpr_cam1,
      lpr_cam2: dataOfLocation.lpr_cam2,
      hold_open: dataOfLocation.hold_open,
      elevator_access: dataOfLocation.elevator_access,
      brivo_access: dataOfLocation.brivo_access,
      dis_off: dataOfLocation.dis_off,
      dis_dir: dataOfLocation.dis_dir,
      dis_doorpin: dataOfLocation.dis_doorpin,
      dis_del: dataOfLocation.dis_del,
      enable_exe_weigand: dataOfLocation.enable_exe_weigand,
      default_vega_state: dataOfLocation.default_vega_state,
      default_vega_state_2: dataOfLocation.default_vega_state_2,
      pdk_enable: dataOfLocation.pdk_enable,
      brivo_enable: dataOfLocation.brivo_enable,
      lpr_accesss: dataOfLocation.lpr_accesss,
      pdk_node: dataOfLocation.pdk_node,
      pdk_group: dataOfLocation.pdk_group,
      brivo_group_name: dataOfLocation.brivo_group_name,
      elevator_accesss_control: dataOfLocation.elevator_accesss_control,
      brivo_array:dataOfLocation.brivo_array,
      brivo_group_array:dataOfLocation.brivo_group_array,
      brivo_group_array:dataOfLocation.brivo_group_array,
      pdk_access:dataOfLocation.pdk_access,
      pdk_cloudid_array:dataOfLocation.pdk_cloudid_array,
      pdk_group_array:dataOfLocation.pdk_group_array,
      pdk_device_array:dataOfLocation.pdk_device_array,
      image_upload_data:dataOfLocation.image_upload_data,
      property_owner:dataOfLocation.property_owner,
      dealer:dataOfLocation.dealer,
      dis_prox:dataOfLocation.dis_prox,
      latitude:dataOfLocation.latitude,
      longitude:dataOfLocation.longitude,
      bridge:dataOfLocation.bridge,		
      camera:dataOfLocation.camera,	
      brivoDoor:dataOfLocation.brivoDoor,
      realy_hostname:dataOfLocation.realy_hostname,
      last_abre:dataOfLocation.last_abre,
      relay_fail_over:dataOfLocation.relay_fail_over,
      issub:token.issub,
      subId:token.subid
      });
      setUploadLocationData({
        id: dataOfLocation.id,
        issub : token.issub,
        subId : token.subid,
        loc_name: dataOfLocation.loc_name,
        loc_no: dataOfLocation.loc_no,
        loc_photo: dataOfLocation.loc_photo,
        loc_code: dataOfLocation.loc_code,
        loc_search_code: dataOfLocation.loc_search_code,
        cam_brand: dataOfLocation.cam_brand,
        cam_ip: dataOfLocation.cam_ip,
        cam_rtsp: dataOfLocation.cam_rtsp,
        cam_username: dataOfLocation.cam_username,
        cam_password: dataOfLocation.cam_password,
        userid: dataOfLocation.userid,
        licence_key: dataOfLocation.licence_key,
        expirydate:dataOfLocation.expirydate,
        added_by:dataOfLocation.added_by,
        status:dataOfLocation.status,
        date_purchased: dataOfLocation.date_purchased,
        subscription: dataOfLocation.subscription,
        sub_id: dataOfLocation.sub_id,
        pin_length: dataOfLocation.pin_length,
        delivery_pin: dataOfLocation.delivery_pin,
        warning_phone: dataOfLocation.warning_phone,
        loc_property_name: dataOfLocation.loc_property_name,
        unlocking_mode: dataOfLocation.unlocking_mode,
        vegax_duration: dataOfLocation.vegax_duration,
        vegax_second_duration: dataOfLocation.vegax_second_duration,
        relay_one_fname: dataOfLocation.relay_one_fname,
        relay_two_fname: dataOfLocation.relay_two_fname,
        tablet_name: dataOfLocation.tablet_name,
        loc_office_name: dataOfLocation.loc_office_name,
        loc_add_one: dataOfLocation.loc_add_one,
        loc_add_two: dataOfLocation.loc_add_two,
        loc_telephone_dial: dataOfLocation.loc_telephone_dial,
        wel_message: dataOfLocation.wel_message,
        wel_message_header: dataOfLocation.wel_message_header,
        wel_message_headerlogo: dataOfLocation.wel_message_headerlogo,
        wel_message_headercolor : dataOfLocation.wel_message_headercolor,
        loc_url_watermark: dataOfLocation.loc_url_watermark,
        loc_refresh_rate: dataOfLocation.loc_refresh_rate,
        loc_screen_layout: dataOfLocation.loc_screen_layout,
        setting_reftesh_rate: dataOfLocation.setting_reftesh_rate,
        web_status: dataOfLocation.web_status,
        loc_zip_code: dataOfLocation.loc_zip_code,
        r_pin_off: dataOfLocation.r_pin_off,
        g_pin_off: dataOfLocation.g_pin_off,
        lkey_reset: dataOfLocation.lkey_reset,
        pin_blaacklist: dataOfLocation.pin_blaacklist,
        time_zone: dataOfLocation.time_zone,
        pin_start_time: dataOfLocation.pin_start_time,
        pin_end_time:dataOfLocation.pin_end_time,
        card_start_time: dataOfLocation.card_start_time,
        card_end_time:dataOfLocation.card_end_time,
        mdm_type:dataOfLocation.mdm_type,
        device_id: dataOfLocation.device_id,
        rebootday: dataOfLocation.rebootday,
        reboottime: dataOfLocation.reboottime,
        rfid_enable: dataOfLocation.rfid_enable,
        app_unlock_mode: dataOfLocation.app_unlock_mode,
        lpr_enable:dataOfLocation.lpr_enable,
        lpr_cam1: dataOfLocation.lpr_cam1,
        lpr_cam2: dataOfLocation.lpr_cam2,
        hold_open: JSON.stringify(dataOfLocation.hold_open),
        elevator_access: JSON.stringify(dataOfLocation.elevator_access),
        brivo_access: JSON.stringify(dataOfLocation.brivo_access),
        pdk_access: JSON.stringify(dataOfLocation.pdk_access),
        dis_off: dataOfLocation.dis_off,
        dis_dir: dataOfLocation.dis_dir,
        dis_doorpin: dataOfLocation.dis_doorpin,
        dis_del: dataOfLocation.dis_del,
        enable_exe_weigand: dataOfLocation.enable_exe_weigand,
        default_vega_state: dataOfLocation.default_vega_state,
        default_vega_state_2: dataOfLocation.default_vega_state_2,
        pdk_enable: dataOfLocation.pdk_enable,
        brivo_enable: dataOfLocation.brivo_enable,
        lpr_accesss: dataOfLocation.lpr_accesss,
        pdk_node: dataOfLocation.pdk_node,
        pdk_group: dataOfLocation.pdk_group,
        brivo_group_name: dataOfLocation.brivo_group_name,
        elevator_accesss_control: dataOfLocation.elevator_accesss_control,
        image_upload_data:dataOfLocation.image_upload_data,
        property_owner:dataOfLocation.property_owner,
        dealer:dataOfLocation.dealer,
        dis_prox:dataOfLocation.dis_prox,
        latitude:dataOfLocation.latitude,
        longitude:dataOfLocation.longitude,
        bridge:dataOfLocation.bridge,		
        camera:dataOfLocation.camera,	
        brivoDoor:dataOfLocation.brivoDoor,
        realy_hostname:dataOfLocation.realy_hostname,
        last_abre:dataOfLocation.last_abre,
        relay_fail_over:dataOfLocation.relay_fail_over,
        issub:token.issub,
        subId:token.subid
        });
        setRecordsBridge(getBridgeData());		
        setRecordsCamera(getCameraData());		
        setAddBridge(getBridgeAddArray());		
        setAddCamera(getCameraAddArray());		
        setBrivoDoorArray(getBrivoDoorArray());	
        setBrivoCustomArray(getBrivoCustomArray());
        setPdkNodeArray(getPdkNodeArray());
        setPdkGroupArray(getPdkGroupArray());
        setPdkDeviceArray(getPdkDeviceArray());
        setCurrentBridge("");		
        setCurrentCamera("");
  }
  const CameraBrand = {
    id: "1",
    label: "ResidentLink",
    items: [
      {
        label: "AXIS",
        callback: () =>  {
          dataOfLocation.cam_brand = "AXIS";
          setLocationForData();
        }
      },
      {
        label: "ACTi",
        callback: () =>  {
          dataOfLocation.cam_brand = "ACTi";
          setLocationForData();
        }
      },
      {
        label: "Hikvision",
        callback: () =>  {
          dataOfLocation.cam_brand = "Hikvision";
          setLocationForData();
        }
      },
      {
        label: "Nortek",
        callback: () =>  {
          dataOfLocation.cam_brand = "Nortek";
          setLocationForData();
        }
      },
      {
        label: "Sunell",
        callback: () =>  {
          dataOfLocation.cam_brand = "Sunell";
          setLocationForData();
        }
      },
      {
        label: "ResidentVue",
        callback: () =>  {
          dataOfLocation.cam_brand = "ResidentLink";
          setLocationForData();
        }
      },
      {
        label:"QR Entry",
        callback: () =>  {
          dataOfLocation.cam_brand = "Virtual Panel";
          setLocationForData();
        }
      }
    ]
  }
  
  const GetValueForCameraBrand = () => {
    if(dataOfLocation.cam_brand == "ResidentLink"){
      return "ResidentVue";
    }
    else if(dataOfLocation.cam_brand == "Virtual Panel"){
      return "QR Entry";
    }
    else if(dataOfLocation.cam_brand == "Sunell"){
      return "Sunell";
    }
    else if(dataOfLocation.cam_brand == "AXIS"){
      return "AXIS";
    }
    else if(dataOfLocation.cam_brand == "Nortek"){
      return "Nortek";
    }
    else if(dataOfLocation.cam_brand == "Hikvision"){
      return "Hikvision";
    }else{
      return dataOfLocation.cam_brand;
    }
  }
  const handleChangeCameraBrand = (event) => {
    if(event.target.value == "ResidentVue") {
      dataOfLocation.cam_brand = "ResidentLink";
      //dataOfLocation.loc_screen_layout = "1";
      setLocationForData();
    }else{
      dataOfLocation.cam_brand = event.target.value;
      setLocationForData();
    }
  }
  const handleInputChange=(event) =>{
    console.log(dataOfLocation);
   // setshowUpdateButton(true);
    let ds = event.target.name;
    dataOfLocation[ds] = event.target.value;
    setLocationForData();
  }
  const handlecheckboxCheck=(event) =>{
    let ds = event.target.name;
    dataOfLocation[ds] = event.target.checked == true ? "1" : "0";
   // setshowUpdateButton(true);
   setLocationForData();
  }
  const handlecheckboxCheckReverse=(event) =>{
    let ds = event.target.name;
    dataOfLocation[ds] = event.target.checked == true ? "0" : "1";
   // setshowUpdateButton(true);
   setLocationForData();
  }
  const handleScanDevice = (event) =>{
    setLoading(true);
    performHexOperation('scan');
  }

  const handlePingDevice = (event) => {
    setLoading(true);
    performEsperOperation('ping');
  }

  const handleRebootDevice = (event) => {
    setLoading(true);
    performEsperOperation('reboot');
  }
  const handleLockDevice = (event) => {
    setLoading(true);
    performHexOperation('lock');
  }
  const handleRestartDevice = (event) => {
    setLoading(true);
    performHexOperation('restart');
  }

  const PinLengthHolder = {
    id: "1",
    label: "4",
    items: [
      {
        label: "4",
        callback: () =>  {}
      },
      {
        label: "6",
        callback: () =>  {}
      }
    ]
  }
  const performEsperOperation = async (val) => {
    console.log('id is '+ idOflocation.id);
    
    try {
      const res = await axios.patch("/admin/esper/"+val+"/"+idOflocation.id,uploadLocationData, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      console.log("response"+ JSON.stringify( res.data));
      setOpenSnackData("Device Operation Successfully Completed.");
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setOpenSnackData("Device Operation Failed, Please Try Again Later");
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  }
  const performHexOperation = async (val) => {
    try {
      const res = await axios.patch("/admin/hex/"+val+"/"+idOflocation.id,uploadLocationData, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      console.log( "response"+JSON.stringify(res.data));
      setOpenSnackData("Device Operation Successfully Completed.");
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
    } catch (error) {
      console.log("response"+JSON.stringify( error.response.data));
      setLoading(false);
      setOpenSnackData("Device Operation Failed, Please Try Again Later");
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  }
  const updateMyPostData = async () => {
    console.log('upload location data'+uploadLocationData);
    try {
      const res = await axios.patch("/admin/location/"+uploadLocationData.userid+"/"+uploadLocationData.id,uploadLocationData, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('ELLUS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setOpenSnackData(getAlertTitle('ELLUF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const [currentId, setCurrentId] = useState(0);
  const [titleforalert, setTitleforalert] = React.useState("");
  const ChangeId = (val,val2) =>{
    
    if(val2 == 0){
      setCurrentId(0);
      
     
      setTitleforalert("Do you want to manage the Accout Owner account for "+ dataOfLocation.property_owner.name + "?");
      setOpen(true);
    }
    else if(val2 == 1){
      setCurrentId(1);
     
      
      setTitleforalert("Do you want to manage the Dealer account for "+ dataOfLocation.dealer.company_name + "?");
      setOpen(true);
    }
   
  }
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseActive =() =>{
    setOpen(false);
    if(currentId == 0){
      switchToSubUser({email:token.email,
        account_name:dataOfLocation.property_owner.name,username:'SUPER ADMIN',user_id:dataOfLocation.property_owner.id,role:'admin'});
    }else{
      switchToSubUser({email:token.email,
        account_name:dataOfLocation.dealer.company_name,username:'SUPER ADMIN',user_id:dataOfLocation.dealer.del_id,role:'dealer'});
    }
    
 
  }
  const handleUpdateLocation = () => {
    console.log(uploadLocationData);
    setLoading(true);
    if(dataOfLocation.loc_name == ""){
      setOpenSnackData(getAlertTitle('ELLNNE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel"){
    if(dataOfLocation.loc_code == ""){
      setOpenSnackData(getAlertTitle('ELLDNE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    
    if(dataOfLocation.cam_ip == ""){
      setOpenSnackData(getAlertTitle('ELLCINE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    
  }
  else {
    //console.log((dataOfLocation.delivery_pin).length + " is " );
    let delivery_pin = dataOfLocation.delivery_pin;
  delivery_pin = delivery_pin.replaceAll(" ","");
  if(delivery_pin == ""){
    console.log("delivery pin can't be null");
    setOpenSnackData(getAlertTitle('ALLDPNE'));
    setSeverityData("error");
    setOpenSnack(true);
    setLoading(false);
    return;
  }
  if((dataOfLocation.delivery_pin).length != parseInt(dataOfLocation.pin_length)){	
    console.log('dataOfLocation.delivery_pin');	
    if(parseInt(dataOfLocation.pin_length) == 6){	
      setOpenSnackData(getAlertTitle('ELLDP6NV'));	
      setSeverityData("error");	
      setOpenSnack(true);	
      setLoading(false);	
      return;	
    }else{	
      setOpenSnackData(getAlertTitle('ELLDP4NV'));	
      setSeverityData("error");	
      setOpenSnack(true);	
      setLoading(false);	
      return;	
    }	
      
  }	
  if(dataOfLocation.lpr_enable == "1"){		
    if(recordsCamera.length > 0 && recordsBridge.length <= 0){		
      setOpenSnackData('Bridge Is Required To Add Camera, Please Add Bridge And Try Again.');		
      setSeverityData("error");		
      setOpenSnack(true);		
      setLoading(false);		
      return;		
    }		
  }
    if(dataOfLocation.cam_brand == "Virtual Panel" && dataOfLocation.unlocking_mode != '1' && dataOfLocation.unlocking_mode != '4'){
      let urls = dataOfLocation.realy_hostname;
      if(!urls.match("api.controlbyweb.cloud/DAT/")){
        setOpenSnackData('Relay Host Url Should Be Like This api.controlbyweb.cloud/DAT/api-key, Other Url Is Not Valid.');
        setSeverityData("error");
        setOpenSnack(true);
        setLoading(false);
        return;
      }
    }
  }
    updateMyPostData();
  }
  const MenuProps = {
    PaperProps: {
      style: {
        backgroundColor: "#1E1E1E",
          borderRadius: 0,
          maxHeight:'250px',
          borderRadius: 10,
          padding:0
      },
    },
  };
  const CamType = {		
    id: "1",		
    label: "Entry",		
    items: [		
      {		
        label: "Entry",		
        callback: () =>  {}		
      },		
      {		
        label: "Exit",		
        callback: () =>  {}		
      },		
    ]		
  }		
  const BriType = {		
    id: "1",		
    label: "Dedicated",		
    items: [		
      {		
        label: "Dedicated",		
        callback: () =>  {}		
      },		
      {		
        label: "Shared",		
        callback: () =>  {}		
      },		
    ]		
  }
  const BootDay = {
    id: "1",
    label: "Monday",
    items: [
      {
        label: "None",
        callback: () =>  {}
      },
      {
        label: "Monday",
        callback: () =>  {}
      },
      {
        label: "Tuesday",
        callback: () =>  {}
      },
      {
        label: "Wednesday",
        callback: () =>  {}
      },
      {
        label: "Thursday",
        callback: () =>  {}
      },
      {
        label: "Friday",
        callback: () =>  {}
      },
      {
        label: "Saturday",
        callback: () =>  {}
      },
      {
        label: "Sunday",
        callback: () =>  {}
      }
    ]
  }
  BootDay.label = dataOfLocation.rebootday;

  const BootTime = {
    id: "1",
    label: "00:00",
    items: [
      {
        label: "None",
        callback: () =>  {}
      },
      {
        label: "12:00 AM",
        callback: () =>  {}
      },
      {
        label: "12:30 AM",
        callback: () =>  {}
      },
      {
        label:  "01:00 AM",
        callback: () =>  {}
      },
      {
        label:  "01:30 AM",
        callback: () =>  {}
      },
      {
        label: "02:00 AM",
        callback: () =>  {}
      },
      {
        label: "02:30 AM",
        callback: () =>  {}
      },
      {
        label: "03:00 AM",
        callback: () =>  {}
      },
      {
        label: "03:30 AM",
        callback: () =>  {}
      },
      {
        label: "04:00 AM",
        callback: () =>  {}
      },
      {
        label: "04:30 AM",
        callback: () =>  {}
      },
      {
        label: "05:00 AM",
        callback: () =>  {}
      },
      {
        label: "05:30 AM",
        callback: () =>  {}
      },
      {
        label:  "06:00 AM",
        callback: () =>  {}
      },
      {
        label: "06:30 AM",
        callback: () =>  {}
      },
      {
        label: "07:00 AM",
        callback: () =>  {}
      },
      {
        label: "07:30 AM",
        callback: () =>  {}
      },
      {
        label: "08:00 AM",
        callback: () =>  {}
      },
      {
        label: "08:30 AM",
        callback: () =>  {}
      },
      {
        label: "09:00 AM",
        callback: () =>  {}
      },
      {
        label: "09:30 AM",
        callback: () =>  {}
      },
      {
        label: "10:00 AM",
        callback: () =>  {}
      },
      {
        label: "10:30 AM",
        callback: () =>  {}
      },
      {
        label: "11:00 AM",
        callback: () =>  {}
      },
      {
        label: "11:30 AM",
        callback: () =>  {}
      },
      {
        label: "12:00 PM",
        callback: () =>  {}
      },
      {
        label: "12:30 PM",
        callback: () =>  {}
      },
      {
        label: "01:00 PM",
        callback: () =>  {}
      },
      {
        label: "01:30 PM",
        callback: () =>  {}
      },
      {
        label: "02:00 PM",
        callback: () =>  {}
      },
      {
        label: "02:30 PM",
        callback: () =>  {}
      },
      {
        label: "03:00 PM",
        callback: () =>  {}
      },
      {
        label: "03:30 PM",
        callback: () =>  {}
      },
      {
        label: "04:00 PM",
        callback: () =>  {}
      },
      {
        label: "04:30 PM",
        callback: () =>  {}
      },
      {
        label: "05:00 PM",
        callback: () =>  {}
      },
      {
        label: "05:30 PM",
        callback: () =>  {}
      },
      {
        label: "06:00 PM",
        callback: () =>  {}
      },
      {
        label: "06:30 PM",
        callback: () =>  {}
      },
      {
        label: "07:00 PM",
        callback: () =>  {}
      },
      {
        label: "07:30 PM",
        callback: () =>  {}
      },
      {
        label: "08:00 PM",
        callback: () =>  {}
      },
      {
        label: "08:30 PM",
        callback: () =>  {}
      },
      {
        label: "09:00 PM",
        callback: () =>  {}
      },
      {
        label: "09:30 PM",
        callback: () =>  {}
      },
      {
        label: "10:00 PM",
        callback: () =>  {}
      },
      {
        label: "10:30 PM",
        callback: () =>  {}
      },
      {
        label: "11:00 PM",
        callback: () =>  {}
      },
      {
        label: "11:30 PM",
        callback: () =>  {}
      },
      {
        label: "11:59 PM",
        callback: () =>  {}
      }
    ]
  }
  BootTime.label = dataOfLocation.reboottime;
 
  const PinStartBootTime = {
    id: "1",
    label: "00:00",
    items: [
      {
        label: "None",
        callback: () =>  {}
      },
      {
        label: "12:00 AM",
        callback: () =>  {}
      },
      {
        label: "12:30 AM",
        callback: () =>  {}
      },
      {
        label:  "01:00 AM",
        callback: () =>  {}
      },
      {
        label:  "01:30 AM",
        callback: () =>  {}
      },
      {
        label: "02:00 AM",
        callback: () =>  {}
      },
      {
        label: "02:30 AM",
        callback: () =>  {}
      },
      {
        label: "03:00 AM",
        callback: () =>  {}
      },
      {
        label: "03:30 AM",
        callback: () =>  {}
      },
      {
        label: "04:00 AM",
        callback: () =>  {}
      },
      {
        label: "04:30 AM",
        callback: () =>  {}
      },
      {
        label: "05:00 AM",
        callback: () =>  {}
      },
      {
        label: "05:30 AM",
        callback: () =>  {}
      },
      {
        label:  "06:00 AM",
        callback: () =>  {}
      },
      {
        label: "06:30 AM",
        callback: () =>  {}
      },
      {
        label: "07:00 AM",
        callback: () =>  {}
      },
      {
        label: "07:30 AM",
        callback: () =>  {}
      },
      {
        label: "08:00 AM",
        callback: () =>  {}
      },
      {
        label: "08:30 AM",
        callback: () =>  {}
      },
      {
        label: "09:00 AM",
        callback: () =>  {}
      },
      {
        label: "09:30 AM",
        callback: () =>  {}
      },
      {
        label: "10:00 AM",
        callback: () =>  {}
      },
      {
        label: "10:30 AM",
        callback: () =>  {}
      },
      {
        label: "11:00 AM",
        callback: () =>  {}
      },
      {
        label: "11:30 AM",
        callback: () =>  {}
      },
      {
        label: "12:00 PM",
        callback: () =>  {}
      },
      {
        label: "12:30 PM",
        callback: () =>  {}
      },
      {
        label: "01:00 PM",
        callback: () =>  {}
      },
      {
        label: "01:30 PM",
        callback: () =>  {}
      },
      {
        label: "02:00 PM",
        callback: () =>  {}
      },
      {
        label: "02:30 PM",
        callback: () =>  {}
      },
      {
        label: "03:00 PM",
        callback: () =>  {}
      },
      {
        label: "03:30 PM",
        callback: () =>  {}
      },
      {
        label: "04:00 PM",
        callback: () =>  {}
      },
      {
        label: "04:30 PM",
        callback: () =>  {}
      },
      {
        label: "05:00 PM",
        callback: () =>  {}
      },
      {
        label: "05:30 PM",
        callback: () =>  {}
      },
      {
        label: "06:00 PM",
        callback: () =>  {}
      },
      {
        label: "06:30 PM",
        callback: () =>  {}
      },
      {
        label: "07:00 PM",
        callback: () =>  {}
      },
      {
        label: "07:30 PM",
        callback: () =>  {}
      },
      {
        label: "08:00 PM",
        callback: () =>  {}
      },
      {
        label: "08:30 PM",
        callback: () =>  {}
      },
      {
        label: "09:00 PM",
        callback: () =>  {}
      },
      {
        label: "09:30 PM",
        callback: () =>  {}
      },
      {
        label: "10:00 PM",
        callback: () =>  {}
      },
      {
        label: "10:30 PM",
        callback: () =>  {}
      },
      {
        label: "11:00 PM",
        callback: () =>  {}
      },
      {
        label: "11:30 PM",
        callback: () =>  {}
      },
      {
        label: "11:59 PM",
        callback: () =>  {}
      }
    ]
  }
  PinStartBootTime.label = dataOfLocation.pin_start_time;
  const PinEndBootTime = {
    id: "1",
    label: "00:00",
    items: [
      {
        label: "None",
        callback: () =>  {}
      },
      {
        label: "12:00 AM",
        callback: () =>  {}
      },
      {
        label: "12:30 AM",
        callback: () =>  {}
      },
      {
        label:  "01:00 AM",
        callback: () =>  {}
      },
      {
        label:  "01:30 AM",
        callback: () =>  {}
      },
      {
        label: "02:00 AM",
        callback: () =>  {}
      },
      {
        label: "02:30 AM",
        callback: () =>  {}
      },
      {
        label: "03:00 AM",
        callback: () =>  {}
      },
      {
        label: "03:30 AM",
        callback: () =>  {}
      },
      {
        label: "04:00 AM",
        callback: () =>  {}
      },
      {
        label: "04:30 AM",
        callback: () =>  {}
      },
      {
        label: "05:00 AM",
        callback: () =>  {}
      },
      {
        label: "05:30 AM",
        callback: () =>  {}
      },
      {
        label:  "06:00 AM",
        callback: () =>  {}
      },
      {
        label: "06:30 AM",
        callback: () =>  {}
      },
      {
        label: "07:00 AM",
        callback: () =>  {}
      },
      {
        label: "07:30 AM",
        callback: () =>  {}
      },
      {
        label: "08:00 AM",
        callback: () =>  {}
      },
      {
        label: "08:30 AM",
        callback: () =>  {}
      },
      {
        label: "09:00 AM",
        callback: () =>  {}
      },
      {
        label: "09:30 AM",
        callback: () =>  {}
      },
      {
        label: "10:00 AM",
        callback: () =>  {}
      },
      {
        label: "10:30 AM",
        callback: () =>  {}
      },
      {
        label: "11:00 AM",
        callback: () =>  {}
      },
      {
        label: "11:30 AM",
        callback: () =>  {}
      },
      {
        label: "12:00 PM",
        callback: () =>  {}
      },
      {
        label: "12:30 PM",
        callback: () =>  {}
      },
      {
        label: "01:00 PM",
        callback: () =>  {}
      },
      {
        label: "01:30 PM",
        callback: () =>  {}
      },
      {
        label: "02:00 PM",
        callback: () =>  {}
      },
      {
        label: "02:30 PM",
        callback: () =>  {}
      },
      {
        label: "03:00 PM",
        callback: () =>  {}
      },
      {
        label: "03:30 PM",
        callback: () =>  {}
      },
      {
        label: "04:00 PM",
        callback: () =>  {}
      },
      {
        label: "04:30 PM",
        callback: () =>  {}
      },
      {
        label: "05:00 PM",
        callback: () =>  {}
      },
      {
        label: "05:30 PM",
        callback: () =>  {}
      },
      {
        label: "06:00 PM",
        callback: () =>  {}
      },
      {
        label: "06:30 PM",
        callback: () =>  {}
      },
      {
        label: "07:00 PM",
        callback: () =>  {}
      },
      {
        label: "07:30 PM",
        callback: () =>  {}
      },
      {
        label: "08:00 PM",
        callback: () =>  {}
      },
      {
        label: "08:30 PM",
        callback: () =>  {}
      },
      {
        label: "09:00 PM",
        callback: () =>  {}
      },
      {
        label: "09:30 PM",
        callback: () =>  {}
      },
      {
        label: "10:00 PM",
        callback: () =>  {}
      },
      {
        label: "10:30 PM",
        callback: () =>  {}
      },
      {
        label: "11:00 PM",
        callback: () =>  {}
      },
      {
        label: "11:30 PM",
        callback: () =>  {}
      },
      {
        label: "11:59 PM",
        callback: () =>  {}
      }
    ]
  }
  PinEndBootTime.label = dataOfLocation.pin_end_time;
  const [age, setAge] = React.useState('');

  const headCellsBrige = [		
    { id: 'name', label: 'Friendly Bridge Name' },		
    { id: 'bridge_id', label: 'Bridge Type'},		
    { id: 'action', label: 'Action', disableSorting: true },		
  ];		
  const headCellsCamera = [		
    { id: 'name', label: 'Friendly Camera Name' },		
    { id: 'camera_id', label: 'Camera Type'},		
    { id: 'action', label: 'Action', disableSorting: true },		
  ];		
  const [filterFnBridge, setFilterFnBridge] = useState({ fn: items => { return items; } });		
  const [filterFnCamera, setFilterFnCamera] = useState({ fn: items => { return items; } });		
  const {		
    TblContainer : TblContainerBridge,		
    TblHead :  TblHeadBridge,		
    TblPagination : TblPaginationBridge,		
    BelowTblPagination : BelowTblPaginationBridge,		
    recordsAfterPagingAndSorting : recordsAfterPagingAndSortingBridge,		
    setPageToNewOne : setPageToNewOneBridge		
  } = useInnerTable(recordsBridge, headCellsBrige, filterFnBridge,5,350);		
  const {		
    TblContainer : TblContainerCamera,		
    TblHead :  TblHeadCamera,		
    TblPagination : TblPaginationCamera,		
    BelowTblPagination : BelowTblPaginationCamera,		
    recordsAfterPagingAndSorting : recordsAfterPagingAndSortingCamera,		
    setPageToNewOne : setPageToNewOneCamera		
  } = useInnerTable(recordsCamera, headCellsCamera, filterFnCamera,5,350);

  const handleChangeBoot = (event) => {
    let ds = event.target.name;
    dataOfLocation[ds] = event.target.value;
    setLocationForData();
  }

  const handleChangeBridge = (event) => {		
    setCurrentBridge( event.target.value);		
    let idOdunit ;		
    dataOfLocation.bridge.forEach(element => {		
      if(element.name == event.target.value){		
        idOdunit = element.id;		
      }		
    });		
    setCurrentBridgeId(idOdunit);		
  }		
  const handleChangeCameraType = (event) => {		
    setCurrentCameraType( event.target.value);		
  }		
  const handleChangeBridgeType = (event) => {		
    setCurrentBridgeType( event.target.value);		
  }		
  const handleAddBridge = () => {		
    if(currentBridge == ""){		
      setOpenSnackData("Please Select Bridge And Try Again.");		
      setSeverityData("error");		
      setOpenSnack(true);		
    }		
    else if(currentBridgeType == ""){		
      setOpenSnackData("Please Select Bridge Type And Try Again.");		
     setSeverityData("error");		
     setOpenSnack(true);		
    }		
    console.log("add before called" +currentBridge);		
    for(let i = 0; i < (dataOfLocation.bridge).length ; i++){		
      if((dataOfLocation.bridge)[i].id == currentBridgeId){		
        (dataOfLocation.bridge)[i].location_id = dataOfLocation.loc_search_code;		
        console.log("dataOfLocation.bridge "+(dataOfLocation.bridge)[i].location_id);		
        if((dataOfLocation.bridge)[i].type  != "1")		
        (dataOfLocation.bridge)[i].type = currentBridgeType == "Dedicated" ? "0" : "1";		
        console.log("dataOfLocation.type "+(dataOfLocation.bridge)[i].type);		
      }		
    }		
    console.log("add called");		
    setLocationForData();		
  }		
  const handleAddCamera = () => {		
    if(currentCamera == ""){		
      setOpenSnackData("Please Select Camera And Try Again.");		
      setSeverityData("error");		
      setOpenSnack(true);		
    }		
    else if(currentCameraType == ""){		
      setOpenSnackData("Please Select Camera Type And Try Again.");		
     setSeverityData("error");		
     setOpenSnack(true);		
    }		
    console.log("add before called" +currentBridge);		
    for(let i = 0; i < (dataOfLocation.camera).length ; i++){		
      if((dataOfLocation.camera)[i].id == currentCameraId){		
        (dataOfLocation.camera)[i].location_id = dataOfLocation.loc_search_code;		
        console.log("dataOfLocation.bridge "+(dataOfLocation.camera)[i].location_id);		
        (dataOfLocation.camera)[i].type = currentCameraType == "Entry" ? "0" : "1";		
        console.log("dataOfLocation.type "+(dataOfLocation.camera)[i].type);		
      }		
    }		
    console.log("add called");		
    setLocationForData();		
  }		
  		
  const handleChangeCamera = (event) => {		
    setCurrentCamera( event.target.value);		
    let idOdunit ;		
    dataOfLocation.camera.forEach(element => {		
      if(element.name == event.target.value){		
        idOdunit = element.id;		
      }		
    });		
    setCurrentCameraId(idOdunit);		
  }

  return <> 
 {/* {showUpdateButton &&  <LoadingButton
         
         sx={mainThemeStyle.activityButton}
         loading={loading}
         onClick={handleUpdateLocation}
         variant="outlined"
         startIcon={<SaveIcon />}
         loadingPosition="start"
       >
         Save Location Details
       </LoadingButton>
   
      } */}
       {showUpdateButton && <> <Box sx={{
           display: { xs: "none", md: "flex" }
           }}><LoadingButton
         
         sx={mainThemeStyle.activityButton}
         loading={loading}
         onClick={handleUpdateLocation}
         variant="outlined"
         startIcon={<SaveIcon />}
         loadingPosition="start"
       >
         Save Device Details
       </LoadingButton>
       </Box>
       <Box sx={{
           display: { xs: "flex", md: "none" }
           }}><LoadingButton
         
         sx={{ position: 'absolute',
         width: 'auto',
         height: '2.06rem',
         left: '2.375rem',
         fontWeight: '700',
         fontSize: '0.9375rem',
         lineHeight: '1.1rem',
         display: 'flex',
         top: '1.75rem',
         alignItems: 'center',
         color: '#FFFFFF',
         fontFamily: "Lato",
         borderRadius:'0.31rem',
        '&:hover': {
         backgroundColor: 'rgba(109, 195, 252, 1)',
   }}}
         loading={loading}
         onClick={handleUpdateLocation}
         variant="outlined"
         startIcon={<SaveIcon />}
         loadingPosition="start"
       >
         Save Device Details
       </LoadingButton>
       </Box>
       </>
    }
  <Box sx={mainThemeStyle.boxTopHeader}>
  
     {(currentTab == 0 && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(0)}} active>General</Button>
    {dataOfLocation.cam_brand == "Virtual Panel" && 
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>QR Entry</Button>
    }
    {dataOfLocation.cam_brand == "Virtual Panel" && 
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    }
     {/* {dataOfLocation.cam_brand == "Virtual Panel" && 
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Hold Open</Button>
    } */}
     {dataOfLocation.cam_brand == "Virtual Panel" &&  dataOfLocation.lpr_accesss == '1' &&		
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>LPR</Button>		
    }
    {dataOfLocation.cam_brand == "Virtual Panel" && 
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>QR Code</Button>
    } 
    {dataOfLocation.cam_brand == "ResidentLink" && 
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    }
    {dataOfLocation.cam_brand == "ResidentLink" && 
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    }
    {dataOfLocation.cam_brand == "ResidentLink" &&  dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>LPR</Button>
    }
    {dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>
    }
    {dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>
    }
    {dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>
    }
     {dataOfLocation.cam_brand == "ResidentLink"  &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
    }
  </Stack>)}
  {(currentTab == 1 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(1)}}>QR Entry</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
   {/*  <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Hold Open</Button> */}
    { dataOfLocation.lpr_accesss == '1' &&		
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>LPR</Button>}	
   <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>QR Code</Button> 
   
  </Stack>)}
  {(currentTab == 2 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>QR Entry</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    {/* <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Hold Open</Button> */}
    { dataOfLocation.lpr_accesss == '1' &&		
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>LPR</Button>}	
     <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>QR Code</Button>
   
  </Stack>)}
   {(currentTab == 3 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>QR Entry</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    {/* <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(3)}}>Hold Open</Button> */}
    { dataOfLocation.lpr_accesss == '1' &&		
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>LPR</Button>}	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>QR Code</Button>
   
  </Stack>)} 
  {(currentTab == 4 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>QR Entry</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
   {/*  <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Hold Open</Button> */}
    { dataOfLocation.lpr_accesss == '1' &&		
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(4)}}>LPR</Button>}	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>QR Code</Button>	
   	
  </Stack>)} 	
  {(currentTab == 5 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Stack spacing={2} direction="row">	
    <Typography></Typography>	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>QR Entry</Button>	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>	
    {/* <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Hold Open</Button>	 */}
    { dataOfLocation.lpr_accesss == '1' &&		
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>LPR</Button>}	
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(5)}}>QR Code</Button>	
   	
  </Stack>)} 
  {(currentTab == 1 &&  dataOfLocation.cam_brand == "ResidentLink" && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    { dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
  </Stack>)}
  {(currentTab == 2 &&  dataOfLocation.cam_brand == "ResidentLink" && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} >General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
  </Stack>)}
  {(currentTab == 3 && dataOfLocation.cam_brand == "ResidentLink" && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} active>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
  </Stack>)}
  {(currentTab == 4 &&  dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '2' && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} active>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
  </Stack>)}
  {(currentTab == 5 && dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '2' && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} active>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
  </Stack>)}
  {(currentTab == 6 && dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '3' && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} active>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
  </Stack>)}
  {(currentTab == 7 && dataOfLocation.cam_brand == "ResidentLink"  && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} active>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
   <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
 
  </Stack>)}
            
</Box>
<Box sx={{position: 'absolute',
           width:'auto',
          
           top:'100px',
           left: '5px',
           display: { xs: "flex", md: "none" },
           right: '5px',
           flexGrow: 1,
          
           }}>
  {(currentTab == 0 && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
    <Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(0)}} active>General</Button></Grid>
    {dataOfLocation.cam_brand == "Virtual Panel" && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>QR Entry</Button>
    </Grid>}
    {dataOfLocation.cam_brand == "Virtual Panel" && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    </Grid>}
  {/*   {dataOfLocation.cam_brand == "Virtual Panel" && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Hold Open</Button>
    </Grid>}  */}
    {dataOfLocation.cam_brand == "Virtual Panel" &&  dataOfLocation.lpr_accesss == '1' && <Grid item>	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>LPR</Button>	
    </Grid>} 
    {dataOfLocation.cam_brand == "Virtual Panel" && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>QR Code</Button>
    </Grid>} 
    {dataOfLocation.cam_brand == "ResidentLink" && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    </Grid>}
    {dataOfLocation.cam_brand == "ResidentLink" && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    </Grid>}
    {dataOfLocation.cam_brand == "ResidentLink" &&  dataOfLocation.lpr_accesss == '1' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button>
    </Grid>}
    {dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '2' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>
    </Grid>}
    {dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '2' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>
    </Grid>}
    {dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '3' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>
    </Grid>}
    {dataOfLocation.cam_brand == "ResidentLink"  &&<Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
    </Grid>}
  </Grid>)}
  {(currentTab == 1 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}}>General</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(1)}}>QR Entry</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button></Grid>
    {/* <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Hold Open</Button> </Grid>
    */} { dataOfLocation.lpr_accesss == '1' && <Grid item>		
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>LPR</Button></Grid>}	
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>QR Code</Button> </Grid>
   </Grid>)}
  {(currentTab == 2 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}}>General</Button></Grid>
    <Grid item > <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>QR Entry</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button></Grid>
   {/*  <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Hold Open</Button></Grid>
   */}  { dataOfLocation.lpr_accesss == '1' && <Grid item>		
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>LPR</Button></Grid>}	
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>QR Code</Button> </Grid>
     </Grid>)}
   {(currentTab == 3 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
   <Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}}>General</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>QR Entryl</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button></Grid>
   {/*  <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(3)}}>Hold Open</Button></Grid> */}
    { dataOfLocation.lpr_accesss == '1' && <Grid item>		
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>LPR</Button></Grid>}	
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>QR Code</Button> </Grid>
    </Grid>)} 
    {(currentTab == 4 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
   <Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}}>General</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>QR Entry</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button></Grid>
    {/* <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Hold Open</Button></Grid>
    */} { dataOfLocation.lpr_accesss == '1' && <Grid item>		
    <Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(4)}}>LPR</Button></Grid>}		
   		
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>QR Code</Button> </Grid>
   
    </Grid>)} 	
    	
    {(currentTab == 5 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">		
   <Grid item >		
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}}>General</Button></Grid>		
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>QR Entry</Button></Grid>		
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button></Grid>		
    {/* <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Hold Open</Button></Grid>		
     */}{ dataOfLocation.lpr_accesss == '1' && <Grid item>		
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>LPR</Button></Grid>}		
   		
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(5)}}>QR Code</Button> </Grid>		
   		
    </Grid>)} 
  {(currentTab == 1 &&  dataOfLocation.cam_brand == "ResidentLink" && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}}>General</Button></Grid>
    <Grid item><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button></Grid>
    <Grid item> <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button></Grid>
    { dataOfLocation.lpr_accesss == '1' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button></Grid>}
     {dataOfLocation.unlocking_mode == '2' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Hold Open</Button></Grid>}
     {dataOfLocation.unlocking_mode == '2' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button></Grid>}
     {dataOfLocation.unlocking_mode == '3' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button></Grid>}
    <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
    </Grid>
  </Grid>)}
  {(currentTab == 2 &&  dataOfLocation.cam_brand == "ResidentLink" && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item> <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} >General</Button></Grid>
   <Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
  </Grid><Grid item><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
  </Grid>  {dataOfLocation.lpr_accesss == '1' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button></Grid>}
     {dataOfLocation.unlocking_mode == '2' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Hold Open</Button></Grid>}
     {dataOfLocation.unlocking_mode == '2' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button> </Grid>}
     {dataOfLocation.unlocking_mode == '3' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button> </Grid>}
    <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
    </Grid>
  </Grid>)}
  {(currentTab == 3 && dataOfLocation.cam_brand == "ResidentLink" && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
    
  <Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} active>General</Button>
  </Grid><Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
  </Grid><Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
  </Grid><Grid item>{dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
   </Grid><Grid item> {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
   </Grid><Grid item> {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
  </Grid>
  <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
    </Grid>
  </Grid>)}
  {(currentTab == 4 &&  dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '2' && <Grid  container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} active>General</Button>
    </Grid><Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    </Grid><Grid item> <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    </Grid><Grid item> {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    </Grid> <Grid item> {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    </Grid><Grid item> {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
  </Grid>
  <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
    </Grid>
  </Grid>)}
  {(currentTab == 5 && dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '2' && <Grid  container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} active>General</Button>
    </Grid>
    <Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    </Grid>
    <Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    </Grid>
    <Grid item> {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
  </Grid>
  <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
    </Grid>
  </Grid>)}
  {(currentTab == 6 && dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '3' && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} active>General</Button>
    </Grid><Grid item>  <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    </Grid><Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    </Grid><Grid item> {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}</Grid>
  <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
    </Grid>
  </Grid>
  )}
   {(currentTab == 7 && dataOfLocation.cam_brand == "ResidentLink" && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} active>General</Button>
    </Grid><Grid item>  <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    </Grid><Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    </Grid><Grid item> {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}</Grid>
  <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(7)}}>Panel Status</Button>
    </Grid>
  </Grid>
  )}
        </Box>
<Box sx={mainThemeStyle.boxHeaderBelow}>
{(currentTab == 0 && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                   Device Details
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
     {/*  <Typography sx={mainThemeStyle.popUpLable} height={40}> id </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> User id </Typography> */}
      {/* <Typography sx={mainThemeStyle.popUpLable} height={40}> Subscription Type </Typography> */}
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Accout Owner Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Dealer Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Telephone Number </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Code </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> License Key </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Camera Type </Typography>
     
      {dataOfLocation.cam_brand == "ResidentLink" && 
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Weekly Boot Day </Typography>}
      {dataOfLocation.cam_brand == "ResidentLink" && 
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Weekly Boot Time </Typography>}
       {dataOfLocation.cam_brand != "ResidentLink" &&  dataOfLocation.cam_brand != "Virtual Panel" && 
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device DTMF Code </Typography>}
      {/* {dataOfLocation.cam_brand != "ResidentLink" && 
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Location Code </Typography>} */}
        {dataOfLocation.cam_brand != "ResidentLink" &&  dataOfLocation.cam_brand != "Virtual Panel" && 
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Camera IP Address Or Hostname </Typography>}
      {dataOfLocation.cam_brand == "Virtual Panel" && 
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Latitude </Typography>}
     {dataOfLocation.cam_brand == "Virtual Panel" && 
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Longitude </Typography>}
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      {/* <Typography sx={mainThemeStyle.popUpLableAnswer}height={40} > {dataOfLocation.id}</Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer}height={40}>  {dataOfLocation.userid} </Typography> */}
     {/*  <Typography sx={mainThemeStyle.popUpLableAnswer}height={40}>{dataOfLocation.subscription}</Typography> */}
      <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="loc_name"
                        variant="filled"
                        value={dataOfLocation.loc_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("39")}

                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <Typography sx={mainThemeStyle.popUpLableGreenClickable} height={40} onClick={() => {ChangeId(dataOfLocation.property_owner.id,0)}}>{dataOfLocation.property_owner.name}</Typography>
                    <Typography sx={mainThemeStyle.popUpLableGreenClickable} height={40} onClick={() => {ChangeId(dataOfLocation.property_owner.del_id, 1)}}>{dataOfLocation.dealer.company_name}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer}height={40}>{dataOfLocation.loc_no}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer}height={40}> {dataOfLocation.loc_search_code} </Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer}height={40}> {dataOfLocation.licence_key} </Typography>
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "cam_brand"
            value={GetValueForCameraBrand()}
          label="Age"
          onChange={handleChangeCameraBrand}
          variant="filled"
          hiddenLabel
          disabled = {getDisableValue("40")}
          MenuProps={MenuProps}>
            
            {CameraBrand.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
     
      
      {dataOfLocation.cam_brand == "ResidentLink" && <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "rebootday"
            disabled = {getDisableValue("45")}
            value={dataOfLocation.rebootday}
          label="Age"
          onChange={handleChangeBoot}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootDay.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
         
          }
        
            
            {dataOfLocation.cam_brand == "ResidentLink" && <Select
            
            
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "reboottime"
          value={dataOfLocation.reboottime}
          label="Age"
          onChange={handleChangeBoot}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}
          disabled = {getDisableValue("46")}
            >
              {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
            </Select>
          }
          {dataOfLocation.cam_brand != "ResidentLink" &&  dataOfLocation.cam_brand != "Virtual Panel" &&
          <TextField
          id="input-dtmf-code"
          height={40}
          type= 'text'
          name="loc_code"
          value={dataOfLocation.loc_code}
          onChange={handleInputChange}
          sx={mainThemeStyle.InputMenuItem}
          InputLabelProps={{ shrink: true }
        }
        disabled = {getDisableValue("41")}
        variant="filled"
          InputProps={{
             
              sx: {
                  
                  color:'rgba(135, 135, 135, 0.5)',
                  height:'33px',
                  borderRadius: '5px',
                  backgroundColor: '#121212',
                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  padding: '0 0 15px 0',
                  textIndent: '15px !important',
                
                  '&.Mui-disabled': {
             

                    background: 'rgba(135, 135, 135, 0.5)',
                  },
                  
                  "& input.Mui-disabled": {
                    color: "white"
                  }
                  
              },
          }}
         
      />}
      {/*  {dataOfLocation.cam_brand != "ResidentLink" &&
       <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{dataOfLocation.loc_search_code} </Typography>}
     */}   {dataOfLocation.cam_brand != "ResidentLink"  &&  dataOfLocation.cam_brand != "Virtual Panel" &&
          <TextField
          id="input-dtmf-code"
          height={40}
          type= 'text'
          name="cam_ip"
          value={dataOfLocation.cam_ip}
          onChange={handleInputChange}
          disabled = {getDisableValue("42")}
          sx={mainThemeStyle.InputMenuItem}
          InputLabelProps={{ shrink: true }
        }
        variant="filled"
          InputProps={{
             
              sx: {
                  
                  color:'rgba(135, 135, 135, 0.5)',
                  height:'33px',
                  borderRadius: '5px',
                  backgroundColor: '#121212',
                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  padding: '0 0 15px 0',
                  textIndent: '15px !important',
                
                  '&.Mui-disabled': {
             

                    background: 'rgba(135, 135, 135, 0.5)',
                  },
                  
                  "& input.Mui-disabled": {
                    color: "white"
                  }
                  
              },
          }}
         
      />}
      { dataOfLocation.cam_brand == "Virtual Panel" &&
          <TextField
          id="latitude"
          height={40}
          type= 'text'
          name="latitude"
          value={dataOfLocation.latitude}
          onChange={handleInputChange}
          sx={mainThemeStyle.InputMenuItem}
          InputLabelProps={{ shrink: true }
        }
        disabled = {getDisableValue("50")}
        variant="filled"
          InputProps={{
             
              sx: {
               
                  color:'rgba(135, 135, 135, 0.5)',
                  height:'33px',
                  borderRadius: '5px',
                  backgroundColor: '#121212',
                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  padding: '0 0 15px 0',
                  textIndent: '15px !important',
                  '&.Mui-disabled': {
             

                    background: 'rgba(135, 135, 135, 0.5)',
                  },
                  
                  "& input.Mui-disabled": {
                    color: "white"
                  }
                 
                  
              },
          }}
         
      />}
      { dataOfLocation.cam_brand == "Virtual Panel" &&
          <TextField
          id="longitude"
          height={40}
          type= 'text'
          name="longitude"
          value={dataOfLocation.longitude}
          onChange={handleInputChange}
          sx={mainThemeStyle.InputMenuItem}
          InputLabelProps={{ shrink: true }
        }
        disabled = {getDisableValue("51")}
        variant="filled"
          InputProps={{
             
              sx: {
                
                  color:'rgba(135, 135, 135, 0.5)',
                  height:'33px',
                  borderRadius: '5px',
                  backgroundColor: '#121212',
                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  padding: '0 0 15px 0',
                  textIndent: '15px !important',
                
                  '&.Mui-disabled': {
             

                    background: 'rgba(135, 135, 135, 0.5)',
                  },
                  
                  "& input.Mui-disabled": {
                    color: "white"
                  }
                  
              },
          }}
         
      />}
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={200}> Thumbnail Photo </Typography>
      {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && 
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Pin Length </Typography>}
       {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && 
       <Typography sx={mainThemeStyle.popUpLable} height={40}>Delivery PIN </Typography>}
        {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && dataOfLocation.brivo_enable == '1' &&
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Brivo Group Name </Typography>}
      {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && dataOfLocation.pdk_enable == '1' &&
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>PDK CloudNode Id </Typography>}
     {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && dataOfLocation.pdk_enable == '1' &&
   <Typography  sx={mainThemeStyle.popUpLable} height={40}>PDK Group </Typography>}
       {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" && 
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Camera UserName </Typography>}
       {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" && 
       <Typography sx={mainThemeStyle.popUpLable} height={40}>Camera Password </Typography>}
       {dataOfLocation.cam_brand == "ResidentLink" &&
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>Select Time Zone</Typography>}
       {dataOfLocation.cam_brand == "ResidentLink" &&
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>Display Abbreviated Last Names</Typography>}
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
          <Stack direction="row" spacing={2}>
        <img  src={imageUrl} height={200} width={200}></img>
        <Button  sx={mainThemeStyle.normalButton} variant="contained" disabled = {getDisableValue("52")} component="label">Upload <input hidden accept="image/png, image/jpeg" multiple type="file" 
        onChange={ 
          e => {
            if (e.target.files[0].size > 2000000) {
              console.log("File too large");
              return;
            }
            
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
        
            reader.onload = () => {
              console.log(reader.result);
              setSelectedImage(e.target.files[0]);
              dataOfLocation.image_upload_data = reader.result;
              setLocationForData();
            };
            reader.onerror = error => {
              console.log("Error: ", error);
            };


          
          }
        }/></Button>
        </Stack>
       {/*  {dataOfLocation.cam_brand == "ResidentLink" && 
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {dataOfLocation.pin_length}</Typography>
        } */}
        {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && 
        <Select
        sx={{ color: 'rgba(135, 135, 135, 0.5)',
        height:'33px',
        top:'4px',
        bottom:'3px',
        borderRadius: '5px',
        backgroundColor: '#121212',
        boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
        padding: '5px 0 5px 0',
        "& .MuiSvgIcon-root": {
          color: "rgba(135, 135, 135, 0.5)",
        },
        
        '&.Mui-disabled': {
         

          background: 'rgba(135, 135, 135, 0.5)',
        },
        "& input.Mui-disabled": {
          color: "white"
        }
        }}
        name = "pin_length"
        disabled = {getDisableValue("47")}
        value={dataOfLocation.pin_length}
      label="Age"
      onChange={handleInputChange}
      variant="filled"
      
      hiddenLabel
     
      MenuProps={MenuProps}>
        
        {PinLengthHolder.items.map(menuItem => {
              
              return (
                <MenuItem  sx={
                  {
                  
                  color:"#fff",
                  backgroundColor: "#2F323A",
                  borderBottom: '.5px solid grey',
                  fontFamily: "Lato",fontSize:'12px',
              }}
               value={menuItem.label}>
                  {menuItem.label}
                </MenuItem>
              );
            })}
      </Select>}
        {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") &&
      <TextField
                        id="delivery-pin"
                        height={40}
                        type= 'number'
                        name="delivery_pin"
                        value={dataOfLocation.delivery_pin}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("48")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                  display: "none",
                                },
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />}
                    {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") &&  dataOfLocation.brivo_enable == '1' &&
                    //   <TextField
                    //     id="brivo-group-name"
                    //     height={40}
                    //     type= 'text'
                    //     name="brivo_group_name"
                    //     value={dataOfLocation.brivo_group_name}
                    //     onChange={handleInputChange}
                    //     sx={mainThemeStyle.InputMenuItem}
                    //     InputLabelProps={{ shrink: true }
                    //   }
                    //   disabled = {getDisableValue("241")}
                    //   variant="filled"
                    //     InputProps={{
                           
                    //         sx: {
                                
                    //             color:'rgba(135, 135, 135, 0.5)',
                    //             height:'33px',
                    //             borderRadius: '5px',
                    //             backgroundColor: '#121212',
                    //             boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                    //             padding: '0 0 15px 0',
                    //             textIndent: '15px !important',
                    //             '&.Mui-disabled': {
             

                    //               background: 'rgba(135, 135, 135, 0.5)',
                    //             },
                                
                    //             "& input.Mui-disabled": {
                    //               color: "white"
                    //             }
                               
                                
                    //         },
                    //     }}
                       
                    // />
                    <Autocomplete
                    style={{marginTop: 22,minWidth: 250}}
                     sx={{  "& .MuiAutocomplete-inputRoot": {
                      color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
                        color: "rgba(170, 170, 170, 1)",
                      },}}
                    disablePortal
                    disabled = {getDisableValue("241")}
                    id="brivo-group-name"
                    name = 'brivo_group_name'
                    value={getBrivoGroupValue()}
                  onChange={handleChangeBrivoGroupValue}
                  variant="filled"
                  hiddenLabel
                  options={dataOfLocation.brivo_group_array}
                 
                  PaperComponent={({ children }) => (
                    <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
                  )}
                  renderInput={(params) => <TextField {...params}  variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
                
                    
                }} />}
                  ></Autocomplete>
                    }
                    {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") &&  dataOfLocation.pdk_enable == '1' &&
                       <Select
                       sx={{ color: 'rgba(135, 135, 135, 0.5)',
                       height:'33px',
                       marginTop:'4px',
                       marginBottom:'3px',
                       borderRadius: '5px',
                       backgroundColor: '#121212',
                       boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                       padding: '5px 0 5px 0',
                       "& .MuiSvgIcon-root": {
                         color: "rgba(135, 135, 135, 0.5)",
                       },
                       
                       '&.Mui-disabled': {
                        
           
                         background: 'rgba(135, 135, 135, 0.5)',
                       },
                       "& input.Mui-disabled": {
                         color: "white"
                       }
                       }}
                       disabled = {getDisableValue("236")}
                       id="pdk_node"
                       name = 'pdk_node'
                       value={getPDKNodeValue()}
                     onChange={handleChangePDKNodeValue}
                     variant="filled"
                     hiddenLabel
                     MenuProps={MenuProps}>
                       {pdkNodeArray?.map(menuItem => {
                             
                             return (
                               <MenuItem  sx={
                                 {
                                 
                                 color:"#fff",
                                 backgroundColor: "#2F323A",
                                 borderBottom: '.5px solid grey',
                                 fontFamily: "Lato",fontSize:'12px',
                             }}
                             disabled={menuItem.disabled}
                              value={menuItem.name}>
                               <Stack direction="row" justifyContent="left"
             alignItems="center">
                             { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
                       <DoNotDisturbIcon fontSize="small" />
                     </ListItemIcon>} 
                       {menuItem.name}</Stack>
                               </MenuItem>
                             );
                           })}
                     </Select>
                     
                    }
                    {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") &&  dataOfLocation.pdk_enable == '1' &&
                       /*   <Select
                    sx={{ color: 'rgba(135, 135, 135, 0.5)',
                    height:'33px',
                    marginTop:'4px',
                    marginBottom:'3px',
                    borderRadius: '5px',
                    backgroundColor: '#121212',
                    boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                    padding: '5px 0 5px 0',
                    "& .MuiSvgIcon-root": {
                      color: "rgba(135, 135, 135, 0.5)",
                    },
                    
                    '&.Mui-disabled': {
                     
        
                      background: 'rgba(135, 135, 135, 0.5)',
                    },
                    "& input.Mui-disabled": {
                      color: "white"
                    }
                    }}
                    disabled = {getDisableValue("236")}
                    id="pdk_group"
                    name = 'pdk_group'
                    value={getPDKGroupValue()}
                  onChange={handleChangePDKGroupValue}
                  variant="filled"
                  hiddenLabel
                  MenuProps={MenuProps}>
                    {pdkGroupArray?.map(menuItem => {
                          
                          return (
                            <MenuItem  sx={
                              {
                              
                              color:"#fff",
                              backgroundColor: "#2F323A",
                              borderBottom: '.5px solid grey',
                              fontFamily: "Lato",fontSize:'12px',
                          }}
                          disabled={menuItem.disabled}
                           value={menuItem.name}>
                            <Stack direction="row" justifyContent="left"
          alignItems="center">
                          { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
                    <DoNotDisturbIcon fontSize="small" />
                  </ListItemIcon>} 
                    {menuItem.name}</Stack>
                            </MenuItem>
                          );
                        })}
                  </Select> */
                    
                  <Autocomplete
                  style={{marginTop: 22,minWidth: 250}}
                   sx={{  "& .MuiAutocomplete-inputRoot": {
                    color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
                      color: "rgba(170, 170, 170, 1)",
                    },}}
                  disablePortal
                  disabled = {getDisableValue("236")}
                  id="pdk_group"
                  name = 'pdk_group'
                  value={getPDKGroupValue()}
                onChange={handleChangePDKGroupValue}
                variant="filled"
                hiddenLabel
                options={dataOfLocation.pdk_group_array}
               
                PaperComponent={({ children }) => (
                  <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
                )}
                renderInput={(params) => <TextField {...params}  variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
              
                  
              }} />}
                ></Autocomplete>
                    }
                     {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" &&
      <TextField
                        id="cam-username"
                        height={40}
                        type= 'text'
                        name="cam_username"
                        value={dataOfLocation.cam_username}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                      disabled = {getDisableValue("43")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />}
                    {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" &&
                      <TextField
                        id="cam-password"
                        height={40}
                        type= 'text'
                        name="cam_password"
                        value={dataOfLocation.cam_password}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                      disabled = {getDisableValue("44")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />}
{dataOfLocation.cam_brand == "ResidentLink" && 
<Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'5px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "time_zone"
            disabled = {getDisableValue("49")}
            value={dataOfLocation.time_zone}
          label="Age"
          onChange={handleChangeBoot}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {TimeZoneList.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem}>
                      {menuItem}
                    </MenuItem>
                  );
                })}
          </Select> }

          {dataOfLocation.cam_brand == "ResidentLink" &&
          <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="last_abre" onChange={handlecheckboxCheck} disabled = {getDisableValue("49")} checked={dataOfLocation.last_abre == "1" ? true : false } />}
        label=""
      />}
      </Stack>
      </Grid>
     
      
      </Grid>
      </Box>
      <Box sx={{display: { xs: "flex", md: "none" }}}>
      <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
     {/*  <Typography sx={mainThemeStyle.popUpLable} height={40}> id </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> User id </Typography> */}
      {/* <Typography sx={mainThemeStyle.popUpLable} height={40}> Subscription Type </Typography> */}
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Accout Owner Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Dealer Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Telephone Number </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Code </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> License Key </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Camera Type </Typography>
     
      {dataOfLocation.cam_brand == "ResidentLink" && 
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Weekly Boot Day </Typography>}
      {dataOfLocation.cam_brand == "ResidentLink" && 
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Weekly Boot Time </Typography>}
       {dataOfLocation.cam_brand != "ResidentLink" &&  dataOfLocation.cam_brand != "Virtual Panel" && 
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device DTMF Code </Typography>}
      {/* {dataOfLocation.cam_brand != "ResidentLink" && 
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Location Code </Typography>} */}
        {dataOfLocation.cam_brand != "ResidentLink" &&  dataOfLocation.cam_brand != "Virtual Panel" && 
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Camera IP Address</Typography>}
       {dataOfLocation.cam_brand == "Virtual Panel" && 
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Latitude </Typography>}
     {dataOfLocation.cam_brand == "Virtual Panel" && 
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Longitude </Typography>}
      <Typography sx={mainThemeStyle.popUpLable} height={140}> Thumbnail Photo </Typography>
      {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && 
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Pin Length </Typography>}
       {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && 
       <Typography sx={mainThemeStyle.popUpLable} height={40}>Delivery PIN </Typography>}
        {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && dataOfLocation.brivo_enable == '1' &&
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Brivo Group Name </Typography>}
      {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && dataOfLocation.pdk_enable == '1' &&
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>PDK CloudNode Id </Typography>}
     {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && dataOfLocation.pdk_enable == '1' &&
   <Typography  sx={mainThemeStyle.popUpLable} height={40}>PDK Group </Typography>}
       {dataOfLocation.cam_brand != "ResidentLink"  &&  dataOfLocation.cam_brand != "Virtual Panel" && 
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Camera UserName </Typography>}
       {dataOfLocation.cam_brand != "ResidentLink"  &&  dataOfLocation.cam_brand != "Virtual Panel" && 
       <Typography sx={mainThemeStyle.popUpLable} height={40}>Camera Password </Typography>}
       {dataOfLocation.cam_brand == "ResidentLink" &&
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>Select Time Zone</Typography>}
       {dataOfLocation.cam_brand == "ResidentLink" &&
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>Display Abbreviated Last Names</Typography>}
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      {/* <Typography sx={mainThemeStyle.popUpLableAnswer}height={40} > {dataOfLocation.id}</Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer}height={40}>  {dataOfLocation.userid} </Typography> */}
     {/*  <Typography sx={mainThemeStyle.popUpLableAnswer}height={40}>{dataOfLocation.subscription}</Typography> */}
      <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="loc_name"
                        variant="filled"
                        value={dataOfLocation.loc_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("39")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <Typography sx={mainThemeStyle.popUpLableGreenClickable} height={40} onClick={() => {ChangeId(dataOfLocation.property_owner.id,0)}}>{dataOfLocation.property_owner.name}</Typography>
                    <Typography sx={mainThemeStyle.popUpLableGreenClickable} height={40} onClick={() => {ChangeId(dataOfLocation.property_owner.del_id, 1)}}>{dataOfLocation.dealer.company_name}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer}height={40}>{dataOfLocation.loc_no}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer}height={40}> {dataOfLocation.loc_search_code} </Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer}height={40}> {dataOfLocation.licence_key} </Typography>
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled = {getDisableValue("40")}
            name = "cam_brand"
            value={GetValueForCameraBrand()}
          label="Age"
          onChange={handleChangeCameraBrand}
          variant="filled"
          hiddenLabel
         
          MenuProps={MenuProps}>
            
            {CameraBrand.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
     
      
      {dataOfLocation.cam_brand == "ResidentLink" && <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "rebootday"
            disabled = {getDisableValue("45")}
            value={dataOfLocation.rebootday}
          label="Age"
          onChange={handleChangeBoot}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootDay.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
         
          }
        
            
            {dataOfLocation.cam_brand == "ResidentLink" && <Select
            
            
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "reboottime"
            disabled = {getDisableValue("46")}
          value={dataOfLocation.reboottime}
          label="Age"
          onChange={handleChangeBoot}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}
         
            >
              {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
            </Select>
          }
          {dataOfLocation.cam_brand != "ResidentLink" &&  dataOfLocation.cam_brand != "Virtual Panel" &&
          <TextField
          id="input-dtmf-code"
          height={40}
          type= 'text'
          name="loc_code"
          value={dataOfLocation.loc_code}
          onChange={handleInputChange}
          sx={mainThemeStyle.InputMenuItem}
          InputLabelProps={{ shrink: true }
        }
        variant="filled"
        disabled = {getDisableValue("41")}
          InputProps={{
             
              sx: {
                  
                  color:'rgba(135, 135, 135, 0.5)',
                  height:'33px',
                  borderRadius: '5px',
                  backgroundColor: '#121212',
                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  padding: '0 0 15px 0',
                  textIndent: '15px !important',
                  '&.Mui-disabled': {
             

                    background: 'rgba(135, 135, 135, 0.5)',
                  },
                  
                  "& input.Mui-disabled": {
                    color: "white"
                  }
                 
                  
              },
          }}
         
      />}
       {/* {dataOfLocation.cam_brand != "ResidentLink" &&
       <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{dataOfLocation.loc_search_code} </Typography>}
        */}{dataOfLocation.cam_brand != "ResidentLink" &&  dataOfLocation.cam_brand != "Virtual Panel" &&
          <TextField
          id="input-dtmf-code"
          height={40}
          type= 'text'
          name="cam_ip"
          value={dataOfLocation.cam_ip}
          onChange={handleInputChange}
          disabled = {getDisableValue("42")}
          sx={mainThemeStyle.InputMenuItem}
          InputLabelProps={{ shrink: true }
        }
        variant="filled"
          InputProps={{
             
              sx: {
                  
                  color:'rgba(135, 135, 135, 0.5)',
                  height:'33px',
                  borderRadius: '5px',
                  backgroundColor: '#121212',
                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  padding: '0 0 15px 0',
                  textIndent: '15px !important',
                
                  '&.Mui-disabled': {
             

                    background: 'rgba(135, 135, 135, 0.5)',
                  },
                  
                  "& input.Mui-disabled": {
                    color: "white"
                  }
                  
              },
          }}
         
      />}
       { dataOfLocation.cam_brand == "Virtual Panel" &&
      <TextField
      id="latitude"
      height={40}
      type= 'text'
      name="latitude"
      value={dataOfLocation.latitude}
      onChange={handleInputChange}
      sx={mainThemeStyle.InputMenuItem}
      InputLabelProps={{ shrink: true }
    }
    variant="filled"
    disabled = {getDisableValue("50")}
      InputProps={{
         
          sx: {
            top:'10px',
              color:'rgba(135, 135, 135, 0.5)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#121212',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '0 0 15px 0',
              textIndent: '15px !important',
              '&.Mui-disabled': {
             

                background: 'rgba(135, 135, 135, 0.5)',
              },
              
              "& input.Mui-disabled": {
                color: "white"
              }
             
              
          },
      }}
     
  />}
  { dataOfLocation.cam_brand == "Virtual Panel" &&
      <TextField
      id="longitude"
      height={40}
      type= 'text'
      name="longitude"
      value={dataOfLocation.longitude}
      onChange={handleInputChange}
      disabled = {getDisableValue("51")}
      sx={mainThemeStyle.InputMenuItem}
      InputLabelProps={{ shrink: true }
    }
    variant="filled"
      InputProps={{
         
          sx: {
            top:'10px',
              color:'rgba(135, 135, 135, 0.5)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#121212',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '0 0 15px 0',
              textIndent: '15px !important',
              '&.Mui-disabled': {
             

                background: 'rgba(135, 135, 135, 0.5)',
              },
              
              "& input.Mui-disabled": {
                color: "white"
              }
             
              
          },
      }}
     
  />}
  <Typography></Typography>
       <Stack direction="column" spacing={2}>
        <img  src={imageUrl} height={100} width={100}></img>
        <Button  sx={mainThemeStyle.normalButton} variant="contained" disabled = {getDisableValue("52")} component="label">Upload <input hidden accept="image/png, image/jpeg" multiple type="file" 
        onChange={ 
          e => {
            if (e.target.files[0].size > 2000000) {
              console.log("File too large");
              return;
            }
            
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
        
            reader.onload = () => {
              console.log(reader.result);
              setSelectedImage(e.target.files[0]);
              dataOfLocation.image_upload_data = reader.result;
              setLocationForData();
            };
            reader.onerror = error => {
              console.log("Error: ", error);
            };


          
          }
        }/></Button>
        </Stack>
     
        {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && 
        <Select
        sx={{ color: 'rgba(135, 135, 135, 0.5)',
        height:'33px',
        top:'4px',
        bottom:'3px',
        borderRadius: '5px',
        backgroundColor: '#121212',
        boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
        padding: '5px 0 5px 0',
        "& .MuiSvgIcon-root": {
          color: "rgba(135, 135, 135, 0.5)",
        },
        
        '&.Mui-disabled': {
         

          background: 'rgba(135, 135, 135, 0.5)',
        },
        "& input.Mui-disabled": {
          color: "white"
        }
        }}
        disabled = {getDisableValue("47")}
        name = "pin_length"
        value={dataOfLocation.pin_length}
      label="Age"
      onChange={handleInputChange}
      variant="filled"
      
      hiddenLabel
     
      MenuProps={MenuProps}>
        
        {PinLengthHolder.items.map(menuItem => {
              
              return (
                <MenuItem  sx={
                  {
                  
                  color:"#fff",
                  backgroundColor: "#2F323A",
                  borderBottom: '.5px solid grey',
                  fontFamily: "Lato",fontSize:'12px',
              }}
               value={menuItem.label}>
                  {menuItem.label}
                </MenuItem>
              );
            })}
      </Select>}
        {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") &&
      <TextField
                        id="delivery-pin"
                        height={40}
                        type= 'number'
                        name="delivery_pin"
                        value={dataOfLocation.delivery_pin}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("48")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                  display: "none",
                                },
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />}
                    {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") &&  dataOfLocation.brivo_enable == '1' &&
                    //   <TextField
                    //     id="brivo-group-name"
                    //     height={40}
                    //     type= 'text'
                    //     name="brivo_group_name"
                    //     value={dataOfLocation.brivo_group_name}
                    //     onChange={handleInputChange}
                    //     sx={mainThemeStyle.InputMenuItem}
                    //     InputLabelProps={{ shrink: true }
                    //   }
                    //   disabled = {getDisableValue("241")}
                    //   variant="filled"
                    //     InputProps={{
                           
                    //         sx: {
                                
                    //             color:'rgba(135, 135, 135, 0.5)',
                    //             height:'33px',
                    //             borderRadius: '5px',
                    //             backgroundColor: '#121212',
                    //             boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                    //             padding: '0 0 15px 0',
                    //             textIndent: '15px !important',
                              
                    //             '&.Mui-disabled': {
             

                    //               background: 'rgba(135, 135, 135, 0.5)',
                    //             },
                                
                    //             "& input.Mui-disabled": {
                    //               color: "white"
                    //             }
                                
                    //         },
                    //     }}
                       
                    // />
                    <Autocomplete
                    style={{marginTop: 22,minWidth: 250}}
                     sx={{  "& .MuiAutocomplete-inputRoot": {
                      color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
                        color: "rgba(170, 170, 170, 1)",
                      },}}
                    disablePortal
                    disabled = {getDisableValue("241")}
                    id="brivo-group-name"
                    name = 'brivo_group_name'
                    value={getBrivoGroupValue()}
                  onChange={handleChangeBrivoGroupValue}
                  variant="filled"
                  hiddenLabel
                  options={dataOfLocation.brivo_group_array}
                 
                  PaperComponent={({ children }) => (
                    <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
                  )}
                  renderInput={(params) => <TextField {...params}  variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
                
                    
                }} />}
                  ></Autocomplete>
                    }
                     {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") &&  dataOfLocation.pdk_enable == '1' &&
                       <Select
                       sx={{ color: 'rgba(135, 135, 135, 0.5)',
                       height:'33px',
                       marginTop:'4px',
                       marginBottom:'3px',
                       borderRadius: '5px',
                       backgroundColor: '#121212',
                       boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                       padding: '5px 0 5px 0',
                       "& .MuiSvgIcon-root": {
                         color: "rgba(135, 135, 135, 0.5)",
                       },
                       
                       '&.Mui-disabled': {
                        
           
                         background: 'rgba(135, 135, 135, 0.5)',
                       },
                       "& input.Mui-disabled": {
                         color: "white"
                       }
                       }}
                       disabled = {getDisableValue("236")}
                       id="pdk_node"
                       name = 'pdk_node'
                       value={getPDKNodeValue()}
                     onChange={handleChangePDKNodeValue}
                     variant="filled"
                     hiddenLabel
                     MenuProps={MenuProps}>
                       {pdkNodeArray?.map(menuItem => {
                             
                             return (
                               <MenuItem  sx={
                                 {
                                 
                                 color:"#fff",
                                 backgroundColor: "#2F323A",
                                 borderBottom: '.5px solid grey',
                                 fontFamily: "Lato",fontSize:'12px',
                             }}
                             disabled={menuItem.disabled}
                              value={menuItem.name}>
                               <Stack direction="row" justifyContent="left"
             alignItems="center">
                             { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
                       <DoNotDisturbIcon fontSize="small" />
                     </ListItemIcon>} 
                       {menuItem.name}</Stack>
                               </MenuItem>
                             );
                           })}
                     </Select>
                     
                    }
                    {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") &&  dataOfLocation.pdk_enable == '1' &&
          //                <Select
          //           sx={{ color: 'rgba(135, 135, 135, 0.5)',
          //           height:'33px',
          //           marginTop:'4px',
          //           marginBottom:'3px',
          //           borderRadius: '5px',
          //           backgroundColor: '#121212',
          //           boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
          //           padding: '5px 0 5px 0',
          //           "& .MuiSvgIcon-root": {
          //             color: "rgba(135, 135, 135, 0.5)",
          //           },
                    
          //           '&.Mui-disabled': {
                     
        
          //             background: 'rgba(135, 135, 135, 0.5)',
          //           },
          //           "& input.Mui-disabled": {
          //             color: "white"
          //           }
          //           }}
          //           disabled = {getDisableValue("236")}
          //           id="pdk_group"
          //           name = 'pdk_group'
          //           value={getPDKGroupValue()}
          //         onChange={handleChangePDKGroupValue}
          //         variant="filled"
          //         hiddenLabel
          //         MenuProps={MenuProps}>
          //           {pdkGroupArray?.map(menuItem => {
                          
          //                 return (
          //                   <MenuItem  sx={
          //                     {
                              
          //                     color:"#fff",
          //                     backgroundColor: "#2F323A",
          //                     borderBottom: '.5px solid grey',
          //                     fontFamily: "Lato",fontSize:'12px',
          //                 }}
          //                 disabled={menuItem.disabled}
          //                  value={menuItem.name}>
          //                   <Stack direction="row" justifyContent="left"
          // alignItems="center">
          //                 { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
          //           <DoNotDisturbIcon fontSize="small" />
          //         </ListItemIcon>} 
          //           {menuItem.name}</Stack>
          //                   </MenuItem>
          //                 );
          //               })}
          //         </Select>
                    
          <Autocomplete
          style={{marginTop: 22,minWidth: 250}}
           sx={{  "& .MuiAutocomplete-inputRoot": {
            color: "rgba(170, 170, 170,1)" },"& .MuiSvgIcon-root": {
              color: "rgba(170, 170, 170, 1)",
            },}}
          disablePortal
          disabled = {getDisableValue("236")}
          id="pdk_group"
          name = 'pdk_group'
          value={getPDKGroupValue()}
        onChange={handleChangePDKGroupValue}
        variant="filled"
        hiddenLabel
        options={dataOfLocation.pdk_group_array}
       
        PaperComponent={({ children }) => (
          <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
        )}
        renderInput={(params) => <TextField {...params}  variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
      
          
      }} />}
        ></Autocomplete>
                    }
                     {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" &&
      <TextField
                        id="cam-username"
                        height={40}
                        type= 'text'
                        name="cam_username"
                        value={dataOfLocation.cam_username}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("43")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />}
                    {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" &&
                      <TextField
                        id="cam-password"
                        height={40}
                        type= 'text'
                        name="cam_password"
                        value={dataOfLocation.cam_password}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("44")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                            },
                        }}
                       
                    />}
{dataOfLocation.cam_brand == "ResidentLink" && 
<Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'5px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "time_zone"
            disabled = {getDisableValue("49")}
            value={dataOfLocation.time_zone}
          label="Age"
          onChange={handleChangeBoot}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {TimeZoneList.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem}>
                      {menuItem}
                    </MenuItem>
                  );
                })}
          </Select> }

          {dataOfLocation.cam_brand == "ResidentLink" &&
          <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="last_abre" onChange={handlecheckboxCheck} disabled = {getDisableValue("49")} checked={dataOfLocation.last_abre == "1" ? true : false } />}
        label=""
      />}
      </Stack>
      </Grid>
     
      
     
      
      </Grid>
      </Box>
      </>
     
)}


{(currentTab == 1 && dataOfLocation.cam_brand == "ResidentLink" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Panel Settings
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Scrolling Marquee </Typography>
     {/*  <Typography sx={mainThemeStyle.popUpLable} height={40}> License Key </Typography> */}
      
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Accout Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Button Descriptor </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Accout Address </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>City, State, ZIP </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Text Alert Phone # </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Displayed Phone # </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Unlocking Mode </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>App User Unlock Mode</Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <TextField
                        id="input-welcome"
                        height={40}
                        type= 'text'
                        name="wel_message"
                        value={dataOfLocation.wel_message}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("53")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                  {/*   <Typography sx={mainThemeStyle.popUpLableAnswer}height={40}> {dataOfLocation.licence_key} </Typography>
                 */}    {/* <TextField
                        id="input-tablet-name"
                        height={40}
                        type= 'text'
                        name="tablet_name"
                        value={dataOfLocation.tablet_name}
                        onChange={handleInputChange}
                        variant="filled"
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    /> */}
                    <TextField
                        id="input-property-name"
                        height={40}
                        type= 'text'
                        name="loc_property_name"
                        value={dataOfLocation.loc_property_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("54")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-office-name"
                        height={40}
                        type= 'text'
                        name="loc_office_name"
                        value={dataOfLocation.loc_office_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("55")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-address-one"
                        height={40}
                        type= 'text'
                        name="loc_add_one"
                        value={dataOfLocation.loc_add_one}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("56")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-address-two"
                        height={40}
                        type= 'text'
                        name="loc_add_two"
                        value={dataOfLocation.loc_add_two}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("57")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                       <TextField
                        id="input-sms-alert"
                        height={40}
                        type= 'text'
                        name="warning_phone"
                        value={dataOfLocation.warning_phone}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("58")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                     <TextField
                        id="input-panel-telephone"
                        height={40}
                        type= 'text'
                        name="loc_telephone_dial"
                        value={dataOfLocation.loc_telephone_dial}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("59")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
       <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "unlocking_mode"
            disabled = {getDisableValue("60")}
            value={GetUnlockingMode()}
          label="Age"
          onChange={handleSetUnlockingMode}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {UnlcokingMode.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "app_unlock_mode"
            disabled = {getDisableValue("61")}
            value={GetAppUnlockMode()}
          label="Age"
          onChange={handleSetAppUnlockMode}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {AppUserUnlockMode.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
     
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
   {/*      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Location Code </Typography> */}
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>URL for Watermark Logo </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Refresh Rate(In minutes) </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Setting Refresh Rate(In minutes) </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Screen Layout </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Enable Office Button</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Enable Directory Button </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Enable Door PIN Button </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Enable Delivery PIN Button </Typography>
      
      
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
         
     
      {/* <Typography sx={mainThemeStyle.popUpLableAnswer}height={40}> {dataOfLocation.loc_search_code} </Typography> */}
      
      <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="loc_url_watermark"
                        value={dataOfLocation.loc_url_watermark}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("62")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
      <TextField
                        id="input-location"
                        height={40}
                        type= 'number'
                        name="loc_refresh_rate"
                        value={dataOfLocation.loc_refresh_rate}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("63")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-location"
                        height={40}
                        type= 'number'
                        name="setting_reftesh_rate"
                        value={dataOfLocation.setting_reftesh_rate}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("64")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                    <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "loc_screen_layout"
            disabled = {getDisableValue("65")}
            value={GetValueOfLocationScreenLayout(dataOfLocation.loc_screen_layout)}
          label="Age"
          onChange={ChangeScreenLayout}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {ScreenLayout.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_off" onChange={handlecheckboxCheckReverse} disabled = {getDisableValue("66")} checked={dataOfLocation.dis_off == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_dir" onChange={handlecheckboxCheckReverse} disabled = {getDisableValue("67")} checked={dataOfLocation.dis_dir == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_doorpin" onChange={handlecheckboxCheckReverse} disabled = {getDisableValue("68")} checked={dataOfLocation.dis_doorpin == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_del" onChange={handlecheckboxCheckReverse}  disabled = {getDisableValue("69")} checked={dataOfLocation.dis_del == "0" ? true : false } />}
        label=""
      />
                    {/* <Checkbox  sx={mainThemeStyle.formCheckBox} name="dis_off" onChange={handlecheckboxCheck} checked={dataOfLocation.dis_off == "1" ? true : false} />
                    <Checkbox sx={mainThemeStyle.formCheckBox} name="dis_dir" onChange={handlecheckboxCheck} checked={dataOfLocation.dis_dir  == "1" ? true : false}/>
                    <Checkbox sx={mainThemeStyle.formCheckBox} name="dis_doorpin" onChange={handlecheckboxCheck} checked={dataOfLocation.dis_doorpin  == "1" ? true : false}/>
                    <Checkbox sx={mainThemeStyle.formCheckBox} name="dis_del" onChange={handlecheckboxCheck} checked={dataOfLocation.dis_del  == "1" ? true : false}/>
                    */}
      </Stack>
      </Grid>
     
      
      </Grid>
      </Box>
      <Box sx={{display: { xs: "flex", md: "none" }}}>
           <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Scrolling Marquee </Typography>
     {/*  <Typography sx={mainThemeStyle.popUpLable} height={40}> License Key </Typography> */}
      
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Accout Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Button Descriptor </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Accout Address </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>City, State, ZIP </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Text Alert Phone # </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Displayed Phone # </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Unlocking Mode </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>App User Unlock Mode</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Watermark URL </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Refresh Rate </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Setting Refresh Rate </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Screen Layout </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Office Button</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}> Directory Button </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}> Door PIN Button </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}> Delivery PIN Button </Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <TextField
                        id="input-welcome"
                        height={40}
                        type= 'text'
                        name="wel_message"
                        value={dataOfLocation.wel_message}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("53")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                 
                    <TextField
                        id="input-property-name"
                        height={40}
                        type= 'text'
                        name="loc_property_name"
                        value={dataOfLocation.loc_property_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("54")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-office-name"
                        height={40}
                        type= 'text'
                        name="loc_office_name"
                        value={dataOfLocation.loc_office_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("55")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-address-one"
                        height={40}
                        type= 'text'
                        name="loc_add_one"
                        value={dataOfLocation.loc_add_one}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("56")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-address-two"
                        height={40}
                        type= 'text'
                        name="loc_add_two"
                        value={dataOfLocation.loc_add_two}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("57")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                       <TextField
                        id="input-sms-alert"
                        height={40}
                        type= 'text'
                        name="warning_phone"
                        value={dataOfLocation.warning_phone}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("58")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                     <TextField
                        id="input-panel-telephone"
                        height={40}
                        type= 'text'
                        name="loc_telephone_dial"
                        value={dataOfLocation.loc_telephone_dial}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("59")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
       <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'13px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "unlocking_mode"
            disabled = {getDisableValue("60")}
            value={GetUnlockingMode()}
          label="Age"
          onChange={handleSetUnlockingMode}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {UnlcokingMode.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "app_unlock_mode"
            disabled = {getDisableValue("61")}
            value={GetAppUnlockMode()}
          label="Age"
          onChange={handleSetAppUnlockMode}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {AppUserUnlockMode.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="loc_url_watermark"
                        value={dataOfLocation.loc_url_watermark}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("62")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                              marginTop:'14px',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
      <TextField
                        id="input-location"
                        height={40}
                        type= 'number'
                        name="loc_refresh_rate"
                        value={dataOfLocation.loc_refresh_rate}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("63")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                              marginTop:'14px',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-location"
                        height={40}
                        type= 'number'
                        name="setting_reftesh_rate"
                        value={dataOfLocation.setting_reftesh_rate}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("64")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                              marginTop:'14px',
                              marginBottom:'14px',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'14px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 14px 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "loc_screen_layout"
            disabled = {getDisableValue("65")}
            value={GetValueOfLocationScreenLayout(dataOfLocation.loc_screen_layout)}
          label="Age"
          onChange={ChangeScreenLayout}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {ScreenLayout.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_off" onChange={handlecheckboxCheckReverse}  disabled = {getDisableValue("66")} checked={dataOfLocation.dis_off == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_dir" onChange={handlecheckboxCheckReverse}  disabled = {getDisableValue("67")} checked={dataOfLocation.dis_dir == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_doorpin" onChange={handlecheckboxCheckReverse} disabled = {getDisableValue("68")}  checked={dataOfLocation.dis_doorpin == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_del" onChange={handlecheckboxCheckReverse}  disabled = {getDisableValue("69")} checked={dataOfLocation.dis_del == "0" ? true : false } />}
        label=""
      />
      </Stack>
      </Grid>
      
     
      
     
      
      </Grid>
      </Box>
</>)}
{(currentTab == 1 && dataOfLocation.cam_brand == "Virtual Panel" &&<>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                   QR Entry
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={41}> Displayed Heading </Typography>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Displayed Heading Custom Color </Typography>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Displayed Icon </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={41}> Displayed Message </Typography>
     {/*  <Typography sx={mainThemeStyle.popUpLable} height={40}> Serial Number </Typography> */}
      <Typography sx={mainThemeStyle.popUpLable} height={41}> Accout Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={41}>Button Descriptor </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={41}>Accout Address </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={41}>City, State, ZIP </Typography>
      
      <Typography  sx={mainThemeStyle.popUpLable} height={41}>Displayed Phone# </Typography>
      {/* <Typography  sx={mainThemeStyle.popUpLable} height={40}>Unlocking Mode </Typography> */}
       <Typography  sx={mainThemeStyle.popUpLable} height={41}>App User Unlock Mode</Typography> 
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <TextField
                        id="wel_message_header"
                        height={40}
                        type= 'text'
                        name="wel_message_header"
                        value={dataOfLocation.wel_message_header}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("85")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-location"
                        height={40}
                        type= 'color'
                        name="wel_message_headercolor"
                        value={dataOfLocation.wel_message_headercolor}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                       
                      }
                      disabled = {getDisableValue("86")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(255, 255, 255, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                     <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            }
            }}
            name = "wel_message_headerlogo"
            value={getDisplayedLogo(dataOfLocation.wel_message_headerlogo)}
          label="Age"
          disabled = {getDisableValue("87")}
          onChange={handleSetDisplayLogo}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {DisplayedICon.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
      <TextField
                        id="input-welcome"
                        height={40}
                        type= 'text'
                        name="wel_message"
                        value={dataOfLocation.wel_message}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                      disabled = {getDisableValue("88")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                   
                    {/* <TextField
                        id="input-tablet-name"
                        height={40}
                        type= 'text'
                        name="tablet_name"
                        value={dataOfLocation.tablet_name}
                        onChange={handleInputChange}
                        variant="filled"
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    /> */}
                    <TextField
                        id="input-property-name"
                        height={40}
                        type= 'text'
                        name="loc_property_name"
                        value={dataOfLocation.loc_property_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("54")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-office-name"
                        height={40}
                        type= 'text'
                        name="loc_office_name"
                        value={dataOfLocation.loc_office_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("55")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-address-one"
                        height={40}
                        type= 'text'
                        name="loc_add_one"
                        value={dataOfLocation.loc_add_one}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("56")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-address-two"
                        height={40}
                        type= 'text'
                        name="loc_add_two"
                        value={dataOfLocation.loc_add_two}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("57")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                       
                     <TextField
                        id="input-panel-telephone"
                        height={40}
                        type= 'text'
                        name="loc_telephone_dial"
                        value={dataOfLocation.loc_telephone_dial}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("59")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
     
           <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "app_unlock_mode"
            
            value={GetAppUnlockModeCBW()}
          label="Age"
          onChange={handleSetAppUnlockModeCBW}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {AppUserUnlockModeCBW.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select> 
     
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography  sx={mainThemeStyle.popUpLable} height={41}>Text Alert Phone# </Typography>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>URL for Watermark Logo </Typography>
     {/*  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Refresh Rate(In minutes) </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Setting Refresh Rate(In minutes) </Typography> */}
     {/*  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Screen Layout </Typography> */}
     
        
      <Typography sx={mainThemeStyle.popUpLable} height={41}>Enable Office Button</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={41}>Enable Directory Button </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={41}>Enable Door PIN Button </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={42}>Enable Delivery PIN Button </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={41}>Require GPS Proximty to Launch</Typography>
      
      </Stack>
      
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <TextField
                        id="input-sms-alert"
                        height={40}
                        type= 'text'
                        name="warning_phone"
                        value={dataOfLocation.warning_phone}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("58")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
      
      
      <TextField
                        id="input-location"
                        height={30}
                        type= 'text'
                        name="loc_url_watermark"
                        value={dataOfLocation.loc_url_watermark}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                       
                      }
                      disabled = {getDisableValue("62")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    /> 
      
                   {/*  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "loc_screen_layout"
            
            value={GetValueOfLocationScreenLayout(dataOfLocation.loc_screen_layout)}
          label="Age"
          onChange={ChangeScreenLayout}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {ScreenLayout.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select> */}
          {/* <TextField
                        id="input-location"
                        height={30}
                        type= 'text'
                        name="wel_message_headerlogo"
                        value={dataOfLocation.wel_message_headerlogo}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                       
                      }
                      
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                              
                               
                                
                            },
                        }}
                       
                    />
                     */}
          <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_off" onChange={handlecheckboxCheckReverse} disabled = {getDisableValue("66")} checked={dataOfLocation.dis_off == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_dir" onChange={handlecheckboxCheckReverse} disabled = {getDisableValue("67")} checked={dataOfLocation.dis_dir == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_doorpin" onChange={handlecheckboxCheckReverse} disabled = {getDisableValue("68")} checked={dataOfLocation.dis_doorpin == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_del" onChange={handlecheckboxCheckReverse} disabled = {getDisableValue("69")} checked={dataOfLocation.dis_del == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_prox" onChange={handlecheckboxCheckReverse} disabled = {getDisableValue("89")} checked={dataOfLocation.dis_prox == "0" ? true : false } />}
        label=""
      />
                  {/*   <Checkbox  sx={mainThemeStyle.formCheckBox} name="dis_off" onChange={handlecheckboxCheck} checked={dataOfLocation.dis_off == "1" ? true : false} />
                    <Checkbox sx={mainThemeStyle.formCheckBox} name="dis_dir" onChange={handlecheckboxCheck} checked={dataOfLocation.dis_dir  == "1" ? true : false}/>
                    <Checkbox sx={mainThemeStyle.formCheckBox} name="dis_doorpin" onChange={handlecheckboxCheck} checked={dataOfLocation.dis_doorpin  == "1" ? true : false}/>
                    <Checkbox sx={mainThemeStyle.formCheckBox} name="dis_del" onChange={handlecheckboxCheck} checked={dataOfLocation.dis_del  == "1" ? true : false}/> */}
                   
      </Stack>
      </Grid>
     
      
      </Grid></Box>
      <Box sx={{display: { xs: "flex", md: "none" }}}>
           <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={41}> Displayed Heading </Typography>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Displayed Head Color </Typography>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Displayed Icon</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={41}> Displayed Message </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Accout Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Button Descriptor </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Accout Address </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>City, State, ZIP </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Text Alert Phone# </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Displayed Phone# </Typography>
      {/* <Typography  sx={mainThemeStyle.popUpLable} height={40}>Unlocking Mode </Typography> */}
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>App User Unlock Mode</Typography> 
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Watermark URL </Typography>
     
      
      {/* <Typography  sx={mainThemeStyle.popUpLable} height={40}>Refresh Rate </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Setting Refresh Rate</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Screen Layout </Typography> */}
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Office Button</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}> Directory Button </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}> Door PIN Button </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}> Dilevery PIN Button </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Require GPS Proximty to Launch</Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <TextField
                        id="wel_message_header"
                        height={40}
                        type= 'text'
                        name="wel_message_header"
                        value={dataOfLocation.wel_message_header}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                      disabled = {getDisableValue("85")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-location"
                        height={40}
                        type= 'color'
                        name="wel_message_headercolor"
                        value={dataOfLocation.wel_message_headercolor}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                       
                      }
                      disabled = {getDisableValue("86")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                              marginTop:'15px',
                                color:'rgba(255, 255, 255, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                     <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            }
            }}
            name = "wel_message_headerlogo"
            value={getDisplayedLogo(dataOfLocation.wel_message_headerlogo)}
          label="Age"
          disabled = {getDisableValue("87")}
          onChange={handleSetDisplayLogo}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {DisplayedICon.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
      <TextField
                        id="input-welcome"
                        height={40}
                        type= 'text'
                        name="wel_message"
                        value={dataOfLocation.wel_message}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("88")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                  
                    <TextField
                        id="input-property-name"
                        height={40}
                        type= 'text'
                        name="loc_property_name"
                        value={dataOfLocation.loc_property_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                      disabled = {getDisableValue("54")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-office-name"
                        height={40}
                        type= 'text'
                        name="loc_office_name"
                        value={dataOfLocation.loc_office_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("55")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-address-one"
                        height={40}
                        type= 'text'
                        name="loc_add_one"
                        value={dataOfLocation.loc_add_one}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("56")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-address-two"
                        height={40}
                        type= 'text'
                        name="loc_add_two"
                        value={dataOfLocation.loc_add_two}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        disabled = {getDisableValue("57")}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                       <TextField
                        id="input-sms-alert"
                        height={40}
                        type= 'text'
                        name="warning_phone"
                        value={dataOfLocation.warning_phone}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                      disabled = {getDisableValue("58")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                     <TextField
                        id="input-panel-telephone"
                        height={40}
                        type= 'text'
                        name="loc_telephone_dial"
                        value={dataOfLocation.loc_telephone_dial}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        disabled = {getDisableValue("59")}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                     
           <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "app_unlock_mode"
            
            value={GetAppUnlockModeCBW()}
          label="Age"
          onChange={handleSetAppUnlockModeCBW}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {AppUserUnlockModeCBW.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select> 
          
      
      <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="loc_url_watermark"
                        value={dataOfLocation.loc_url_watermark}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                       
                      }
                      disabled = {getDisableValue("62")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                              
                               
                                
                            },
                        }}
                       
                    />    {/* <TextField
                        id="input-location"
                        height={30}
                        type= 'text'
                        name="wel_message_headerlogo"
                        value={dataOfLocation.wel_message_headerlogo}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                       
                      }
                      
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                              marginTop:'15px',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                              
                               
                                
                            },
                        }}
                       
                    /> */}
                    
          <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_off" onChange={handlecheckboxCheckReverse} disabled = {getDisableValue("66")} checked={dataOfLocation.dis_off == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_dir" onChange={handlecheckboxCheckReverse} disabled = {getDisableValue("67")} checked={dataOfLocation.dis_dir == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_doorpin" onChange={handlecheckboxCheckReverse} disabled = {getDisableValue("67")} checked={dataOfLocation.dis_doorpin == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_del" onChange={handlecheckboxCheckReverse} disabled = {getDisableValue("69")} checked={dataOfLocation.dis_del == "0" ? true : false } />}
        label=""
      />
     <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_prox" onChange={handlecheckboxCheckReverse} disabled = {getDisableValue("89")} checked={dataOfLocation.dis_prox == "0" ? true : false } />}
        label=""
      />
      </Stack>
      </Grid>
     
      
     
      
      </Grid></Box>

</>)}
{(currentTab == 2 && dataOfLocation.cam_brand == "ResidentLink" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Relay Settings
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>PIN Schedule Time</Typography>
        {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Enable External Wiegand Input</Typography>}
        {dataOfLocation.unlocking_mode == '2' && dataOfLocation.enable_exe_weigand == "1" &&
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>RFID/Wiegand Schedule Time</Typography>}

      {dataOfLocation.unlocking_mode == '3'  &&
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay Failover</Typography>}

    {/*   <Typography  sx={mainThemeStyle.popUpLable} height={40}>Unlocking Mode </Typography> */}
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Resident PIN </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Guest PIN </Typography>
     {/*   <Typography  sx={mainThemeStyle.popUpLable} height={40}>App User Unlock Mode</Typography> */}
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
             
<Stack direction="row" spacing={2} height={40}>

<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 
 marginTop:'4px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "pin_start_time"
 disabled = {getDisableValue("70")}
 value={dataOfLocation.pin_start_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select>
<Typography sx={mainThemeStyle.popUpLableAnswer} paddingTop="7px"> To </Typography>
<Box>
<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'5px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "pin_end_time"
 disabled = {getDisableValue("70")}
 value={dataOfLocation.pin_end_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select></Box>
</Stack>
{dataOfLocation.unlocking_mode == '2' &&     <FormControlLabel  sx={mainThemeStyle.popUpLable} disabled = {getDisableValue("71")}
        control={<IOSSwitch sx={{ m: 1 }} name="enable_exe_weigand" onChange={handlecheckboxCheck} checked={dataOfLocation.enable_exe_weigand == "1" ? true : false } />}
        label={dataOfLocation.enable_exe_weigand == "1" ? "On" : "Off"}
      />}

{dataOfLocation.unlocking_mode == '3' &&     <FormControlLabel  sx={mainThemeStyle.popUpLable} disabled = {getDisableValue("71")}
        control={<IOSSwitch sx={{ m: 1 }} name="relay_fail_over" onChange={handlecheckboxCheck} checked={dataOfLocation.relay_fail_over == "1" ? true : false } />}
        label={dataOfLocation.relay_fail_over == "1" ? "On" : "Off"}
      />}

      {dataOfLocation.unlocking_mode == '2' && dataOfLocation.enable_exe_weigand == "1" &&
<Stack direction="row" spacing={2} height={40}>

<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'4px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "card_start_time"
 disabled = {getDisableValue("71")}
 value={dataOfLocation.card_start_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select>
<Typography sx={mainThemeStyle.popUpLableAnswer} paddingTop="7px"> To </Typography>
<Box>
<Select

 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'4px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "card_end_time"
 disabled = {getDisableValue("71")}
 value={dataOfLocation.card_end_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select></Box>
</Stack>}
           {/*  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "unlocking_mode"
            
            value={GetUnlockingMode()}
          label="Age"
          onChange={handleSetUnlockingMode}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {UnlcokingMode.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select> */}
          <FormControlLabel  sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="r_pin_off" onChange={handlecheckboxCheckReverse}  disabled = {getDisableValue("72")} checked={dataOfLocation.r_pin_off == "0" ? true : false} />}
        label={dataOfLocation.r_pin_off == "0" ? "Enabled" : "Disabled"}
      />
      <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="g_pin_off" onChange={handlecheckboxCheckReverse}  disabled = {getDisableValue("73")}  checked={dataOfLocation.g_pin_off == "0" ? true : false} />}
        label={dataOfLocation.g_pin_off == "0" ? "Enabled" : "Disabled"}
      />
           {/*  <Checkbox sx={mainThemeStyle.formCheckBox} name="r_pin_off" onChange={handlecheckboxCheckReverse} checked={dataOfLocation.r_pin_off == "0" ? true : false}/>
                    <Checkbox sx={mainThemeStyle.formCheckBox} name="g_pin_off" onChange={handlecheckboxCheckReverse} checked={dataOfLocation.g_pin_off == "0" ? true : false}/>
              */}       
              
      {/* {dataOfLocation.unlocking_mode == '2' && <Checkbox sx={mainThemeStyle.formCheckBox}  name="enable_exe_weigand" onChange={handlecheckboxCheck} checked={dataOfLocation.enable_exe_weigand == "1" ? true : false}/>}
       */}
          {/* <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "app_unlock_mode"
            
            value={GetAppUnlockMode()}
          label="Age"
          onChange={handleSetAppUnlockMode}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {AppUserUnlockMode.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select> */}
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      
    
      
      
     
           {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>ResidentVue Relay 1 Friendly Name</Typography>}
      {dataOfLocation.unlocking_mode == '2' &&  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Default {dataOfLocation.relay_one_fname != ""?dataOfLocation.relay_one_fname:' Relay 1 '} State</Typography>}
      {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={43}>{ (dataOfLocation.relay_one_fname).length > 0 ? dataOfLocation.relay_one_fname + " Unlock Duration (Enter Between 0 to 99 Seconds)" : "Relay 1 Unlock Duration (Enter Between 0 to 99 Seconds)"}</Typography>}
      
      {dataOfLocation.unlocking_mode == '3' &&  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Default Failover Relay 1 State</Typography>}
      {dataOfLocation.unlocking_mode == '3' && <Typography  sx={mainThemeStyle.popUpLable} height={43}> Failover Relay 1 Unlock Duration (Enter Between 0 to 99 Seconds)</Typography>}
      
      {(dataOfLocation.unlocking_mode == '2' ||   dataOfLocation.unlocking_mode == '3') && <> <Typography></Typography>
      <Divider sx={{backgroundColor:'white'}} />
      <Typography></Typography> </>}
      {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>ResidentVue Relay 2 Friendly Name</Typography>}
      {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Default {dataOfLocation.relay_one_fname != ""?dataOfLocation.relay_two_fname:' Relay 2 '} State</Typography>}
     
        {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ (dataOfLocation.relay_two_fname).length > 0 ? dataOfLocation.relay_two_fname + " Unlock Duration (Enter Between 0 to 99 Seconds)" : "Relay 2 Unlock Duration (Enter Between 0 to 99 Seconds)"}</Typography>}
      
        {dataOfLocation.unlocking_mode == '3' &&  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Default Failover Relay 2 State</Typography>}
      {dataOfLocation.unlocking_mode == '3' && <Typography  sx={mainThemeStyle.popUpLable} height={43}> Failover Relay 2 Unlock Duration (Enter Between 0 to 99 Seconds)</Typography>}
      {dataOfLocation.unlocking_mode == '4' &&<Typography  sx={mainThemeStyle.popUpLable} height={35}>PDK Primary Door ID</Typography>}
       {dataOfLocation.unlocking_mode == '4' && <Typography  sx={mainThemeStyle.popUpLable} height={35}>PDK Secondary Door ID</Typography>}

      </Stack>
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
         
      
       {/* <TimezoneSelect
          value={selectedTimezone}
          onChange={setSelectedTimezone}
          sx={{ color: 'rgba(135, 135, 135, 0.5)',
          height:'34px',
          borderRadius: '5px',
          marginTop:'3px',
          marginBottom:'3px',
          backgroundColor: '#121212',
          boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
          padding: '5px 0 5px 0',
          textIndent: '15px !important',}}
        />  */} 
                
               
         
          {dataOfLocation.unlocking_mode == '2' && <TextField
                        id="input-vegax-relay1"
                        height={40}
                        type= 'text'
                        name="relay_one_fname"
                        value={dataOfLocation.relay_one_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                      disabled = {getDisableValue("74")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />}
                    {(dataOfLocation.unlocking_mode == '2' ||   dataOfLocation.unlocking_mode == '3')  && <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="default_vega_state"  disabled = {getDisableValue("75")} onChange={handlecheckboxCheck}  checked={dataOfLocation.default_vega_state == "1" ? true : false} />}
        label={dataOfLocation.default_vega_state == "1" ? "Normally Open" : "Normally Closed"}
      />}
       {(dataOfLocation.unlocking_mode == '2' ||   dataOfLocation.unlocking_mode == '3')  &&   <TextField
                        id="input-vegax-duration"
                        height={40}
                        type= 'number'
                        name="vegax_duration"
                        value={dataOfLocation.vegax_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("76")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />}
                    {(dataOfLocation.unlocking_mode == '2' ||   dataOfLocation.unlocking_mode == '3') && <>
                    <Typography></Typography>
                    <Divider sx={{backgroundColor:'white'}} /><Typography></Typography></>}
                  {dataOfLocation.unlocking_mode == '2' &&   <TextField
                        id="input-vegax-relay2"
                        height={40}
                        type= 'text'
                        name="relay_two_fname"
                        value={dataOfLocation.relay_two_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("77")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />}
                     {(dataOfLocation.unlocking_mode == '2' ||   dataOfLocation.unlocking_mode == '3')  &&     <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="default_vega_state_2"  disabled = {getDisableValue("78")} onChange={handlecheckboxCheck} checked={dataOfLocation.default_vega_state_2 == "1" ? true : false } />}
        label={dataOfLocation.default_vega_state_2 == "1" ? "Normally Open" : "Normally Closed"}
      />}
                 
                     {(dataOfLocation.unlocking_mode == '2' ||   dataOfLocation.unlocking_mode == '3')  &&<TextField
                        id="input-vegax2-duration"
                        height={40}
                        type= 'number'
                        name="vegax_second_duration"
                        value={dataOfLocation.vegax_second_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("79")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />}

{dataOfLocation.unlocking_mode == '4' &&<Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled = {getDisableValue("236")}
            name = 'primeid'
            value={getPDKValueMain()}
          onChange={handleChangePDKMainValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {pdkDeviceArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                    <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>}
          {dataOfLocation.unlocking_mode == '4' &&<Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled = {getDisableValue("236")}
            name = 'brivoprimeid'
            value={getPDKValueOptional()}
          onChange={handleChangePDKOptionalValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {pdkDeviceArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                    <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>}
      </Stack>
      </Grid>
     
      
      </Grid>
      </Box>
      <Box sx={{display: { xs: "flex", md: "none" }}}>
      <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
        <Typography  sx={mainThemeStyle.popUpLable} height={120}>PIN Time</Typography>
        <Typography  sx={mainThemeStyle.popUpLable} height={120}>RFID/Wiegand Time</Typography>
    {/*   <Typography  sx={mainThemeStyle.popUpLable} height={40}>Unlocking Mode </Typography> */}
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Resident PIN </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Guest PIN </Typography>
     {/*   <Typography  sx={mainThemeStyle.popUpLable} height={40}>App User Unlock Mode</Typography> */}
     {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>External Wiegand</Typography>}
    
     {dataOfLocation.unlocking_mode == '3'  &&
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay Failover</Typography>}
        
   {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}> Relay 1  Name</Typography>}
   {dataOfLocation.unlocking_mode == '2' &&  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Default {dataOfLocation.relay_one_fname != ""?dataOfLocation.relay_one_fname:' Relay 1 '} State</Typography>}
   {dataOfLocation.unlocking_mode == '3' &&  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Default Failover Relay 1 State</Typography>}
     
   {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}> Relay 2  Name</Typography>}
   {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Default {dataOfLocation.relay_one_fname != ""?dataOfLocation.relay_two_fname:' Relay 2 '} State</Typography>}
   {dataOfLocation.unlocking_mode == '3' &&  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Default Failover Relay 2 State</Typography>}
   
   {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ (dataOfLocation.relay_one_fname).length > 0 ? dataOfLocation.relay_one_fname + " Unlock Duration" : "Relay 1 Unlock Duration"}</Typography>}
   {dataOfLocation.unlocking_mode == '3' && <Typography  sx={mainThemeStyle.popUpLable} height={43}> Failover Relay 1 Unlock Duration (Enter Between 0 to 99 Seconds)</Typography>}
       
   {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ (dataOfLocation.relay_two_fname).length > 0 ? dataOfLocation.relay_two_fname + " Unlock Duration" : "Relay 2 Unlock Duration"}</Typography>}
      {dataOfLocation.unlocking_mode == '3' && <Typography  sx={mainThemeStyle.popUpLable} height={43}> Failover Relay 2 Unlock Duration (Enter Between 0 to 99 Seconds)</Typography>}
      {dataOfLocation.unlocking_mode == '4' &&<Typography  sx={mainThemeStyle.popUpLable} height={35}>PDK Primary Door ID</Typography>}
       {dataOfLocation.unlocking_mode == '4' && <Typography  sx={mainThemeStyle.popUpLable} height={35}>PDK Secondary Door ID</Typography>}

      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
             
<Stack direction="column" spacing={2} height={120}>

<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 
 marginTop:'4px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "pin_start_time"
 disabled = {getDisableValue("70")}
 value={dataOfLocation.pin_start_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select>
<Typography sx={mainThemeStyle.popUpLableAnswer} paddingTop="7px"> To </Typography>
<Box>
<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'-10px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "pin_end_time"
 disabled = {getDisableValue("70")}
 value={dataOfLocation.pin_end_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select></Box>
</Stack>
<Stack direction="column" spacing={2} height={120}>

<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'4px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "card_start_time"
 disabled = {getDisableValue("70")}
 value={dataOfLocation.card_start_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select>
<Typography sx={mainThemeStyle.popUpLableAnswer} paddingTop="7px"> To </Typography>
<Box>
<Select

 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'-10px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "card_end_time"
 disabled = {getDisableValue("70")}
 value={dataOfLocation.card_end_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select></Box>
</Stack>
          
          <FormControlLabel  sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="r_pin_off" onChange={handlecheckboxCheckReverse}  disabled = {getDisableValue("72")}  checked={dataOfLocation.r_pin_off == "0" ? true : false} />}
        label={dataOfLocation.r_pin_off == "0" ? "Enabled" : "Disabled"}
      />
      <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="g_pin_off" onChange={handlecheckboxCheckReverse}  disabled = {getDisableValue("73")}  checked={dataOfLocation.g_pin_off == "0" ? true : false} />}
        label={dataOfLocation.g_pin_off == "0" ? "Enabled" : "Disabled"}
      />

           {dataOfLocation.unlocking_mode == '2' &&     <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="enable_exe_weigand" onChange={handlecheckboxCheck}  disabled = {getDisableValue("71")} checked={dataOfLocation.enable_exe_weigand == "1" ? true : false } />}
        label={dataOfLocation.enable_exe_weigand == "1" ? "On" : "Off"}
      />}
       
                 {dataOfLocation.unlocking_mode == '3' &&     <FormControlLabel  sx={mainThemeStyle.popUpLable} disabled = {getDisableValue("71")}
        control={<IOSSwitch sx={{ m: 1 }} name="relay_fail_over" onChange={handlecheckboxCheck} checked={dataOfLocation.relay_fail_over == "1" ? true : false } />}
        label={dataOfLocation.relay_fail_over == "1" ? "On" : "Off"}
      />}

        
          {dataOfLocation.unlocking_mode == '2' && <TextField
                        id="input-vegax-relay1"
                        height={40}
                        type= 'text'
                        name="relay_one_fname"
                        value={dataOfLocation.relay_one_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("74")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />}
                    {(dataOfLocation.unlocking_mode == '2' ||   dataOfLocation.unlocking_mode == '3') &&      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="default_vega_state"  disabled = {getDisableValue("75")} onChange={handlecheckboxCheck}  checked={dataOfLocation.default_vega_state == "1" ? true : false} />}
        label={dataOfLocation.default_vega_state == "1" ? "Normally Open" : "Normally Closed"}
      />}
                  {dataOfLocation.unlocking_mode == '2'  &&   <TextField
                        id="input-vegax-relay2"
                        height={40}
                        type= 'text'
                        name="relay_two_fname"
                        value={dataOfLocation.relay_two_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("77")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />}
                     {(dataOfLocation.unlocking_mode == '2' ||   dataOfLocation.unlocking_mode == '3') &&     <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="default_vega_state_2"  disabled = {getDisableValue("78")} onChange={handlecheckboxCheck} checked={dataOfLocation.default_vega_state_2 == "1" ? true : false } />}
        label={dataOfLocation.default_vega_state_2 == "1" ? "Normally Open" : "Normally Closed"}
      />}
      
                  {(dataOfLocation.unlocking_mode == '2' ||   dataOfLocation.unlocking_mode == '3') &&   <TextField
                        id="input-vegax-duration"
                        height={40}
                        type= 'number'
                        name="vegax_duration"
                        value={dataOfLocation.vegax_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("76")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />}
                     {(dataOfLocation.unlocking_mode == '2' ||   dataOfLocation.unlocking_mode == '3') &&<TextField
                        id="input-vegax2-duration"
                        height={40}
                        type= 'number'
                        name="vegax_second_duration"
                        value={dataOfLocation.vegax_second_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("79")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />}
                      {dataOfLocation.unlocking_mode == '4' &&<Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled = {getDisableValue("236")}
            name = 'primeid'
            value={getPDKValueMain()}
          onChange={handleChangePDKMainValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {pdkDeviceArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                    <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>}
          {dataOfLocation.unlocking_mode == '4' &&<Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled = {getDisableValue("236")}
            name = 'brivoprimeid'
            value={getPDKValueOptional()}
          onChange={handleChangePDKOptionalValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {pdkDeviceArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                    <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>}
      </Stack>
      </Grid>
      
      
      
     
      
      </Grid>
      </Box>
</>)}

{(currentTab == 2  &&  dataOfLocation.cam_brand == "Virtual Panel" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Relay Settings
                   </Typography>
                   
               </div>
              
           </div>  <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "block" }}}>
          
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      
     {/* <Typography  sx={mainThemeStyle.popUpLable} height={40}>PIN Schedule Time</Typography>
     {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Enable External Wiegand Input</Typography>}
        {dataOfLocation.unlocking_mode == '2' && dataOfLocation.enable_exe_weigand == "1" &&
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>RFID/Wiegand Schedule Time</Typography>} */}
         <Typography  sx={mainThemeStyle.popUpLable} height={40}>Unlocking Mode </Typography>
       {dataOfLocation.unlocking_mode != '1'&& dataOfLocation.unlocking_mode != '4' && <Typography sx={mainThemeStyle.popUpLable} height={40}> Relay Hostname </Typography> }
      
       {dataOfLocation.unlocking_mode != '1' && dataOfLocation.unlocking_mode != '4' && <Typography  sx={mainThemeStyle.popUpLable} height={40}># of System Relays</Typography>}
      {dataOfLocation.unlocking_mode != '1' && dataOfLocation.unlocking_mode != '4' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Primary Relay Duration</Typography>}
      {dataOfLocation.unlocking_mode != '1' && dataOfLocation.unlocking_mode != '4' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Floor Relay Duration</Typography>}
       
       {dataOfLocation.unlocking_mode != '1' && dataOfLocation.unlocking_mode != '4' && <Typography sx={mainThemeStyle.popUpLable} height={40}> Resident PIN </Typography> }	
       {dataOfLocation.unlocking_mode != '1' && dataOfLocation.unlocking_mode != '4' && <Typography sx={mainThemeStyle.popUpLable} height={40}> Guest PIN </Typography>}	
       {dataOfLocation.unlocking_mode == '1' &&<Typography  sx={mainThemeStyle.popUpLable} height={40}>Brivo Primary Door Id</Typography>}	
       {dataOfLocation.unlocking_mode == '1' && <Typography  sx={mainThemeStyle.popUpLable} height={40}># of Brivo Floors</Typography>}
       {dataOfLocation.unlocking_mode == '1' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>In-Call Relay Triggers</Typography>}
     
       {dataOfLocation.unlocking_mode == '4' &&<Typography  sx={mainThemeStyle.popUpLable} height={35}>PDK Primary Door ID</Typography>}
       {dataOfLocation.unlocking_mode == '4' && <Typography  sx={mainThemeStyle.popUpLable} height={35}>PDK Secondary Door ID</Typography>}
   
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
           
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "unlocking_mode"
            disabled = {getDisableValue("60")}
            value={GetUnlockingModeQR()}
          label="Age"
          onChange={handleSetUnlockingModeQR}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {UnlockingModeQR.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          {dataOfLocation.unlocking_mode != '1'  && dataOfLocation.unlocking_mode != '4' && <TextField
                        id="input-relay-host"
                        height={80}
                        type= 'text'
                        name="realy_hostname"
                        value={dataOfLocation.realy_hostname}
                        onChange={handleInputChange}
                        placeholder='api.controlbyweb.cloud/DAT/api-key'
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("237")}
                      variant="filled"
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />}
                    {dataOfLocation.unlocking_mode != '1' && dataOfLocation.unlocking_mode != '4' && <TextField
        id="total-relays"
        type="number"
        value={dataOfLocation.elevator_access.totalrelays}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled={getDisableValue("82")}
                      name="totalrelays"
                      onChange={handleChangeEleValue}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 66, min: 0 
                        },
                            sx: {
                              
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />}
      {dataOfLocation.unlocking_mode != '1' && dataOfLocation.unlocking_mode != '4' && <TextField
                        id="input-vegax-duration"
                        height={40}
                        type= 'number'
                        name="vegax_duration"
                        value={dataOfLocation.vegax_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("76")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />}
      {dataOfLocation.unlocking_mode != '1' && dataOfLocation.unlocking_mode != '4' && <TextField
                        id="input-vegax2-duration"
                        height={40}
                        type= 'number'
                        name="vegax_second_duration"
                        value={dataOfLocation.vegax_second_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("79")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />}
           {dataOfLocation.unlocking_mode != '1' && dataOfLocation.unlocking_mode != '4' &&    <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}	
        control={<IOSSwitch sx={{ m: 1 }} name="r_pin_off" disabled = {getDisableValue("72")} onChange={handlecheckboxCheckReverse}  checked={dataOfLocation.r_pin_off == "0" ? true : false} />}	
        label={dataOfLocation.r_pin_off == "0" ? "Enabled" : "Disabled"}	
      /> }	
     {dataOfLocation.unlocking_mode != '1' && dataOfLocation.unlocking_mode != '4' && <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}	
        control={<IOSSwitch sx={{ m: 1 }} name="g_pin_off" disabled = {getDisableValue("73")} onChange={handlecheckboxCheckReverse}  checked={dataOfLocation.g_pin_off == "0" ? true : false} />}	
        label={dataOfLocation.g_pin_off == "0" ? "Enabled" : "Disabled"}	
      /> }	
    {dataOfLocation.unlocking_mode == '1' &&<Select	
            sx={{ color: 'rgba(135, 135, 135, 0.5)',	
            height:'33px',	
            marginTop:'4px',	
            marginBottom:'3px',	
            borderRadius: '5px',	
            backgroundColor: '#121212',	
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',	
            padding: '5px 0 5px 0',	
            "& .MuiSvgIcon-root": {	
              color: "rgba(135, 135, 135, 0.5)",	
            },	
            	
            '&.Mui-disabled': {	
             	
              background: 'rgba(135, 135, 135, 0.5)',	
            },	
            "& input.Mui-disabled": {	
              color: "white"	
            }	
            }}	
            name = 'brivoprimeid'	
            disabled = {getDisableValue("84")}
            value={getBrivoValueMain()}	
          onChange={handleChangeBrivoDoorValue}	
          variant="filled"	
          hiddenLabel	
          MenuProps={MenuProps}>	
            {brivoDoorArray?.map(menuItem => {	
                  	
                  return (	
                    <MenuItem  sx={	
                      {	
                      	
                      color:"#fff",	
                      backgroundColor: "#2F323A",	
                      borderBottom: '.5px solid grey',	
                      fontFamily: "Lato",fontSize:'12px',	
                  }}	
                  disabled={menuItem.disabled}	
                   value={menuItem.name}>	
                    <Stack direction="row" justifyContent="left"	
  alignItems="center">	
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
            <DoNotDisturbIcon fontSize="small" />	
          </ListItemIcon>} 	
            {menuItem.name}</Stack>	
                    </MenuItem>	
                  );	
                })}	
          </Select>}	
  {/*    {dataOfLocation.unlocking_mode == '1' &&	
     <Typography	
        height={40}	
        	
        sx={mainThemeStyle.popUpLableAnswer}	
                       	
        >{dataOfLocation.brivo_access.brivofloors}</Typography>} */}	
{dataOfLocation.unlocking_mode == '1' &&<TextField	
                        id="brivofloors"	
                        height={40}	
                        type= 'number'	
                        name="brivofloors"	
                        value={dataOfLocation.brivo_access.brivofloors}	
                        onChange={handleChangeBrivoFloorValue}	
                        sx={mainThemeStyle.InputMenuItem}	
                        InputLabelProps={{ shrink: true }	
                      }	
                      disabled = {getDisableValue("84")}
                      variant="filled"	
                        InputProps={{	
                          inputProps: { 	
                            max:(dataOfLocation.brivo_array.length), min: 0 	
                        },	
                            sx: {	
                                	
                                color:'rgba(135, 135, 135, 0.5)',	
                                height:'33px',	
                                borderRadius: '5px',	
                                backgroundColor: '#121212',	
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',	
                                padding: '0 0 15px 0',	
                                textIndent: '15px !important',	
                              	'&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               	
                                	
                            },	
                        }}	
                       	
                    />}

{dataOfLocation.unlocking_mode == '1' && <FormControlLabel   sx={mainThemeStyle.popUpLableNewone} height={40}
        control={<IOSSwitch sx={{ m: 1 }} name="icrt"  disabled = {getDisableValue("84")} onChange={handleBrivoInCallRelayTriggerCheck}  checked={dataOfLocation.brivo_access.icrt == null ? false : (dataOfLocation.brivo_access.icrt == "1" ? true : false)} />}
        label={dataOfLocation.brivo_access.icrt == null ? "Disabled" : (dataOfLocation.brivo_access.icrt == "1" ? "Enabled" : "Disabled")}
      /> }
     

     {dataOfLocation.unlocking_mode == '4' &&<Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled = {getDisableValue("236")}
            name = 'primeid'
            value={getPDKValueMain()}
          onChange={handleChangePDKMainValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {pdkDeviceArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                    <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>}
          {dataOfLocation.unlocking_mode == '4' &&<Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled = {getDisableValue("236")}
            name = 'brivoprimeid'
            value={getPDKValueOptional()}
          onChange={handleChangePDKOptionalValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {pdkDeviceArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                    <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>}
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
       {/*  {dataOfLocation.unlocking_mode != '1' && <>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 1 Friendly Name</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ (dataOfLocation.relay_one_fname).length > 0 ? dataOfLocation.relay_one_fname + " Unlock Duration (Enter Between 0 to 99 Seconds)" : "Relay 1 Unlock Duration (Enter Between 0 to 99 Seconds)"}</Typography>
      <Typography></Typography>
      <Divider sx={{backgroundColor:'white'}} />
      <Typography></Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2 Friendly Name</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ (dataOfLocation.relay_two_fname).length > 0 ? dataOfLocation.relay_two_fname + " Unlock Duration (Enter Between 0 to 99 Seconds)" : "Relay 2 Unlock Duration (Enter Between 0 to 99 Seconds)"}</Typography></>} */}
      {dataOfLocation.unlocking_mode != '1'  && dataOfLocation.unlocking_mode != '4' && <>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Primary Relay</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Floor Relay</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Floor Relay for App Unlock</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Primary Relay Friendly Name</Typography>
      </>}
      {(dataOfLocation.unlocking_mode == '1' || dataOfLocation.unlocking_mode == '4')  && <Typography sx={mainThemeStyle.popUpLable} height={40}> Resident PIN </Typography> }	
       {(dataOfLocation.unlocking_mode == '1' || dataOfLocation.unlocking_mode == '4')  && <Typography sx={mainThemeStyle.popUpLable} height={40}> Guest PIN </Typography>}
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        {dataOfLocation.unlocking_mode != '1'  && dataOfLocation.unlocking_mode != '4' && <>
        <FormControlLabel   sx={{ marginLeft: '0px',  height:'2.18rem',
            color:'#fff',}}
        control={<IOSSwitch sx={{ m: 1 }} name="diselevator"  disabled={getDisableValue("82")} onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselevator == "1" ? true : false} />}
        label=""
      />
       <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="disrelease" disabled={getDisableValue("82")} onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.disrelease == "1" ? true : false} />}
        label=""
      />
       <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="diselapp" disabled={getDisableValue("82")} onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselapp == "1" ? true : false} />}
        label=""
      />
       <TextField
                        id="input-vegax-relay1"
                        height={40}
                        type= 'text'
                        name="relay_one_fname"
                        value={dataOfLocation.relay_one_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("74")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
      </>}
        {/* {dataOfLocation.unlocking_mode != '1' && <>
            <TextField
                        id="input-vegax-relay1"
                        height={40}
                        type= 'text'
                        name="relay_one_fname"
                        value={dataOfLocation.relay_one_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("74")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
         <TextField
                        id="input-vegax-duration"
                        height={40}
                        type= 'number'
                        name="vegax_duration"
                        value={dataOfLocation.vegax_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("76")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <Typography></Typography>
                    <Divider sx={{backgroundColor:'white'}} />
                    <Typography></Typography>
                 <TextField
                        id="input-vegax-relay2"
                        height={40}
                        type= 'text'
                        name="relay_two_fname"
                        value={dataOfLocation.relay_two_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("77")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                     
                 
                    <TextField
                        id="input-vegax2-duration"
                        height={40}
                        type= 'number'
                        name="vegax_second_duration"
                        value={dataOfLocation.vegax_second_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("79")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    /></>} */}
                     {/*dataOfLocation.unlocking_mode == '1' && <>
                     <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = 'brivoprimeid'
            value={getBrivoValueMain()}
          onChange={handleChangeBrivoDoorValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
         
                   
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "0"
            value={getBrivoValue(0)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
                     
                 
              </>*/}
               {(dataOfLocation.unlocking_mode == '1' || dataOfLocation.unlocking_mode == '4')  &&   <FormControlLabel   sx={{ marginLeft: '0px',  height:'2.18rem',	
            color:'#fff',}}	
        control={<IOSSwitch sx={{ m: 1 }} name="r_pin_off" disabled = {getDisableValue("72")}  onChange={handlecheckboxCheckReverse}  checked={dataOfLocation.r_pin_off == "0" ? true : false} />}	
        label={dataOfLocation.r_pin_off == "0" ? "Enabled" : "Disabled"}	
      /> }	
     {(dataOfLocation.unlocking_mode == '1' || dataOfLocation.unlocking_mode == '4')  && <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}	
        control={<IOSSwitch sx={{ m: 1 }} name="g_pin_off" disabled = {getDisableValue("73")} onChange={handlecheckboxCheckReverse}  checked={dataOfLocation.g_pin_off == "0" ? true : false} />}	
        label={dataOfLocation.g_pin_off == "0" ? "Enabled" : "Disabled"}	
      /> }
      </Stack>
      </Grid>
     
     
      
      </Grid>
      {dataOfLocation.unlocking_mode == '1'  && dataOfLocation.brivo_access.icrt == 1 && <Grid container spacing={2} padding= '32px'>
     
      <Grid item xs={5.5}>
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 350 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>In-Call Relay Trigger Settings</TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          </TableHead>
        <TableBody>
       
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell  sx={mainThemeStyle.cellHeader}>
              Custom Name 1 
              </TableCell>
             
                <TableCell align="right">
                <TextField
                        id="0"
                       
                        type= 'text'
                        name="0"
                        value={getCustomNameValue(0)}
                        onChange={handleChangeCustomNameValue}
                       
                        sx={{
                          ...mainThemeStyle.InputMenuItem,
                          marginTop:'10px',
                        }}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("84")}
                      variant="filled"
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    
                </TableCell>
                <TableCell align="right">
                
                <Select
              sx={{ color: 'rgba(135, 135, 135, 0.5)',
              height:'33px',
              marginTop:'8px',
              marginBottom:'3px',
              borderRadius: '5px',
              backgroundColor: '#121212',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              "& .MuiSvgIcon-root": {
                color: "rgba(135, 135, 135, 0.5)",
              },
              
              '&.Mui-disabled': {
               
  
                background: 'rgba(135, 135, 135, 0.5)',
              },
              "& input.Mui-disabled": {
                color: "white"
              }
              }}
              name = "0"
              value={getCustomDoorValue(0)}
            onChange={handleChangeCustomDoorValue}
            variant="filled"
            disabled={getDisableValue("84")}
            hiddenLabel
            MenuProps={MenuProps}>
              {brivoCustomArray?.map(menuItem => {
                    
                    return (
                      <MenuItem  sx={
                        {
                        
                        color:"#fff",
                        backgroundColor: "#2F323A",
                        borderBottom: '.5px solid grey',
                        fontFamily: "Lato",fontSize:'12px',
                    }}
                    disabled={menuItem.disabled}
                     value={menuItem.name}>
                         <Stack direction="row" justifyContent="left"
    alignItems="center">
                    { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
              <DoNotDisturbIcon fontSize="small" />
            </ListItemIcon>} 
              {menuItem.name}</Stack>
                      </MenuItem>
                    );
                  })}
            </Select>
                  </TableCell>
            </TableRow>
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell  sx={mainThemeStyle.cellHeader}>
              Custom Name 2 
              </TableCell>
             
                <TableCell align="right">
                <TextField
                        id="1"
                       
                        type= 'text'
                        name="1"
                        value={getCustomNameValue(1)}
                        onChange={handleChangeCustomNameValue}
                       
                        sx={{
                          ...mainThemeStyle.InputMenuItem,
                          marginTop:'10px',
                        }}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("84")}
                      variant="filled"
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    
                </TableCell>
                <TableCell align="right">
                
                <Select
              sx={{ color: 'rgba(135, 135, 135, 0.5)',
              height:'33px',
              marginTop:'8px',
              marginBottom:'3px',
              borderRadius: '5px',
              backgroundColor: '#121212',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              "& .MuiSvgIcon-root": {
                color: "rgba(135, 135, 135, 0.5)",
              },
              
              '&.Mui-disabled': {
               
  
                background: 'rgba(135, 135, 135, 0.5)',
              },
              "& input.Mui-disabled": {
                color: "white"
              }
              }}
              name = "1"
              value={getCustomDoorValue(1)}
            onChange={handleChangeCustomDoorValue}
            variant="filled"
            disabled={getDisableValue("84")}
            hiddenLabel
            MenuProps={MenuProps}>
              {brivoCustomArray?.map(menuItem => {
                    
                    return (
                      <MenuItem  sx={
                        {
                        
                        color:"#fff",
                        backgroundColor: "#2F323A",
                        borderBottom: '.5px solid grey',
                        fontFamily: "Lato",fontSize:'12px',
                    }}
                    disabled={menuItem.disabled}
                     value={menuItem.name}>
                         <Stack direction="row" justifyContent="left"
    alignItems="center">
                    { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
              <DoNotDisturbIcon fontSize="small" />
            </ListItemIcon>} 
              {menuItem.name}</Stack>
                      </MenuItem>
                    );
                  })}
            </Select>
                  </TableCell>
            </TableRow>
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell  sx={mainThemeStyle.cellHeader}>
              Custom Name 3 
              </TableCell>
             
                <TableCell align="right">
                <TextField
                        id="2"
                       
                        type= 'text'
                        name="2"
                        value={getCustomNameValue(2)}
                        onChange={handleChangeCustomNameValue}
                       
                        sx={{
                          ...mainThemeStyle.InputMenuItem,
                          marginTop:'10px',
                        }}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("84")}
                      variant="filled"
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    
                </TableCell>
                <TableCell align="right">
                
                <Select
              sx={{ color: 'rgba(135, 135, 135, 0.5)',
              height:'33px',
              marginTop:'8px',
              marginBottom:'3px',
              borderRadius: '5px',
              backgroundColor: '#121212',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              "& .MuiSvgIcon-root": {
                color: "rgba(135, 135, 135, 0.5)",
              },
              
              '&.Mui-disabled': {
               
  
                background: 'rgba(135, 135, 135, 0.5)',
              },
              "& input.Mui-disabled": {
                color: "white"
              }
              }}
              name = "2"
              value={getCustomDoorValue(2)}
            onChange={handleChangeCustomDoorValue}
            variant="filled"
            disabled={getDisableValue("84")}
            hiddenLabel
            MenuProps={MenuProps}>
              {brivoCustomArray?.map(menuItem => {
                    
                    return (
                      <MenuItem  sx={
                        {
                        
                        color:"#fff",
                        backgroundColor: "#2F323A",
                        borderBottom: '.5px solid grey',
                        fontFamily: "Lato",fontSize:'12px',
                    }}
                    disabled={menuItem.disabled}
                     value={menuItem.name}>
                         <Stack direction="row" justifyContent="left"
    alignItems="center">
                    { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
              <DoNotDisturbIcon fontSize="small" />
            </ListItemIcon>} 
              {menuItem.name}</Stack>
                      </MenuItem>
                    );
                  })}
            </Select>
                  </TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer></Grid>
    </Grid>}
      {dataOfLocation.unlocking_mode == '1' && 
       <TableContainer component={Paper} sx={{	
        width:'auto',	
        height: 'auto',	
        marginLeft: '38px',	
        marginRight:'38px',	
        marginBottom:'38px',	
        backgroundColor:'#171E29',	
       	
        }}>	
      <Table sx={{ minWidth: 650 }} >	
        	
        <TableHead sx={{ borderBottom: "1.5px solid black",	
        }}>	
          <TableRow>	
            	
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>Brivo Relays</TableCell>	
            	
          </TableRow>	
         	
        </TableHead>	
        	
        <TableHead sx={{ borderBottom: "1.5px solid black",	
        }}>	
          <TableRow>	
          <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>	Door Id</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>	
          </TableRow></TableHead>	
        <TableBody>	
         {rowsElevatorControl.map((row) => (	
            <TableRow sx={{ borderBottom: "1.5px solid black",	
          }}>	
              <TableCell sx={mainThemeStyle.cellRow}>	
              {parseInt(row*8)+1}	
              </TableCell>	
              <TableCell align="right">	
                	
              <Select	
            sx={{ color: 'rgba(135, 135, 135, 0.5)',	
            height:'33px',	
            marginTop:'4px',	
            marginBottom:'3px',	
            borderRadius: '5px',	
            backgroundColor: '#121212',	
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',	
            padding: '5px 0 5px 0',	
            "& .MuiSvgIcon-root": {	
              color: "rgba(135, 135, 135, 0.5)",	
            },	
            	
            '&.Mui-disabled': {	
             	
              background: 'rgba(135, 135, 135, 0.5)',	
            },	
            "& input.Mui-disabled": {	
              color: "white"	
            }	
            }}	
           
            name = {parseInt(row*8)}	
            value={getBrivoValue(parseInt(row*8))}	
          onChange={handleChangeBrivoRelay}	
          variant="filled"	
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+1) ? true : false) :true}	
          hiddenLabel	
          MenuProps={MenuProps}>	
            {brivoDoorArray?.map(menuItem => {	
                  	
                  return (	
                    <MenuItem  sx={	
                      {	
                      	
                      color:"#fff",	
                      backgroundColor: "#2F323A",	
                      borderBottom: '.5px solid grey',	
                      fontFamily: "Lato",fontSize:'12px',	
                  }}	
                  disabled={menuItem.disabled}	
                   value={menuItem.name}>	
                       <Stack direction="row" justifyContent="left"	
  alignItems="center">	
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
            <DoNotDisturbIcon fontSize="small" />	
          </ListItemIcon>} 	
            {menuItem.name}</Stack>	
                    </MenuItem>	
                  );	
                })}	
          </Select>
          </TableCell>	
              <TableCell sx={mainThemeStyle.cellRow}>	
              {parseInt(row*8)+2}	
              </TableCell>	
              <TableCell align="right">	
              <Select	
            sx={{ color: 'rgba(135, 135, 135, 0.5)',	
            height:'33px',	
            marginTop:'4px',	
            marginBottom:'3px',	
            borderRadius: '5px',	
            backgroundColor: '#121212',	
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',	
            padding: '5px 0 5px 0',	
            "& .MuiSvgIcon-root": {	
              color: "rgba(135, 135, 135, 0.5)",	
            },	
            	
            '&.Mui-disabled': {	
             	
              background: 'rgba(135, 135, 135, 0.5)',	
            },	
            "& input.Mui-disabled": {	
              color: "white"	
            }	
            }}	
            name = {(row*8)+1}	
            value={getBrivoValue(parseInt(row*8)+1)}	
          onChange={handleChangeBrivoRelay}	
          variant="filled"	
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+2) ? true : false) :true}	
          hiddenLabel	
          MenuProps={MenuProps}>	
            {brivoDoorArray?.map(menuItem => {	
                  	
                  return (	
                    <MenuItem  sx={	
                      {	
                      	
                      color:"#fff",	
                      backgroundColor: "#2F323A",	
                      borderBottom: '.5px solid grey',	
                      fontFamily: "Lato",fontSize:'12px',	
                  }}	
                  disabled={menuItem.disabled}	
                   value={menuItem.name}>	
                       <Stack direction="row" justifyContent="left"	
  alignItems="center">	
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
            <DoNotDisturbIcon fontSize="small" />	
          </ListItemIcon>} 	
            {menuItem.name}</Stack>	
                    </MenuItem>	
                  );	
                })}	
          </Select>
          </TableCell>	
      <TableCell sx={mainThemeStyle.cellRow}>	
              {parseInt(row*8)+3}	
              </TableCell>	
              <TableCell align="right"> 	
              <Select	
            sx={{ color: 'rgba(135, 135, 135, 0.5)',	
            height:'33px',	
            marginTop:'4px',	
            marginBottom:'3px',	
            borderRadius: '5px',	
            backgroundColor: '#121212',	
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',	
            padding: '5px 0 5px 0',	
            "& .MuiSvgIcon-root": {	
              color: "rgba(135, 135, 135, 0.5)",	
            },	
            	
            '&.Mui-disabled': {	
             	
              background: 'rgba(135, 135, 135, 0.5)',	
            },	
            "& input.Mui-disabled": {	
              color: "white"	
            }	
            }}	
            name = {(row*8)+2}	
            value={getBrivoValue((row*8)+2)}	
          onChange={handleChangeBrivoRelay}	
          variant="filled"	
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+3) ? true : false) :true}	
          hiddenLabel	
          MenuProps={MenuProps}>	
            {brivoDoorArray?.map(menuItem => {	
                  	
                  return (	
                    <MenuItem  sx={	
                      {	
                      	
                      color:"#fff",	
                      backgroundColor: "#2F323A",	
                      borderBottom: '.5px solid grey',	
                      fontFamily: "Lato",fontSize:'12px',	
                  }}	
                  disabled={menuItem.disabled}	
                   value={menuItem.name}>	
                       <Stack direction="row" justifyContent="left"	
  alignItems="center">	
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
            <DoNotDisturbIcon fontSize="small" />	
          </ListItemIcon>} 	
            {menuItem.name}</Stack>	
                    </MenuItem>	
                  );	
                })}	
          </Select>
          </TableCell>	
      <TableCell sx={mainThemeStyle.cellRow}>	
              {(row*8)+4}	
              </TableCell>	
              <TableCell align="right"> 	
              <Select	
            sx={{ color: 'rgba(135, 135, 135, 0.5)',	
            height:'33px',	
            marginTop:'4px',	
            marginBottom:'3px',	
            borderRadius: '5px',	
            backgroundColor: '#121212',	
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',	
            padding: '5px 0 5px 0',	
            "& .MuiSvgIcon-root": {	
              color: "rgba(135, 135, 135, 0.5)",	
            },	
            	
            '&.Mui-disabled': {	
             	
              background: 'rgba(135, 135, 135, 0.5)',	
            },	
            "& input.Mui-disabled": {	
              color: "white"	
            }	
            }}	
            name = {(row*8)+3}	
            value={getBrivoValue((row*8)+3)}	
          onChange={handleChangeBrivoRelay}	
          variant="filled"	
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+4) ? true : false) :true}	
          hiddenLabel	
          MenuProps={MenuProps}>	
            {brivoDoorArray?.map(menuItem => {	
                  	
                  return (	
                    <MenuItem  sx={	
                      {	
                      	
                      color:"#fff",	
                      backgroundColor: "#2F323A",	
                      borderBottom: '.5px solid grey',	
                      fontFamily: "Lato",fontSize:'12px',	
                  }}	
                  disabled={menuItem.disabled}	
                   value={menuItem.name}>	
                       <Stack direction="row" justifyContent="left"	
  alignItems="center">	
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
            <DoNotDisturbIcon fontSize="small" />	
          </ListItemIcon>} 	
            {menuItem.name}</Stack>	
                    </MenuItem>	
                  );	
                })}	
          </Select>
          </TableCell>	
      <TableCell sx={mainThemeStyle.cellRow}>	
              {(row*8)+5}	
              </TableCell>	
              <TableCell align="right">	
                      <Select	
            sx={{ color: 'rgba(135, 135, 135, 0.5)',	
            height:'33px',	
            marginTop:'4px',	
            marginBottom:'3px',	
            borderRadius: '5px',	
            backgroundColor: '#121212',	
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',	
            padding: '5px 0 5px 0',	
            "& .MuiSvgIcon-root": {	
              color: "rgba(135, 135, 135, 0.5)",	
            },	
            	
            '&.Mui-disabled': {	
             	
              background: 'rgba(135, 135, 135, 0.5)',	
            },	
            "& input.Mui-disabled": {	
              color: "white"	
            }	
            }}	
            name = {(row*8)+4}	
            value={getBrivoValue((row*8)+4)}	
          onChange={handleChangeBrivoRelay}	
          variant="filled"	
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+5) ? true : false) :true}	
          hiddenLabel	
          MenuProps={MenuProps}>	
            {brivoDoorArray?.map(menuItem => {	
                  	
                  return (	
                    <MenuItem  sx={	
                      {	
                      	
                      color:"#fff",	
                      backgroundColor: "#2F323A",	
                      borderBottom: '.5px solid grey',	
                      fontFamily: "Lato",fontSize:'12px',	
                  }}	
                  disabled={menuItem.disabled}	
                   value={menuItem.name}>	
                        <Stack direction="row" justifyContent="left"	
  alignItems="center">	
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
            <DoNotDisturbIcon fontSize="small" />	
          </ListItemIcon>} 	
            {menuItem.name}</Stack>	
                    </MenuItem>	
                  );	
                })}	
          </Select> 
          </TableCell>	
      <TableCell sx={mainThemeStyle.cellRow}>	
              {(row*8)+6}	
              </TableCell>	
              <TableCell align="right"> 	
              <Select	
            sx={{ color: 'rgba(135, 135, 135, 0.5)',	
            height:'33px',	
            marginTop:'4px',	
            marginBottom:'3px',	
            borderRadius: '5px',	
            backgroundColor: '#121212',	
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',	
            padding: '5px 0 5px 0',	
            "& .MuiSvgIcon-root": {	
              color: "rgba(135, 135, 135, 0.5)",	
            },	
            	
            '&.Mui-disabled': {	
             	
              background: 'rgba(135, 135, 135, 0.5)',	
            },	
            "& input.Mui-disabled": {	
              color: "white"	
            }	
            }}	
            name = {(row*8)+5}	
            value={getBrivoValue((row*8)+5)}	
          onChange={handleChangeBrivoRelay}	
          variant="filled"	
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+6) ? true : false) :true}	
          hiddenLabel	
          MenuProps={MenuProps}>	
            {brivoDoorArray?.map(menuItem => {	
                  	
                  return (	
                    <MenuItem  sx={	
                      {	
                      	
                      color:"#fff",	
                      backgroundColor: "#2F323A",	
                      borderBottom: '.5px solid grey',	
                      fontFamily: "Lato",fontSize:'12px',	
                  }}	
                  disabled={menuItem.disabled}	
                   value={menuItem.name}>	
                        <Stack direction="row" justifyContent="left"	
  alignItems="center">	
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
            <DoNotDisturbIcon fontSize="small" />	
          </ListItemIcon>} 	
            {menuItem.name}</Stack>	
                    </MenuItem>	
                  );	
                })}	
          </Select> 
          </TableCell>	
      <TableCell sx={mainThemeStyle.cellRow}>	
              {(row*8)+7}	
              </TableCell>	
              <TableCell align="right"> 	
              <Select	
            sx={{ color: 'rgba(135, 135, 135, 0.5)',	
            height:'33px',	
            marginTop:'4px',	
            marginBottom:'3px',	
            borderRadius: '5px',	
            backgroundColor: '#121212',	
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',	
            padding: '5px 0 5px 0',	
            "& .MuiSvgIcon-root": {	
              color: "rgba(135, 135, 135, 0.5)",	
            },	
            	
            '&.Mui-disabled': {	
             	
              background: 'rgba(135, 135, 135, 0.5)',	
            },	
            "& input.Mui-disabled": {	
              color: "white"	
            }	
            }}	
            name = {(row*8)+6}	
            value={getBrivoValue((row*8)+6)}	
          onChange={handleChangeBrivoRelay}	
          variant="filled"	
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+7) ? true : false) :true}	
          hiddenLabel	
          MenuProps={MenuProps}>	
            {brivoDoorArray?.map(menuItem => {	
                  	
                  return (	
                    <MenuItem  sx={	
                      {	
                      	
                      color:"#fff",	
                      backgroundColor: "#2F323A",	
                      borderBottom: '.5px solid grey',	
                      fontFamily: "Lato",fontSize:'12px',	
                  }}	
                  disabled={menuItem.disabled}	
                   value={menuItem.name}>	
                        <Stack direction="row" justifyContent="left"	
  alignItems="center">	
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
            <DoNotDisturbIcon fontSize="small" />	
          </ListItemIcon>} 	
            {menuItem.name}</Stack>	
                    </MenuItem>	
                  );	
                })}	
          </Select> 
          </TableCell>	
      <TableCell sx={mainThemeStyle.cellRow}>	
              {(row*8)+8}	
              </TableCell>	
              <TableCell align="right"> 	
              <Select	
            sx={{ color: 'rgba(135, 135, 135, 0.5)',	
            height:'33px',	
            marginTop:'4px',	
            marginBottom:'3px',	
            borderRadius: '5px',	
            backgroundColor: '#121212',	
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',	
            padding: '5px 0 5px 0',	
            "& .MuiSvgIcon-root": {	
              color: "rgba(135, 135, 135, 0.5)",	
            },	
            	
            '&.Mui-disabled': {	
             	
              background: 'rgba(135, 135, 135, 0.5)',	
            },	
            "& input.Mui-disabled": {	
              color: "white"	
            }	
            }}	
            name = {(row*8)+7}	
            value={getBrivoValue((row*8)+7)}	
          onChange={handleChangeBrivoRelay}	
          variant="filled"	
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+8) ? true : false) :true}	
          hiddenLabel	
          MenuProps={MenuProps}>	
            {brivoDoorArray?.map(menuItem => {	
                  	
                  return (	
                    <MenuItem  sx={	
                      {	
                      	
                      color:"#fff",	
                      backgroundColor: "#2F323A",	
                      borderBottom: '.5px solid grey',	
                      fontFamily: "Lato",fontSize:'12px',	
                  }}	
                  disabled={menuItem.disabled}	
                   value={menuItem.name}>	
                        <Stack direction="row" justifyContent="left"	
  alignItems="center">	
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
            <DoNotDisturbIcon fontSize="small" />	
          </ListItemIcon>} 	
            {menuItem.name}</Stack>	
                    </MenuItem>	
                  );	
                })}	
          </Select> </TableCell>
          </TableRow>	
          ))}	
        </TableBody>	
      </Table>	
    </TableContainer>}

    {dataOfLocation.unlocking_mode != '1'  && dataOfLocation.unlocking_mode != '4' && 
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>Web Relay Programming
              <Tooltip title="Add a letter 'P' to designate a Primary Relay, and add a numeral 1-64 to designate a Floor Relay">
      <IconButton  size="small" color="primary" >
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip>
              </TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsElevatorControl.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black"
          }}>
              <TableCell sx={mainThemeStyle.cellRow} >
              {parseInt(row*8)+1}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[parseInt(row*8)]}
        name={parseInt(row*8)}
        onChange={handleChangeEleInnerRelay}
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+1) ? true : false) :true}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"   
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+2}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[(row*8)+1]}
        name={(row*8)+1}
        onChange={handleChangeEleInnerRelay}
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+2) ? true : false) :true}
        variant="filled"   
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+3}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+2]}
        name={(row*8)+2}
        onChange={handleChangeEleInnerRelay}
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+3) ? true : false) :true}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+4}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+3]}
        name={(row*8)+3}
        onChange={handleChangeEleInnerRelay}                
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+4) ? true : false) :true}
        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+5}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+4]}
        name={(row*8)+4}
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+5) ? true : false) :true}
        onChange={handleChangeEleInnerRelay}   
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+6}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[(row*8)+5]}
        name={(row*8)+5}
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+6) ? true : false) :true}
        onChange={handleChangeEleInnerRelay}   
        variant="filled" 
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+7}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled" 
        value={ dataOfLocation.elevator_access.program[(row*8)+6]}
        name={(row*8)+6}
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+7) ? true : false) :true}
        onChange={handleChangeEleInnerRelay}  
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+8}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled" 
        value={dataOfLocation.elevator_access.program[(row*8)+7]}
        name={(row*8)+7}
        onChange={handleChangeEleInnerRelay}  
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+8) ? true : false) :true}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                         
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
      </Box>
      <Box sx={{display: { xs: "block", md: "none" }}}>
           <Divider sx={{backgroundColor:'white'}} />
           <Grid container  spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
      
        <Typography sx={mainThemeStyle.popUpLable} height={40}> Resident PIN </Typography>
    <Typography sx={mainThemeStyle.popUpLable} height={35}> Guest PIN </Typography>
    <Typography  sx={mainThemeStyle.popUpLable} height={35}>Unlocking Mode </Typography>
   {/*  {dataOfLocation.unlocking_mode != '1' && <>
    <Typography sx={mainThemeStyle.popUpLable} height={40}> Relay Hostname </Typography>
    <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 1 Name</Typography>
    <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ (dataOfLocation.relay_one_fname).length > 0 ? dataOfLocation.relay_one_fname + " Unlock Duration" : "Relay 1 Unlock Duration"}</Typography>
    <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2 Name</Typography>
   <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ (dataOfLocation.relay_two_fname).length > 0 ? dataOfLocation.relay_two_fname + " Unlock Duration" : "Relay 2 Unlock Duration"}</Typography>
   </>} */}

{dataOfLocation.unlocking_mode != '1'  && dataOfLocation.unlocking_mode != '4' && <>
<Typography sx={mainThemeStyle.popUpLable} height={40}> Relay Hostname </Typography> 
  <Typography  sx={mainThemeStyle.popUpLable} height={40}># of System Relays</Typography>
    <Typography  sx={mainThemeStyle.popUpLable} height={40}>Primary Relay Duration</Typography>
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>Floor Relay Duration</Typography>
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Primary Relay</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Floor Relay</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Floor Relay for App Unlock</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Primary Relay Friendly Name</Typography>
      
</>}
   {dataOfLocation.unlocking_mode == '1' && <>
       {/*  <Typography  sx={mainThemeStyle.popUpLable} height={35}>Brivo Door ID 1</Typography>	
      <Typography  sx={mainThemeStyle.popUpLable} height={35}>Brivo Door ID 2</Typography> */}	
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>Brivo Primary Door Id</Typography>	
       <Typography  sx={mainThemeStyle.popUpLable} height={40}># of Brivo Floors</Typography>
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>In-Call Relay Triggers</Typography>
      </>}
      {dataOfLocation.unlocking_mode == '4' && <>
    
    <Typography  sx={mainThemeStyle.popUpLable} height={35}>PDK Primary Door ID</Typography>
    <Typography  sx={mainThemeStyle.popUpLable} height={35}>PDK Secondary Door ID</Typography>
  
  
   </>}
    </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
        
      

          <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="r_pin_off" onChange={handlecheckboxCheckReverse} disabled={getDisableValue("72") } />}
        label={dataOfLocation.r_pin_off == "0" ? "Enabled" : "Disabled"}
      />
      <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="g_pin_off" onChange={handlecheckboxCheckReverse}  disabled={getDisableValue("73")} checked={dataOfLocation.g_pin_off == "0" ? true : false} />}
        label={dataOfLocation.g_pin_off == "0" ? "Enabled" : "Disabled"}
      />
       <Select 
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "unlocking_mode"
            disabled={getDisableValue("60")}
            value={GetUnlockingModeQR()}
          label="Age"
          onChange={handleSetUnlockingModeQR}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {UnlockingModeQR.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          {dataOfLocation.unlocking_mode != '1'   && dataOfLocation.unlocking_mode != '4' && <>
           <TextField
                        id="input-relay-host"
                        height={80}
                        type= 'text'
                        name="realy_hostname"
                        value={dataOfLocation.realy_hostname}
                        onChange={handleInputChange}
                        placeholder='api.controlbyweb.cloud/DAT/api-key'
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("237")}
                      variant="filled"
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    /> 
                    <TextField
        id="total-relays"
        type="number"
        value={dataOfLocation.elevator_access.totalrelays}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled={getDisableValue("82")}
                      name="totalrelays"
                      onChange={handleChangeEleValue}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 66, min: 0 
                        },
                            sx: {
                              
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
      <TextField
                        id="input-vegax-duration"
                        height={40}
                        type= 'number'
                        name="vegax_duration"
                        value={dataOfLocation.vegax_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("76")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
      <TextField
                        id="input-vegax2-duration"
                        height={40}
                        type= 'number'
                        name="vegax_second_duration"
                        value={dataOfLocation.vegax_second_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("79")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <FormControlLabel   sx={{ marginLeft: '0px',  height:'2.18rem',
            color:'#fff',}}
        control={<IOSSwitch sx={{ m: 1 }} name="diselevator"  disabled={getDisableValue("82")} onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselevator == "1" ? true : false} />}
        label=""
      />
       <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="disrelease" disabled={getDisableValue("82")} onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.disrelease == "1" ? true : false} />}
        label=""
      />
       <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="diselapp" disabled={getDisableValue("82")} onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselapp == "1" ? true : false} />}
        label=""
      />
       <TextField
                        id="input-vegax-relay1"
                        height={40}
                        type= 'text'
                        name="relay_one_fname"
                        value={dataOfLocation.relay_one_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("74")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                    </>}
          {/* {dataOfLocation.unlocking_mode != '1' && <>  <TextField
                        id="input-relay-host"
                        height={40}
                        type= 'text'
                        name="realy_hostname"
                        value={dataOfLocation.realy_hostname}
                        onChange={handleInputChange}
                        placeholder='api.controlbyweb.cloud/DAT/api-key'
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("237")}
                      variant="filled"
                        InputProps={{
                         
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
         
           <TextField
                        id="input-vegax-relay1"
                        height={40}
                        type= 'text'
                        name="relay_one_fname"
                        value={dataOfLocation.relay_one_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled={getDisableValue("74")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                   <TextField
                        id="input-vegax-duration"
                        height={40}
                        type= 'number'
                        name="vegax_duration"
                        value={dataOfLocation.vegax_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled={getDisableValue("76")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                   <TextField
                        id="input-vegax-relay2"
                        height={40}
                        type= 'text'
                        name="relay_two_fname"
                        disabled={getDisableValue("77")}
                        value={dataOfLocation.relay_two_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                    
                     
                      <TextField
                        id="input-vegax2-duration"
                        height={40}
                        type= 'number'
                        name="vegax_second_duration"
                        value={dataOfLocation.vegax_second_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled={getDisableValue("79")}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    /></> } */}
             { /* dataOfLocation.unlocking_mode == '1' && <>
                     <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = 'brivoprimeid'
            value={getBrivoValueMain()}
          onChange={handleChangeBrivoDoorValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
         
                   
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "0"
            value={getBrivoValue(0)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
                     
                 
              </>*/}
      {dataOfLocation.unlocking_mode == '1' &&<Select	
            sx={{ color: 'rgba(135, 135, 135, 0.5)',	
            height:'33px',	
            marginTop:'4px',	
            marginBottom:'3px',	
            borderRadius: '5px',	
            backgroundColor: '#121212',	
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',	
            padding: '5px 0 5px 0',	
            "& .MuiSvgIcon-root": {	
              color: "rgba(135, 135, 135, 0.5)",	
            },	
            	
            '&.Mui-disabled': {	
             	
              background: 'rgba(135, 135, 135, 0.5)',	
            },	
            "& input.Mui-disabled": {	
              color: "white"	
            }	
            }}	
            name = 'brivoprimeid'	
            value={getBrivoValueMain()}	
          onChange={handleChangeBrivoDoorValue}	
          variant="filled"	
          hiddenLabel	
          disabled={getDisableValue("84")}
          MenuProps={MenuProps}>	
            {brivoDoorArray?.map(menuItem => {	
                  	
                  return (	
                    <MenuItem  sx={	
                      {	
                      	
                      color:"#fff",	
                      backgroundColor: "#2F323A",	
                      borderBottom: '.5px solid grey',	
                      fontFamily: "Lato",fontSize:'12px',	
                  }}	
                  disabled={menuItem.disabled}	
                   value={menuItem.name}>	
                        <Stack direction="row" justifyContent="left"	
  alignItems="center">	
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
            <DoNotDisturbIcon fontSize="small" />	
          </ListItemIcon>} 	
            {menuItem.name}</Stack>	
                    </MenuItem>	
                  );	
                })}	
          </Select>}
          {dataOfLocation.unlocking_mode == '1' &&	
     <TextField	
     id="brivofloors"	
     height={40}	
     type= 'number'	
     name="brivofloors"	
     value={dataOfLocation.brivo_access.brivofloors}	
     onChange={handleChangeBrivoFloorValue}	
     sx={mainThemeStyle.InputMenuItem}	
     InputLabelProps={{ shrink: true }	
   }	
   disabled={getDisableValue("84")}
   variant="filled"	
     InputProps={{	
       inputProps: { 	
         max: (dataOfLocation.brivo_array.length), min: 0 	
     },	
         sx: {	
             	
             color:'rgba(135, 135, 135, 0.5)',	
             height:'33px',	
             borderRadius: '5px',	
             backgroundColor: '#121212',	
             boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',	
             padding: '0 0 15px 0',	
             textIndent: '15px !important',	
             '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            
            "& input.Mui-disabled": {
              color: "white"
            }
            	
             	
         },	
     }}	
    	
 />}
 {dataOfLocation.unlocking_mode == '1' && <FormControlLabel   sx={mainThemeStyle.popUpLableNewone} height={40}
     control={<IOSSwitch sx={{ m: 1 }} name="icrt"  disabled = {getDisableValue("84")} onChange={handleBrivoInCallRelayTriggerCheck}  checked={dataOfLocation.brivo_access.icrt == null ? false : (dataOfLocation.brivo_access.icrt == "1" ? true : false)} />}
     label={dataOfLocation.brivo_access.icrt == null ? "Disabled" : (dataOfLocation.brivo_access.icrt == "1" ? "Enabled" : "Disabled")}
   /> }

{dataOfLocation.unlocking_mode == '4' &&<Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled = {getDisableValue("236")}
            name = 'primeid'
            value={getPDKValueMain()}
          onChange={handleChangePDKMainValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {pdkDeviceArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                    <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>}
          {dataOfLocation.unlocking_mode == '4' &&<Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled = {getDisableValue("236")}
            name = 'brivoprimeid'
            value={getPDKValueOptional()}
          onChange={handleChangePDKOptionalValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {pdkDeviceArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}
                   value={menuItem.name}>
                    <Stack direction="row" justifyContent="left"
  alignItems="center">
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
            <DoNotDisturbIcon fontSize="small" />
          </ListItemIcon>} 
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>}
      </Stack>
      </Grid>
      
     
      
     
      
      </Grid>
      {dataOfLocation.unlocking_mode == '1'  && dataOfLocation.brivo_access.icrt == 1 && <Grid container spacing={2} padding= '32px'>
     
     <Grid item >
     <TableContainer component={Paper} sx={{
       width:'auto',
       height: 'auto',
       marginLeft: '38px',
       marginRight:'38px',
       marginBottom:'38px',
       backgroundColor:'#171E29',
      
       }}>
     <Table sx={{ minWidth: 350 }} >
       
       <TableHead sx={{ borderBottom: "1.5px solid black",
       }}>
         <TableRow>
           
           <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>In-Call Relay Trigger Settings</TableCell>
           
         </TableRow>
        
       </TableHead>

       
       <TableHead sx={{ borderBottom: "1.5px solid black",
       }}>
         </TableHead>
       <TableBody>
      
           <TableRow sx={{ borderBottom: "1.5px solid black",
         }}>
             <TableCell  sx={mainThemeStyle.cellHeader}>
             Custom Name 1 
             </TableCell>
            
               <TableCell align="right">
               <TextField
                       id="0"
                      
                       type= 'text'
                       name="0"
                       value={getCustomNameValue(0)}
                       onChange={handleChangeCustomNameValue}
                      
                       sx={{
                         ...mainThemeStyle.InputMenuItem,
                         marginTop:'10px',
                       }}
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("84")}
                     variant="filled"
                       InputProps={{
                         
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
            

                                 background: 'rgba(135, 135, 135, 0.5)',
                               },
                               
                               "& input.Mui-disabled": {
                                 color: "white"
                               }
                              
                               
                           },
                       }}
                      
                   />
                   
               </TableCell>
               <TableCell align="right">
               
               <Select
             sx={{ color: 'rgba(135, 135, 135, 0.5)',
             height:'33px',
             marginTop:'8px',
             marginBottom:'3px',
             borderRadius: '5px',
             backgroundColor: '#121212',
             boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
             padding: '5px 0 5px 0',
             "& .MuiSvgIcon-root": {
               color: "rgba(135, 135, 135, 0.5)",
             },
             
             '&.Mui-disabled': {
              
 
               background: 'rgba(135, 135, 135, 0.5)',
             },
             "& input.Mui-disabled": {
               color: "white"
             }
             }}
             name = "0"
             value={getCustomDoorValue(0)}
           onChange={handleChangeCustomDoorValue}
           variant="filled"
           disabled={getDisableValue("84")}
           hiddenLabel
           MenuProps={MenuProps}>
             {brivoCustomArray?.map(menuItem => {
                   
                   return (
                     <MenuItem  sx={
                       {
                       
                       color:"#fff",
                       backgroundColor: "#2F323A",
                       borderBottom: '.5px solid grey',
                       fontFamily: "Lato",fontSize:'12px',
                   }}
                   disabled={menuItem.disabled}
                    value={menuItem.name}>
                        <Stack direction="row" justifyContent="left"
   alignItems="center">
                   { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>} 
             {menuItem.name}</Stack>
                     </MenuItem>
                   );
                 })}
           </Select>
                 </TableCell>
           </TableRow>
           <TableRow sx={{ borderBottom: "1.5px solid black",
         }}>
             <TableCell  sx={mainThemeStyle.cellHeader}>
             Custom Name 2 
             </TableCell>
            
               <TableCell align="right">
               <TextField
                       id="1"
                      
                       type= 'text'
                       name="1"
                       value={getCustomNameValue(1)}
                       onChange={handleChangeCustomNameValue}
                      
                       sx={{
                         ...mainThemeStyle.InputMenuItem,
                         marginTop:'10px',
                       }}
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("84")}
                     variant="filled"
                       InputProps={{
                         
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
            

                                 background: 'rgba(135, 135, 135, 0.5)',
                               },
                               
                               "& input.Mui-disabled": {
                                 color: "white"
                               }
                              
                               
                           },
                       }}
                      
                   />
                   
               </TableCell>
               <TableCell align="right">
               
               <Select
             sx={{ color: 'rgba(135, 135, 135, 0.5)',
             height:'33px',
             marginTop:'8px',
             marginBottom:'3px',
             borderRadius: '5px',
             backgroundColor: '#121212',
             boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
             padding: '5px 0 5px 0',
             "& .MuiSvgIcon-root": {
               color: "rgba(135, 135, 135, 0.5)",
             },
             
             '&.Mui-disabled': {
              
 
               background: 'rgba(135, 135, 135, 0.5)',
             },
             "& input.Mui-disabled": {
               color: "white"
             }
             }}
             name = "1"
             value={getCustomDoorValue(1)}
           onChange={handleChangeCustomDoorValue}
           variant="filled"
           disabled={getDisableValue("84")}
           hiddenLabel
           MenuProps={MenuProps}>
             {brivoCustomArray?.map(menuItem => {
                   
                   return (
                     <MenuItem  sx={
                       {
                       
                       color:"#fff",
                       backgroundColor: "#2F323A",
                       borderBottom: '.5px solid grey',
                       fontFamily: "Lato",fontSize:'12px',
                   }}
                   disabled={menuItem.disabled}
                    value={menuItem.name}>
                        <Stack direction="row" justifyContent="left"
   alignItems="center">
                   { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>} 
             {menuItem.name}</Stack>
                     </MenuItem>
                   );
                 })}
           </Select>
                 </TableCell>
           </TableRow>
           <TableRow sx={{ borderBottom: "1.5px solid black",
         }}>
             <TableCell  sx={mainThemeStyle.cellHeader}>
             Custom Name 3 
             </TableCell>
            
               <TableCell align="right">
               <TextField
                       id="2"
                      
                       type= 'text'
                       name="2"
                       value={getCustomNameValue(2)}
                       onChange={handleChangeCustomNameValue}
                      
                       sx={{
                         ...mainThemeStyle.InputMenuItem,
                         marginTop:'10px',
                       }}
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("84")}
                     variant="filled"
                       InputProps={{
                         
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
            

                                 background: 'rgba(135, 135, 135, 0.5)',
                               },
                               
                               "& input.Mui-disabled": {
                                 color: "white"
                               }
                              
                               
                           },
                       }}
                      
                   />
                   
               </TableCell>
               <TableCell align="right">
               
               <Select
             sx={{ color: 'rgba(135, 135, 135, 0.5)',
             height:'33px',
             marginTop:'8px',
             marginBottom:'3px',
             borderRadius: '5px',
             backgroundColor: '#121212',
             boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
             padding: '5px 0 5px 0',
             "& .MuiSvgIcon-root": {
               color: "rgba(135, 135, 135, 0.5)",
             },
             
             '&.Mui-disabled': {
              
 
               background: 'rgba(135, 135, 135, 0.5)',
             },
             "& input.Mui-disabled": {
               color: "white"
             }
             }}
             name = "2"
             value={getCustomDoorValue(2)}
           onChange={handleChangeCustomDoorValue}
           variant="filled"
           disabled={getDisableValue("84")}
           hiddenLabel
           MenuProps={MenuProps}>
             {brivoCustomArray?.map(menuItem => {
                   
                   return (
                     <MenuItem  sx={
                       {
                       
                       color:"#fff",
                       backgroundColor: "#2F323A",
                       borderBottom: '.5px solid grey',
                       fontFamily: "Lato",fontSize:'12px',
                   }}
                   disabled={menuItem.disabled}
                    value={menuItem.name}>
                        <Stack direction="row" justifyContent="left"
   alignItems="center">
                   { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
             <DoNotDisturbIcon fontSize="small" />
           </ListItemIcon>} 
             {menuItem.name}</Stack>
                     </MenuItem>
                   );
                 })}
           </Select>
                 </TableCell>
           </TableRow>
       </TableBody>
     </Table>
   </TableContainer></Grid>
   </Grid>}
      {dataOfLocation.unlocking_mode == '1' &&	
      <TableContainer component={Paper} sx={{	
        width:'auto',	
        height: 'auto',	
        marginLeft: '38px',	
        marginRight:'38px',	
        marginBottom:'38px',	
        backgroundColor:'#171E29',	
       	
        }}>	
      <Table sx={{ minWidth: 650 }} >	
        	
        <TableHead sx={{ borderBottom: "1.5px solid black",	
        }}>	
          <TableRow>	
            	
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>Brivo Relays</TableCell>	
            	
          </TableRow>	
         	
        </TableHead>	
        	
        <TableHead sx={{ borderBottom: "1.5px solid black",	
        }}>	
          <TableRow>	
          <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>	Door Id</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>	
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>	
          </TableRow></TableHead>	
        <TableBody>
        {rowsElevatorControl.map((row) => (	
            <TableRow sx={{ borderBottom: "1.5px solid black",	
          }}>	
              <TableCell sx={mainThemeStyle.cellRow}>	
              {parseInt(row*8)+1}	
              </TableCell>	
              <TableCell align="right">	
                	
              <Select	
            sx={{ color: 'rgba(135, 135, 135, 0.5)',	
            height:'33px',	
            marginTop:'4px',	
            marginBottom:'3px',	
            borderRadius: '5px',	
            backgroundColor: '#121212',	
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',	
            padding: '5px 0 5px 0',	
            "& .MuiSvgIcon-root": {	
              color: "rgba(135, 135, 135, 0.5)",	
            },	
            	
            '&.Mui-disabled': {	
             	
              background: 'rgba(135, 135, 135, 0.5)',	
            },	
            "& input.Mui-disabled": {	
              color: "white"	
            }	
            }}
            name = {parseInt(row*8)}	
            value={getBrivoValue(parseInt(row*8))}	
          onChange={handleChangeBrivoRelay}	
          variant="filled"	
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+1) ? true : false ) : true}	
          hiddenLabel	
          MenuProps={MenuProps}>	
            {brivoDoorArray?.map(menuItem => {	
                  	
                  return (	
                    <MenuItem  sx={	
                      {	
                      	
                      color:"#fff",	
                      backgroundColor: "#2F323A",	
                      borderBottom: '.5px solid grey',	
                      fontFamily: "Lato",fontSize:'12px',	
                  }}	
                  disabled={menuItem.disabled}	
                   value={menuItem.name}>	
                        <Stack direction="row" justifyContent="left"	
  alignItems="center">	
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
            <DoNotDisturbIcon fontSize="small" />	
          </ListItemIcon>} 	
            {menuItem.name}</Stack>	
                    </MenuItem>	
                  );	
                })}	
          </Select>
          </TableCell>	
              <TableCell sx={mainThemeStyle.cellRow}>	
              {parseInt(row*8)+2}	
              </TableCell>	
              <TableCell align="right">	
              <Select	
            sx={{ color: 'rgba(135, 135, 135, 0.5)',	
            height:'33px',	
            marginTop:'4px',	
            marginBottom:'3px',	
            borderRadius: '5px',	
            backgroundColor: '#121212',	
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',	
            padding: '5px 0 5px 0',	
            "& .MuiSvgIcon-root": {	
              color: "rgba(135, 135, 135, 0.5)",	
            },	
            	
            '&.Mui-disabled': {	
             	
              background: 'rgba(135, 135, 135, 0.5)',	
            },	
            "& input.Mui-disabled": {	
              color: "white"	
            }	
            }}	
            name = {(row*8)+1}	
            value={getBrivoValue(parseInt(row*8)+1)}	
          onChange={handleChangeBrivoRelay}	
          variant="filled"	
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+2) ? true : false ) : true}	
          hiddenLabel	
          MenuProps={MenuProps}>	
            {brivoDoorArray?.map(menuItem => {	
                  	
                  return (	
                    <MenuItem  sx={	
                      {	
                      	
                      color:"#fff",	
                      backgroundColor: "#2F323A",	
                      borderBottom: '.5px solid grey',	
                      fontFamily: "Lato",fontSize:'12px',	
                  }}	
                  disabled={menuItem.disabled}	
                   value={menuItem.name}>	
                       <Stack direction="row" justifyContent="left"	
  alignItems="center">	
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
            <DoNotDisturbIcon fontSize="small" />	
          </ListItemIcon>} 	
            {menuItem.name}</Stack>	
                    </MenuItem>	
                  );	
                })}	
          </Select>
          </TableCell>	
      <TableCell sx={mainThemeStyle.cellRow}>	
              {parseInt(row*8)+3}	
              </TableCell>	
              <TableCell align="right"> 	
              <Select	
            sx={{ color: 'rgba(135, 135, 135, 0.5)',	
            height:'33px',	
            marginTop:'4px',	
            marginBottom:'3px',	
            borderRadius: '5px',	
            backgroundColor: '#121212',	
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',	
            padding: '5px 0 5px 0',	
            "& .MuiSvgIcon-root": {	
              color: "rgba(135, 135, 135, 0.5)",	
            },	
            	
            '&.Mui-disabled': {	
             	
              background: 'rgba(135, 135, 135, 0.5)',	
            },	
            "& input.Mui-disabled": {	
              color: "white"	
            }	
            }}	
            name = {(row*8)+2}	
            value={getBrivoValue((row*8)+2)}	
          onChange={handleChangeBrivoRelay}	
          variant="filled"	
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+3) ? true : false ) : true}	
          hiddenLabel	
          MenuProps={MenuProps}>	
            {brivoDoorArray?.map(menuItem => {	
                  	
                  return (	
                    <MenuItem  sx={	
                      {	
                      	
                      color:"#fff",	
                      backgroundColor: "#2F323A",	
                      borderBottom: '.5px solid grey',	
                      fontFamily: "Lato",fontSize:'12px',	
                  }}	
                  disabled={menuItem.disabled}	
                   value={menuItem.name}>	
                       <Stack direction="row" justifyContent="left"	
  alignItems="center">	
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
            <DoNotDisturbIcon fontSize="small" />	
          </ListItemIcon>} 	
            {menuItem.name}</Stack>	
                    </MenuItem>	
                  );	
                })}	
          </Select>
          </TableCell>	
      <TableCell sx={mainThemeStyle.cellRow}>	
              {(row*8)+4}	
              </TableCell>	
              <TableCell align="right"> 	
              <Select	
            sx={{ color: 'rgba(135, 135, 135, 0.5)',	
            height:'33px',	
            marginTop:'4px',	
            marginBottom:'3px',	
            borderRadius: '5px',	
            backgroundColor: '#121212',	
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',	
            padding: '5px 0 5px 0',	
            "& .MuiSvgIcon-root": {	
              color: "rgba(135, 135, 135, 0.5)",	
            },	
            	
            '&.Mui-disabled': {	
             	
              background: 'rgba(135, 135, 135, 0.5)',	
            },	
            "& input.Mui-disabled": {	
              color: "white"	
            }	
            }}	
            name = {(row*8)+3}	
            value={getBrivoValue((row*8)+3)}	
          onChange={handleChangeBrivoRelay}	
          variant="filled"	
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+4) ? true : false ) : true}	
          hiddenLabel	
          MenuProps={MenuProps}>	
            {brivoDoorArray?.map(menuItem => {	
                  	
                  return (	
                    <MenuItem  sx={	
                      {	
                      	
                      color:"#fff",	
                      backgroundColor: "#2F323A",	
                      borderBottom: '.5px solid grey',	
                      fontFamily: "Lato",fontSize:'12px',	
                  }}	
                  disabled={menuItem.disabled}	
                   value={menuItem.name}>	
                       <Stack direction="row" justifyContent="left"	
  alignItems="center">	
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
            <DoNotDisturbIcon fontSize="small" />	
          </ListItemIcon>} 	
            {menuItem.name}</Stack>	
                    </MenuItem>	
                  );	
                })}	
          </Select>	
              </TableCell>
              <TableCell sx={mainThemeStyle.cellRow}>	
              {(row*8)+5}	
              </TableCell>	
              <TableCell align="right">	
                      <Select	
            sx={{ color: 'rgba(135, 135, 135, 0.5)',	
            height:'33px',	
            marginTop:'4px',	
            marginBottom:'3px',	
            borderRadius: '5px',	
            backgroundColor: '#121212',	
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',	
            padding: '5px 0 5px 0',	
            "& .MuiSvgIcon-root": {	
              color: "rgba(135, 135, 135, 0.5)",	
            },	
            	
            '&.Mui-disabled': {	
             	
              background: 'rgba(135, 135, 135, 0.5)',	
            },	
            "& input.Mui-disabled": {	
              color: "white"	
            }	
            }}	
            name = {(row*8)+4}	
            value={getBrivoValue((row*8)+4)}	
          onChange={handleChangeBrivoRelay}	
          variant="filled"	
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+5) ? true : false ) : true}	
          hiddenLabel	
          MenuProps={MenuProps}>	
            {brivoDoorArray?.map(menuItem => {	
                  	
                  return (	
                    <MenuItem  sx={	
                      {	
                      	
                      color:"#fff",	
                      backgroundColor: "#2F323A",	
                      borderBottom: '.5px solid grey',	
                      fontFamily: "Lato",fontSize:'12px',	
                  }}	
                  disabled={menuItem.disabled}	
                   value={menuItem.name}>	
                        <Stack direction="row" justifyContent="left"	
  alignItems="center">	
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
            <DoNotDisturbIcon fontSize="small" />	
          </ListItemIcon>} 	
            {menuItem.name}</Stack>	
                    </MenuItem>	
                  );	
                })}	
          </Select> 	
                </TableCell>
                <TableCell sx={mainThemeStyle.cellRow}>	
              {(row*8)+6}	
              </TableCell>	
              <TableCell align="right"> 	
              <Select	
            sx={{ color: 'rgba(135, 135, 135, 0.5)',	
            height:'33px',	
            marginTop:'4px',	
            marginBottom:'3px',	
            borderRadius: '5px',	
            backgroundColor: '#121212',	
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',	
            padding: '5px 0 5px 0',	
            "& .MuiSvgIcon-root": {	
              color: "rgba(135, 135, 135, 0.5)",	
            },	
            	
            '&.Mui-disabled': {	
             	
              background: 'rgba(135, 135, 135, 0.5)',	
            },	
            "& input.Mui-disabled": {	
              color: "white"	
            }	
            }}	
            name = {(row*8)+5}	
            value={getBrivoValue((row*8)+5)}	
          onChange={handleChangeBrivoRelay}	
          variant="filled"	
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+6) ? true : false ) : true}	
          hiddenLabel	
          MenuProps={MenuProps}>	
            {brivoDoorArray?.map(menuItem => {	
                  	
                  return (	
                    <MenuItem  sx={	
                      {	
                      	
                      color:"#fff",	
                      backgroundColor: "#2F323A",	
                      borderBottom: '.5px solid grey',	
                      fontFamily: "Lato",fontSize:'12px',	
                  }}	
                  disabled={menuItem.disabled}	
                   value={menuItem.name}>	
                        <Stack direction="row" justifyContent="left"	
  alignItems="center">	
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
            <DoNotDisturbIcon fontSize="small" />	
          </ListItemIcon>} 	
            {menuItem.name}</Stack>	
                    </MenuItem>	
                  );	
                })}	
          </Select> 	
              </TableCell>
              <TableCell sx={mainThemeStyle.cellRow}>	
              {(row*8)+7}	
              </TableCell>	
              <TableCell align="right"> 	
              <Select	
            sx={{ color: 'rgba(135, 135, 135, 0.5)',	
            height:'33px',	
            marginTop:'4px',	
            marginBottom:'3px',	
            borderRadius: '5px',	
            backgroundColor: '#121212',	
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',	
            padding: '5px 0 5px 0',	
            "& .MuiSvgIcon-root": {	
              color: "rgba(135, 135, 135, 0.5)",	
            },	
            	
            '&.Mui-disabled': {	
             	
              background: 'rgba(135, 135, 135, 0.5)',	
            },	
            "& input.Mui-disabled": {	
              color: "white"	
            }	
            }}	
            name = {(row*8)+6}	
            value={getBrivoValue((row*8)+6)}	
          onChange={handleChangeBrivoRelay}	
          variant="filled"	
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+7) ? true : false ) : true}		
          hiddenLabel	
          MenuProps={MenuProps}>	
            {brivoDoorArray?.map(menuItem => {	
                  	
                  return (	
                    <MenuItem  sx={	
                      {	
                      	
                      color:"#fff",	
                      backgroundColor: "#2F323A",	
                      borderBottom: '.5px solid grey',	
                      fontFamily: "Lato",fontSize:'12px',	
                  }}	
                  disabled={menuItem.disabled}	
                   value={menuItem.name}>	
                        <Stack direction="row" justifyContent="left"	
  alignItems="center">	
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
            <DoNotDisturbIcon fontSize="small" />	
          </ListItemIcon>} 	
            {menuItem.name}</Stack>	
                    </MenuItem>	
                  );	
                })}	
          </Select> 	
              </TableCell>
              <TableCell sx={mainThemeStyle.cellRow}>	
              {(row*8)+8}	
              </TableCell>	
              <TableCell align="right"> 	
              <Select	
            sx={{ color: 'rgba(135, 135, 135, 0.5)',	
            height:'33px',	
            marginTop:'4px',	
            marginBottom:'3px',	
            borderRadius: '5px',	
            backgroundColor: '#121212',	
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',	
            padding: '5px 0 5px 0',	
            "& .MuiSvgIcon-root": {	
              color: "rgba(135, 135, 135, 0.5)",	
            },	
            	
            '&.Mui-disabled': {	
             	
              background: 'rgba(135, 135, 135, 0.5)',	
            },	
            "& input.Mui-disabled": {	
              color: "white"	
            }	
            }}	
            name = {(row*8)+7}	
            value={getBrivoValue((row*8)+7)}	
          onChange={handleChangeBrivoRelay}	
          variant="filled"	
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+8) ? true : false ) : true}	
          hiddenLabel	
          MenuProps={MenuProps}>	
            {brivoDoorArray?.map(menuItem => {	
                  	
                  return (	
                    <MenuItem  sx={	
                      {	
                      	
                      color:"#fff",	
                      backgroundColor: "#2F323A",	
                      borderBottom: '.5px solid grey',	
                      fontFamily: "Lato",fontSize:'12px',	
                  }}	
                  disabled={menuItem.disabled}	
                   value={menuItem.name}>	
                        <Stack direction="row" justifyContent="left"	
  alignItems="center">	
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
            <DoNotDisturbIcon fontSize="small" />	
          </ListItemIcon>} 	
            {menuItem.name}</Stack>	
                    </MenuItem>	
                  );	
                })}	
          </Select> </TableCell>
          </TableRow>	
          ))}	
        </TableBody>	
      </Table>	
    </TableContainer>}

    {dataOfLocation.unlocking_mode != '1' && 
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>
             Web Relay Programming
              <Tooltip title="Add a letter 'P' to designate a Primary Relay, and add a numeral 1-64 to designate a Floor Relay">
      <IconButton  size="small" color="primary" >
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip>
             
            </TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsElevatorControl.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black"
          }}>
              <TableCell sx={mainThemeStyle.cellRow} >
              {parseInt(row*8)+1}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[parseInt(row*8)]}
        name={parseInt(row*8)}
        onChange={handleChangeEleInnerRelay}
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+1) ? true : false) :true}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"   
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+2}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[(row*8)+1]}
        name={(row*8)+1}
        onChange={handleChangeEleInnerRelay}
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+2) ? true : false) :true}
        variant="filled"   
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+3}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+2]}
        name={(row*8)+2}
        onChange={handleChangeEleInnerRelay}
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+3) ? true : false) :true}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+4}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+3]}
        name={(row*8)+3}
        onChange={handleChangeEleInnerRelay}                
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+4) ? true : false) :true}
        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+5}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+4]}
        name={(row*8)+4}
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+5) ? true : false) :true}
        onChange={handleChangeEleInnerRelay}   
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+6}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[(row*8)+5]}
        name={(row*8)+5}
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+6) ? true : false) :true}
        onChange={handleChangeEleInnerRelay}   
        variant="filled" 
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+7}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled" 
        value={ dataOfLocation.elevator_access.program[(row*8)+6]}
        name={(row*8)+6}
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+7) ? true : false) :true}
        onChange={handleChangeEleInnerRelay}  
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+8}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled" 
        value={dataOfLocation.elevator_access.program[(row*8)+7]}
        name={(row*8)+7}
        onChange={handleChangeEleInnerRelay}  
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+8) ? true : false) :true}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                         
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
      </Box>

</>)}
{/* {(currentTab == 3  &&  dataOfLocation.cam_brand == "Virtual Panel" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Hold Open Settings
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "block" }}}>
      <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={1}>
      <Typography  variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>Enable Hold Open </Typography>
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }}   onChange={handleChangeEventOfHoldOpen}  disabled={getDisableValue("81")}/>}
        
      />
      </Stack>
      </Grid>
      </Grid>
       {dataOfLocation.hold_open.holdopen == "1" && <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={10}>
      <Stack direction="row"  spacing={4}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Indefinite Mode </Typography>
      
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name="indefinite" disabled={getDisableValue("81")} onChange={handleChangeValueOfHoldOpen} checked={dataOfLocation.hold_open.indefinite == "1" ? true : false}/>
      
      </Stack>
      </Grid>
      </Grid>}
      
      {dataOfLocation.hold_open.holdopen == "1" && dataOfLocation.hold_open.indefinite == "1" &&
      <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={1}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={{fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '1rem',
            lineHeight: '1.37rem',
            color:'#fff',
            marginTop:'8px',
            }} height={40} >Relay 1 </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2 </Typography>
      </Stack>
      </Grid>
      <Grid item xs={1}>
      <Stack direction="column"  spacing={2}>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay1" disabled={getDisableValue("81")}  onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay1 == "1" ? true : false }/>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay2" disabled={getDisableValue("81")} onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay2 == "1" ? true : false }/>
      </Stack>
      </Grid>
      </Grid>}
      
      

    
      </Box>

      <Box sx={{display: { xs: "block", md: "none" }}}>
      <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={8}>
      <Stack direction="column"  spacing={1}>
      <Typography  variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>Enable Hold Open </Typography>
      </Stack>
      </Grid>
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }}   onChange={handleChangeEventOfHoldOpen} disabled={getDisableValue("81")}  checked={dataOfLocation.hold_open.holdopen == "1" ? true : false }/>}
        
      />
      </Stack>
      </Grid>
      </Grid>
       {dataOfLocation.hold_open.holdopen == "1" && <Grid container spacing={2} padding= '27px' paddingBottom='32px' paddingTop='32px'> 
      <Stack direction="column"  spacing={2}>
      <Stack direction="row"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Indefinite Mode </Typography>
      
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name="indefinite" disabled={getDisableValue("81")} onChange={handleChangeValueOfHoldOpen} checked={dataOfLocation.hold_open.indefinite == "1" ? true : false}/>
      </Stack>
      
      </Stack>
      </Grid>}
      
      {dataOfLocation.hold_open.holdopen == "1" && dataOfLocation.hold_open.indefinite == "1" &&
      <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='10px'> 
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 1 </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2 </Typography>
      </Stack>
      </Grid>
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay1" onChange={handleIndefiniteRelay} disabled={getDisableValue("81")} checked={dataOfLocation.hold_open.relay1 == "1" ? true : false }/>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay2" onChange={handleIndefiniteRelay} disabled={getDisableValue("81")}  checked={dataOfLocation.hold_open.relay2 == "1" ? true : false }/>
      </Stack>
      </Grid>
      </Grid>}
      
      </Box>
</>)} */}

{(currentTab == 3 && dataOfLocation.cam_brand == "ResidentLink" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       LPR Settings
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "block" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2}>
        <Stack direction="column"  spacing={2}>
      
      <Typography variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>LPR Device </Typography>
     {/*  <Typography sx={mainThemeStyle.popUpLable} height={40}>LPR Camera 1 Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>LPR Camera 2 Name </Typography> */}
      
      </Stack>
      </Grid>
      
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      
      
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="lpr_enable" onChange={handlecheckboxCheck} disabled={getDisableValue("80")} checked={dataOfLocation.lpr_enable == "1" ? true : false } />}
        label=""
      />
            {/* <Checkbox sx={mainThemeStyle.formCheckBox} name="lpr_enable" onChange={handlecheckboxCheck} checked={dataOfLocation.lpr_enable == "1" ? true : false}/> */}
           {/*  <TextField
                        id="input-lpr-camera1"
                        height={40}
                        type= 'text'
                        name="lpr_cam1"
                        value={dataOfLocation.lpr_cam1}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="lpr_cam2"
                        value={dataOfLocation.lpr_cam2}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    /> */}
     
          
      </Stack>
      </Grid>
     
      
      
      </Grid>
      
      
      </Box>
      {dataOfLocation.lpr_enable == "1" && <Box sx={{display: { xs: "none", md: "block" }}}>
        <Grid container spacing={2} padding= '32px'> 
        <Grid item xs={0.3}></Grid>
      <Grid item xs={5.2}>
        <Stack direction="row"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40} > Add Bridge </Typography>
      
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("80")}
      value={currentBridge}
      label="Age"
      onChange={handleChangeBridge}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {addBridge?.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            );
          })}
      </Select>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("80")}
      value={currentBridgeType}
      label="Age"
      onChange={handleChangeBridgeType}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {BriType.items.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.label}>
                {menuItem.label}
              </MenuItem>
            );
          })}
      </Select>
      <Tooltip title= {'A "Dedicated" bridge can only be assigned to a single LPR devices.  If the account has multiple LPR devices utilizing this same bridge, please select "Shared".'}>
      <IconButton  size="small" color="primary" >
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip>
      <LoadingButton sx={mainThemeStyle.normalButton} disabled={getDisableValue("80") == false ? ((recordsBridge.length > 0 )? true : false) :true} loading={loading} onClick={handleAddBridge}>Add</LoadingButton>
      </Stack>
      </Grid>
      {/* </Stack>
      </Grid> */}
      <Grid item xs={1.7}>
    
      </Grid>
      <Grid item xs={4.8}>
        <Stack direction="row"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40} > Add Camera </Typography>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("80")}
      value={currentCamera}
      label="Age"
      onChange={handleChangeCamera}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {addCamera?.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            );
          })}
      </Select>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("80")}
      value={currentCameraType}
      label="Age"
      onChange={handleChangeCameraType}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {CamType.items.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.label}>
                {menuItem.label}
              </MenuItem>
            );
          })}
      </Select>
             <LoadingButton sx={mainThemeStyle.normalButton} disabled={getDisableValue("80")} onClick={handleAddCamera} loading={loading}>Add</LoadingButton>
          
      </Stack>
      </Grid>
      
      
      </Grid>
     
      <Grid container  padding= '32px'>
       
       <Grid item xs={5}>
       <TblContainerBridge>
                     <TblHeadBridge />
         <TableBody>
          {recordsAfterPagingAndSortingBridge().map((row) => (
             <TableRow sx={{ borderBottom: "1.5px solid black",
           }}>
               
               <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.type == "0" ? "Dedicated" :"Shared"} </TableCell>
              
               <TableCell align="left">
              
                 <LoadingButton loading={loading} disabled={getDisableValue("80")}  sx={mainThemeStyle.viewLogButtonRed} onClick={ () => handleBridgeDelete(row.id,row.name)}>Delete</LoadingButton>
                 
                 </TableCell>
             </TableRow>
           ))}
         </TableBody>
       
     </TblContainerBridge>
     
     </Grid>
     <Grid item xs={2}>
     
     </Grid>
     <Grid item xs={5}>
     <TblContainerCamera>
                     <TblHeadCamera />
         <TableBody>
          {recordsAfterPagingAndSortingCamera().map((row) => (
             <TableRow sx={{ borderBottom: "1.5px solid black",
           }}>
               
               <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.type == "0" ? "Entry" : "Exit"} </TableCell>
              
             <TableCell align="left">
                <LoadingButton loading={loading}  sx={mainThemeStyle.viewLogButtonRed} disabled={getDisableValue("80")} onClick={ () => handleCameraDelete(row.id,row.name)}>Delete</LoadingButton>
                    </TableCell>
             </TableRow>
           ))}
         </TableBody>
       
     </TblContainerCamera>
    
     </Grid>
       </Grid>
       
     
       <Grid container spacing={3}>
       <Grid item xs={5}>
       {/* <BelowTblPaginationBridge></BelowTblPaginationBridge> */}</Grid>
       <Grid item xs={1}>
       </Grid>
       <Grid item xs={6}>
       <BelowTblPaginationCamera></BelowTblPaginationCamera></Grid></Grid>
      
      
      
      
      </Box>}
      
      <Box sx={{display: { xs: "block", md: "none" }}}>
      <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
      
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>LPR Device </Typography>
      {/* <Typography sx={mainThemeStyle.popUpLable} height={40}>LPR Camera 1 Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>LPR Camera 2 Name </Typography> */}
      
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      
      
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="lpr_enable" onChange={handlecheckboxCheck} disabled={getDisableValue("80")} checked={dataOfLocation.lpr_enable == "1" ? true : false } />}
        label=""
      />
            {/* <Checkbox sx={mainThemeStyle.formCheckBox} name="lpr_enable" onChange={handlecheckboxCheck} checked={dataOfLocation.lpr_enable == "1" ? true : false}/> */}
           {/*  <TextField
                        id="input-lpr-camera1"
                        height={40}
                        type= 'text'
                        name="lpr_cam1"
                        value={dataOfLocation.lpr_cam1}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="lpr_cam2"
                        value={dataOfLocation.lpr_cam2}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    /> */}
     
          
      </Stack>
      </Grid>
     
      
      
      </Grid>
      {dataOfLocation.lpr_enable == "1" &&<Box sx={{display: { xs: "block", md: "none" }}}>
      <Grid container spacing={2} padding= '40px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={12}>
        <Stack direction="column"  spacing={2} >
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Add Bridge </Typography>
      
      </Stack>
      </Grid>
      
      <Grid item xs={12}>
      <Stack direction="column"  spacing={2}>
      <Stack direction="row"  spacing={2}>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("80")}
      value={currentBridge}
      label="Age"
      onChange={handleChangeBridge}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {addBridge?.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            );
          })}
      </Select>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("80")}
      value={currentBridgeType}
      label="Age"
      onChange={handleChangeBridgeType}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {BriType.items.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.label}>
                {menuItem.label}
              </MenuItem>
            );
          })}
      </Select>
      <LoadingButton sx={mainThemeStyle.normalButton} disabled={getDisableValue("80") == false ? ((recordsBridge.length > 0 )? true : false) :true}   loading={loading} onClick={handleAddBridge} >Add</LoadingButton>
     </Stack>
      </Stack>
      </Grid>

      
     
      
      
      </Grid>
      
      
      
      <TblContainerBridge>
                     <TblHeadBridge />
         <TableBody>
          {recordsAfterPagingAndSortingBridge().map((row) => (
             <TableRow sx={{ borderBottom: "1.5px solid black",
           }}>
               
               <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.type == "0" ? "Dedicated" :"Shared"} </TableCell>
              
               <TableCell align="left">
              
                 <LoadingButton loading={loading}  sx={mainThemeStyle.viewLogButtonRed} disabled={getDisableValue("80")} onClick={ () => handleBridgeDelete(row.id,row.name)}>Delete</LoadingButton>
                 
                 </TableCell>
             </TableRow>
           ))}
         </TableBody>
       
     </TblContainerBridge>
    
    
    
    <Grid container spacing={2} padding= '40px' paddingBottom='32px' paddingTop='100px' > 
    <Grid item xs={12} >
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable}> Add Camera </Typography>
      </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="column"  spacing={2}>
          <Stack direction="row" spacing={2}>
          <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("80")}
      value={currentCamera}
      label="Age"
      onChange={handleChangeCamera}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {addCamera?.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            );
          })}
      </Select>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("80")}
      value={currentCameraType}
      label="Age"
      onChange={handleChangeCameraType}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {CamType.items.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.label}>
                {menuItem.label}
              </MenuItem>
            );
          })}
      </Select>
             <LoadingButton sx={mainThemeStyle.normalButton} disabled={getDisableValue("80")} onClick={handleAddCamera} loading={loading}>Add</LoadingButton>
          </Stack>
      </Stack>
      </Grid>
      
     
      
      
      </Grid>
      
      <TblContainerCamera>
                     <TblHeadCamera />
         <TableBody>
          {recordsAfterPagingAndSortingCamera().map((row) => (
             <TableRow sx={{ borderBottom: "1.5px solid black",
           }}>
               
               <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.type == "0" ? "Entry" : "Exit"} </TableCell>
              
             <TableCell align="left">
                <LoadingButton loading={loading}  sx={mainThemeStyle.viewLogButtonRed} disabled={getDisableValue("80")} onClick={ () => handleCameraDelete(row.id,row.name)}>Delete</LoadingButton>
                    </TableCell>
             </TableRow>
           ))}
         </TableBody>
       
     </TblContainerCamera>
    
    <BelowTblPaginationCamera></BelowTblPaginationCamera>
      </Box>}
      </Box>

</>)}
{(currentTab == 4 && dataOfLocation.cam_brand == "Virtual Panel" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       LPR Settings
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "block" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2}>
        <Stack direction="column"  spacing={2}>
      
      <Typography variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>LPR Device </Typography>
     {/*  <Typography sx={mainThemeStyle.popUpLable} height={40}>LPR Camera 1 Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>LPR Camera 2 Name </Typography> */}
      
      </Stack>
      </Grid>
      
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      
      
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="lpr_enable" disabled={getDisableValue("80")} onChange={handlecheckboxCheck} checked={dataOfLocation.lpr_enable == "1" ? true : false } />}
        label=""
      />
            {/* <Checkbox sx={mainThemeStyle.formCheckBox} name="lpr_enable" onChange={handlecheckboxCheck} checked={dataOfLocation.lpr_enable == "1" ? true : false}/> */}
           {/*  <TextField
                        id="input-lpr-camera1"
                        height={40}
                        type= 'text'
                        name="lpr_cam1"
                        value={dataOfLocation.lpr_cam1}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="lpr_cam2"
                        value={dataOfLocation.lpr_cam2}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    /> */}
     
          
      </Stack>
      </Grid>
     
      
      
      </Grid>
      
      
      </Box>
      {dataOfLocation.lpr_enable == "1" && <Box sx={{display: { xs: "none", md: "block" }}}>
        <Grid container spacing={2} padding= '32px'> 
        <Grid item xs={0.3}></Grid>
      <Grid item xs={5.2}>
        <Stack direction="row"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40} > Add Bridge </Typography>
      
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("80")}
      value={currentBridge}
      label="Age"
      onChange={handleChangeBridge}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {addBridge?.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            );
          })}
      </Select>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("80")}
      value={currentBridgeType}
      label="Age"
      onChange={handleChangeBridgeType}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {BriType.items.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.label}>
                {menuItem.label}
              </MenuItem>
            );
          })}
      </Select>
      <LoadingButton sx={mainThemeStyle.normalButton}  disabled={getDisableValue("80") == false ? ((recordsBridge.length > 0 )? true : false) :true}  loading={loading} onClick={handleAddBridge} >Add</LoadingButton>
      </Stack>
      </Grid>
      {/* </Stack>
      </Grid> */}
      <Grid item xs={1.7}>
    
      </Grid>
      <Grid item xs={4.8}>
        <Stack direction="row"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40} > Add Camera </Typography>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("80")}
      value={currentCamera}
      label="Age"
      onChange={handleChangeCamera}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {addCamera?.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            );
          })}
      </Select>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("80")}
      value={currentCameraType}
      label="Age"
      onChange={handleChangeCameraType}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {CamType.items.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.label}>
                {menuItem.label}
              </MenuItem>
            );
          })}
      </Select>
             <LoadingButton sx={mainThemeStyle.normalButton} disabled={getDisableValue("80")} onClick={handleAddCamera} loading={loading}>Add</LoadingButton>
          
      </Stack>
      </Grid>
      
      
      </Grid>
     
      <Grid container  padding= '32px'>
       
       <Grid item xs={5}>
       <TblContainerBridge>
                     <TblHeadBridge />
         <TableBody>
          {recordsAfterPagingAndSortingBridge().map((row) => (
             <TableRow sx={{ borderBottom: "1.5px solid black",
           }}>
               
               <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.type == "0" ? "Dedicated" :"Shared"} </TableCell>
              
               <TableCell align="left">
              
                 <LoadingButton loading={loading}  sx={mainThemeStyle.viewLogButtonRed} disabled={getDisableValue("80")} onClick={ () => handleBridgeDelete(row.id,row.name)}>Delete</LoadingButton>
                 
                 </TableCell>
             </TableRow>
           ))}
         </TableBody>
       
     </TblContainerBridge>
     
     </Grid>
     <Grid item xs={2}>
     
     </Grid>
     <Grid item xs={5}>
     <TblContainerCamera>
                     <TblHeadCamera />
         <TableBody>
          {recordsAfterPagingAndSortingCamera().map((row) => (
             <TableRow sx={{ borderBottom: "1.5px solid black",
           }}>
               
               <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.type == "0" ? "Entry" : "Exit"} </TableCell>
              
             <TableCell align="left">
                <LoadingButton loading={loading}  sx={mainThemeStyle.viewLogButtonRed} disabled={getDisableValue("80")} onClick={ () => handleCameraDelete(row.id,row.name)}>Delete</LoadingButton>
                    </TableCell>
             </TableRow>
           ))}
         </TableBody>
       
     </TblContainerCamera>
    
     </Grid>
       </Grid>
       
     
       <Grid container spacing={3}>
       <Grid item xs={5}>
       {/* <BelowTblPaginationBridge></BelowTblPaginationBridge> */}</Grid>
       <Grid item xs={1}>
       </Grid>
       <Grid item xs={6}>
       <BelowTblPaginationCamera></BelowTblPaginationCamera></Grid></Grid>
      
      
      
      
      </Box>}
      
      <Box sx={{display: { xs: "block", md: "none" }}}>
      <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
      
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>LPR Device </Typography>
      {/* <Typography sx={mainThemeStyle.popUpLable} height={40}>LPR Camera 1 Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>LPR Camera 2 Name </Typography> */}
      
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      
      
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="lpr_enable" onChange={handlecheckboxCheck} disabled={getDisableValue("80")} checked={dataOfLocation.lpr_enable == "1" ? true : false } />}
        label=""
      />
            {/* <Checkbox sx={mainThemeStyle.formCheckBox} name="lpr_enable" onChange={handlecheckboxCheck} checked={dataOfLocation.lpr_enable == "1" ? true : false}/> */}
           {/*  <TextField
                        id="input-lpr-camera1"
                        height={40}
                        type= 'text'
                        name="lpr_cam1"
                        value={dataOfLocation.lpr_cam1}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="lpr_cam2"
                        value={dataOfLocation.lpr_cam2}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    /> */}
     
          
      </Stack>
      </Grid>
     
      
      
      </Grid>
      {dataOfLocation.lpr_enable == "1" &&<Box sx={{display: { xs: "block", md: "none" }}}>
      <Grid container spacing={2} padding= '40px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={12}>
        <Stack direction="column"  spacing={2} >
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Add Bridge </Typography>
      
      </Stack>
      </Grid>
      
      <Grid item xs={12}>
      <Stack direction="column"  spacing={2}>
      <Stack direction="row"  spacing={2}>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("80")}
      value={currentBridge}
      label="Age"
      onChange={handleChangeBridge}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {addBridge?.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            );
          })}
      </Select>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("80")}
      value={currentBridgeType}
      label="Age"
      onChange={handleChangeBridgeType}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {BriType.items.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.label}>
                {menuItem.label}
              </MenuItem>
            );
          })}
      </Select>
      <LoadingButton sx={mainThemeStyle.normalButton}  disabled={getDisableValue("80") == false ? ((recordsBridge.length > 0 )? true : false) :true}  loading={loading} onClick={handleAddBridge} >Add</LoadingButton>
     </Stack>
      </Stack>
      </Grid>

      
     
      
      
      </Grid>
      
      
      
      <TblContainerBridge>
                     <TblHeadBridge />
         <TableBody>
          {recordsAfterPagingAndSortingBridge().map((row) => (
             <TableRow sx={{ borderBottom: "1.5px solid black",
           }}>
               
               <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.type == "0" ? "Dedicated" :"Shared"} </TableCell>
              
               <TableCell align="left">
              
                 <LoadingButton loading={loading}  sx={mainThemeStyle.viewLogButtonRed}disabled={getDisableValue("80")}  onClick={ () => handleBridgeDelete(row.id,row.name)}>Delete</LoadingButton>
                 
                 </TableCell>
             </TableRow>
           ))}
         </TableBody>
       
     </TblContainerBridge>
    
    
    
    <Grid container spacing={2} padding= '40px' paddingBottom='32px' paddingTop='100px' > 
    <Grid item xs={12} >
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable}> Add Camera </Typography>
      </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="column"  spacing={2}>
          <Stack direction="row" spacing={2}>
          <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("80")}
      value={currentCamera}
      label="Age"
      onChange={handleChangeCamera}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {addCamera?.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            );
          })}
      </Select>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      disabled={getDisableValue("80")}
      value={currentCameraType}
      label="Age"
      onChange={handleChangeCameraType}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {CamType.items.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.label}>
                {menuItem.label}
              </MenuItem>
            );
          })}
      </Select>
             <LoadingButton sx={mainThemeStyle.normalButton} onClick={handleAddCamera} disabled={getDisableValue("80")} loading={loading}>Add</LoadingButton>
          </Stack>
      </Stack>
      </Grid>
      
     
      
      
      </Grid>
      
      <TblContainerCamera>
                     <TblHeadCamera />
         <TableBody>
          {recordsAfterPagingAndSortingCamera().map((row) => (
             <TableRow sx={{ borderBottom: "1.5px solid black",
           }}>
               
               <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.type == "0" ? "Entry" : "Exit"} </TableCell>
              
             <TableCell align="left">
                <LoadingButton loading={loading}  sx={mainThemeStyle.viewLogButtonRed} disabled={getDisableValue("80")} onClick={ () => handleCameraDelete(row.id,row.name)}>Delete</LoadingButton>
                    </TableCell>
             </TableRow>
           ))}
         </TableBody>
       
     </TblContainerCamera>
    
    <BelowTblPaginationCamera></BelowTblPaginationCamera>
      </Box>}
      </Box>

</>)}
{(currentTab == 5 && dataOfLocation.cam_brand == "Virtual Panel" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       QR Code
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />

           <img style={{
            display :'block',
            width : '50%',
            height : '50%',
            margin : '2rem auto'
           }} src={qr}>
           </img>
           <Button variant="contained"  style={{
            display :'block',
            width : '50%',
            height : '50%',
            margin : '2rem auto',
            textAlign : 'center',
            marginTop:'0rem',
            button: {
                textTransform: "none"
              },
            fontWeight: '700',
            fontSize: '0.93rem',
            
            
            alignItems: 'center',
            color: '#FFFFFF',
            fontFamily: "Lato",
            borderRadius:'0.31rem',
           '&:hover': {
            backgroundColor: 'rgba(109, 195, 252, 1)',
           }
           }} href={qr} download="qrcode.png">Download</Button>
</>)}
{(currentTab == 4 && dataOfLocation.cam_brand == "ResidentLink" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Hold Open Settings
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "block" }}}>
      <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={1}>
      <Typography  variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>Enable Hold Open </Typography>
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }}   onChange={handleChangeEventOfHoldOpen} disabled={getDisableValue("81")} checked={dataOfLocation.hold_open.holdopen == "1" ? true : false }/>}
        
      />
      </Stack>
      </Grid>
      </Grid>
       {dataOfLocation.hold_open.holdopen == "1" && <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={10}>
      <Stack direction="row"  spacing={4}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Indefinite Mode </Typography>
      
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name="indefinite" onChange={handleChangeValueOfHoldOpen} disabled={getDisableValue("81")} checked={dataOfLocation.hold_open.indefinite == "1" ? true : false}/>
      
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Recurring Schedule Mode </Typography>
     
      
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name="definite" onChange={handleChangeValueOfHoldOpen} disabled={getDisableValue("81")} checked={dataOfLocation.hold_open.definite == "1" ? true : false}/>
      
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>One-Time Schedule Mode </Typography>
      
      
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name="finite" onChange={handleChangeValueOfHoldOpen} disabled={getDisableValue("81")} checked={dataOfLocation.hold_open.finite == "1" ? true : false}/>
      </Stack>
      </Grid>
      </Grid>
      
      }
      
      {dataOfLocation.hold_open.holdopen == "1" && dataOfLocation.hold_open.indefinite == "1" &&
      <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={1}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 1 </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2 </Typography>
      </Stack>
      </Grid>
      <Grid item xs={1}>
      <Stack direction="column"  spacing={2}>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay1"  disabled={getDisableValue("81")} onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay1 == "1" ? true : false }/>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay2" disabled={getDisableValue("81")} onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay2 == "1" ? true : false }/>
      </Stack>
      </Grid>
      </Grid>}
      
      {dataOfLocation.hold_open.holdopen == "1" && dataOfLocation.hold_open.definite == "1" &&
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
          <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={3}>Relay Hold Open Times</TableCell>
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={7}>Weekdays</TableCell>
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={2}>Relay Number</TableCell>
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}></TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Begin Time</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>End Time</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>S</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>M</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>T</TableCell>
            <TableCell align="right"sx={mainThemeStyle.cellHeader}>W</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>T</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>F</TableCell>
            <TableCell align="right"sx={mainThemeStyle.cellHeader}>S</TableCell>
            <TableCell align="right"sx={mainThemeStyle.cellHeader}>1</TableCell>
            <TableCell align="right"sx={mainThemeStyle.cellHeader}>2</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsHoleOpen.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {row}
              </TableCell>
              <TableCell align="right">
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'25px',
            marginTop:'0',
            marginBottom:'0',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled={getDisableValue("81")}
            name = {parseInt(row)-1}
            value={dataOfLocation.hold_open.table[parseInt(row)-1]?dataOfLocation.hold_open.table[parseInt(row)-1].st:'12:00 AM'}
          onChange={handleChangeOpenStart}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          </TableCell>
           <TableCell align="right">  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'25px',
            marginTop:'0px',
            marginBottom:'0px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled={getDisableValue("81")}
            name = {parseInt(row)-1}
            value={dataOfLocation.hold_open.table[parseInt(row)-1] ? dataOfLocation.hold_open.table[parseInt(row)-1].en : '12:00 AM'}
          onChange={handleChangEndStart}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select> </TableCell>
          <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow}  disabled={getDisableValue("81")} name = {parseInt(row)-1} onChange={handleSundayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].s == "1" ? true : false):false}/></TableCell>	
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("81")} name = {parseInt(row)-1} onChange={handleMondayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].m == "1" ? true : false):false}/></TableCell>	
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("81")} name = {parseInt(row)-1} onChange={handleTuesdayRelay}  checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].t == "1" ? true : false):false}/></TableCell>	
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("81")} name = {parseInt(row)-1} onChange={handleWednesdayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].w == "1" ? true : false):false}/></TableCell>	
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("81")} name = {parseInt(row)-1} onChange={handleThursdayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].th == "1" ? true : false):false}/></TableCell>	
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("81")} name = {parseInt(row)-1} onChange={handleFridayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].f == "1" ? true : false):false}/></TableCell>	
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("81")} name = {parseInt(row)-1} onChange={handleSaturdayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].sa == "1" ? true : false):false}/></TableCell>	
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("81")} name = {parseInt(row)-1} onChange={handleHoldRelayOne} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].r1 == "1" ? true : false):false}/></TableCell>	
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("81")} name = {parseInt(row)-1} onChange={handleHoldRelayTwo} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].r2 == "1" ? true : false):false}/></TableCell>
              </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}

    {dataOfLocation.hold_open.holdopen == "1" && dataOfLocation.hold_open.finite == "1" &&
    <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Date</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Start Time</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>End Time</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 1</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2</Typography>
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <TextField
        id="date"
        type="date"
        name="expiry"
        value={dataOfLocation.hold_open.expiry}
        onChange={handleOneTimeValue}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled" 
                      disabled={getDisableValue("81")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
      <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "time_on"
            disabled={getDisableValue("81")}
            value={dataOfLocation.hold_open.time_on}
          onChange={handleOneTimeValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "time_off"
            disabled={getDisableValue("81")}
            value={dataOfLocation.hold_open.time_off}
          onChange={handleOneTimeValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay1" disabled={getDisableValue("81")}  onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay1 == "1" ? true : false }/>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay2" disabled={getDisableValue("81")} onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay2 == "1" ? true : false}/>
      </Stack>
      </Grid>
      </Grid>} </Box>

      <Box sx={{display: { xs: "block", md: "none" }}}>
      <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={8}>
      <Stack direction="column"  spacing={1}>
      <Typography  variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>Enable Hold Open </Typography>
      </Stack>
      </Grid>
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }}   onChange={handleChangeEventOfHoldOpen} disabled={getDisableValue("81")}  checked={dataOfLocation.hold_open.holdopen == "1" ? true : false }/>}
        
      />
      </Stack>
      </Grid>
      </Grid>
      {dataOfLocation.hold_open.holdopen == "1" && <Grid container spacing={2} padding= '27px' paddingBottom='32px' paddingTop='32px'> 
       <Stack direction="column"  spacing={2}>
       <Stack direction="row"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Indefinite Mode </Typography>
      
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name="indefinite" disabled={getDisableValue("81")}  onChange={handleChangeValueOfHoldOpen} checked={dataOfLocation.hold_open.indefinite == "1" ? true : false}/>
      </Stack>
      <Stack direction="row"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Recurring Schedule Mode </Typography>
     
      
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name="definite" disabled={getDisableValue("81")} onChange={handleChangeValueOfHoldOpen} checked={dataOfLocation.hold_open.definite == "1" ? true : false}/>
      </Stack>
      <Stack direction="row"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>One-Time Schedule Mode </Typography>
      
      
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name="finite" disabled={getDisableValue("81")} onChange={handleChangeValueOfHoldOpen} checked={dataOfLocation.hold_open.finite == "1" ? true : false}/>
      </Stack>
      </Stack>
      </Grid>}
      
      {dataOfLocation.hold_open.holdopen == "1" && dataOfLocation.hold_open.indefinite == "1" &&
      <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='10px'> 
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 1 </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2 </Typography>
      </Stack>
      </Grid>
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay1" disabled={getDisableValue("81")} onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay1 == "1" ? true : false }/>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay2" disabled={getDisableValue("81")} onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay2 == "1" ? true : false }/>
      </Stack>
      </Grid>
      </Grid>}
      
      {dataOfLocation.hold_open.holdopen == "1" && dataOfLocation.hold_open.definite == "1" &&
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '10px',
        marginRight:'10px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
          <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={3}>Relay Hold Open Times</TableCell>
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={7}>Weekdays</TableCell>
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={2}>Relay Number</TableCell>
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}></TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Begin Time</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>End Time</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>S</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>M</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>T</TableCell>
            <TableCell align="right"sx={mainThemeStyle.cellHeader}>W</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>T</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>F</TableCell>
            <TableCell align="right"sx={mainThemeStyle.cellHeader}>S</TableCell>
            <TableCell align="right"sx={mainThemeStyle.cellHeader}>1</TableCell>
            <TableCell align="right"sx={mainThemeStyle.cellHeader}>2</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsHoleOpen.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {row}
              </TableCell>
              <TableCell align="right">
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'25px',
            marginTop:'0px',
            marginBottom:'0px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled={getDisableValue("81")}
            name = {parseInt(row)-1}
            value={dataOfLocation.hold_open.table[parseInt(row)-1]?dataOfLocation.hold_open.table[parseInt(row)-1].st:'12:00 AM'}
          onChange={handleChangeOpenStart}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          </TableCell>
           <TableCell align="right">  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'25px',
            marginTop:'0px',
            marginBottom:'0px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled={getDisableValue("81")}
            name = {parseInt(row)-1}
            value={dataOfLocation.hold_open.table[parseInt(row)-1]?dataOfLocation.hold_open.table[parseInt(row)-1].en:'12:00 AM'}
          onChange={handleChangEndStart}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select> </TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("81")}  name = {parseInt(row)-1} onChange={handleSundayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].s == "1" ? true : false):false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("81")} name = {parseInt(row)-1} onChange={handleMondayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].m == "1" ? true : false):false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("81")} name = {parseInt(row)-1} onChange={handleTuesdayRelay}  checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].t == "1" ? true : false):false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("81")} name = {parseInt(row)-1} onChange={handleWednesdayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].w == "1" ? true : false):false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("81")} name = {parseInt(row)-1} onChange={handleThursdayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].th == "1" ? true : false):false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("81")} name = {parseInt(row)-1} onChange={handleFridayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].f == "1" ? true : false):false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("81")} name = {parseInt(row)-1} onChange={handleSaturdayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].sa == "1" ? true : false):false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("81")} name = {parseInt(row)-1} onChange={handleHoldRelayOne} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].r1 == "1" ? true : false):false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled={getDisableValue("81")} name = {parseInt(row)-1} onChange={handleHoldRelayTwo} checked={dataOfLocation.hold_open.table[parseInt(row)-1]?(dataOfLocation.hold_open.table[parseInt(row)-1].r2 == "1" ? true : false):false}/></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}

    {dataOfLocation.hold_open.holdopen == "1" && dataOfLocation.hold_open.finite == "1" &&
    <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='10px'> 
    <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Date</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Start Time</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>End Time</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 1</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2</Typography>
      </Stack>
      </Grid>
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <TextField
        id="date"
        type="date"
        name="expiry"
        value={dataOfLocation.hold_open.expiry}
        onChange={handleOneTimeValue}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled" 
                      disabled={getDisableValue("81")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
      <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "time_on"
            disabled={getDisableValue("81")}
            value={dataOfLocation.hold_open.time_on}
          onChange={handleOneTimeValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "time_off"
            disabled={getDisableValue("81")}
            value={dataOfLocation.hold_open.time_off}
          onChange={handleOneTimeValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay1" onChange={handleIndefiniteRelay} disabled={getDisableValue("81")} checked={dataOfLocation.hold_open.relay1 == "1" ? true : false }/>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay2" onChange={handleIndefiniteRelay} disabled={getDisableValue("81")} checked={dataOfLocation.hold_open.relay2 == "1" ? true : false}/>
      </Stack>
      </Grid>
      </Grid>}  </Box>
</>)}

{(currentTab == 5 && dataOfLocation.cam_brand == "ResidentLink" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Elevator Control Settings
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "block" }}}>
      <Grid container spacing={2} padding= '16px'> 
      <Grid item xs={3}>
      <Stack direction="column"  spacing={1}>
      <Typography  variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>Elevator Control Device </Typography>
      </Stack>
      </Grid>
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} onChange={handleChangeEventOfElevatorControl} disabled={getDisableValue("82")} checked={dataOfLocation.elevator_access.elevatorcontrol == "1" ? true : false}/>}
        
      />
      </Stack>
      </Grid>
      </Grid>
      
      {dataOfLocation.elevator_access.elevatorcontrol == 1 && 
      <Grid container spacing={2} padding= '16px'> 
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Elevator Controller IP Address</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}># of System Relays</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Call Elevator Relay Duration</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Floor Release Relay Duration</Typography>
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <TextField
        id="elevtaor-ip-address"
        type="text"
        value={dataOfLocation.elevator_access.ipaddress}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      name="ipaddress"
                      onChange={handleChangeEleValue}
                      variant="filled" 
                      disabled={getDisableValue("82")}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
     <TextField
        id="total-relays"
        type="number"
        value={dataOfLocation.elevator_access.totalrelays}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      name="totalrelays"
                      onChange={handleChangeEleValue}
                      variant="filled"
                      disabled={getDisableValue("82")}
                        InputProps={{
                          inputProps: { 
                            max: 66, min: 0 
                        },
                            sx: {
                              
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
      <TextField
        id="call-elevator-duration"
        type="number"
        value={dataOfLocation.elevator_access.elduration}
        name="elduration"
        onChange={handleChangeEleValue}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled={getDisableValue("82")}
                      variant="filled" 
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
      <TextField
        id="text"
        type="number"
        value={dataOfLocation.elevator_access.flduration}
        name="flduration"
        onChange={handleChangeEleValue}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled={getDisableValue("82")}
                      variant="filled"       
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
        
      </Stack>
      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={3}>
      <Stack direction="column"  spacing={2}>
      <Typography></Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Call Elevator</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Floor Release</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Elevator Control For App Unlock</Typography>
      </Stack>
      </Grid>
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
    <Typography></Typography>
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="diselevator" disabled={getDisableValue("82")} onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselevator == "1" ? true : false} />}
        label=""
      />
       <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="disrelease" disabled={getDisableValue("82")} onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.disrelease == "1" ? true : false} />}
        label=""
      />
       <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="diselapp" disabled={getDisableValue("82")} onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselapp == "1" ? true : false} />}
        label=""
      />
      {/* <Checkbox  sx={mainThemeStyle.formCheckBox} name="diselevator" onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselevator == "1" ? true : false}/>
      <Checkbox  sx={mainThemeStyle.formCheckBox} name="disrelease" onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.disrelease == "1" ? true : false}/>
      <Checkbox  sx={mainThemeStyle.formCheckBox} name="diselapp" onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselapp == "1" ? true : false}/>
       */}
     
        
      </Stack>
      </Grid>
      </Grid>}
      {dataOfLocation.elevator_access.elevatorcontrol == 1 && 
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>Elevator Relay Programming</TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsElevatorControl.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black"
          }}>
              <TableCell sx={mainThemeStyle.cellRow} >
              {parseInt(row*8)+1}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[parseInt(row*8)]}
        name={parseInt(row*8)}
        onChange={handleChangeEleInnerRelay}
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+1) ? true : false) :true}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"   
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+2}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[(row*8)+1]}
        name={(row*8)+1}
        onChange={handleChangeEleInnerRelay}
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+2) ? true : false) :true}
        variant="filled"   
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+3}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+2]}
        name={(row*8)+2}
        onChange={handleChangeEleInnerRelay}
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+3) ? true : false) :true}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+4}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+3]}
        name={(row*8)+3}
        onChange={handleChangeEleInnerRelay}                
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+4) ? true : false) :true}
        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+5}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+4]}
        name={(row*8)+4}
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+5) ? true : false) :true}
        onChange={handleChangeEleInnerRelay}   
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+6}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[(row*8)+5]}
        name={(row*8)+5}
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+6) ? true : false) :true}
        onChange={handleChangeEleInnerRelay}   
        variant="filled" 
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+7}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled" 
        value={ dataOfLocation.elevator_access.program[(row*8)+6]}
        name={(row*8)+6}
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+7) ? true : false) :true}
        onChange={handleChangeEleInnerRelay}  
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+8}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled" 
        value={dataOfLocation.elevator_access.program[(row*8)+7]}
        name={(row*8)+7}
        onChange={handleChangeEleInnerRelay}  
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+8) ? true : false) :true}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                         
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
    </Box>
    <Box sx={{display: { xs: "block", md: "none" }}}>
    <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={8}>
      <Stack direction="column"  spacing={1}>
      <Typography  variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>Elevator Control Device </Typography>
      </Stack>
      </Grid>
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} onChange={handleChangeEventOfElevatorControl} disabled={getDisableValue("82")} checked={dataOfLocation.elevator_access.elevatorcontrol == "1" ? true : false}/>}
        
      />
      </Stack>
      </Grid>
      </Grid>
      
      {dataOfLocation.elevator_access.elevatorcontrol == 1 && 
     <Grid container spacing={2} padding= '10px' paddingBottom='16px' paddingTop='16px'> 
     <Grid item xs={6}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Elevator IP Address</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}># of System Relays</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Call Elevator Relay Duration</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Floor Release Relay Duration</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Call Elevator</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Floor Release</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Elevator Control For App Unlock</Typography>
      </Stack>
      </Grid>
      <Grid item xs={6}>
      <Stack direction="column"  spacing={2}>
      <TextField
        id="elevtaor-ip-address"
        type="text"
        value={dataOfLocation.elevator_access.ipaddress}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      name="ipaddress"
                      disabled={getDisableValue("82")}
                      onChange={handleChangeEleValue}
                      variant="filled" 
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
     <TextField
        id="total-relays"
        type="number"
        value={dataOfLocation.elevator_access.totalrelays}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled={getDisableValue("82")}
                      name="totalrelays"
                      onChange={handleChangeEleValue}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 66, min: 0 
                        },
                            sx: {
                              
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
      <TextField
        id="call-elevator-duration"
        type="number"
        value={dataOfLocation.elevator_access.elduration}
        name="elduration"
        onChange={handleChangeEleValue}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled={getDisableValue("82")}
                      variant="filled" 
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
      <TextField
        id="text"
        type="number"
        value={dataOfLocation.elevator_access.flduration}
        name="flduration"
        onChange={handleChangeEleValue}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled={getDisableValue("82")}
                      variant="filled"       
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
        <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="diselevator" disabled={getDisableValue("82")} onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselevator == "1" ? true : false} />}
        label=""
      />
       <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="disrelease" disabled={getDisableValue("82")} onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.disrelease == "1" ? true : false} />}
        label=""
      />
       <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="diselapp" disabled={getDisableValue("82")} onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselapp == "1" ? true : false} />}
        label=""
      />
      </Stack>
      </Grid>
      
      </Grid>}
      {dataOfLocation.elevator_access.elevatorcontrol == 1 && 
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '10px',
        marginRight:'10px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>Elevator Relay Programming</TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsElevatorControl.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+1}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[parseInt(row*8)]}
        name={parseInt(row*8)}
        onChange={handleChangeEleInnerRelay}
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+1) ? true : false) :true}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"   
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+2}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[(row*8)+1]}
        name={(row*8)+1}
        onChange={handleChangeEleInnerRelay}
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+2) ? true : false) :true}
        variant="filled"   
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+3}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+2]}
        name={(row*8)+2}
        onChange={handleChangeEleInnerRelay}
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+3) ? true : false) :true}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+4}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+3]}
        name={(row*8)+3}
        onChange={handleChangeEleInnerRelay}                
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+4) ? true : false) :true}
        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+5}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+4]}
        name={(row*8)+4}
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+5) ? true : false) :true}
        onChange={handleChangeEleInnerRelay}   
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+6}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[(row*8)+5]}
        name={(row*8)+5}
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+6) ? true : false) :true}
        onChange={handleChangeEleInnerRelay}   
        variant="filled" 
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+7}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled" 
        value={ dataOfLocation.elevator_access.program[(row*8)+6]}
        name={(row*8)+6}
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+7) ? true : false) :true}
        onChange={handleChangeEleInnerRelay}  
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+8}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled" 
        value={dataOfLocation.elevator_access.program[(row*8)+7]}
        name={(row*8)+7}
        onChange={handleChangeEleInnerRelay}  
        disabled={getDisableValue("82") == false ? (dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+8) ? true : false) :true}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                         
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
    </Box>
   
    
</>)}

{(currentTab == 6 && dataOfLocation.cam_brand == "ResidentLink" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Brivo Control Settings
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
      
      
           <Box sx={{display: { xs: "none", md: "block" }}}>
      <Grid container spacing={2} padding= '16px'> 
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Brivo Primary Door Id</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}># of Brivo Floors</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>In-Call Relay Triggers</Typography>
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled={getDisableValue("84")}
            name = 'brivoprimeid'
            value={getBrivoValueMain()}
          onChange={handleChangeBrivoDoorValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}	
                  value={menuItem.name}>	
                      <Stack direction="row" justifyContent="left"	
 alignItems="center">	
                 { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
           <DoNotDisturbIcon fontSize="small" />	
         </ListItemIcon>} 	
           {menuItem.name}</Stack>	
                   </MenuItem>
                  );
                })}
          </Select>
          <TextField	
                        id="brivofloors"	
                        height={40}	
                        type= 'number'	
                        name="brivofloors"	
                        value={dataOfLocation.brivo_access.brivofloors}	
                        onChange={handleChangeBrivoFloorValue}	
                        sx={mainThemeStyle.InputMenuItem}	
                        InputLabelProps={{ shrink: true }	
                      }	
                      disabled={getDisableValue("84")}
                      variant="filled"	
                        InputProps={{	
                          inputProps: { 	
                            max: (dataOfLocation.brivo_array.length), min: 0 	
                        },	
                            sx: {	
                                	
                                color:'rgba(135, 135, 135, 0.5)',	
                                height:'33px',	
                                borderRadius: '5px',	
                                backgroundColor: '#121212',	
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',	
                                padding: '0 0 15px 0',	
                                textIndent: '15px !important',	
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               	
                                	
                            },	
                        }}	
                       	
                    />
       <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="icrt"  disabled = {getDisableValue("84")} onChange={handleBrivoInCallRelayTriggerCheck}  checked={dataOfLocation.brivo_access.icrt == null ? false : (dataOfLocation.brivo_access.icrt == "1" ? true : false)} />}
        label={dataOfLocation.brivo_access.icrt == null ? "Disabled" : (dataOfLocation.brivo_access.icrt == "1" ? "Enabled" : "Disabled")}
      />
      
        
      </Stack>
      </Grid>
      <Grid item xs={1}></Grid>
      { dataOfLocation.brivo_access.icrt == 1 && 
      <Grid item xs={6}>
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 350 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>In-Call Relay Trigger Settings</TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          </TableHead>
        <TableBody>
       
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell  sx={mainThemeStyle.cellHeader}>
              Custom Name 1 
              </TableCell>
             
                <TableCell align="right">
                <TextField
                        id="0"
                       
                        type= 'text'
                        name="0"
                        value={getCustomNameValue(0)}
                        onChange={handleChangeCustomNameValue}
                       
                        sx={{
                          ...mainThemeStyle.InputMenuItem,
                          marginTop:'10px',
                        }}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("84")}
                      variant="filled"
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    
                </TableCell>
                <TableCell align="right">
                
                <Select
              sx={{ color: 'rgba(135, 135, 135, 0.5)',
              height:'33px',
              marginTop:'8px',
              marginBottom:'3px',
              borderRadius: '5px',
              backgroundColor: '#121212',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              "& .MuiSvgIcon-root": {
                color: "rgba(135, 135, 135, 0.5)",
              },
              
              '&.Mui-disabled': {
               
  
                background: 'rgba(135, 135, 135, 0.5)',
              },
              "& input.Mui-disabled": {
                color: "white"
              }
              }}
              name = "0"
              value={getCustomDoorValue(0)}
            onChange={handleChangeCustomDoorValue}
            variant="filled"
            disabled={getDisableValue("84")}
            hiddenLabel
            MenuProps={MenuProps}>
              {brivoCustomArray?.map(menuItem => {
                    
                    return (
                      <MenuItem  sx={
                        {
                        
                        color:"#fff",
                        backgroundColor: "#2F323A",
                        borderBottom: '.5px solid grey',
                        fontFamily: "Lato",fontSize:'12px',
                    }}
                    disabled={menuItem.disabled}
                     value={menuItem.name}>
                         <Stack direction="row" justifyContent="left"
    alignItems="center">
                    { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
              <DoNotDisturbIcon fontSize="small" />
            </ListItemIcon>} 
              {menuItem.name}</Stack>
                      </MenuItem>
                    );
                  })}
            </Select>
                  </TableCell>
            </TableRow>
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell  sx={mainThemeStyle.cellHeader}>
              Custom Name 2 
              </TableCell>
             
                <TableCell align="right">
                <TextField
                        id="1"
                       
                        type= 'text'
                        name="1"
                        value={getCustomNameValue(1)}
                        onChange={handleChangeCustomNameValue}
                       
                        sx={{
                          ...mainThemeStyle.InputMenuItem,
                          marginTop:'10px',
                        }}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("84")}
                      variant="filled"
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    
                </TableCell>
                <TableCell align="right">
                
                <Select
              sx={{ color: 'rgba(135, 135, 135, 0.5)',
              height:'33px',
              marginTop:'8px',
              marginBottom:'3px',
              borderRadius: '5px',
              backgroundColor: '#121212',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              "& .MuiSvgIcon-root": {
                color: "rgba(135, 135, 135, 0.5)",
              },
              
              '&.Mui-disabled': {
               
  
                background: 'rgba(135, 135, 135, 0.5)',
              },
              "& input.Mui-disabled": {
                color: "white"
              }
              }}
              name = "1"
              value={getCustomDoorValue(1)}
            onChange={handleChangeCustomDoorValue}
            variant="filled"
            disabled={getDisableValue("84")}
            hiddenLabel
            MenuProps={MenuProps}>
              {brivoCustomArray?.map(menuItem => {
                    
                    return (
                      <MenuItem  sx={
                        {
                        
                        color:"#fff",
                        backgroundColor: "#2F323A",
                        borderBottom: '.5px solid grey',
                        fontFamily: "Lato",fontSize:'12px',
                    }}
                    disabled={menuItem.disabled}
                     value={menuItem.name}>
                         <Stack direction="row" justifyContent="left"
    alignItems="center">
                    { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
              <DoNotDisturbIcon fontSize="small" />
            </ListItemIcon>} 
              {menuItem.name}</Stack>
                      </MenuItem>
                    );
                  })}
            </Select>
                  </TableCell>
            </TableRow>
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell  sx={mainThemeStyle.cellHeader}>
              Custom Name 3 
              </TableCell>
             
                <TableCell align="right">
                <TextField
                        id="2"
                       
                        type= 'text'
                        name="2"
                        value={getCustomNameValue(2)}
                        onChange={handleChangeCustomNameValue}
                       
                        sx={{
                          ...mainThemeStyle.InputMenuItem,
                          marginTop:'10px',
                        }}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("84")}
                      variant="filled"
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    
                </TableCell>
                <TableCell align="right">
                
                <Select
              sx={{ color: 'rgba(135, 135, 135, 0.5)',
              height:'33px',
              marginTop:'8px',
              marginBottom:'3px',
              borderRadius: '5px',
              backgroundColor: '#121212',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              "& .MuiSvgIcon-root": {
                color: "rgba(135, 135, 135, 0.5)",
              },
              
              '&.Mui-disabled': {
               
  
                background: 'rgba(135, 135, 135, 0.5)',
              },
              "& input.Mui-disabled": {
                color: "white"
              }
              }}
              name = "2"
              value={getCustomDoorValue(2)}
            onChange={handleChangeCustomDoorValue}
            variant="filled"
            disabled={getDisableValue("84")}
            hiddenLabel
            MenuProps={MenuProps}>
              {brivoCustomArray?.map(menuItem => {
                    
                    return (
                      <MenuItem  sx={
                        {
                        
                        color:"#fff",
                        backgroundColor: "#2F323A",
                        borderBottom: '.5px solid grey',
                        fontFamily: "Lato",fontSize:'12px',
                    }}
                    disabled={menuItem.disabled}
                     value={menuItem.name}>
                         <Stack direction="row" justifyContent="left"
    alignItems="center">
                    { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
              <DoNotDisturbIcon fontSize="small" />
            </ListItemIcon>} 
              {menuItem.name}</Stack>
                      </MenuItem>
                    );
                  })}
            </Select>
                  </TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer></Grid>
    }
      </Grid>
     
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>Brivo Relays</TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>	Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsElevatorControl.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+1}
              </TableCell>
              <TableCell align="right">
                
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
           
            name = {parseInt(row*8)}
            value={getBrivoValue(parseInt(row*8))}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+1) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}	
                  value={menuItem.name}>	
                      <Stack direction="row" justifyContent="left"	
 alignItems="center">	
                 { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
           <DoNotDisturbIcon fontSize="small" />	
         </ListItemIcon>} 	
           {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>
                </TableCell>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+2}
              </TableCell>
              <TableCell align="right">
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
         
            name = {(row*8)+1}
            value={getBrivoValue(parseInt(row*8)+1)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+2) ? true : false) :true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}	
                  value={menuItem.name}>	
                      <Stack direction="row" justifyContent="left"	
 alignItems="center">	
                 { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
           <DoNotDisturbIcon fontSize="small" />	
         </ListItemIcon>} 	
           {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>
                
                
               </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+3}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            
            name = {(row*8)+2}
            value={getBrivoValue((row*8)+2)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+3) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}	
                  value={menuItem.name}>	
                      <Stack direction="row" justifyContent="left"	
 alignItems="center">	
                 { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
           <DoNotDisturbIcon fontSize="small" />	
         </ListItemIcon>} 	
           {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>
              
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+4}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            
            name = {(row*8)+3}
            value={getBrivoValue((row*8)+3)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+4) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}	
                  value={menuItem.name}>	
                       <Stack direction="row" justifyContent="left"	
 alignItems="center">	
                 { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
           <DoNotDisturbIcon fontSize="small" />	
         </ListItemIcon>} 	
           {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+5}
              </TableCell>
              <TableCell align="right">
                      <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
           
            name = {(row*8)+4}
            value={getBrivoValue((row*8)+4)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+5) ? true : false) : true}
         
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}	
                   value={menuItem.name}>	
                      <Stack direction="row" justifyContent="left"	
  alignItems="center">	
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
            <DoNotDisturbIcon fontSize="small" />	
          </ListItemIcon>} 	
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> 
                </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+6}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            
            name = {(row*8)+5}
            value={getBrivoValue((row*8)+5)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+6) ? true : false) : true}
         
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}	
                   value={menuItem.name}>	
                       <Stack direction="row" justifyContent="left"	
  alignItems="center">	
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
            <DoNotDisturbIcon fontSize="small" />	
          </ListItemIcon>} 	
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> 
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+7}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
           
            name = {(row*8)+6}
            value={getBrivoValue((row*8)+6)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+7) ? true : false) : true}
         
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}	
                  value={menuItem.name}>	
                      <Stack direction="row" justifyContent="left"	
 alignItems="center">	
                 { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
           <DoNotDisturbIcon fontSize="small" />	
         </ListItemIcon>} 	
           {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> 
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+8}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            
            name = {(row*8)+7}
            value={getBrivoValue((row*8)+7)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+8) ? true : false) : true}
         
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}	
                  value={menuItem.name}>	
                     <Stack direction="row" justifyContent="left"	
 alignItems="center">	
                 { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
           <DoNotDisturbIcon fontSize="small" />	
         </ListItemIcon>} 	
           {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
    <Box sx={{display: { xs: "block", md: "none" }}}>
    <Grid container spacing={2} padding= '10px' paddingBottom='5px' paddingTop='32px'>
    <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Brivo Primary Door Id</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}># of Brivo Floors</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>In-Call Relay Triggers</Typography>
      </Stack>
      </Grid>
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            disabled={getDisableValue("84")}
            name = 'brivoprimeid'
            value={getBrivoValueMain()}
          onChange={handleChangeBrivoDoorValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}	
                  value={menuItem.name}>	
                     <Stack direction="row" justifyContent="left"	
 alignItems="center">	
                 { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
           <DoNotDisturbIcon fontSize="small" />	
         </ListItemIcon>} 	
           {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>
          <TextField	
                        id="brivofloors"	
                        height={40}	
                        type= 'number'	
                        name="brivofloors"	
                        value={dataOfLocation.brivo_access.brivofloors}	
                        onChange={handleChangeBrivoFloorValue}	
                        sx={mainThemeStyle.InputMenuItem}	
                        InputLabelProps={{ shrink: true }	
                      }	
                      disabled={getDisableValue("84")}
                      variant="filled"	
                        InputProps={{	
                          inputProps: { 	
                            max: (dataOfLocation.brivo_array.length), min: 0 	
                        },	
                            sx: {	
                                	
                                color:'rgba(135, 135, 135, 0.5)',	
                                height:'33px',	
                                borderRadius: '5px',	
                                backgroundColor: '#121212',	
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',	
                                padding: '0 0 15px 0',	
                                textIndent: '15px !important',	
                              	
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                	
                            },	
                        }}	
                       	
                    />
      
      <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="icrt"  disabled = {getDisableValue("84")} onChange={handleBrivoInCallRelayTriggerCheck}  checked={dataOfLocation.brivo_access.icrt == null ? false : (dataOfLocation.brivo_access.icrt == "1" ? true : false)} />}
        label={dataOfLocation.brivo_access.icrt == null ? "Disabled" : (dataOfLocation.brivo_access.icrt == "1" ? "Enabled" : "Disabled")}
      />
      
        
      </Stack>
      </Grid>
      <Grid item xs={2}></Grid>
     
      </Grid>
      { dataOfLocation.brivo_access.icrt == 1 &&  <Grid container spacing={2} padding= '10px' paddingBottom='5px' paddingTop='32px'>
      <Grid item >
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 350 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>In-Call Relay Trigger Settings</TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          </TableHead>
        <TableBody>
       
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell  sx={mainThemeStyle.cellHeader}>
              Custom Name 1 
              </TableCell>
             
                <TableCell align="right">
                <TextField
                        id="0"
                       
                        type= 'text'
                        name="0"
                        value={getCustomNameValue(0)}
                        onChange={handleChangeCustomNameValue}
                       
                        sx={{
                          ...mainThemeStyle.InputMenuItem,
                          marginTop:'10px',
                        }}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("84")}
                      variant="filled"
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    
                </TableCell>
                <TableCell align="right">
                
                <Select
              sx={{ color: 'rgba(135, 135, 135, 0.5)',
              height:'33px',
              marginTop:'8px',
              marginBottom:'3px',
              borderRadius: '5px',
              backgroundColor: '#121212',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              "& .MuiSvgIcon-root": {
                color: "rgba(135, 135, 135, 0.5)",
              },
              
              '&.Mui-disabled': {
               
  
                background: 'rgba(135, 135, 135, 0.5)',
              },
              "& input.Mui-disabled": {
                color: "white"
              }
              }}
              name = "0"
              value={getCustomDoorValue(0)}
            onChange={handleChangeCustomDoorValue}
            variant="filled"
            disabled={getDisableValue("84")}
            hiddenLabel
            MenuProps={MenuProps}>
              {brivoCustomArray?.map(menuItem => {
                    
                    return (
                      <MenuItem  sx={
                        {
                        
                        color:"#fff",
                        backgroundColor: "#2F323A",
                        borderBottom: '.5px solid grey',
                        fontFamily: "Lato",fontSize:'12px',
                    }}
                    disabled={menuItem.disabled}
                     value={menuItem.name}>
                         <Stack direction="row" justifyContent="left"
    alignItems="center">
                    { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
              <DoNotDisturbIcon fontSize="small" />
            </ListItemIcon>} 
              {menuItem.name}</Stack>
                      </MenuItem>
                    );
                  })}
            </Select>
                  </TableCell>
            </TableRow>
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell  sx={mainThemeStyle.cellHeader}>
              Custom Name 2 
              </TableCell>
             
                <TableCell align="right">
                <TextField
                        id="1"
                       
                        type= 'text'
                        name="1"
                        value={getCustomNameValue(1)}
                        onChange={handleChangeCustomNameValue}
                       
                        sx={{
                          ...mainThemeStyle.InputMenuItem,
                          marginTop:'10px',
                        }}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("84")}
                      variant="filled"
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    
                </TableCell>
                <TableCell align="right">
                
                <Select
              sx={{ color: 'rgba(135, 135, 135, 0.5)',
              height:'33px',
              marginTop:'8px',
              marginBottom:'3px',
              borderRadius: '5px',
              backgroundColor: '#121212',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              "& .MuiSvgIcon-root": {
                color: "rgba(135, 135, 135, 0.5)",
              },
              
              '&.Mui-disabled': {
               
  
                background: 'rgba(135, 135, 135, 0.5)',
              },
              "& input.Mui-disabled": {
                color: "white"
              }
              }}
              name = "1"
              value={getCustomDoorValue(1)}
            onChange={handleChangeCustomDoorValue}
            variant="filled"
            disabled={getDisableValue("84")}
            hiddenLabel
            MenuProps={MenuProps}>
              {brivoCustomArray?.map(menuItem => {
                    
                    return (
                      <MenuItem  sx={
                        {
                        
                        color:"#fff",
                        backgroundColor: "#2F323A",
                        borderBottom: '.5px solid grey',
                        fontFamily: "Lato",fontSize:'12px',
                    }}
                    disabled={menuItem.disabled}
                     value={menuItem.name}>
                         <Stack direction="row" justifyContent="left"
    alignItems="center">
                    { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
              <DoNotDisturbIcon fontSize="small" />
            </ListItemIcon>} 
              {menuItem.name}</Stack>
                      </MenuItem>
                    );
                  })}
            </Select>
                  </TableCell>
            </TableRow>
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell  sx={mainThemeStyle.cellHeader}>
              Custom Name 3 
              </TableCell>
             
                <TableCell align="right">
                <TextField
                        id="2"
                       
                        type= 'text'
                        name="2"
                        value={getCustomNameValue(2)}
                        onChange={handleChangeCustomNameValue}
                       
                        sx={{
                          ...mainThemeStyle.InputMenuItem,
                          marginTop:'10px',
                        }}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("84")}
                      variant="filled"
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    
                </TableCell>
                <TableCell align="right">
                
                <Select
              sx={{ color: 'rgba(135, 135, 135, 0.5)',
              height:'33px',
              marginTop:'8px',
              marginBottom:'3px',
              borderRadius: '5px',
              backgroundColor: '#121212',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              "& .MuiSvgIcon-root": {
                color: "rgba(135, 135, 135, 0.5)",
              },
              
              '&.Mui-disabled': {
               
  
                background: 'rgba(135, 135, 135, 0.5)',
              },
              "& input.Mui-disabled": {
                color: "white"
              }
              }}
              name = "2"
              value={getCustomDoorValue(2)}
            onChange={handleChangeCustomDoorValue}
            variant="filled"
            disabled={getDisableValue("84")}
            hiddenLabel
            MenuProps={MenuProps}>
              {brivoCustomArray?.map(menuItem => {
                    
                    return (
                      <MenuItem  sx={
                        {
                        
                        color:"#fff",
                        backgroundColor: "#2F323A",
                        borderBottom: '.5px solid grey',
                        fontFamily: "Lato",fontSize:'12px',
                    }}
                    disabled={menuItem.disabled}
                     value={menuItem.name}>
                         <Stack direction="row" justifyContent="left"
    alignItems="center">
                    { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>
              <DoNotDisturbIcon fontSize="small" />
            </ListItemIcon>} 
              {menuItem.name}</Stack>
                      </MenuItem>
                    );
                  })}
            </Select>
                  </TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer></Grid></Grid>
    }
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '10px',
        marginRight:'10px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>Brivo Relays</TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>	Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsElevatorControl.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+1}
              </TableCell>
              <TableCell align="right">
                
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            
            name = {parseInt(row*8)}
            value={getBrivoValue(parseInt(row*8))}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+1) ? true : false) : true}
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}	
                  value={menuItem.name}>	
                      <Stack direction="row" justifyContent="left"	
 alignItems="center">	
                 { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
           <DoNotDisturbIcon fontSize="small" />	
         </ListItemIcon>} 	
           {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>
                </TableCell>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+2}
              </TableCell>
              <TableCell align="right">
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            
            name = {(row*8)+1}
            value={getBrivoValue(parseInt(row*8)+1)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+2) ? true : false) : true}
         
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}	
                  value={menuItem.name}>	
                       <Stack direction="row" justifyContent="left"	
 alignItems="center">	
                 { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
           <DoNotDisturbIcon fontSize="small" />	
         </ListItemIcon>} 	
           {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>
                
                
               </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+3}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
          
            name = {(row*8)+2}
            value={getBrivoValue((row*8)+2)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+3) ? true : false) : true}
         
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}	
                  value={menuItem.name}>	
                      <Stack direction="row" justifyContent="left"	
 alignItems="center">	
                 { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
           <DoNotDisturbIcon fontSize="small" />	
         </ListItemIcon>} 	
           {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>
              
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+4}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            
            name = {(row*8)+3}
            value={getBrivoValue((row*8)+3)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+4) ? true : false) : true}
         
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}	
                   value={menuItem.name}>	
                     <Stack direction="row" justifyContent="left"	
  alignItems="center">	
                  { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
            <DoNotDisturbIcon fontSize="small" />	
          </ListItemIcon>} 	
            {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select>
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+5}
              </TableCell>
              <TableCell align="right">
                      <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
           
            name = {(row*8)+4}
            value={getBrivoValue((row*8)+4)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+5) ? true : false) : true}
         
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}	
                  value={menuItem.name}>	
                      <Stack direction="row" justifyContent="left"	
 alignItems="center">	
                 { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
           <DoNotDisturbIcon fontSize="small" />	
         </ListItemIcon>} 	
           {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> 
                </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+6}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            
            name = {(row*8)+5}
            value={getBrivoValue((row*8)+5)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+6) ? true : false) : true}
         
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}	
                  value={menuItem.name}>	
                      <Stack direction="row" justifyContent="left"	
 alignItems="center">	
                 { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
           <DoNotDisturbIcon fontSize="small" />	
         </ListItemIcon>} 	
           {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> 
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+7}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
           
            name = {(row*8)+6}
            value={getBrivoValue((row*8)+6)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+7) ? true : false) : true}
         
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}	
                  value={menuItem.name}>	
                     <Stack direction="row" justifyContent="left"	
 alignItems="center">	
                 { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
           <DoNotDisturbIcon fontSize="small" />	
         </ListItemIcon>} 	
           {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> 
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+8}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            
            name = {(row*8)+7}
            value={getBrivoValue((row*8)+7)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={getDisableValue("84") == false ? (dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+8) ? true : false) : true}
         
          hiddenLabel
          MenuProps={MenuProps}>
            {brivoDoorArray?.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                  disabled={menuItem.disabled}	
                  value={menuItem.name}>	
                     <Stack direction="row" justifyContent="left"	
 alignItems="center">	
                 { menuItem.name == "None" && <ListItemIcon sx={{color: "red"}}>	
           <DoNotDisturbIcon fontSize="small" />	
         </ListItemIcon>} 	
           {menuItem.name}</Stack>
                    </MenuItem>
                  );
                })}
          </Select> </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
    
</>)}

{(currentTab == 7 && dataOfLocation.cam_brand == "ResidentLink"  && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                   Panel Device Information
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "block" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={40}> MDM Provider </Typography>
       
        
       
       
       
       </Stack>
      
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      
      <ToggleButtonGroup
      
      value={dataOfLocation.mdm_type ==  1 ? "1" : "0"}
      exclusive
      onChange={handleChangeMDMType}
      aria-label="Platform"
      disabled={getDisableValue("83")}
    >
      <ToggleButton  height = "40px" value="0" selectedColor="#24A0ED"  disabled={getDisableValue("83")} unSelectedColor="gray">Hexnode</ToggleButton>
      <ToggleButton   height = "40px" value="1" selectedColor="#24A0ED"  disabled={getDisableValue("83")} unSelectedColor="gray">Esper</ToggleButton>
      
    </ToggleButtonGroup>
      </Stack>
      </Grid>
     
      
      
      </Grid>
     
    
</Box>
           <Box sx={{display: { xs: "none", md: "block" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        {/* <Typography  sx={mainThemeStyle.popUpLable} height={50}> MDM Provider </Typography> */}
        {dataOfLocation.cam_brand == "ResidentLink" && 
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>MDM Device Id </Typography>}
    {/*     <Typography sx={mainThemeStyle.popUpLable} height={40}> Serial Number </Typography> */}
    {dataOfLocation.mdm_type == "0" && <Typography  sx={mainThemeStyle.popUpLable} height={40}> Device Serial Number </Typography>}
      {dataOfLocation.mdm_type == "0" && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Display Status </Typography>}
      {dataOfLocation.mdm_type == "0" && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Kiosk Enabled</Typography>}
      
      {dataOfLocation.mdm_type == "1" && <Typography  sx={mainThemeStyle.popUpLable} height={40}> Device Serial Number </Typography>}
     {/*  {dataOfLocation.mdm_type == "1" && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Display Status </Typography>}
      */} {dataOfLocation.mdm_type == "1" && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Status</Typography>}
     
      {/* <Typography  sx={mainThemeStyle.popUpLable} height={40}>Scan Device </Typography> */}
    {/*   <Typography  sx={mainThemeStyle.popUpLable} height={40}>Restart Device </Typography> */}
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      {/* <ToggleButtonGroup
      
      value={idOflocation.mdm_type ==  1 ? "1" : "0"}
      exclusive
      onChange={handleChangeMDMType}
      aria-label="Platform"
    >
      <ToggleButton  height = "40px" value="0" selectedColor="#24A0ED" unSelectedColor="gray">Hexnode</ToggleButton>
      <ToggleButton   height = "40px" value="1" selectedColor="#24A0ED" unSelectedColor="gray">Esper</ToggleButton>
      
    </ToggleButtonGroup> */}
      {dataOfLocation.cam_brand == "ResidentLink" && <TextField
        id="hexnode-id"
        type="text"
        name="device_id"
        value={dataOfLocation.device_id}
        onChange={handleInputChange}
        height={40}
        sx={mainThemeStyle.InputMenuItem}
        InputLabelProps={{
          shrink: true,
        }}
        variant="filled"
        disabled={getDisableValue("83")}
        InputProps={{
                           
          sx: {
              
              color: 'rgba(135, 135, 135, 0.5)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#121212',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '0 0 15px 0',
              textIndent: '15px !important',
          
              '&.Mui-disabled': {
             

                background: 'rgba(135, 135, 135, 0.5)',
              },
              
              "& input.Mui-disabled": {
                color: "white"
              }
              
          },
      }}
      />}
      {/* <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{  dataOfLocation.tablet_name} </Typography> */}
      {dataOfLocation.mdm_type == "0" && <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.hexnode.device != null ? (idOflocation.hexnode.device.serial_number != null ? idOflocation.hexnode.device.serial_number : 'N/A'):'N/A'} </Typography>}
      {dataOfLocation.mdm_type == "0" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{ idOflocation.hexnode.agent_active != null ? ( idOflocation.hexnode.agent_active ? 'True' : 'False'):'N/A'} </Typography>}
      {dataOfLocation.mdm_type == "0" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.hexnode.device != null ? (idOflocation.hexnode.device.is_kiosk? 'True' : 'False'):'N/A'} </Typography>}
      {dataOfLocation.mdm_type == "0" &&<LoadingButton sx={mainThemeStyle.normalButton}  disabled={getDisableValue("83")} loading={loading} onClick={handleScanDevice}>Click To Scan Panel</LoadingButton>}
      {dataOfLocation.mdm_type == "0" &&<LoadingButton sx={mainThemeStyle.normalButton}  disabled={getDisableValue("83")} loading={loading} onClick={handleRestartDevice}>Click to Restart Panel</LoadingButton>}
     

      {dataOfLocation.mdm_type == "1" && <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.esper != null ? (idOflocation.esper.hardwareInfo != null ? (idOflocation.esper.hardwareInfo.serialNumber != null ? idOflocation.esper.hardwareInfo.serialNumber : 'N/A'):'N/A'):'N/A'} </Typography>}
  {/*    {dataOfLocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{ idOflocation.esper != null ? ( idOflocation.esper.status > 0 ? 'On' : 'Off'):'N/A'} </Typography>}
  */}     {dataOfLocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.esper != null ? (idOflocation.esper.is_active  ? 'Online' : 'Offline'):'N/A'} </Typography>}
      {dataOfLocation.mdm_type == "1" &&<LoadingButton sx={mainThemeStyle.normalButton}  disabled={getDisableValue("83")} loading={loading} onClick={handlePingDevice}>Click To Ping Panel</LoadingButton>}
      {dataOfLocation.mdm_type == "1" &&<LoadingButton sx={mainThemeStyle.normalButton}  disabled={getDisableValue("83")} loading={loading} onClick={handleRebootDevice}>Click to Reboot Panel</LoadingButton>}
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
       
       {/*  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Total Application </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Total Policies </Typography>
      */} 
      {dataOfLocation.mdm_type == "0" &&<Typography  sx={mainThemeStyle.popUpLable} height={40}>Last Active </Typography>}
      {dataOfLocation.mdm_type == "0" &&<Typography  sx={mainThemeStyle.popUpLable} height={40}>Last Scanned </Typography>}

      {/* {dataOfLocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLable} height={40}>Last Seen </Typography>} */}
      {dataOfLocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLable} height={40}>Last Rebooted </Typography>}
      {dataOfLocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Timezone </Typography>}
    {/*   <Typography  sx={mainThemeStyle.popUpLable} height={40}>Lock Device </Typography> */}
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
       
       
       {/*  <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.hexnode.applications != null ? (idOflocation.hexnode.applications ? (idOflocation.hexnode.applications).length : 'N/A'):'N/A'} </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.hexnode.policies != null ? (idOflocation.hexnode.policies ? (idOflocation.hexnode.policies).length : 'N/A'):'N/A'} </Typography>
       */}
       {dataOfLocation.mdm_type == "0" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.hexnode != null ? (idOflocation.hexnode.lastreported != null ?  moment(idOflocation.hexnode.lastreported).format('YYYY-MM-DD HH:MM'): 'N/A'):'N/A'} </Typography>}
       {dataOfLocation.mdm_type == "0" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.hexnode.lastscanned!= null ? moment(idOflocation.hexnode.lastscanned).format('YYYY-MM-DD HH:MM'):'N/A'}</Typography>}
      

       
     {/*   {dataOfLocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.esper != null ? (idOflocation.esper.updated_on != null ? moment(idOflocation.esper.updated_on).format('YYYY-MM-DD HH:MM') : 'N/A'):'N/A'} </Typography>}
    */}   {dataOfLocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.esper != null ? (idOflocation.esper.boot_time!= null ? moment(idOflocation.esper.boot_time).format('YYYY-MM-DD HH:mm:ss') : 'N/A'):'N/A'} </Typography>}
      {dataOfLocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.esper!= null ? (idOflocation.esper.timezone_string != null ? idOflocation.esper.timezone_string : 'N/A'):'N/A'}</Typography>}
      
     {/*  <LoadingButton sx={mainThemeStyle.normalButton} loading={loading} onClick={handleLockDevice}>Click To Lock Panel</LoadingButton>
      */}
      </Stack>
      </Grid>
     
      
      </Grid></Box>
      <Box sx={{display: { xs: "block", md: "none" }}}>
           <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={7}>
        <Stack direction="column"  spacing={2}>
        {dataOfLocation.cam_brand == "ResidentLink" && 
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>MDM Device Id </Typography>}
    {/*     <Typography sx={mainThemeStyle.popUpLable} height={40}> Serial Number </Typography> */}
     <Typography  sx={mainThemeStyle.popUpLable} height={40}> Device Serial Number </Typography>
      {dataOfLocation.mdm_type == "0" && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Display Status </Typography>}
      {dataOfLocation.mdm_type == "0" &&  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Kiosk Enabled</Typography>}
      {dataOfLocation.mdm_type == "0" && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Last Active </Typography>}
      {dataOfLocation.mdm_type == "0" &&  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Last Scanned </Typography>}
      {dataOfLocation.mdm_type == "1" && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Status </Typography>}
      
      {dataOfLocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLable} height={40}>Last Rebooted </Typography>}
      {dataOfLocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLable} height={40}>Device Timezone </Typography>}
  
      {/* <Typography  sx={mainThemeStyle.popUpLable} height={40}>Scan Device </Typography> */}
    {/*   <Typography  sx={mainThemeStyle.popUpLable} height={40}>Restart Device </Typography> */}
    {dataOfLocation.mdm_type == "0" && <LoadingButton  sx={{
        width: 'auto',
        height: '2.06rem',
        marginTop:'0rem',
        button: {
            textTransform: "none"
          },
        fontWeight: '700',
        fontSize: '0.83rem',
        
        lineHeight:'0.93rem',
        alignItems: 'center',
        color: '#FFFFFF',
        fontFamily: "Lato",
        borderRadius:'0.31rem',
       '&:hover': {
        backgroundColor: 'rgba(109, 195, 252, 1)',
       }
       }}  disabled={getDisableValue("83")} loading={loading} onClick={handleScanDevice}>Click To Scan Panel</LoadingButton>}
       {dataOfLocation.mdm_type == "0" &&   <LoadingButton  sx={{
        width: 'auto',
        height: '2.06rem',
        marginTop:'0rem',
        button: {
            textTransform: "none"
          },
        fontWeight: '700',
        fontSize: '0.83rem',
        
        lineHeight:'0.93rem',
        alignItems: 'center',
        color: '#FFFFFF',
        fontFamily: "Lato",
        borderRadius:'0.31rem',
       '&:hover': {
        backgroundColor: 'rgba(109, 195, 252, 1)',
       }
       }} loading={loading}  disabled={getDisableValue("83")} onClick={handleRestartDevice}>Click to Restart Panel</LoadingButton>}

{dataOfLocation.mdm_type == "1" && <LoadingButton  sx={{
        width: 'auto',
        height: '2.06rem',
        marginTop:'0rem',
        button: {
            textTransform: "none"
          },
        fontWeight: '700',
        fontSize: '0.83rem',
        
        lineHeight:'0.93rem',
        alignItems: 'center',
        color: '#FFFFFF',
        fontFamily: "Lato",
        borderRadius:'0.31rem',
       '&:hover': {
        backgroundColor: 'rgba(109, 195, 252, 1)',
       }
       }} loading={loading}  disabled={getDisableValue("83")} onClick={handlePingDevice}>Click To Ping Panel</LoadingButton>}
       {dataOfLocation.mdm_type == "1" &&   <LoadingButton  sx={{
        width: 'auto',
        height: '2.06rem',
        marginTop:'0rem',
        button: {
            textTransform: "none"
          },
        fontWeight: '700',
        fontSize: '0.83rem',
        
        lineHeight:'0.93rem',
        alignItems: 'center',
        color: '#FFFFFF',
        fontFamily: "Lato",
        borderRadius:'0.31rem',
       '&:hover': {
        backgroundColor: 'rgba(109, 195, 252, 1)',
       }
       }} loading={loading}  disabled={getDisableValue("83")} onClick={handleRebootDevice}>Click to Reboot Panel</LoadingButton>}
        {/*  <LoadingButton  sx={{
        width: 'auto',
        height: '2.06rem',
        marginTop:'0rem',
        button: {
            textTransform: "none"
          },
        fontWeight: '700',
        fontSize: '0.83rem',
        
        lineHeight:'0.93rem',
        alignItems: 'center',
        color: '#FFFFFF',
        fontFamily: "Lato",
        borderRadius:'0.31rem',
       '&:hover': {
        backgroundColor: 'rgba(109, 195, 252, 1)',
       }
       }} loading={loading} onClick={handleLockDevice}>Click To Lock Panel</LoadingButton> */}
      </Stack>
      </Grid>
      
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      {dataOfLocation.cam_brand == "ResidentLink" && <TextField
        id="hexnode-id"
        type="text"
        name="device_id"
        value={dataOfLocation.device_id}
        onChange={handleInputChange}
        height={40}
        sx={mainThemeStyle.InputMenuItem}
        disabled={getDisableValue("83")}
        InputLabelProps={{
          shrink: true,
        }}
        variant="filled"
        InputProps={{
                           
          sx: {
              
              color: 'rgba(135, 135, 135, 0.5)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#121212',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '0 0 15px 0',
              textIndent: '15px !important',
          
              '&.Mui-disabled': {
             

                background: 'rgba(135, 135, 135, 0.5)',
              },
              
              "& input.Mui-disabled": {
                color: "white"
              }
              
          },
      }}
      />}
      {/* <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{  dataOfLocation.tablet_name} </Typography> */}
      {dataOfLocation.mdm_type == "0" && <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.hexnode.device != null ? (idOflocation.hexnode.device.serial_number != null ? idOflocation.hexnode.device.serial_number : 'N/A'):'N/A'} </Typography>}
      {dataOfLocation.mdm_type == "0" && <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{ idOflocation.hexnode.agent_active != null ? ( idOflocation.hexnode.agent_active ? 'True' : 'False'):'N/A'} </Typography>}
      {dataOfLocation.mdm_type == "0" && <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.hexnode.device != null ? (idOflocation.hexnode.device.is_kiosk? 'True' : 'False'):'N/A'} </Typography>}
    
    
      {dataOfLocation.mdm_type == "0" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.hexnode.lastreported != null ? (idOflocation.hexnode.lastreported? moment(idOflocation.hexnode.lastreported).format('YYYY-MM-DD HH:MM') : 'N/A'):'N/A'} </Typography>}
      {dataOfLocation.mdm_type == "0" && <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.hexnode.lastscanned!= null ? (moment(idOflocation.hexnode.lastscanned).format('YYYY-MM-DD HH:MM')):'N/A'}</Typography>}
      
      {dataOfLocation.mdm_type == "1" && <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.esper != null ? (idOflocation.esper.hardwareInfo != null ? (idOflocation.esper.hardwareInfo.serialNumber != null ? idOflocation.esper.hardwareInfo.serialNumber : 'N/A'):'N/A'):'N/A'} </Typography>}
    
    
      {/* {dataOfLocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{ idOflocation.esper != null ? ( idOflocation.esper.state  ? 'On' : 'Off'):'N/A'} </Typography>} */}
      {dataOfLocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.esper != null ? (idOflocation.esper.is_active  ? 'Online' : 'Offline'):'N/A'} </Typography>}
      
      
      {dataOfLocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.esper != null ? (idOflocation.esper.boot_time!= null ? moment(idOflocation.esper.boot_time).format('YYYY-MM-DD HH:mm:ss') : 'N/A'):'N/A'} </Typography>}
       {dataOfLocation.mdm_type == "1" &&<Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{idOflocation.esper!= null ? (idOflocation.esper.timezone_string ? idOflocation.esper.timezone_string : 'N/A'):'N/A'}</Typography>}
      
     
      </Stack>
      </Grid>
    
     
      
      </Grid></Box>
      </>
     
)}

</Box>
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleClose} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActive} autoFocus sx={mainThemeStyle.normalBigButton}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
 </> 
          
          
          
          
          ;
};

