import React from "react";
import {  ThemeProvider } from '@mui/material/styles';
import Theme from './components/ItemTheme'
import { Box } from "@mui/system";
import { Button, Grid, List, Typography } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import  NestedButtonDropDownHeader  from "./components/header/NestedButtonDropDownHeader";
import ManageLocation from "./ManageLocation";
import EditLocation from "./EditLocation";
import AddLocation from "./AddLocation";
import CallLog from "./CallLog";
import AddIcon from '@mui/icons-material/Add';
import LpTags from "./LpTags";
import { useState } from "react";
import GuestPIN from "./GuestPIN";
import GuestParking from "./GuestParking";
import { useParams,useNavigate ,useLocation } from "react-router-dom";
import { useEffect } from "react";
import PuffLoader from "react-spinners/PuffLoader"; 
import useToken from "./useToken";
import axios from "./axios";
const Location = (props) => {
  console.log("IN location Page");
  const navigate = useNavigate();
  const location = useLocation();
  const {RefreshToken} = props;
  const {id} = useParams();
  console.log('id is '+id);
  const {token} = useToken();
  if(location != null){
    console.log('id is '+location);
  }

  const theme = Theme;
  const[dataOfLocation,setDataOfLocation]  = useState('');
  const[addDataOfLocation,setaddDataOfLocation] = useState('');
  const getMyPostData = async () => {
    try {
      console.log("token id "+token.id+" and location id "+ location.state.editlocation.id);
      const res = await axios.get("/admin/location/"+token.id+"/"+location.state.editlocation.id,{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email },
      });
      if(res.data[0].elevator_access == ""){
        res.data[0].elevator_access = {elevatorcontrol:"0",ipaddress:"",totalrelays:"",elduration:"",flduration:"",diselevator:"",disrelease:"",diselapp:"",program:[null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]};
      }else{
      res.data[0].elevator_access = JSON.parse(res.data[0].elevator_access);
      }
      if(res.data[0].hold_open == ""){
        res.data[0].hold_open = {holdopen:"0",indefinite:"1",definite:"0",finite:"0",expiry:"",time_on:"12:00 AM",time_off:"12:00 AM",relay1:"0",relay2:"0",table:[{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"}]};
      }else{
      res.data[0].hold_open = JSON.parse(res.data[0].hold_open);
      if(res.data[0].hold_open.table == ""){
        res.data[0].hold_open.table = [{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"}];
      }
      }
      if(res.data[0].brivo_access == ""){
        res.data[0].brivo_access = {brivoprimeid:"",brivoprimename:"",brivofloors:"",icrt:0,icrs:[{name:"",id:""},{name:"",id:""},{name:"",id:""}],program:[null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]};
      }else{
      res.data[0].brivo_access = JSON.parse(res.data[0].brivo_access);
      }
      if(res.data[0].brivo_array == ""){
        res.data[0].brivo_array =new Array();
      }
      if(res.data[0].brivo_group_array == ""){
        res.data[0].brivo_group_array =new Array();
      }
      if(res.data[0].pdk_access == ""){
        res.data[0].pdk_access = {primeid:"",primename:"",secondid:"",secondname:""};
      }else{
      res.data[0].pdk_access = JSON.parse(res.data[0].pdk_access);
      }
      if(res.data[0].pdk_cloudid_array == ""){
        res.data[0].pdk_cloudid_array =new Array();
      }
      if(res.data[0].pdk_group_array == ""){
        res.data[0].pdk_group_array =new Array();
      }
      if(res.data[0].pdk_device_array == ""){
        res.data[0].pdk_device_array =new Array();
      }

      if(res.data[0].pin_length == ""){
        res.data[0].pin_length = 4;
      }
      if(res.data[0].brivoDoor == ""){
        res.data[0].brivoDoor =new Array();
      }
     // res.data[0].hold_open = JSON.parse(JSON.stringify(res.data[0].hold_open));
     // res.data[0].brivo_access = JSON.parse(JSON.stringify(res.data[0].brivo_access));
      setDataOfLocation(res.data[0]);
     //// dataOfLocation.elevator_access = JSON.parse(dataOfLocation.elevator_access);
      //dataOfLocation.hold_open = JSON.parse(dataOfLocation.hold_open);
     // dataOfLocation.brivo_access = JSON.parse(dataOfLocation.brivo_access);
     // setDataOfLocation(dataOfLocation);
      console.log("dataof loca"+JSON.stringify( res.data[0]));
    } catch (error) {
      console.log(error);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };
 
  const getAddPostData = async () => {
    try {
      const res = await axios.get("/admin/addocation/"+token.id,{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email },
      });
      console.log("add location data"+JSON.stringify(res.data));
      let briarray =new Array();
      setaddDataOfLocation({
        loc_name: "",
        loc_no: "",
        loc_photo: "",
        loc_code: "",
        loc_search_code: "",
        cam_brand: "ResidentLink",
        cam_ip: "",
        area_code:"",
        cam_rtsp: "",
        cam_username: "",
        cam_password: "",
        userid: token.id,
        licence_key: "",
        expirydate:res.data.expirydate,
        added_by: "",
        status: 0,
        date_purchased: res.data.date_purchased,
        subscription: res.data.subscription,
        sub_id: res.data.sub_id,
        pin_length: "4",
        delivery_pin: "",
        warning_phone: res.data.user_data.office,
        loc_property_name: res.data.user_data.copmpany,
        unlocking_mode: 2,
        vegax_duration: 0,
        vegax_second_duration: 0,
        relay_one_fname: "",
        relay_two_fname: "",
        tablet_name: "",
        loc_office_name: 'Office',
        loc_add_one: res.data.user_data.address,
        loc_add_two: res.data.user_data.city+", "+  res.data.user_data.state + " "+ res.data.user_data.zip_code,
        loc_telephone_dial: res.data.user_data.office,
        wel_message: "",
        wel_message_header: "",
        wel_message_headerlogo: "https://cdn-icons-png.flaticon.com/512/1028/1028741.png",
        wel_message_headercolor : "#ffffff",
        loc_url_watermark: "http://mydoorview.com/wp-content/uploads/2019/02/my-door-view-updated-2019-logo-75px.png",
        loc_refresh_rate: 5,
        loc_screen_layout: 1,
        setting_reftesh_rate: 10,
        web_status: 0,
        loc_zip_code: "",
        r_pin_off: 0,
        g_pin_off: 0,
        lkey_reset: 0,
        pin_blaacklist: "",
        time_zone: "",
        pin_start_time: "12:00 AM",
        pin_end_time: "11:59 PM",
        card_start_time:"12:00 AM",
        card_end_time:"11:59 PM",
        device_id: "",
        rebootday: "None",
        reboottime: "11:59 PM",
        rfid_enable: 0,
        app_unlock_mode: 0,
        lpr_enable: 0,
        lpr_cam1: "",
        lpr_cam2: "",
        hold_open: {holdopen:"0",indefinite:"1",definite:"0",finite:"0",expiry:"",time_on:"12:00 AM",time_off:"12:00 AM",relay1:"0",relay2:"0",table:[{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"}]},
        elevator_access:  {elevatorcontrol:"0",ipaddress:"",totalrelays:"",elduration:"",flduration:"",diselevator:"",disrelease:"",diselapp:"",program:[null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]},
        brivo_access: {brivoprimeid:"",brivoprimename:"",brivofloors:"",icrt:0,icrs:[{name:"",id:""},{name:"",id:""},{name:"",id:""}],program:[null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]},
        pdk_access: {primeid:"",primename:"",secondid:"",secondname:""},
        dis_off: 0,
        brivoDoor:briarray,
        dis_dir: 0,
        dis_doorpin: 0,
        dis_del: 0,
        enable_exe_weigand: 0,
        default_vega_state: 0,
        default_vega_state_2: 0,
        pdk_enable: res.data.pdk_enable,
        brivo_enable: res.data.brivo_enable,
        lpr_accesss:res.data.lpr_accesss,
        pdk_node: "",
        pdk_group:"",
        brivo_group_name: "",
        camera:res.data.camera,	
        bridge:res.data.bridge,
        elevator_accesss_control: res.data.elevator_accesss_control,
        brivo_array:briarray,
        pdk_cloudid_array:briarray,
        pdk_group_array:briarray,
        pdk_device_array:briarray,
        image_upload_data:"",
        dis_prox:0,
        latitude:"",
        longitude:"",
        realy_hostname:"",
        last_abre:0,
        relay_fail_over:0,
        });
    } catch (error) {
      console.log(error.response);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };
  useEffect(()=>{
   if(id == 'editlocation'){
    getMyPostData();
   }
   if(id == 'addlocation'){
    setaddDataOfLocation('');
    getAddPostData();
   }
   else{
    setDataOfLocation('');
   }
  },[id]);
  
  
 const handleAdd = () =>{
  navigate('../location/addlocation');
 }
  
 const handleEdit = () =>{
  navigate('../location/managelocation');
 }

  return (
<ThemeProvider theme={theme}>

    <Box sx={mainThemeStyle.mainbox} backgroundColor="menubarprimary.main">
    <Box sx={mainThemeStyle.box}>
    {(id === 'managelocation' &&  <Typography sx={mainThemeStyle.lable}>Devices</Typography>)}
    {(id === 'addlocation' &&  <Typography sx={mainThemeStyle.lable}>Add Device</Typography>)}
    {(id === 'editlocation' &&  <Typography sx={mainThemeStyle.lable}>Device : {location.state.editlocation.loc_name}</Typography>)}
   {/*  {token.name == 'SUPER ADMIN' && <Button
                  variant="text"
                  key="user"
                  disabled
                 
                  sx={{ mt: "-6px", right:"0px",position: "absolute", color: "white","&:disabled": {color: "#68BBE3" ,backgroundColor:'#ffffff00'}, height: "60px",borderRadius: 0,fontFamily: "Lato",
               fontSize:'100%',width:'400px'}}
                >
                   Currently Managing :  {token.fname}
                  
                </Button>} */}
    </Box>
  {/*   {(id === 'managelocation' &&
    <Button startIcon={<AddIcon />} sx={mainThemeStyle.activityButton} onClick={handleAdd} >Add Location</Button>)}
   {(id === 'addlocation' && <Button sx={mainThemeStyle.activityButton} onClick={handleEdit} >View Locations</Button>)}
   {(id === 'editlocation' && <Button sx={mainThemeStyle.activityButton} onClick={handleEdit} >View Locations</Button>)}
   */}
    {(id === 'managelocation' && <ManageLocation RefreshToken={RefreshToken}/>)}
   {(id === 'addlocation'  && addDataOfLocation != '' && <AddLocation idOflocation={addDataOfLocation} RefreshToken={RefreshToken}/>)}
   {(id === 'addlocation'  && addDataOfLocation == '' &&<PuffLoader color="#878787" loading cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"250px"}} />)}
   {(id === 'editlocation' && dataOfLocation != '' && <EditLocation idOflocation={dataOfLocation} RefreshToken={RefreshToken}/>)}
   {(id === 'editlocation'  && dataOfLocation == '' &&<PuffLoader color="#878787" loading cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"250px"}} />)}
    
    </Box>
   
</ThemeProvider>);
};

export default Location;