import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Support } from '@mui/icons-material';
  

export const ActivityData = {
  id: "1",
  title: "Activity",
  label: "Activity",
  items: [
    {
      label: "Entry Logs",
      
      callback: (navigate) =>  navigate('/activity/entrylogs')
    },
    {
      label: "Call Logs",
      
      callback: (navigate) => navigate('/activity/calllogs')
    },
    {
      label: "LP Tag Logs",
      
      callback: (navigate) => navigate('/activity/lptaglogs')
    },
    {
      label: "Admin Logs",
      
      callback: (navigate) => navigate('/activity/adminlogs')
    },
    
    /*{
      label: "Guest PIN",
      
      callback: (navigate) => navigate('/activity/guestpin')
    },
    {
      label: "Guest Parking",
      
      callback: (navigate) => navigate('/activity/guestparking')
    },*/
  ]
};

export const UserActivityData= {
  id: "1",
  title: "Activity",
  label: "Activity",
  items: [
    {
      label: "Entry Logs",
      
      callback: (navigate) =>  navigate('/activity/entrylogs')
    },
    {
      label: "Call Logs",
      
      callback: (navigate) => navigate('/activity/calllogs')
    },
    {
      label: "LP Tag Logs",
      
      callback: (navigate) => navigate('/activity/lptaglogs')
    },
    /* ,
    {
      label: "Admin Logs",
      
      callback: (navigate) => navigate('/activity/adminlogs')
    } */
  ]
};

export const LocationData ={
  id: "1",
  title: "Devices",
  label: "Devices",
  items: [

    {
      label: "Add Device",
      
      callback: (navigate) => navigate('/location/addlocation')
    },
    {
      label: "Manage Devices",
      
      callback: (navigate) => navigate('/location/managelocation')
    },
 
 ]
};

export const UserLocationData={
  id: "1",
  title: "Devices",
  label: "Devices",
  items: [

    {
      label: "User Devices",
      
      callback: (navigate) => navigate('/location')
    },
 
 ]
};
export const UserData ={
  id: "1",
  title: "User",
  label: "User",
  items: [

    {
      label: "Add Users",
      
      callback: (navigate) => navigate('/user/adduser')
    },
    {
      label: "Manage Users",
      
      callback: (navigate) => navigate('/user/manageuser')
    },
  {
    label: "Groups",
    
    items: [
      {
        label: "Family Group",
        
        callback: (navigate) => navigate('/user/groups/callinggroup')
      },
      {
        label: "Office Group",
        
        callback: (navigate) => navigate('/user/groups/officegroup')
      }
    ]
  },
  {
    label: "Guest PIN",
    
    callback: (navigate) => navigate('/user/guestpin')
  },
  {
    label: "Guest Parking",
    
    callback: (navigate) => navigate('/user/guestparking')
  },
  {
    label: "Master Search",
    
    callback: (navigate) => navigate('/user/search')
  },
 ]
};

export const UserUserData={
  id: "1",
  title: "User",
  label: "User",
  items: [

    {
      label: "Call Groups",
      
      callback: (navigate) => navigate('/user/callgroup')
    },
    {
      label: "License Plate",
      
      callback: (navigate) => navigate('/user/license')
    },
  {
    label: "Guest PIN",
    
    callback: (navigate) => navigate('/user/guestpin')
  },
  {
    label: "Guest Parking",
    
    callback: (navigate) => navigate('/user/guestparking')
  },
 ]
};
export const UserProfileData={
  id: "1",
  title: "Profile",
  label: "Profile",
  items: [
    {
      label: "Profile",
      
      callback: (navigate) => navigate('/profile')
    },
  ]
  }
export const SetUpData ={
  id: "1",
  title: "Set Up",
  label: "Set Up",
  items: [

    {
      label: "API",
      
      callback: (navigate) => navigate('/setup/API')
    },
    {
      label: "RFID Card Format",
      
      callback: (navigate) => navigate('/setup/rfidcardformat')
    },
  {
    label: "Blacklist",
    
    items: [
      {
        label: "LP Blacklist",
        
        callback:  (navigate) =>navigate('/setup/blacklist/lpblacklist')
      },
      {
        label: "PIN Blacklist",
        
        callback: (navigate) =>navigate('/setup/blacklist/pinblacklist')
      }
    ]
  },
  {
    label: "Account",
    
    items: [
      {
        label: "Profile",
        
        callback: (navigate) =>navigate('/setup/account/profile')
      },
      {
        label: "Account Manager",
        
        callback: (navigate) =>navigate('/setup/account/property')
      },
     /* {
        label: "Passwords",
        
        callback: (navigate) =>navigate('/setup/account/password')
      }*/
    ]
  },
 ]
};

export const DistributorData = {
  id: "1",
  title: "Partner",
  label: "Partner",
  items: [
    {
      label: "Manage Partner",
      
      callback: (navigate) =>  navigate('/distributor/managedistributor')
    },
    {
      label: "Add Partner",
      
      callback: (navigate) => navigate('/distributor/adddistributor')
    }
   
  ]
};

export const DealerData = {
  id: "1",
  title: "Dealer",
  label: "Dealer",
  items: [
    {
      label: "Manage Dealer",
      
      callback: (navigate) =>  navigate('/dealer/managedealer')
    },
    {
      label: "Add Dealer",
      
      callback: (navigate) => navigate('/dealer/adddealer')
    }
   
  ]
};

export const PropertyOwnerData = {
  id: "1",
  title: "Accounts",
  label: "Accounts",
  items: [
    {
      label: "Manage Accounts",
      
      callback: (navigate) =>  navigate('/propertyowner/manageproperty')
    },
    {
      label: "Add Accounts",
      
      callback: (navigate) => navigate('/propertyowner/addproperty')
    }
   
  ]
};

export const MainAdminLocationData ={
  id: "1",
  title: "More Options",
  label: "More Options",
  items: [
    {
      label: "Devices",
      
      callback: (navigate) => navigate('/extra/location')
    }/* ,
    {
      label: "User",
      
      callback: (navigate) => navigate('/extra/user')
    } */,
    {
      label: "Twilio Number",
      
      callback: (navigate) => navigate('/extra/twilio')
    },
    {
      label: "Features",
      
      callback: (navigate) => navigate('/extra/features')
    },
    {
      label: "System Logs",
      
      callback: (navigate) => navigate('/extra/systemlogs')
    },
    {
      label: "Global Alerts",
      
      callback: (navigate) => navigate('/extra/globalalert')
    },
    {
      label: "Super Admin Manager",
      
      callback: (navigate) => navigate('/extra/manager')
    },
    {
      label: "Role-Based Access Control",
      
      callback: (navigate) => navigate('/extra/roleaccess')
    },
    {
      label:'One-Time Code Override',
      
      callback: (navigate) => navigate('/extra/otpoverride')
    },
    {
      label:'Manage Sale Items',
      
      callback: (navigate) => navigate('/extra/saleitems')
    },
    {
      label:'Manage Promo Codes',
      
      callback: (navigate) => navigate('/extra/managepromo')
    },
    {
      label: "Order History",
      
      callback: (navigate) =>  navigate('/extra/manageorder')
    }
 
 ]
};
export const DealerDistData = {
  id: "1",
  title: "Dealer",
  label: "Dealer",
  items: [
    {
      label: "Manage Dealer",
      
      callback: (navigate) =>  navigate('/dealer/managedealer')
    },
    {
      label: "Add Dealer",
      
      callback: (navigate) => navigate('/dealer/adddealer')
    }
   
  ]
};
export const PropertyOwnerDistData = {
  id: "1",
  title: "Accounts",
  label: "Accounts",
  items: [
    {
      label: "View Accounts",
      
      callback: (navigate) =>  navigate('/property')
    }
   
  ]
};
export const DistribuorProfileData = {
  id: "1",
  title: "Profile",
  label: "Profile",
  items: [
    {
      label: "Profile",
      
      callback: (navigate) =>  navigate('/profile')
    }
   
  ]
};
export const DistribuorManagerData = {
  id: "1",
  title: "Set Up",
  label: "Set Up",
  items: [
    {
      label: "Partner Manager",
      
      callback: (navigate) =>  navigate('/setup/manager')
    },
    {
      label: "System Logs",
      
      callback: (navigate) =>  navigate('/setup/syslogs')
    }
  ]
};
export const PropertyOwnerDealerData = {
  id: "1",
  title: "Accounts",
  label: "Accounts",
  items: [
    {
      label: "Manage Accounts",
      
      callback: (navigate) =>  navigate('/property/manage')
    },
    {
      label: "Add Accounts",
      
      callback: (navigate) =>  navigate('/property/add')
    }
   
  ]
};
export const DealerProfileData = {
  id: "1",
  title: "Profile",
  label: "Profile",
  items: [
    {
      label: "Profile",
      
      callback: (navigate) =>  navigate('/profile')
    }
   
  ]
};
export const DealerManagerData = {
  id: "1",
  title: "Set Up",
  label: "Set Up",
  items: [
    {
      label: "Dealer Manager",
      
      callback: (navigate) =>  navigate('/setup/manager')
    },
    
    {
     /*  label: "Password",
      
      callback: (navigate) =>  navigate('/setup/password') */

       label: "System Logs",
      
      callback: (navigate) =>  navigate('/setup/syslogs')
    },

  /*  {

      label: "New Order",

      

      callback: (navigate) =>  navigate('/setup/neworder')

    },*/

    {

      label: "Order History",

      

      callback: (navigate) =>  navigate('/setup/manageorder')

    }
    
   
  ]
};

export const SupportData = {
  id: "1",
  title: "Support",
  label: "Support",
  items: [
    {
      label: "Support",
      
      callback: (navigate) =>  window.open("https://mydoorview.com/resources", '_blank')
    },
  ]
};
export const SideMenu = [{
  title: "Home",
  label: "Home",
  icon: <HomeOutlinedIcon />,
  items: [],
  callback: (navigate) =>  navigate('/')
},
{
  title: "Activity",
  label: "Activity",
  icon: <StickyNote2OutlinedIcon />,
  items: [
    {
      label: "Entry Logs",
      
      callback: (navigate) =>  navigate('/activity/entrylogs')
    },
    {
      label: "Call Logs",
      
      callback: (navigate) => navigate('/activity/calllogs')
    },
    {
      label: "LP Tag Logs",
      
      callback: (navigate) => navigate('/activity/lptaglogs')
    },
   /* {
      label: "Guest PIN",
      
      callback: (navigate) => navigate('/activity/guestpin')
    },
    {
      label: "Guest Parking",
      
      callback: (navigate) => navigate('/activity/guestparking')
    }, */
  ]
},
{
  title: "Devices",
  label: "Devices",
  icon: <LocationOnOutlinedIcon/>,
  items: [

    {
      label: "Add Device",
      
      callback: (navigate) => navigate('/location/addlocation')
    },
    {
      label: "Manage Devices",
      
      callback: (navigate) => navigate('/location/managelocation')
    },
 
 ]
},

{
  title: "User",
  label: "User",
  icon: <PersonOutlinedIcon />,
  items: [

    {
      label: "Add Users",
      
      callback: (navigate) => navigate('/user/adduser')
    },
    {
      label: "Manage Users",
      
      callback: (navigate) => navigate('/user/manageuser')
    },
  {
    label: "Groups",
    
    items: [
      {
        label: "Family Group",
        
        callback: (navigate) =>navigate('/user/groups/callinggroup')
      },
      {
        label: "Office Group",
        
        callback: (navigate) =>navigate('/user/groups/officegroup')
      }
    ]
  },
  {
    label: "Guest PIN",
    
    callback: (navigate) => navigate('/user/guestpin')
  },
  {
    label: "Guest Parking",
    
    callback: (navigate) => navigate('/user/guestparking')
  },
  
  {
    label: "Master Search",
    
    callback: (navigate) => navigate('/user/search')
  },
 ]
},
{
  title: "Set Up",
  label: "Set Up",
  icon: <AdminPanelSettingsOutlinedIcon />,
  items: [

    {
      label: "API",
      
      callback: (navigate) => navigate('/setup/API')
    },
    {
      label: "RFID Card Format",
      
      callback:  (navigate) => navigate('/setup/rfidcardformat')
    },
  {
    label: "Blacklist",
    
    items: [
      {
        label: "LP Blacklist",
        
        callback: (navigate) =>navigate('/setup/blacklist/lpblacklist')
      },
      {
        label: "PIN Blacklist",
        
        callback: (navigate) =>navigate('/setup/blacklist/pinblacklist')
      }
    ]
  },
  {
    label: "Account",
    
    items: [
      {
        label: "Profile",
        
        callback: (navigate) =>navigate('/setup/account/profile')
      },
      {
        label: "Account Manager",
        
        callback: (navigate) =>navigate('/setup/account/property')
      },
      /*{
        label: "Passwords",
        
        callback: (navigate) =>navigate('/setup/account/password')
      }*/
    ]
  },
 ]
},
{
  title: "Support",
  label: "Support",
  icon: <SupportAgentIcon />,
  items: [
    {
      label: "Support",
      
      callback: (navigate) =>  window.open("https://mydoorview.com/resources", '_blank')
    },
  ]
}
];

export const SideMenuAdministrator = [{
  title: "Home",
  label: "Home",
  icon: <HomeOutlinedIcon />,
  items: [],
  callback: (navigate) =>  navigate('/')
},
{
  title: "Partner",
  label: "Partner",
  icon: <PersonOutlinedIcon />,
  items: [
    {
      label: "Manage Partner",
      
      callback: (navigate) =>  navigate('/distributor/managedistributor')
    },
    {
      label: "Add Partner",
      
      callback: (navigate) => navigate('/distributor/adddistributor')
    }
   
  ]
},
{
  title: "Dealer",
  label: "Dealer",
  icon: <PersonOutlinedIcon />,
  items: [
    {
      label: "Manage Dealer",
      
      callback: (navigate) =>  navigate('/dealer/managedealer')
    },
    {
      label: "Add Dealer",
      
      callback: (navigate) => navigate('/dealer/adddealer')
    }
   
  ]
},

{
  title: "Accounts",
  label: "Accounts",
  icon: <PersonOutlinedIcon />,
  items: [
    {
      label: "Manage Accounts",
      
      callback: (navigate) =>  navigate('/propertyowner/manageproperty')
    },
    {
      label: "Add Accounts",
      
      callback: (navigate) => navigate('/propertyowner/addproperty')
    }
   
  ]
},
{
  title: "More Options",
  label: "More Options",
  icon: <AdminPanelSettingsOutlinedIcon />,
  items: [
    {
      label: "Devices",
      
      callback: (navigate) => navigate('/extra/location')
    },
    {
      label: "Twilio Number",
      
      callback: (navigate) => navigate('/extra/twilio')
    },
    {
      label: "Features",
      
      callback: (navigate) => navigate('/extra/features')
    },
    {
      label: "System Logs",
      
      callback: (navigate) => navigate('/extra/systemlogs')
    },
    {
      label: "Global Alerts",
      
      callback: (navigate) => navigate('/extra/globalalert')
    },
    {
      label: "Super Admin Manager",
      
      callback: (navigate) => navigate('/extra/manager')
    },
    {
      label: "Role-Based Access Control",
      
      callback: (navigate) => navigate('/extra/roleaccess')
    },
    {
      label:'One-Time Code Override',
      
      callback: (navigate) => navigate('/extra/otpoverride')
    },
    {
      label:'Manage Sale Items',
      
      callback: (navigate) => navigate('/extra/saleitems')
    },
    {
      label:'Manage Promo Codes',
      
      callback: (navigate) => navigate('/extra/managepromo')
    },
    {
      label: "Order History",
      
      callback: (navigate) =>  navigate('/extra/manageorder')
    }
    
 
 ]
}
];


export const SideMenuDistributor = [{
  title: "Home",
  label: "Home",
  icon: <HomeOutlinedIcon />,
  items: [],
  callback: (navigate) =>  navigate('/')
},
{
  title: "Dealer",
  label: "Dealer",
  icon: <PersonOutlinedIcon />,
  items: [
    {
      label: "Manage Dealer",
      
      callback: (navigate) =>  navigate('/dealer/managedealer')
    },
    {
      label: "Add Dealer",
      
      callback: (navigate) => navigate('/dealer/adddealer')
    }
   
  ]
},

{
  title: "Accounts",
  label: "Accounts",
  icon: <PersonOutlinedIcon />,
  items: [
    {
      label: "View Accounts",
      
      callback: (navigate) =>  navigate('/property')
    }
   
  ]
},
{
  title: "Profile",
  label: "Profile",
  icon: <LocationOnOutlinedIcon />,
  items: [
    {
      label: "Profile",
      
      callback: (navigate) =>  navigate('/profile')
    }
   
  ]
},
{
  title: "Set Up",
  label: "Set Up",
  icon: <AdminPanelSettingsOutlinedIcon />,
  
  items: [
    {
      label: "Partner Manager",
      
      callback: (navigate) =>  navigate('/setup/manager')
    },
    {
      /*label: "Password",
      
      callback: (navigate) =>  navigate('/setup/password')*/
      label: "System Logs",
      
      callback: (navigate) =>  navigate('/setup/syslogs')
    }
  ]
}
,
{
  title: "Support",
  label: "Support",
  icon: <SupportAgentIcon />,
  items: [
    {
      label: "Support",
      
      callback: (navigate) =>  window.open("https://mydoorview.com/resources", '_blank')
    },
  ]
}
];

export const SideMenuDealer = [{
  title: "Home",
  label: "Home",
  icon: <HomeOutlinedIcon />,
  items: [],
  callback: (navigate) =>  navigate('/')
},


{
  title: "Accounts",
  label: "Accounts",
  icon: <PersonOutlinedIcon />,
  items: [
    {
      label: "Manage Accounts",
      
      callback: (navigate) =>  navigate('/property/manage')
    },
    {
      label: "Add Accounts",
      
      callback: (navigate) =>  navigate('/property/add')
    }
   
  ]
},
{
  title: "Profile",
  label: "Profile",
  icon: <LocationOnOutlinedIcon />,
  items: [
    {
      label: "Profile",
      
      callback: (navigate) =>  navigate('/profile')
    }
   
  ]
},
{
  title: "Set Up",
  label: "Set Up",
  icon: <AdminPanelSettingsOutlinedIcon />,
  
  items: [
    {
      label: "Dealer Manager",
      
      callback: (navigate) =>  navigate('/setup/manager')
    },
    {
     /* label: "Password",
      
      callback: (navigate) =>  navigate('/setup/password')*/

      label: "System Logs",
      
      callback: (navigate) =>  navigate('/setup/syslogs')
    },
    
   /* {

      label: "New Order",

      

      callback: (navigate) =>  navigate('/setup/neworder')

    },*/

    {

      label: "Order History",

      

      callback: (navigate) =>  navigate('/setup/manageorder')

    }
  ]
}
,
{
  title: "Support",
  label: "Support",
  icon: <SupportAgentIcon />,
  items: [
    {
      label: "Support",
      
      callback: (navigate) =>  window.open("https://mydoorview.com/resources", '_blank')
    },
  ]
}
];

export const SideMenuUser = [{
  title: "Home",
  label: "Home",
  icon: <HomeOutlinedIcon />,
  items: [],
  callback: (navigate) =>  navigate('/')
},
{
  title: "Activity",
  label: "Activity",
  icon: <StickyNote2OutlinedIcon />,
  items: [
    {
      label: "Entry Logs",
      
      callback: (navigate) =>  navigate('/activity/entrylogs')
    },
    {
      label: "Call Logs",
      
      callback: (navigate) => navigate('/activity/calllogs')
    },
    {
      label: "LP Tag Logs",
      
      callback: (navigate) => navigate('/activity/lptaglogs')
    },
  ]
},
{
  title: "Devices",
  label: "Devices",
  icon: <LocationOnOutlinedIcon/>,
  items: [

    {
      label: "User Devices",
      
      callback: (navigate) => navigate('/location')
    },
 
 ]
},

{
  title: "User",
  label: "User",
  icon: <PersonOutlinedIcon />,
  items: [

    {
      label: "Call Groups",
      
      callback: (navigate) => navigate('/user/callgroup')
    },
    {
      label: "License Plate",
      
      callback: (navigate) => navigate('/user/license')
    },
  {
    label: "Guest PIN",
    
    callback: (navigate) => navigate('/user/guestpin')
  },
  {
    label: "Guest Parking",
    
    callback: (navigate) => navigate('/user/guestparking')
  },
 ]
},
{
  title: "Profile",
  label: "Profile",
  icon: <AdminPanelSettingsOutlinedIcon />,
  
  items: [
    {
      label: "Profile",
      
      callback: (navigate) => navigate('/profile')
    },
  ]
}
];

export default [
    {
      path: 'home',
      label: 'HOME',
    },
    {
      path: 'activity',
      label: 'Activity',
    },
    {
      path: 'location',
      label: 'Devices',
    },
    {
      path: 'user',
      label: 'User',
    },
    {
      path: 'setup',
      label: 'SetUp',
    },
  ];